import React from "react";

const Faq = () => {
  return (
    <>
      <div className="page-header text-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
              <div className="page-title">
                <h1>Faq</h1>
              </div>
              {/* <div className="breadcrumbs">
                <a to="/" title="Back to the home page">
                  Home
                </a>
                <span className="title">
                  <i className="icon anm anm-angle-right-l" />
                  Pages
                </span>
                <span className="main-title fw-bold">
                  <i className="icon anm anm-angle-right-l" />
                  Information Page
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-content">
          <h3 style={{ color: "#1b59ba", fontWeight: "700", fontSize: "22px" }}>
            Elderwise Shopping India pvt Ltd{" "}
          </h3>

          <h3 style={{ textAlign: "center" }}>My Account & My Order</h3>

          <h3>How do I place Order?</h3>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Follow these steps to quickly place the order:
          </p>

          <p style={{ textAlign: "justify" }}>
            {" "}
            <b>1. </b> At Home Page (www.EWShopping.com) or Top Left corner you
            can explore the Categories. Select your favorite category and start
            adding the products to the cart with a single click.
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            <b>2. </b> After adding products, Click on “My Cart” and proceed to
            Checkout
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            <b>3. </b> You can Checkout by Creating an Account/Sign-in or via
            Guest User (Fill in Correct Shipping Details for Accurate Tracking
            of your order).
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            <b>4. </b> Click on “Continue to Payment”. Select your preferred
            Payment Gateway, Pay the Amount, and Confirm Your Order.
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            <b>5. </b> You will receive the Confirmation Email and SMS once the
            order is successfully placed.
          </p>

          <h3>How will I Receive My Order ?</h3>
          <p style={{ textAlign: "justify" }}>
            {" "}
            We will ship your order to your mentioned shipping address. Your
            order will be delivered to your doorstep by trusted courier
            services. All orders will be delivered in fully sealed packages to
            protect your goods and ensure that they reach you in perfect
            condition.
          </p>

          <ul className="">
            <h3>
              We'll send an email and SMS with your Order ID and Order Details.
              Also, once the order is shipped from the warehouse you will
              receive the tracking details via SMS and email.
            </h3>
            <p>
              I tried placing my order using my debit card/credit card/Net
              Banking. The order was not successful but my money got deducted.
              What really happened to my money?
            </p>
            <p>
              Don’t worry we will help you out in every possible way. We request
              you to check your bank/credit card account to first ensure if your
              account has been debited. If your amount has been debited after a
              payment failure, it will get automatically reversed back by the
              respective bank within 7-10 business days.
            </p>
            <h3 style={{ textAlign: "center" }}>
              Cancellation & Related Queries
            </h3>
            {/* <h3 style={{ textAlign: "center" }}>Order Cancellation</h3> */}
            <h3 >
              What is EWShopping’s Cancellation Policy?
            </h3>
            <li className="mb-1 ">
              You can cancel the order within 24 hours from the duration of
              placing the order
            </li>
            <li className="mb-1 ">
              {" "}
              You just have to raise a cancellation request for your order(s) by
              sharing us an email with your Order ID and reason for
              cancellation. Remember the cancellation request must be raised
              from the same email ID used while ordering on EWShopping. Send us
              the email at info@EWShopping.com
            </li>
            <li className="mb-1 ">
              {" "}
              We'll send you a confirmation mail along with the refund details
              to the registered e-mail address. Please Note: Once the order is
              shipped from the warehouse, it cannot be canceled. When the
              shipment is delivered and if the customer receives a faulty
              product then the customer can raise the return request.
            </li>
            <h3 style={{ textAlign: "center" }}>Payment Mode</h3>
            <h3>Order Cancellation</h3>
            <li className="mb-1 ">
              Themes in the Mobirise website builder offer multiple blocks:
              intros, sliders, galleries, forms, articles, and so on. Start a
              project and click on the red plus buttons to see the blocks
              available for your theme.
            </li>
            <li className="mb-1 ">
              You can have multiple pages in each project in Mobirise website
              builder software. Don't forget to set links to your pages after
              creating them. You can use menu blocks to create navigation for
              your site visitors.
            </li>
            <h3>Plesae Note :</h3>
            <p>
              EWShopping Team does not ask for your personal information or
              banking details over call or email. Please do not share the
              details with anyone. Only follow the secured checkout process on
              the Website.
            </p>
            <h3 style={{ textAlign: "center" }}>
              Why am I not getting any COD option on the payment page?
            </h3>
            <h3>
              The COD option might not be available because of the following
              reasons:
            </h3>
            <p>
              <b>1. </b>Our courier partners may not support the Cash on
              Delivery option for your delivery address.
            </p>
            <p>
              <b>2. </b>The products ordered are not available for the COD
              option.
            </p>
            <h3>Are there any hidden charges?</h3>
            <p>
              Start with creating a There are absolutely no hidden charges. You
              pay only the amount that you see in your order summary.All the
              products are inclusive of taxes.
            </p>
            <h3>What should I do if my payment fails?</h3>
            <p>
              <b>1. </b> Make sure you have entered the correct account details
              and the Internet connection is not disrupted during the payment
              process.
            </p>
            <p>
              <b>2. </b>In case your amount has been debited after a payment
              failure, it is automatically reversed back by the respective bank
              within 7-10 business days.
            </p>
            <h3 style={{ textAlign: "center" }}>Shipping & Tracking</h3>
            <h3>What is the Shipping Policy of EWShopping?</h3>
            <p>
              We personally ensure that every delivery from Being processed
              under excellent condition and in the fastest time. We take care of
              all parameters that ensure your delivery reaches you in a safe and
              sound state.
            </p>
            <h3>What is the delivery time period?</h3>
            <p>
              The tentative delivery period is 4-5 working days. However, the
              actual delivery time may vary due to unexpected circumstances
            </p>
            <h3>Do you apply any shipping charges?</h3>
            <p>
              We provide free shipping on all orders. If you choose the COD
              payment method, there are nominal cash collection charges i.e. Rs.
              50 for every product. For example, if your order includes three
              products, you will be levied a COD charge of Rs.150
            </p>
            <h3 style={{ textAlign: "center" }}>What is Return Policy?</h3>
            <p>
              EWShopping has 7 days Return Policy that gives you an option to
              return the product within 15 days from the delivery date under the
              circumstances mentioned below. We request you to not use the
              product and preserve its original condition, tags, and packaging
            </p>
            <b>Case 1</b>
            <h3>
              In case of any issue or defect in the product or Combo products
            </h3>
            <p>You need to raise a return/refund request</p>
            <p>
              We will create a reverse pickup. Further, you can place a request
              for exchange/refund at <b>info@EWShopping.com</b>During Pick Up,
              our delivery partner may do a quality check on the return. You
              need to send only the defective products.
              <b>Case 2</b>
              <h3>In case you have placed the wrong order</h3>
              <p>
                You need to self-ship the product(s) back to us. The Reverse
                pick-up charges will be bared by you. After the quality check,
                we will provide a replacement/refund for the same.
              </p>
            </p>
            <h3>Refund Policy</h3>
            <b>What are the terms and conditions for the refund?</b>
            <p>
              All refunds will be credited to your used payment method or bank
              account. If you are a guest user you will need to register with
              the email address which you had used to place your order. For any
              other requests or queries regarding this, kindly write to us at{" "}
              <b>info@beyoung.in</b>
            </p>
            <b>What are the rules for Courier charges refund?</b>
            <p>
              In case you receive a faulty product, we will create a reverse
              pick-up. There is no additional cost in this process. In case you
              need to return the product for exchange then you have to self-ship
              the product(s) back to us and bear the courier charges.
            </p>
            <h3>How long does the refund procedure take?</h3>
            <p>
              The refund procedure will be initiated within 24 hours once we
              receive your product with a valid reason for return and QC
              approval. Once initiated, the amount will be credited to your
              account in the next 7-10 working days
            </p>
            <h3>I haven't received my refund yet. What should I do?</h3>
            <p>
              We update our customers through emails once we initiate the refund
              procedure. If our last email states that we have initiated the
              refund procedure, kindly wait for 7-10 working days. For queries,
              you can write to us at <b>info@EWShopping.com</b>
            </p>
            <h3 style={{ textAlign: "center" }}>Miscellaneous</h3>
            <b>(Q) What to do if I forgot my password?.</b>
            <p>
              <b>(A) </b>Go to the “Log In” button then click on the 'Forgot
              Password' option and you can reset the password with the link sent
              on your registered email id
            </p>
            <h3>
              (Q) How can I return Custom T-shirt or Custom Mobile Cover?.
            </h3>
            <p>
              (A) There will be no replacement or refund for the custom
              products. However, if the product is faulty or damaged then the
              customer can proceed with the return request.
            </p>
            <p>
              (A)You are requested to check the size guide before placing the
              order. The color of the custom t-shirt and custom mobile cover may
              slightly vary as per the customer's screen resolution.
            </p>
            <b>(Q) How long does the refund procedure take?.</b>\
            <p>
              (A)The refund procedure will be initiated within 24 hours once we
              receive your product with a valid reason for return and QC
              approval. Once initiated, the amount will be credited to your
              account in the next 7-10 working days
            </p>
            <b>(Q) Do you accept bulk orders?.</b>
            <p>(A)Yes, we do accept bulk orders.</p>
            <b>
              (Q) I want to place an order but I don't want any price tag or
              invoice attached as it is a gift for someone. Is it possible.
            </b>
            <p>
              (A)Unfortunately no! Because of the prevalent regulations, we send
              the invoice along with the product.
            </p>
            <p>
              Tags are also intact so that a product can be replaced if your
              loved one faces any issues.
            </p>
            <b>(Q) Is there any trial then purchase option available?</b>
            <p>
              (A)There is no trial and buy option available. However, in order
              to assist you in picking the right size, we have a size chart with
              body measurements featured on our product pages. For any queries
              please write to us at <b>info@EWShopping.com</b>
            </p>
            <h3 style={{ textAlign: "center" }}>Wash Care instructions</h3>
            <b>Single Topwear (T-shirts, Vests, Tank Tops, T-Shirt Dresses)</b>
            <p>
              <b>1. </b>Do not bleach or wash with chlorine-based
              detergent/water; use a mild detergent in moderate quantity
              instead.
            </p>
            <p>
              <b>2. </b>Do not iron on stitches, seams, trims, or embellishments
              as high temperatures may cause them to open
            </p>
            <p>
              <b>3. </b> Do not soak for more than 20-30 minutes; prolonged
              soaking may lead to color loss, eventually weakening the fabric
            </p>
            <p>
              <b>4. </b> Do not wring
            </p>
            <p>
              <b>5. </b>Do not use hard brushes during hand wash as they may
              damage the prints{" "}
            </p>
            <p>
              <b>6. </b>Do not leave the iron on at one place for too long;
              especially at the neck rib where the elastane may break and loosen
              the rib
            </p>
            <p>
              <b>7. </b>Do not iron directly on prints (in case of printed
              products)
            </p>
            <p>
              <b>8. </b>Machine wash cold
            </p>
            <p>
              <b>9. </b>Wash/dry inside out
            </p>
            <p>
              <b>10. </b>Fabric subject to initial color loss; hence wash
              separately{" "}
            </p>
            <p>
              <b>11. </b>Tumble dry low
            </p>
            <p>
              <b>12. </b>Warm iron is needed.
            </p>
            <p>
              <b>13. </b>Dry promptly in shade; preferably on a flat surface as
              hanging may cause measurement variations{" "}
            </p>
          </ul>

          <h3 style={{ textAlign: "center" }}>
            OUR TOP CATEGORIES FOR ONLINE SHOPPING
          </h3>
          <p style={{ textAlign: "justify" }}>
            ELECTRONICS: Televisions | Speakers | Headphone & Earphones | Audio
            & Video | Winter Appliances | Washing Machines | Coolers | Fans |
            Grooming Appliances | Small Appliances
          </p>
          <p style={{ textAlign: "justify" }}>
            MOBILE, TABLETS: Mobile Cases & Covers | Mobile Phones | Tablets |
            Mobile Accessories | Wearables & Smartwatches | Tablet Accessories
          </p>
          <p style={{ textAlign: "justify" }}>
            Women's Fashion: Ethnic Wear | Footwear | Western Wear | Winter Wear
            | Handbags & Clutches | Eyewear | Fashion Jewellery | Watches |
            Precious Jewellery | Gold Coins & Bars | Fashion Accessories |
            Sunglasses
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Mens Fashion: Foot Wear | Bags & Luggage | Clothing | Winterwear |
            Sports Wear | Mens Grooming | Eye Wear | Watches | Jewellery &
            Cufflinks | Accessories | Fragrances
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Beauty Health: Beauty | Personal-Care-Grooming | Sexual Wellness |
            Food And Gourmet | Nutrition & Supplements | Health Monitoring
            Devices | General Wellness | Baby Care | Household Essentials
          </p>
          <p style={{ textAlign: "justify" }}>
            Toys Kids Fashion: Toys | Bicycles & Tricycles | Kids Footwear |
            Accessories | Kids Clothing | Girls Clothing | Boys Clothing | Baby
            Clothing | Baby Care | Stationery
          </p>
          <p style={{ textAlign: "justify" }}>
            Home Kitchen: Kitchen Appliances | Kitchenware | Home-Furnishing |
            Furniture | Home Decor | Home Improvement | Tools & Hardware | Pet
            Supplies
          </p>
          <p style={{ textAlign: "justify" }}>
            Sports Fitness Outdoor: Sports & Fitness | Get Fit At Home | Get
            Ready For Gym | Sports | Hiking & Outdoor
          </p>
          <p style={{ textAlign: "justify" }}>
            CAR MOTORBIKE: Automotive Accessories | Car & Vehicle Electronics |
            Car Audio Finder | Car Accessories | Tyre Inflators |Automotive |
            Car Fresheners | Air Purifiers & Ionizers | Biker Gear & Accessories
            | Biker Raincoats | Helmets & Accessories | Parts & Spares | Tyres &
            Alloys | Motorbike Batteries
          </p>
          <p style={{ textAlign: "justify" }}>
            Computers Gaming: Laptops | Printers & Inks | Networking Devices |
            Office Equipment | Storage | Computer Accessories | Desktops |
            Gaming | Computer Components | Software | Monitors | Top Laptop
            Brands
          </p>
          <p style={{ textAlign: "justify" }}>
            BOOKS MEDIA MUSIC: Top Exams| Browse By Genre | Online Education |
            Musical Instruments | Indian Instruments | Pa & Stage | Other
            Instruments | Hobbies New | E-Gift Cards | Physical Gift Cards |
            Digital Subscriptions | Movies & Tv Shows
          </p>
          <p style={{ textAlign: "justify" }}>
            Why Choose EWShopping as the Best Online Shopping Sites in India
          </p>
          <p style={{ textAlign: "justify" }}>
            Worldwide Shipping: Available as Standard or Express delivery
            EWShopping Promise: Products with this tag are quality checked, and
            shipped the same day from certified warehouses. So you get the right
            product, faster. Secure Payments: At EWShopping 100% Secure payment.
            If you have any issues, your money is immediately refunded. Sit back
            and enjoy your shopping. Free Return: Your money is yours! All
            refunds come with a question-asked guarantee for all orders. 24/7
            Dedicated support: Need Help? Click Here. You can also talk to us on
            -------- to resolve your query.
          </p>
          <p style={{ textAlign: "justify" }}>
            India's Best Online Shopping Website for Fashion & Lifestyle
          </p>
          <p style={{ textAlign: "justify" }}>
            EWShopping is the Best Site for Online shopping in India when it
            comes to online clothes shopping for men, women, and kids at the
            lowest prices. The latest fashion trends and styles are showcased
            here, yes at your favorite online shopping destination. Well, if
            fashion is medicine then Beyoung is the chemist shop where you can
            do your online shopping for men and women with ease. EWShopping is
            without a doubt one of the best online shopping sites in India. The
            website is replete with the cool products that you always crave.
            Indeed, online shopping for women, men & kids at Beyoung is such
            hassle-free that in just a few clicks one can purchase whatever he
            or she wants. A one-stop destination for all your shopping needs,
            Beyoung caters to each taste and need for men’s clothing, Women's
            Clothing, and accessories.
          </p>
          <p style={{ textAlign: "justify" }}>
            EWShopping is the Best Site for Online shopping in India when it
            comes to online clothes shopping for men, women, and kids at the
            lowest prices. The latest fashion trends and styles are showcased
            here, yes at your favorite online shopping destination. Well, if
            fashion is medicine then Beyoung is the chemist shop where you can
            do your online shopping for men and women with ease. EWShopping is
            without a doubt one of the best online shopping sites in India. The
            website is replete with the cool products that you always crave.
            Indeed, online shopping for women, men & kids at Beyoung is such
            hassle-free that in just a few clicks one can purchase whatever he
            or she wants. A one-stop destination for all your shopping needs,
            Beyoung caters to each taste and need for men’s clothing, Women's
            Clothing, and accessories.
          </p>
          <br />
        </div>
      </div>
      <div style={{ marginBottom: "50px" }}></div>
    </>
  );
};

export default Faq;


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    categoryTagTotal: localStorage.getItem("categoryTagTotal")
        ? JSON.parse(localStorage.getItem("categoryTagTotal"))
        : [],
    categoryTagShow: localStorage.getItem("categoryTagTotal")
        ? JSON.parse(localStorage.getItem("categoryTagTotal")).filter((data) => data.status === true).sort()
        : [],
    isCategoryTagAvailable: localStorage.getItem("categoryTagTotal")
        ? true
        : false,
    CategoryTagLoading: true,
};

export const getCategorytag = createAsyncThunk(
    "categorytag/getCategorytag",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/categorytag/allforadmin`;
            const resp = await axios(url);

            return resp.data.ctegoryTags;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);


export const validateSlugUrl = createAsyncThunk(
    "categorytag/validateSlugUrl",
    async (slugurl, thunkAPI) => {
        let resp = {
            success: false,
            message: "new email",
        };
        try {
            const url = `${Baseurl}/api/v1/categorytag/slugurl/${slugurl}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);




const CategoryTagSlice = createSlice({
    name: "categorytag",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder

            .addCase(getCategorytag.pending, (state) => {
                state.CategoryTagLoading = true;
            })
            .addCase(getCategorytag.fulfilled, (state, action) => {
                state.categoryTagTotal = action.payload;
                state.categoryTagShow = state.categoryTagTotal.filter((data) => data.status === true).sort();
                localStorage.setItem("categoryTagTotal", JSON.stringify(state.categoryTagTotal));
                state.CategoryTagLoading = false;
                state.isCategoryTagAvailable = true;
            })
            .addCase(getCategorytag.rejected, (state) => {
                state.CategoryTagLoading = true;
            });
    }

});

export const {
} = CategoryTagSlice.actions;
export default CategoryTagSlice.reducer;
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setQuickViewProduct } from "../../redux/product/productSlice";
import { addTocart } from "../../redux/cart/CartSlice";

const SingleProduct = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quickViewProduct } = useSelector((store) => store.product);
  const addTocartPress = async (
    e,
    packIndex,
    optionIndex,
    pack,
    packOption
  ) => {
    e.preventDefault();

    const cart_form = {
      ProductName: product.name,
      ProductId: product._id,
      PackId: pack._id,
      PackOptionId: packOption._id,
      packSizeName: pack.packSizeName,
      thumbnail: pack.thumbnail,
      packSizeOptionName: packOption.packSizeOptionName,
      Mrp: Number(packOption.Mrp),
      Price: Number(packOption.Price),
      Product_total_Mrp: Number(packOption.Mrp) * 1,
      Product_total_Price: Number(packOption.Price) * 1,
      Product_total_Saving:
        Number(packOption.Mrp) * 1 - Number(packOption.Price) * 1,
      Discount: Number(packOption.Discount),
      superCategory: product.superCategory,
      category: product.category,
      SubCategory: product.SubCategory,
      CategoryTag: product.CategoryTag,
      cart_Quentity: 1,
      shopId: product.shopId,
      shopName: product.shopName,
      // shopId: "EWSiD",
      // shopName: "EWS",
    };

    const cart = await dispatch(addTocart(cart_form));
  };
  return (
    <>
      <div className="item col-item" style={{ marginTop: "-20px" }}>
        {product.packSize.map((pack, packIndex) =>
          pack.PsPrime === true ? (
            <>
              <div
                className="product-box"
                key={packIndex}
                style={{ marginLeft: "12px", marginBottom: "10px" }}
              >
                <div className="product-image" style={{marginBottom: "0px"}}>
                  <Link
                    to={`/product-info/${product.slugUrl}`}
                    className="product-img rounded-0"
                  >
                    <img
                      className="primary blur-up lazyload"
                      data-src={pack.thumbnail}
                      src={pack.thumbnail}
                      alt="Product"
                      title="Product"
                      width={625}
                      height={625}
                    />
                    {/* End Image */}
                    {/* Hover Image */}
                    <img
                      className="hover blur-up lazyload"
                      data-src={pack.thumbnail}
                      src={pack.thumbnail}
                      alt="Product"
                      title="Product"
                      width={625}
                      height={625}
                    />
                  </Link>
                  {pack.PackSizeOptions.map((packOption, optionIndex) =>
                    packOption.PsnPrime === true ? (
                      <>
                        <div className="product-labels" key={optionIndex}>
                          <span className="lbl on-sale">
                            {packOption.Discount}% Off
                          </span>
                        </div>

                        <div className="product-availability rounded-5">
                          <div className="lh-1 d-flex justify-content-between">
                            <div className="text-sold">
                              Sold:
                              <strong className="text-primary ms-1">
                                {packOption.maximumQuantity}
                              </strong>
                            </div>
                            <div className="text-available">
                              Available:
                              <strong className="text-primary ms-1">
                                {packOption.availablestock}
                              </strong>
                            </div>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar w-100"
                              role="progressbar"
                              aria-valuenow={packOption.availablestock}
                              aria-valuemin={0}
                              aria-valuemax={packOption.maximumQuantity}
                            />
                          </div>
                        </div>

                        <div className="button-set style1">
                          <span
                            to="#addtocart-modal"
                            className="btn-icon addtocart add-to-cart-modal"
                            data-bs-toggle="modal"
                            data-bs-target="#addtocart_modal"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              dispatch(setQuickViewProduct(product));
                              addTocartPress(
                                e,
                                packIndex,
                                optionIndex,
                                pack,
                                packOption
                              );
                            }}
                          >
                            <span
                              className="icon-wrap d-flex-justify-center h-100 w-100"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Add to Cart"
                            >
                              <i className="icon anm anm-cart-l" />
                              <span className="text">Add to Cart</span>
                            </span>
                          </span>
                          <span
                            to="#quickview-modal"
                            className="btn-icon quickview quick-view-modal"
                            data-bs-toggle="modal"
                            data-bs-target="#quickview_modal"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              dispatch(setQuickViewProduct(product))
                            }
                          >
                            <span
                              className="icon-wrap d-flex-justify-center h-100 w-100"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Quick View"
                            >
                              <i className="icon anm anm-search-plus-l" />
                              <span className="text">Quick View</span>
                            </span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={optionIndex}></div>
                      </>
                    )
                  )}
                </div>
                {pack.PackSizeOptions.map((packOption, optionIndex) =>
                  packOption.PsnPrime === true ? (
                    <>
                      <div
                        className="product-details text-left"
                        key={optionIndex}
                      >
                        <div className="product-vendor">
                          {product.brand}
                        </div>

                        <div className="product-name limited-paragraph">
                          <Link to={`/product-info/${product.slugUrl}`}>
                            {product.name}
                          </Link>
                        </div>

                        <div
                          style={{ display: "flex" }}
                          className="product-price"
                        >
                          <span className="price">
                            <b>₹{packOption.Price}</b>
                          </span>
                          <span
                            className="price old-price"
                            style={{ paddingLeft: "10px" }}
                          >
                            ₹{packOption.Mrp}
                          </span>
                        </div>

                        <p className="sort-desc hidden">
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration
                          in some form, by injected humour, or randomised words
                          which don't look even slightly believable. If you are
                          going to use a passage...
                        </p>
                        <div className="button-action hidden">
                          <div className="addtocart-btn">
                            <form
                              className="addtocart"
                              action="#"
                              method="post"
                            >
                              <Link
                                to="#addtocart-modal"
                                className="btn btn-md add-to-cart-modal"
                                data-bs-toggle="modal"
                                data-bs-target="#addtocart_modal"
                              >
                                <i className="icon anm anm-cart-l me-2" />
                                <span className="text">Add to Cart</span>
                              </Link>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div key={optionIndex}></div>
                    </>
                  )
                )}
              </div>
            </>
          ) : (
            <>
              <div key={packIndex}></div>
            </>
          )
        )}
      </div>
      <div
        className="addtocart-modal modal fade"
        id="addtocart_modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <form
                id="product-form-addtocart"
                className="product-form align-items-center"
              >
                <h3 className="title mb-3 text-success text-center">
                  Added to cart Successfully!
                </h3>
                {quickViewProduct !== "" &&
                  quickViewProduct.packSize.map((pack, packIndex) =>
                    pack.PsPrime === true ? (
                      <>
                        {pack.PackSizeOptions.map((packOption, optionIndex) =>
                          packOption.PsnPrime === true ? (
                            <>
                              <div
                                className="row d-flex-center text-center"
                                key={optionIndex}
                              >
                                <div className="col-md-6">
                                  <Link className="product-image" to="">
                                    <img
                                      className="primary blur-up lazyload"
                                      data-src={pack.thumbnail}
                                      src={pack.thumbnail}
                                      alt="Product"
                                      title="Product"
                                      width={625}
                                      height={625}
                                    />
                                    {/* End Image */}
                                    {/* Hover Image */}
                                    <img
                                      className="hover blur-up lazyload"
                                      data-src={pack.thumbnail}
                                      src={pack.thumbnail}
                                      alt="Product"
                                      title="Product"
                                      width={625}
                                      height={625}
                                    />
                                  </Link>
                                </div>
                                <div className="col-md-6 mt-3 mt-md-0">
                                  <div className="product-details">
                                    <Link className="product-title" to="">
                                      <Link
                                        to={`/product-info/${quickViewProduct.slugUrl}`}
                                      >
                                        {quickViewProduct.name}
                                      </Link>
                                    </Link>
                                    <p className="product-clr my-2 text-muted">
                                      Black / XL
                                    </p>
                                  </div>
                                  <div className="addcart-total rounded-5">
                                    <p className="product-items mb-2">
                                      There are <strong>1</strong> items in your
                                      cart
                                    </p>
                                    <p className="d-flex-justify-center">
                                      Total:{" "}
                                      <span className="price">
                                        ₹{packOption.Price}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="product-form-submit d-flex-justify-center">
                                    <span
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      className="btn btn-outline-primary product-continue w-100"
                                    >
                                      Continue Shopping
                                    </span>
                                    <span
                                      onClick={() => navigate("/cart")}
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      className="btn btn-secondary product-viewcart w-100 my-2 my-md-3"
                                    >
                                      View Cart
                                    </span>
                                    <span
                                      onClick={() => navigate("/check-out")}
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      className="btn btn-primary product-checkout w-100"
                                    >
                                      Proceed to checkout
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div key={optionIndex}></div>
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        <div key={packIndex}></div>
                      </>
                    )
                  )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="quickview-modal modal fade"
        id="quickview_modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              {quickViewProduct !== "" &&
                quickViewProduct.packSize.map((pack, packIndex) =>
                  pack.PsPrime === true ? (
                    <>
                      <div className="row" key={packIndex}>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3 mb-md-0">
                          <div id="quickView" className="carousel slide">
                            <div className="carousel-inner">
                              {pack.slider.map((image, imageIndex) => (
                                <div
                                  className="item carousel-item active"
                                  data-bs-slide-number={Number(imageIndex)}
                                  key={imageIndex}
                                >
                                  <img
                                    className="primary blur-up lazyload"
                                    data-src={pack.thumbnail}
                                    src={pack.thumbnail}
                                    alt="Product"
                                    title="Product"
                                    width={625}
                                    height={625}
                                  />
                                  {/* End Image */}
                                  {/* Hover Image */}
                                  <img
                                    className="hover blur-up lazyload"
                                    data-src={pack.thumbnail}
                                    src={pack.thumbnail}
                                    alt="Product"
                                    title="Product"
                                    width={625}
                                    height={625}
                                  />
                                </div>
                              ))}
                            </div>
                            {/* End Image Slide carousel items */}
                            {/* Thumbnail image */}
                            <div className="model-thumbnail-img">
                              {/* Thumbnail slide */}
                              <div className="carousel-indicators list-inline">
                                {pack.slider.map((image, imageIndex) => (
                                  <div
                                    className="list-inline-item active"
                                    id="carousel-selector-0"
                                    data-bs-slide-to={Number(imageIndex)}
                                    data-bs-target="#quickView"
                                    key={imageIndex}
                                  >
                                    <img
                                      className="primary blur-up lazyload"
                                      data-src={pack.thumbnail}
                                      src={pack.thumbnail}
                                      alt="Product"
                                      title="Product"
                                      width={625}
                                      height={625}
                                    />
                                    {/* End Image */}
                                    {/* Hover Image */}
                                    <img
                                      className="hover blur-up lazyload"
                                      data-src={pack.thumbnail}
                                      src={pack.thumbnail}
                                      alt="Product"
                                      title="Product"
                                      width={625}
                                      height={625}
                                    />
                                  </div>
                                ))}
                              </div>
                              <Link
                                className="carousel-control-prev carousel-arrow rounded-1"
                                to="#quickView"
                                data-bs-target="#quickView"
                                data-bs-slide="prev"
                              >
                                <i className="icon anm anm-angle-left-r" />
                              </Link>
                              <Link
                                className="carousel-control-next carousel-arrow rounded-1"
                                to="#quickView"
                                data-bs-target="#quickView"
                                data-bs-slide="next"
                              >
                                <i className="icon anm anm-angle-right-r" />
                              </Link>
                            </div>
                          </div>
                          <div className="text-center mt-3">
                            {/* <Link to="" className="text-link">
                                View More Details
                              </Link> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                          <div className="product-arrow d-flex justify-content-between">
                            <h2 className="product-title">
                              {quickViewProduct.name}
                            </h2>
                          </div>
                          <div className="product-review d-flex mt-0 mb-2">
                            <div className="rating">
                              <i className="icon anm anm-star" />
                              <i className="icon anm anm-star" />
                              <i className="icon anm anm-star" />
                              <i className="icon anm anm-star" />
                              <i className="icon anm anm-star-o" />
                            </div>
                            <div className="reviews ms-2">
                              <Link to="#">6 Reviews</Link>
                            </div>
                          </div>
                          <div className="product-info">
                            <p className="product-vendor">
                              Vendor:
                              <span className="text">
                                <Link to="#">Sparx</Link>
                              </span>
                            </p>
                            <p className="product-type">
                              Product Type:
                              <span className="text">
                                {quickViewProduct.CategoryTag}
                              </span>
                            </p>
                            <p className="product-sku">
                              SKU:<span className="text">RF104456</span>
                            </p>
                          </div>
                          {pack.PackSizeOptions.map((packOption, optionIndex) =>
                            packOption.PsnPrime === true ? (
                              <>
                                <div
                                  className="pro-stockLbl my-2"
                                  key={optionIndex}
                                >
                                  <span className="d-flex-center stockLbl instock d-none">
                                    <i className="icon anm anm-check-cil" />
                                    <span> In stock</span>
                                  </span>
                                  <span className="d-flex-center stockLbl preorder d-none">
                                    <i className="icon anm anm-clock-r" />
                                    <span> Pre-order Now</span>
                                  </span>
                                  <span className="d-flex-center stockLbl outstock d-none">
                                    <i className="icon anm anm-times-cil" />{" "}
                                    <span>Sold out</span>
                                  </span>
                                  <span
                                    className="d-flex-center stockLbl lowstock"
                                    data-qty={15}
                                  >
                                    <i className="icon anm anm-exclamation-cir" />
                                    <span>
                                      {" "}
                                      Order now, Only{" "}
                                      <span className="items">
                                        {packOption.availablestock}
                                      </span>{" "}
                                      left!
                                    </span>
                                  </span>
                                </div>
                                <div className="product-price d-flex-center my-3">
                                  <span className="price old-price">
                                    ₹{packOption.Mrp}
                                  </span>
                                  <span className="price">
                                    ₹{packOption.Price}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                {" "}
                                <div key={optionIndex}></div>
                              </>
                            )
                          )}
                          <form
                            method="post"
                            action="#"
                            id="product_form--option"
                            className="product-form"
                          >
                            <div className="product-options d-flex-wrap">
                            </div>
                          </form>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div key={packIndex}></div>
                    </>
                  )
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProduct;

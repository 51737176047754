import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  add_Address,
  set_checkout_process_steps,
  update_Address,
} from "../../../redux/athentication/Athentication";
import axios from "axios";

const AddAddress = () => {
  const dispatch = useDispatch();
  const { loginData, current_address } = useSelector(
    (state) => state.Athentication
  );

  const [Mobile, setMobile] = useState("");
  const [MobileError, setMobileError] = useState("");
  const [HNo, setHNo] = useState("");
  const [HNoerror, setHNoError] = useState("");
  const [Area, setArea] = useState("");
  const [Areaerror, setAreaError] = useState("");
  const [sub_locality, setsub_locality] = useState("");
  const [sub_localityerror, setsub_localityError] = useState("");
  const [locality, setlocality] = useState("");
  const [localityerror, setlocalityError] = useState("");
  const [LandMark, setLandMark] = useState("");
  const [LandMarkerror, setLandMarkError] = useState("");
  const [City, setCity] = useState("");
  const [Cityerror, setCityError] = useState("");
  const [State, setState] = useState("");
  const [Stateerror, setStateError] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Pincodeerror, setPincodeError] = useState("");
  const [Type, setType] = useState("Home");

  const [modelvisible, setModelVisible] = useState(true)

  const [btnLoading, setBtnLoading] = useState(false);

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    address: {
      city: "",
      city_district: "",
      country: "",
      county: "",
      neighbourhood: "",
      postcode: "",
      state: "",
      state_district: "",
      suburb: "",
    },
    error: null,
  });

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation((prevState) => ({
            ...prevState,
            latitude,
            longitude,
            error: null,
          }));
          getAddress(latitude, longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            alert("Please allow location access in your browser settings.");
          }
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          });
        },
        { enableHighAccuracy: true }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  };


  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setLocation((prevState) => ({
  //           ...prevState,
  //           latitude,
  //           longitude,
  //           error: null,
  //         }));
  //         getAddress(latitude, longitude); // Only call if location is available
  //       },
  //       (error) => {
  //         setLocation({
  //           latitude: null,
  //           longitude: null,
  //           error: error.message,
  //         });

  //         if (error.code === error.PERMISSION_DENIED) {
  //           alert("Location access is blocked. Please enable it in your browser settings.");
  //         }
  //       },
  //       { enableHighAccuracy: true }
  //     );
  //   } else {
  //     setLocation({
  //       latitude: null,
  //       longitude: null,
  //       error: "Geolocation is not supported by this browser.",
  //     });
  //   }
  // };

  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(showPosition, showError)
  //   } else {
  //     alert("Geolocation is not supported by this browser")
  //   }
  // }
  // const showPosition = (position) => {
  //   const { latitude, longitude } = position.coords;
  //   setLocation((prevState) => ({
  //     ...prevState,
  //     latitude,
  //     longitude,
  //     error: null,
  //   }));
  //   getAddress(latitude, longitude);
  // }
  // const showError = (error) => {
  //   switch (error.code) {
  //     case error.PERMISSION_DENIED:
  //       alert("user denied the request for geolocation")
  //       break;
  //     case error.POSITION_UNAVAILABLE:
  //       alert("location information is unavaible")
  //       break;
  //     case error.TIMEOUT:
  //       alert("the request to get user location timed out ")
  //       break;
  //     case error.UNKNOWN_ERROR:
  //       alert("An unknown  error occurred")
  //       break;

        
  //       default:
  //         alert("An unknown error occcured")
  //   }


  // }


  const getAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
      );
      // const address = {...response.data.address}
      // || 'Address not found';
      const address = {
        city: response.data.address.city,
        city_district: response.data.address.city_district,
        country: response.data.address.country,
        neighbourhood: response.data.address.neighbourhood,
        postcode: response.data.address.postcode,
        state: response.data.address.state,
        state_district: response.data.address.state_district,
        suburb: response.data.address.suburb,
        county: response.data.address.county,
      };
      setLocation((prevState) => ({
        ...prevState,
        address,
      }));
    } catch (error) {
      setLocation((prevState) => ({
        ...prevState,
        address: "Unable to fetch address",
        error: error.message,
      }));
    }
  };

  useEffect(() => {
    setHNo(location.address.neighbourhood);
    setArea(location.address.suburb);
    setCity(location.address.city);
    setState(location.address.state);
    setPincode(location.address.postcode);
    setlocality(location.address.county);
    setsub_locality(location.address.city_district);
  }, [location]);

  useEffect(() => {
    if (btnLoading === true) {
      if (Mobile.length !== 10) {
        setMobileError("Required");
      } else {
        setMobileError("");
      }
      if (HNo === "") {
        setHNoError("Required");
      } else {
        setHNoError("");
      }
      if (Area === "") {
        setAreaError("Required");
      } else {
        setAreaError("");
      }
      if (sub_locality === "") {
        setsub_localityError("Required");
      } else {
        setsub_localityError("");
      }
      if (locality === "") {
        setlocalityError("Required");
      } else {
        setlocalityError("");
      }
      if (City === "") {
        setCityError("Required");
      } else {
        setCityError("");
      }
      if (State === "") {
        setStateError("Required");
      } else {
        setStateError("");
      }
      if (Pincode.length !== 6) {
        setPincodeError("Required");
      } else {
        setPincodeError("");
      }
    }
  }, [
    btnLoading,
    Mobile,
    HNo,
    Area,
    sub_locality,
    locality,
    City,
    State,
    Pincode,
  ]);

  const add_address = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (Mobile.length !== 10) {
      setMobileError("Required");
    } else {
      setMobileError("");
    }

    if (HNo === "") {
      setHNoError("Required");
    } else {
      setHNoError("");
    }
    if (Area === "") {
      setAreaError("Required");
    } else {
      setAreaError("");
    }
    if (sub_locality === "") {
      setsub_localityError("Required");
    } else {
      setsub_localityError("");
    }
    if (locality === "") {
      setlocalityError("Required");
    } else {
      setlocalityError("");
    }

    if (City === "") {
      setCityError("Required");
    } else {
      setCityError("");
    }
    if (State === "") {
      setStateError("Required");
    } else {
      setStateError("");
    }
    if (Pincode.length !== 6) {
      setPincodeError("Required");
    } else {
      setPincodeError("");
    }

    if (
      Pincode.length === 6 &&
      Mobile.length === 10 &&
      HNo !== "" &&
      Area !== "" &&
      locality !== "" &&
      City !== "" &&
      State !== "" &&
      sub_locality !== ""
    ) {
      const form_data = {
        Name: loginData.Name,
        Mobile: Mobile,
        Alternative_Mobile: loginData.Mobile,
        ClientId: loginData._id,
        HNo: HNo,
        Area: Area,
        sub_locality: sub_locality,
        locality: locality,
        LandMark: LandMark,
        City: City,
        State: State,
        Pincode: Pincode,
        Type: Type,
        LandMark: LandMark,
        prime: true,
      };

      const add_address_post = await dispatch(add_Address(form_data));




      if (add_address_post.payload.success) {
        const client = add_address_post.payload.client;
        const data_address = client.Addresses;
        await dispatch(update_Address(data_address));
        setMobile("");
        setHNo("");
        setArea("");
        setsub_locality("");
        setlocality("");
        setLandMark("");
        setCity("");
        setState("");
        setPincode("");
        setBtnLoading(false);

      }
    }
  };
  // const navigate =   useNavigate()
  //   const handleAddAddres = (e) => {
  //     add_address(e)
  //     navigate('/my-account')
  //   }
  return (
    <>
      <div className="tab-pane active" id="steps2">
        <div className="block shipping-address mb-4">
          <div className="block-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <div
                className="title"
                style={{ fontSize: "18px", fontWeight: "600" }}
              >
                Your Address
              </div>
              <button
                style={{
                  backgroundColor: "#f1c40f",
                  padding: "5px 10px",
                  borderRadius: "10px",

                }}
                onClick={() => getLocation()}
              >
                Add Current Location
              </button>
            </div>
            <fieldset>
              <div className="row">
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="firstname"
                    className="form-label mobile_start_add"
                  >
                    Name
                  </label>
                  <input
                    name="firstname"
                    id="firstname"
                    type="text"
                    className="form-control name_control_add1"
                    value={loginData.Name}
                    disabled={true}
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="lastname"
                    className="form-label mobile_start_add"
                  >
                    Alternative Mobile <span className="required">*</span>
                  </label>
                  <input
                    name="lastname"
                    id="lastname"
                    type="text"
                    placeholder="Enter Mobile Number"
                    className={
                      MobileError !== ""
                        ? "form-control error_show name_control_add1"
                        : "form-control name_control_add1"
                    }
                    minLength={10}
                    maxLength={10}
                    value={Mobile}
                    onChange={(e) =>
                      setMobile(e.target.value.replace(/\D/g, ""))
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="address-1"
                    className="form-label mobile_start_add"
                  >
                    Mobile
                  </label>
                  <input
                    name="address_1"
                    id="address-1"
                    type="text"
                    placeholder="Alternative Mobile"
                    value={loginData.Mobile}
                    disabled={true}
                    className="name_control_add1"
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="address-1"
                    className="form-label mobile_start_add"
                  >
                    HNo <span className="required">*</span>
                  </label>
                  <input
                    name="address_1"
                    id="address-1"
                    type="text"
                    placeholder="Street address"
                    value={HNo}
                    onChange={(e) => setHNo(e.target.value)}
                    className={
                      HNoerror !== ""
                        ? "form-control error_show name_control_add1"
                        : "form-control name_control_add1"
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="address-1"
                    className="form-label mobile_start_add"
                  >
                    Area <span className="required">*</span>
                  </label>
                  <input
                    name="address_1"
                    id="address-1"
                    type="text"
                    placeholder="Area"
                    value={Area}
                    onChange={(e) => setArea(e.target.value)}
                    className={
                      Areaerror !== ""
                        ? "form-control error_show name_control_add1"
                        : "form-control name_control_add1"
                    }
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="address-1"
                    className="form-label mobile_start_add"
                  >
                    sub locality <span className="required">*</span>
                  </label>
                  <input
                    name="address_1"
                    id="address-1"
                    type="text"
                    placeholder="sub locality"
                    value={sub_locality}
                    onChange={(e) => setsub_locality(e.target.value)}
                    className={
                      sub_localityerror !== ""
                        ? "form-control error_show name_control_add1"
                        : "form-control name_control_add1"
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="address-1"
                    className="form-label mobile_start_add"
                  >
                    locality <span className="required">*</span>
                  </label>
                  <input
                    name="address_1"
                    id="address-1"
                    type="text"
                    placeholder="locality"
                    value={locality}
                    onChange={(e) => setlocality(e.target.value)}
                    className={
                      localityerror !== ""
                        ? "form-control error_show name_control_add1"
                        : "form-control name_control_add1"
                    }
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="address-1"
                    className="form-label mobile_start_add"
                  >
                    LandMark
                  </label>
                  <input
                    name="address_1"
                    id="address-1"
                    type="text"
                    placeholder="LandMark"
                    value={LandMark}
                    onChange={(e) => setLandMark(e.target.value)}
                    className={
                      LandMarkerror !== ""
                        ? "form-control error_show name_control_add1"
                        : "form-control name_control_add1"
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="address-1"
                    className="form-label mobile_start_add"
                  >
                    City <span className="required">*</span>
                  </label>
                  <input
                    name="address_1"
                    id="address-1"
                    type="text"
                    placeholder="City"
                    value={City}
                    onChange={(e) => setCity(e.target.value)}
                    className={
                      Cityerror !== ""
                        ? "form-control error_show name_control_add1"
                        : "form-control name_control_add1"
                    }
                  />
                </div>
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="address-1"
                    className="form-label mobile_start_add"
                  >
                    State <span className="required">*</span>
                  </label>
                  <input
                    name="address_1"
                    id="address-1"
                    type="text"
                    placeholder="State"
                    value={State}
                    onChange={(e) => setState(e.target.value)}
                    className={
                      Stateerror !== ""
                        ? "form-control error_show name_control_add1"
                        : "form-control name_control_add1"
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                  <label
                    htmlFor="address-1"
                    className="form-label mobile_start_add"
                  >
                    Pincode <span className="required">*</span>
                  </label>
                  <input
                    name="address_1"
                    id="address-1"
                    type="text"
                    placeholder="Pincode"
                    value={Pincode}
                    minLength={6}
                    maxLength={6}
                    onChange={(e) =>
                      setPincode(e.target.value.replace(/\D/g, ""))
                    }
                    className={
                      Pincodeerror !== ""
                        ? "form-control error_show name_control_add1"
                        : "form-control name_control_add1"
                    }
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div
                  className="form-group col-md-12 col-lg-12 mb-0"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="checkout-tearm customCheckbox"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      id="checkout_tearm"
                      name="tearm"
                      type="radio"
                      defaultValue="checkout tearm"
                      value={"Home"}
                      checked={Type === "Home"}
                      onChange={() => setType("Home")}
                    />
                    <label htmlFor="checkout_tearm">Home</label>
                  </div>
                  <div
                    className="checkout-tearm customCheckbox"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      id="checkout_tearm"
                      name="tearm"
                      type="radio"
                      defaultValue="checkout tearm"
                      value={Type}
                      checked={Type === "Office"}
                      onChange={() => setType("Office")}
                    />
                    <label htmlFor="checkout_tearm">Office</label>
                  </div>
                  <div
                    // className="checkout-tearm customCheckbox"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      id="checkout_tearm"
                      name="tearm"
                      type="radio"
                      defaultValue="checkout tearm"
                      value={Type}
                      onChange={() => setType("Others")}
                      checked={Type === "Others"}
                    />
                    <label htmlFor="checkout_tearm">Others</label>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button
                  style={{
                    visibility: "hidden",
                  }}
                >
                  {/* Back to USER AUTHENTICATion */}
                </button>
                <button
                  type="button"
                  onClick={(e) => add_address(e)}
                  className="btn btn-secondary btnPrevious me-1 mb-4"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Add address
                </button>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAddress;

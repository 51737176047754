import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    add_Address,
    set_checkout_process_steps,
    updateAddress,
    update_Address,
} from "../../../redux/athentication/Athentication";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";

const UpdateAddress = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { loginData, current_address } = useSelector(
        (state) => state.Athentication
    );



    const [Mobile, setMobile] = useState("");
    const [MobileError, setMobileError] = useState("");
    const [HNo, setHNo] = useState("");
    const [HNoerror, setHNoError] = useState("");
    const [Area, setArea] = useState("");
    const [Areaerror, setAreaError] = useState("");
    const [sub_locality, setsub_locality] = useState("");
    const [sub_localityerror, setsub_localityError] = useState("");
    const [locality, setlocality] = useState("");
    const [localityerror, setlocalityError] = useState("");
    const [LandMark, setLandMark] = useState("");
    const [LandMarkerror, setLandMarkError] = useState("");
    const [City, setCity] = useState("");
    const [Cityerror, setCityError] = useState("");
    const [State, setState] = useState("");
    const [Stateerror, setStateError] = useState("");
    const [Pincode, setPincode] = useState("");
    // console.log(Pincode ,"Pincode");
    //  console.log(typeof(Pincode) ,"pincode");


    const [Pincodeerror, setPincodeError] = useState("");
    const [Type, setType] = useState("Home");

    const [btnLoading, setBtnLoading] = useState(false);

    const params = useParams()

    useEffect(() => {
        const getAddress = async () => {
            const url = `${Baseurl}/api/v1/client/getclientsingleaddress/${loginData._id}/${params._id}`;
            const resp = await axios.get(url);
            // console.log(resp.data ,"resp.data");

            if (resp.data.success) {
                setMobile(resp.data.address.Mobile)
                setHNo(resp.data.address.HNo);
                setArea(resp.data.address.Area);
                setsub_locality(resp.data.address.sub_locality);
                setlocality(resp.data.address.locality);
                setLandMark(resp.data.address.LandMark);
                setCity(resp.data.address.City);
                setState(resp.data.address.State);
                setPincode(resp.data.address.Pincode);
                setType(resp.data.address.Type);
            }
        }
        getAddress()
    }, [params._id])

    useEffect(() => {
        if (btnLoading === true) {
            if (Mobile.length !== 10) {
                setMobileError("Required");
            } else {
                setMobileError("");
            }

            if (HNo === "") {
                setHNoError("Required");
            } else {
                setHNoError("");
            }
            if (Area === "") {
                setAreaError("Required");
            } else {
                setAreaError("");
            }
            if (sub_locality === "") {
                setsub_localityError("Required");
            } else {
                setsub_localityError("");
            }
            if (locality === "") {
                setlocalityError("Required");
            } else {
                setlocalityError("");
            }

            if (City === "") {
                setCityError("Required");
            } else {
                setCityError("");
            }
            if (State === "") {
                setStateError("Required");
            } else {
                setStateError("");
            }
            if (Pincode.toString().length !== 6) {
                setPincodeError("Required");
            } else {
                setPincodeError("");
            }
        }
    }, [
        btnLoading,
        Mobile,
        HNo,
        Area,
        sub_locality,
        locality,
        City,
        State,
        Pincode,
    ]);

    const add_address = async (e) => {
        e.preventDefault();
        setBtnLoading(true);
        if (Mobile.length !== 10) {
            setMobileError("Required");
        } else {
            setMobileError("");
        }

        if (HNo === "") {
            setHNoError("Required");
        } else {
            setHNoError("");
        }
        if (Area === "") {
            setAreaError("Required");
        } else {
            setAreaError("");
        }
        if (sub_locality === "") {
            setsub_localityError("Required");
        } else {
            setsub_localityError("");
        }
        if (locality === "") {
            setlocalityError("Required");
        } else {
            setlocalityError("");
        }

        if (City === "") {
            setCityError("Required");
        } else {
            setCityError("");
        }
        if (State === "") {
            setStateError("Required");
        } else {
            setStateError("");
        }
        if (Pincode.toString().length !== 6) {
            setPincodeError("Required");
        } else {
            setPincodeError("");
        }



        if (
            Pincode.toString().length === 6 &&
            Mobile.length === 10 &&
            HNo !== "" &&
            Area !== "" &&
            locality !== "" &&
            City !== "" &&
            State !== "" &&
            sub_locality !== ""
        ) {
            const form_data = {
                Name: loginData.Name,
                Mobile: Mobile,
                Alternative_Mobile: loginData.Mobile,
                HNo: HNo,
                Area: Area,
                sub_locality: sub_locality,
                locality: locality,
                LandMark: LandMark,
                City: City,
                State: State,
                country: "india",
                Pincode: Pincode,
                Type: Type,
                prime: true,
                ClientId: loginData._id,
                addressId: params._id
            };

            const add_address_post = await dispatch(updateAddress(form_data));

            if (add_address_post.payload.success) {
                alert('Address Updated');

                const client = add_address_post.payload.client;
                const data_address = client.Addresses;

                await dispatch(update_Address(data_address));

                setMobile("");
                setHNo("");
                setArea("");
                setsub_locality("");
                setlocality("");
                setLandMark("");
                setCity("");
                setState("");
                setPincode("");
                setBtnLoading(false);
                navigate('/my-account')
            }
        }
    };

    return (
        <>
            <div className="container mt-4" >
                <div className="togivegap" >
                    <div className="block-content">
                        <h3 className="title mb-3">Update Address</h3>

                        <div className="updatedaddressss">
                            <fieldset>
                                <div className="row">
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="firstname" className="form-label mobile_start_add">
                                            Name
                                        </label>
                                        <input
                                            name="firstname"
                                            id="firstname"
                                            type="text"
                                            className="form-control number_input_mob"
                                            value={loginData.Name}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="lastname" className="form-label mobile_start_add">
                                            Mobile <span className="required">*</span>
                                        </label>
                                        <input
                                            name="lastname"
                                            id="lastname"
                                            type="text"
                                            className={
                                                MobileError !== ""
                                                    ? "form-control error_show number_input_mob"
                                                    : "form-control number_input_mob"
                                            }
                                            minLength={10}
                                            maxLength={10}
                                            value={Mobile}
                                            onChange={(e) =>
                                                setMobile(e.target.value.replace(/\D/g, ""))
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="address-1" className="form-label mobile_start_add">
                                            Alternative Mobile
                                        </label>
                                        <input
                                            name="address_1"
                                            id="address-1"
                                            type="text"
                                            placeholder="Alternative Mobile"
                                            value={loginData.Mobile}
                                            disabled={true}
                                            className="number_input_mob"
                                        />
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="address-1" className="form-label mobile_start_add">
                                            HNo <span className="required">*</span>
                                        </label>
                                        <input
                                            name="address_1"
                                            id="address-1"
                                            type="text"
                                            placeholder="Street address"
                                            value={HNo}
                                            onChange={(e) => setHNo(e.target.value)}
                                            className={
                                                HNoerror !== ""
                                                    ? "form-control error_show number_input_mob"
                                                    : "form-control number_input_mob"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="address-1" className="form-label mobile_start_add">
                                            Area <span className="required">*</span>
                                        </label>
                                        <input
                                            name="address_1"
                                            id="address-1"
                                            type="text"
                                            placeholder="Area"
                                            value={Area}
                                            onChange={(e) => setArea(e.target.value)}
                                            className={
                                                Areaerror !== ""
                                                    ? "form-control error_show number_input_mob"
                                                    : "form-control number_input_mob"
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="address-1" className="form-label mobile_start_add">
                                            sub locality <span className="required">*</span>
                                        </label>
                                        <input
                                            name="address_1"
                                            id="address-1"
                                            type="text"
                                            placeholder="sub locality"
                                            value={sub_locality}
                                            onChange={(e) => setsub_locality(e.target.value)}
                                            className={
                                                sub_localityerror !== ""
                                                    ? "form-control error_show number_input_mob"
                                                    : "form-control number_input_mob"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="address-1" className="form-label mobile_start_add">
                                            locality <span className="required">*</span>
                                        </label>
                                        <input
                                            name="address_1"
                                            id="address-1"
                                            type="text"
                                            placeholder="locality"
                                            value={locality}
                                            onChange={(e) => setlocality(e.target.value)}
                                            className={
                                                localityerror !== ""
                                                    ? "form-control error_show number_input_mob"
                                                    : "form-control number_input_mob"
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="address-1" className="form-label mobile_start_add">
                                            LandMark
                                        </label>
                                        <input
                                            name="address_1"
                                            id="address-1"
                                            type="text"
                                            placeholder="LandMark"
                                            value={LandMark}
                                            onChange={(e) => setLandMark(e.target.value)}
                                            className={
                                                LandMarkerror !== ""
                                                    ? "form-control error_show number_input_mob"
                                                    : "form-control number_input_mob"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="address-1" className="form-label mobile_start_add">
                                            City <span className="required">*</span>
                                        </label>
                                        <input
                                            name="address_1"
                                            id="address-1"
                                            type="text"
                                            placeholder="City"
                                            value={City}
                                            onChange={(e) => setCity(e.target.value)}
                                            className={
                                                Cityerror !== ""
                                                    ? "form-control error_show number_input_mob"
                                                    : "form-control number_input_mob"
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="address-1" className="form-label mobile_start_add">
                                            State <span className="required">*</span>
                                        </label>
                                        <input
                                            name="address_1"
                                            id="address-1"
                                            type="text"
                                            placeholder="State"
                                            value={State}
                                            onChange={(e) => setState(e.target.value)}
                                            className={
                                                Stateerror !== ""
                                                    ? "form-control error_show number_input_mob"
                                                    : "form-control number_input_mob"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                        <label htmlFor="address-1" className="form-label mobile_start_add">
                                            Pincode <span className="required">*</span>
                                        </label>
                                        <input
                                            name="address_1"
                                            id="address-1"
                                            type="text"
                                            placeholder="Pincode"
                                            value={Pincode}
                                            minLength={6}
                                            maxLength={6}
                                            onChange={(e) =>
                                                setPincode(e.target.value.replace(/\D/g, ""))
                                            }
                                            className={
                                                Pincodeerror !== ""
                                                    ? "form-control error_show number_input_mob"
                                                    : "form-control number_input_mob"
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div
                                        className="form-group col-md-12 col-lg-12 mb-0"
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            gap: "10px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            className="checkout-tearm customCheckbox"
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                gap: "10px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <input
                                                id="checkout_tearm"
                                                name="tearm"
                                                type="radio"
                                                defaultValue="checkout tearm"
                                                value={"Home"}
                                                checked={Type === "Home"}
                                                onChange={() => setType("Home")}
                                            />
                                            <label htmlFor="checkout_tearm">Home</label>
                                        </div>
                                        <div
                                            className="checkout-tearm customCheckbox"
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                gap: "10px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <input
                                                id="checkout_tearm"
                                                name="tearm"
                                                type="radio"
                                                defaultValue="checkout tearm"
                                                value={Type}
                                                checked={Type === "Office"}
                                                onChange={() => setType("Office")}
                                            />
                                            <label htmlFor="checkout_tearm">Office</label>
                                        </div>
                                        <div
                                            // className="checkout-tearm customCheckbox"
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                gap: "10px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <input
                                                id="checkout_tearm"
                                                name="tearm"
                                                type="radio"
                                                defaultValue="checkout tearm"
                                                value={Type}
                                                onChange={() => setType("Others")}
                                                checked={Type === "Others"}
                                            />
                                            <label htmlFor="checkout_tearm">Others</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <button
                                        style={{
                                            visibility: "hidden",
                                        }}
                                    >
                                        {/* Back to USER AUTHENTICATion */}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={(e) => add_address(e)}
                                        className="btn btn-secondary btnPrevious me-1 mb-4"
                                    >
                                        Update address
                                    </button>
                                </div>
                                <div style={{ marginBottom: '50px' }}></div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateAddress;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  set_checkout_authentication_status,
  set_checkout_process_steps,
  signin,
  userRegistation,
  verifyUserEmail,
} from "../../../redux/athentication/Athentication";

const Registation = () => {
  const dispatch = useDispatch();

  const { mob } = useSelector((state) => state.Athentication);

  const [email, setemail] = useState("");
  const [confirm_email, setconfirm_email] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [Name, setName] = useState("");
  const [errorName, setErrorName] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);

  useEffect(() => {
    if (buttonPress === true) {
      if (confirm_email === "") {
        setErrorEmail("Requied");
      } else {
        setErrorEmail("");
      }
      if (Name === "") {
        setErrorName("Requied");
      } else {
        setErrorName("");
      }
    }
  }, [buttonPress, confirm_email, Name]);

  const emailVerifyChange = async (e) => {
    const value = e.target.value;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setemail(value);
    if (value.length > 0) {
      if (value.match(mailformat)) {
        let responce = await dispatch(verifyUserEmail(value));

        if (responce.payload.success) {
          setErrorEmail("Requied");
        } else {
          setErrorEmail("");
          setconfirm_email(value);
        }
      }
    } else {
      setErrorEmail("Requied");
    }
  };

  const user_register_press = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    if (confirm_email === "") {
      setErrorEmail("Requied");
    } else {
      setErrorEmail("");
    }
    if (Name === "") {
      setErrorName("Requied");
    } else {
      setErrorName("");
    }

    if (confirm_email !== "" && Name !== "" && mob !== "") {
      const formdata = {
        Name: Name.charAt(0).toUpperCase() + Name.slice(1).toLowerCase(),
        Email: confirm_email,
        Mobile: mob,
      };

      const user_registation = await dispatch(userRegistation(formdata));

      if (user_registation.payload.success) {
      }
      const user_data = user_registation.payload.client;
      dispatch(
        signin({
          ...user_data,
          isAuth: true,
        })
      );
      dispatch(set_checkout_authentication_status(0));
      dispatch(set_checkout_process_steps(1));
    }
  };

  return (
    <>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <div className="block h-100">
          <div className="block-content">
            <form method="post" action="#" className="login-form">
              <h3 className="title">Register ?</h3>
              <div className="row">
                <div className="col-12 form-group">
                  <label htmlFor="CustomerEmail" className="form-label name_star_box">
                    Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your Name"
                    autofocus
                    className={
                      errorName !== ""
                        ? "form-control error_show mobile_input_box"
                        : "form-control mobile_input_box"
                    }
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-12 form-group">
                  <label htmlFor="CustomerEmail" className="form-label name_star_box">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your Email"
                    autofocus
                    className={
                      errorEmail !== ""
                        ? "form-control error_show mobile_input_box"
                        : "form-control mobile_input_box"
                    }
                    value={email}
                    onChange={(e) => emailVerifyChange(e)}
                  />
                </div>
                <div className="col-12 form-group">
                  <label htmlFor="CustomerEmail" className="form-label">
                    Mobile Number <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your mobile number"
                    className="form-control"
                    disabled={true}
                    value={mob}
                    contentEditable={false}
                  />
                </div>

                <div className="col-12 d-flex justify-content-between align-items-center">
                  <input
                    type="submit"
                    onClick={(e) => user_register_press(e)}
                    className="btn"
                    defaultValue="Sign In"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registation;

import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { Flex } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { isElement } from "react-dom/test-utils";
import useSelection from "antd/es/table/hooks/useSelection";
import { useSelector } from "react-redux";
import { FaArrowRight } from "react-icons/fa6";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const NewCategory = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [uniqueBrands, setUniqueBrands] = useState([]);
  const [nonPriorityBrands, setNonPriorityBrands] = useState([]);
  const [broughtProducts, setBroughtProducts] = useState([]);

  useEffect(() => {
    if (props.products) {
      const brandbycat = props.products;
      const newBrand=brandbycat.sort((a, b) => a.priorityNo - b.priorityNo)
      setUniqueBrands(newBrand);
    }
  }, [props.products]);

  return (
    <>
      {uniqueBrands.length > 0 ? (
        <>
          <div className="offernyka">
            <div className="nykacard">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "19px",
                    fontWeight: "700",
                    fontFamily: "Edu VIC WA NT Beginner,cursive",
                    color: "#315999",
                  }}
                >
                  Top Brands
                </div>
              </div>
              <div className="siperconnewcat" style={{ margin: "10px" }}>
                <Swiper
                  slidesPerView={9} // Adjust this based on your slide count
                  spaceBetween={3}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 4, // Adjust for smaller screens
                      spaceBetween: 4,
                    },
                    "@0.75": {
                      slidesPerView: 5,
                      spaceBetween: 5,
                    },
                    "@1.00": {
                      slidesPerView: 6,
                      spaceBetween: 10,
                    },
                    "@1.50": {
                      slidesPerView: 9, // Max slides to view
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                  style={{ paddingBottom: "5px" }}
                >
                  {/* Mapping through slides */}
                  {uniqueBrands.map((indBrand, index) => (
                    <SwiperSlide
                      key={index}
                      className="forsliderSwiper"
                      onClick={() =>
                        navigate(`/${props?.category}/${indBrand?.name}`)
                      }
                    >
                      <div
                        className="brandNames"
                        style={{
                          width: "7rem",
                          height: "7rem",
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid #F8DE22",
                          backgroundPosition: "center",
                          color: "#000",
                          backgroundSize: "100%",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          overflow: "hidden",
                          backgroundImage: `url(${indBrand.mobileImage})`,
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <mark
                          className="marktag"
                          style={{
                            backgroundColor: "yellow",
                            marginTop: "2rem",
                            borderRadius: "3px",
                          }}
                        >
                          {indBrand.name.length > 8
                            ? `${indBrand.name.slice(0, 8)}...`
                            : indBrand.name}
                        </mark>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default NewCategory;

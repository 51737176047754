import React from "react";

const FootwearSupcat = ({ productifo }) => {
  return (
    <div className="design1">
      {productifo.footwear?.color !== "" && (
        <div className="des">
          <h5>color</h5>
          <p>{productifo.footwear?.color}</p>
        </div>
      )}
      {productifo.footwear?.outer_material !== "" && (
        <div className="des">
          <h5>outer_material</h5>
          <p>{productifo.footwear?.outer_material}</p>
        </div>
      )}
      {productifo.footwear?.model_name !== "" && (
        <div className="des">
          <h5>model_name</h5>
          <p>{productifo.footwear?.model_name}</p>
        </div>
      )}
      {productifo.footwear?.ideal_for !== "" && (
        <div className="des">
          <h5>ideal_for</h5>
          <p>{productifo.footwear?.ideal_for}</p>
        </div>
      )}
      {productifo.footwear?.occassion !== "" && (
        <div className="des">
          <h5>occassion</h5>
          <p>{productifo.footwear?.occassion}</p>
        </div>
      )}
      {productifo.footwear?.type_of_sports !== "" && (
        <div className="des">
          <h5>type_of_sports</h5>
          <p>{productifo.footwear?.type_of_sports}</p>
        </div>
      )}
      {productifo.footwear?.net_quantity !== "" && (
        <div className="des">
          <h5>net_quantity</h5>
          <p>{productifo.footwear?.net_quantity}</p>
        </div>
      )}
      {productifo.footwear?.sale_material !== "" && (
        <div className="des">
          <h5>sale_material</h5>
          <p>{productifo.footwear?.sale_material}</p>
        </div>
      )}
      {productifo.footwear?.closure !== "" && (
        <div className="des">
          <h5>closure</h5>
          <p>{productifo.footwear?.closure}</p>
        </div>
      )}
      {productifo.footwear?.weight !== "" && (
        <div className="des">
          <h5>weight</h5>
          <p>{productifo.footwear?.weight}</p>
        </div>
      )}
      {productifo.footwear?.upper_pattern !== "" && (
        <div className="des">
          <h5>upper_pattern</h5>
          <p>{productifo.footwear?.upper_pattern}</p>
        </div>
      )}
      {productifo.footwear?.sales_package !== "" && (
        <div className="des">
          <h5>sales_package</h5>
          <p>{productifo.footwear?.sales_package}</p>
        </div>
      )}
      {productifo.footwear?.pack_of !== "" && (
        <div className="des">
          <h5>pack_of</h5>
          <p>{productifo.footwear?.pack_of}</p>
        </div>
      )}
      {productifo.footwear?.technology_used !== "" && (
        <div className="des">
          <h5>technology_used</h5>
          <p>{productifo.footwear?.technology_used}</p>
        </div>
      )}
      {productifo.footwear?.season !== "" && (
        <div className="des">
          <h5>season</h5>
          <p>{productifo.footwear?.season}</p>
        </div>
      )}
      {productifo.footwear?.care_instruction !== "" && (
        <div className="des">
          <h5>care_instruction</h5>
          <p>{productifo.footwear?.care_instruction}</p>
        </div>
      )}
      {productifo.footwear?.type_of_causual !== "" && (
        <div className="des">
          <h5>type_of_causual</h5>
          <p>{productifo.footwear?.type_of_causual}</p>
        </div>
      )}
      {productifo.footwear?.tip_shape !== "" && (
        <div className="des">
          <h5>tip_shape</h5>
          <p>{productifo.footwear?.tip_shape}</p>
        </div>
      )}
      {productifo.footwear?.type_of_formals !== "" && (
        <div className="des">
          <h5>type_of_formals</h5>
          <p>{productifo.footwear?.type_of_formals}</p>
        </div>
      )}
      {productifo.footwear?.type !== "" && (
        <div className="des">
          <h5>type</h5>
          <p>{productifo.footwear?.type}</p>
        </div>
      )}
      {productifo.footwear?.type_of_flats !== "" && (
        <div className="des">
          <h5>type_of_flats</h5>
          <p>{productifo.footwear?.type_of_flats}</p>
        </div>
      )}
      {productifo.footwear?.type_of_heels !== "" && (
        <div className="des">
          <h5>type_of_heels</h5>
          <p>{productifo.footwear?.type_of_heels}</p>
        </div>
      )}
      {productifo.footwear?.length !== "" && (
        <div className="des">
          <h5>length</h5>
          <p>{productifo.footwear?.length}</p>
        </div>
      )}
      {productifo.footwear?.number_of_pairs !== "" && (
        <div className="des">
          <h5>number_of_pairs</h5>
          <p>{productifo.footwear?.number_of_pairs}</p>
        </div>
      )}
      {productifo.footwear?.brand !== "" && (
        <div className="des">
          <h5>brand</h5>
          <p>{productifo.footwear?.brand}</p>
        </div>
      )}
      {productifo.footwear?.style_code !== "" && (
        <div className="des">
          <h5>style_code</h5>
          <p>{productifo.footwear?.style_code}</p>
        </div>
      )}
      {productifo.footwear?.size !== "" && (
        <div className="des">
          <h5>size</h5>
          <p>{productifo.footwear?.size}</p>
        </div>
      )}
      {productifo.footwear?.brand_color !== "" && (
        <div className="des">
          <h5>brand_color</h5>
          <p>{productifo.footwear?.brand_color}</p>
        </div>
      )}
      {productifo.footwear?.closure_type !== "" && (
        <div className="des">
          <h5>closure_type</h5>
          <p>{productifo.footwear?.closure_type}</p>
        </div>
      )}
      {productifo.footwear?.base_material !== "" && (
        <div className="des">
          <h5>base_material</h5>
          <p>{productifo.footwear?.base_material}</p>
        </div>
      )}
      {productifo.footwear?.strap_material !== "" && (
        <div className="des">
          <h5>strap_material</h5>
          <p>{productifo.footwear?.strap_material}</p>
        </div>
      )}
      {productifo.footwear?.primary_color !== "" && (
        <div className="des">
          <h5>primary_color</h5>
          <p>{productifo.footwear?.primary_color}</p>
        </div>
      )}
      {productifo.footwear?.sub_type !== "" && (
        <div className="des">
          <h5>sub_type</h5>
          <p>{productifo.footwear?.sub_type}</p>
        </div>
      )}
      {productifo.footwear?.character !== "" && (
        <div className="des">
          <h5>character</h5>
          <p>{productifo.footwear?.character}</p>
        </div>
      )}
      {productifo.footwear?.heel_design !== "" && (
        <div className="des">
          <h5>heel_design</h5>
          <p>{productifo.footwear?.heel_design}</p>
        </div>
      )}
      {productifo.footwear?.warranty_summary !== "" && (
        <div className="des">
          <h5>warranty_summary</h5>
          <p>{productifo.footwear?.warranty_summary}</p>
        </div>
      )}
      {productifo.footwear?.insole_material !== "" && (
        <div className="des">
          <h5>insole_material</h5>
          <p>{productifo.footwear?.insole_material}</p>
        </div>
      )}
      {productifo.footwear?.inner_lining !== "" && (
        <div className="des">
          <h5>inner_lining</h5>
          <p>{productifo.footwear?.inner_lining}</p>
        </div>
      )}
      {productifo.footwear?.removable_insole !== "" && (
        <div className="des">
          <h5>removable_insole</h5>
          <p>{productifo.footwear?.removable_insole}</p>
        </div>
      )}
    </div>
  );
};

export default FootwearSupcat;

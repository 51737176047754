import React from 'react'

const HomeDecor_Supercategory = ({productifo}) => {
  return (
    <tbody>
    {productifo.homeandfurniture.pack_of !== "" && (
      <tr>
        <th>pack_of</th>
        <td>{productifo.homeandfurniture.pack_of}</td>
      </tr>
    )}
    {productifo.homeandfurniture.brand !== "" && (
      <tr>
        <th>brand</th>
        <td>{productifo.homeandfurniture.brand}</td>
      </tr>
    )}
    {productifo.homeandfurniture.model_name !== "" && (
      <tr>
        <th>model_name</th>
        <td>{productifo.homeandfurniture.model_name}</td>
      </tr>
    )}
    {productifo.homeandfurniture.type !== "" && (
      <tr>
        <th>type</th>
        <td>{productifo.homeandfurniture.type}</td>
      </tr>
    )}
    {productifo.homeandfurniture.size !== "" && (
      <tr>
        <th>size</th>
        <td>{productifo.homeandfurniture.size}</td>
      </tr>
    )}
    {productifo.homeandfurniture.color !== "" && (
      <tr>
        <th>color</th>
        <td>{productifo.homeandfurniture.color}</td>
      </tr>
    )}
    {productifo.homeandfurniture.model_number !== "" && (
      <tr>
        <th>model_number</th>
        <td>{productifo.homeandfurniture.model_number}</td>
      </tr>
    )}
    {productifo.homeandfurniture.material !== "" && (
      <tr>
        <th>material</th>
        <td>{productifo.homeandfurniture.material}</td>
      </tr>
    )}
    {productifo.homeandfurniture.other_features !== "" && (
      <tr>
        <th>other_features</th>
        <td>{productifo.homeandfurniture.other_features}</td>
      </tr>
    )}
    {productifo.homeandfurniture.number_of_scrubs !== "" && (
      <tr>
        <th>number_of_scrubs</th>
        <td>{productifo.homeandfurniture.number_of_scrubs}</td>
      </tr>
    )}
    {productifo.homeandfurniture.net_quantity !== "" && (
      <tr>
        <th>net_quantity</th>
        <td>{productifo.homeandfurniture.net_quantity}</td>
      </tr>
    )}
    {productifo.homeandfurniture.sales_package !== "" && (
      <tr>
        <th>sales_package</th>
        <td>{productifo.homeandfurniture.sales_package}</td>
      </tr>
    )}
    {productifo.homeandfurniture.capacity !== "" && (
      <tr>
        <th>capacity</th>
        <td>{productifo.homeandfurniture.capacity}</td>
      </tr>
    )}
    {productifo.homeandfurniture.pan_type !== "" && (
      <tr>
        <th>pan_type</th>
        <td>{productifo.homeandfurniture.pan_type}</td>
      </tr>
    )}
    {productifo.homeandfurniture.induction_bottom !== "" && (
      <tr>
        <th>induction_bottom</th>
        <td>{productifo.homeandfurniture.induction_bottom}</td>
      </tr>
    )}
    {productifo.homeandfurniture.lid_included !== "" && (
      <tr>
        <th>lid_included</th>
        <td>{productifo.homeandfurniture.lid_included}</td>
      </tr>
    )}
    {productifo.homeandfurniture.non_stick !== "" && (
      <tr>
        <th>non_stick</th>
        <td>{productifo.homeandfurniture.non_stick}</td>
      </tr>
    )}
    {productifo.homeandfurniture.dish_washer_safe !== "" && (
      <tr>
        <th>dish_washer_safe</th>
        <td>{productifo.homeandfurniture.dish_washer_safe}</td>
      </tr>
    )}
    {productifo.homeandfurniture.diameter !== "" && (
      <tr>
        <th>diameter</th>
        <td>{productifo.homeandfurniture.diameter}</td>
      </tr>
    )}
    {productifo.homeandfurniture.lid_locking_mechanism !== "" && (
      <tr>
        <th>lid_locking_mechanism</th>
        <td>{productifo.homeandfurniture.lid_locking_mechanism}</td>
      </tr>
    )}
    {productifo.homeandfurniture.other_body_and_design_features !== "" && (
      <tr>
        <th>other_body_and_design_features</th>
        <td>{productifo.homeandfurniture.other_body_and_design_features}</td>
      </tr>
    )}
    {productifo.homeandfurniture.shade !== "" && (
      <tr>
        <th>shade</th>
        <td>{productifo.homeandfurniture.shade}</td>
      </tr>
    )}
    {productifo.homeandfurniture.suitable_for !== "" && (
      <tr>
        <th>suitable_for</th>
        <td>{productifo.homeandfurniture.suitable_for}</td>
      </tr>
    )}
    {productifo.homeandfurniture.handled_features !== "" && (
      <tr>
        <th>handled_features</th>
        <td>{productifo.homeandfurniture.handled_features}</td>
      </tr>
    )}
    {productifo.homeandfurniture.base_thickness !== "" && (
      <tr>
        <th>base_thickness</th>
        <td>{productifo.homeandfurniture.base_thickness}</td>
      </tr>
    )}
    {productifo.homeandfurniture.weight !== "" && (
      <tr>
        <th>weight</th>
        <td>{productifo.homeandfurniture.weight}</td>
      </tr>
    )}
    {productifo.homeandfurniture.covered_in_warranty !== "" && (
      <tr>
        <th>covered_in_warranty</th>
        <td>{productifo.homeandfurniture.covered_in_warranty}</td>
      </tr>
    )}
    {productifo.homeandfurniture.warranty_summary !== "" && (
      <tr>
        <th>warranty_summary</th>
        <td>{productifo.homeandfurniture.warranty_summary}</td>
      </tr>
    )}
    {productifo.homeandfurniture.not_covered_in_warranty !== "" && (
      <tr>
        <th>not_covered_in_warranty</th>
        <td>{productifo.homeandfurniture.not_covered_in_warranty}</td>
      </tr>
    )}
    {productifo.homeandfurniture.warranty_service_type !== "" && (
      <tr>
        <th>warranty_service_type</th>
        <td>{productifo.homeandfurniture.warranty_service_type}</td>
      </tr>
    )}
    {productifo.homeandfurniture.domestic_warranty !== "" && (
      <tr>
        <th>domestic_warranty</th>
        <td>{productifo.homeandfurniture.domestic_warranty}</td>
      </tr>
    )}
    {productifo.homeandfurniture.in_the_box !== "" && (
      <tr>
        <th>in_the_box</th>
        <td>{productifo.homeandfurniture.in_the_box}</td>
      </tr>
    )}
    {productifo.homeandfurniture.body_material !== "" && (
      <tr>
        <th>body_material</th>
        <td>{productifo.homeandfurniture.body_material}</td>
      </tr>
    )}
    {productifo.homeandfurniture.trivet_material !== "" && (
      <tr>
        <th>trivet_material</th>
        <td>{productifo.homeandfurniture.trivet_material}</td>
      </tr>
    )}
    {productifo.homeandfurniture.burner_material !== "" && (
      <tr>
        <th>burner_material</th>
        <td>{productifo.homeandfurniture.burner_material}</td>
      </tr>
    )}
    {productifo.homeandfurniture.number_of_burners !== "" && (
      <tr>
        <th>number_of_burners</th>
        <td>{productifo.homeandfurniture.number_of_burners}</td>
      </tr>
    )}
    {productifo.homeandfurniture.burner_type !== "" && (
      <tr>
        <th>burner_type</th>
        <td>{productifo.homeandfurniture.burner_type}</td>
      </tr>
    )}
  
  </tbody>
  )
}

export default HomeDecor_Supercategory
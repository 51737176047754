import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setQuickViewProduct } from "../../redux/product/productSlice";
import { addTocart } from "../../redux/cart/CartSlice";

const ElectronicSingleProduct = ({ product }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="item col-item">
        {product.packSize.map((pack, packIndex) =>
          pack.PsPrime === true ? (
            <>
              {pack.PackSizeOptions.map((packOption, optionIndex) =>
                packOption.PsnPrime === true ? (
                  <>
                    <div className="product-box">
                      {/* Start Product Image */}
                      <div className="product-image">
                        {/* Start Product Image */}
                        <Link
                          to={`/product-info/${product.slugUrl}`}
                          className="product-img"
                        >
                          {/* Image */}
                          <img
                            className="primary blur-up lazyload"
                            data-src={pack.thumbnail}
                            src={pack.thumbnail}
                            alt="Product"
                            title="Product"
                            width={625}
                            height={625}
                          />
                          {/* End Image */}
                          {/* Hover Image */}
                          <img
                            className="hover blur-up lazyload"
                            data-src={pack.thumbnail}
                            src={pack.thumbnail}
                            alt="Product"
                            title="Product"
                            width={625}
                            height={625}
                          />
                          {/* End Hover Image */}
                        </Link>
                        <div className="product-labels radius">
                          <span className="lbl on-sale">Sale</span>
                        </div>
                        {/* End Product Image */}
                        {/*Product Button*/}
                        <div className="button-set-top style1">
                          {/*Quick View Button*/}
                          <span
                            to="#quickview-modal"
                            className="btn-icon quickview quick-view-modal"
                            data-bs-toggle="modal"
                            data-bs-target="#quickview_modal"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              dispatch(setQuickViewProduct(product))
                            }
                          >
                            <span
                              className="icon-wrap d-flex-justify-center h-100 w-100"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Quick View"
                            >
                              <i className="icon anm anm-search-plus-l" />
                              <span className="text">Quick View</span>
                            </span>
                          </span>
                          {/* <span
                            className="btn-icon rounded-5 wishlist"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Add To Wishlist"
                          >
                            <i className="icon anm anm-heart-l" />
                            <span className="text">Add To Wishlist</span>
                          </span> */}
                        
                          {/* <span
                            className="btn-icon rounded-5 compare"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Add to Compare"
                          >
                            <i className="icon anm anm-random-r" />
                            <span className="text">Add to Compare</span>
                          </span> */}
                     
                        </div>
                        <div className="product-availability rounded-5">
                          <div className="lh-1 d-flex justify-content-between">
                            <div className="text-sold">
                              Sold:
                              <strong className="text-primary ms-1">
                                {packOption.maximumQuantity}
                              </strong>
                            </div>
                            <div className="text-available">
                              Available:
                              <strong className="text-primary ms-1">
                                {packOption.availablestock}
                              </strong>
                            </div>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar w-75"
                              role="progressbar"
                              aria-valuenow={75}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                        {/*End Product Availability*/}
                      </div>
                      {/* End Product Image */}
                      {/* Start Product Details */}
                      <div className="product-details text-left">
                        {/*Product Vendor*/}
                        <div className="product-vendor">
                          {" "}
                          {product.CategoryTag}
                        </div>
                        {/*End Product Vendor*/}
                        {/* Product Name */}
                        <div className="product-name">
                          <Link to={`/product-info/${product.slugUrl}`}>
                            {product.name}
                          </Link>
                        </div>
                        {/* End Product Name */}
                        {/* Product Price */}
                        <div className="product-price">
                          <span className="price old-price">
                            {" "}
                            ₹{packOption.Mrp}
                          </span>
                          <span className="price">
                            <b>₹{packOption.Price}</b>
                          </span>
                        </div>
                        {/* End Product Price */}
                        {/* Product Review */}
                        <div className="product-review">
                          <i className="icon anm anm-star-o" />
                          <i className="icon anm anm-star-o" />
                          <i className="icon anm anm-star-o" />
                          <i className="icon anm anm-star-o" />
                          <i className="icon anm anm-star-o" />
                          <span className="caption hidden ms-1">0 Reviews</span>
                        </div>
                        {/* End Product Review */}
                      </div>
                      {/* End product details */}
                    </div>
                  </>
                ) : (
                  <>
                    <div key={optionIndex}></div>
                  </>
                )
              )}
            </>
          ) : (
            <>
              <div key={packIndex}></div>
            </>
          )
        )}
      </div>
    </>
  );
};

export default ElectronicSingleProduct;

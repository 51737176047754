import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    CartItems: localStorage.getItem("CartItems")
        ? JSON.parse(localStorage.getItem("CartItems"))
        : [],
    TotalMrp: 0,
    TotalPrice: 0,
    //
    SmallCartFee: 0,
    HandllingFee: 10,
    RainFee: 0,
    DeliveryCharge: 0,
    rainStatus: false,
    //
    wallet: 0,
    coupon: 0,
    TotalAmount: 0,
    Netpayable: 0,
    amountToGetfeeDelivery: 0,
    amountToGetfeeDeliveryPercentage: 0,
    all_amount_data: "",
    Cartloading: true,

};

const CartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addTocart(state, action) {
            let itemIndex = -1;
            itemIndex = state.CartItems.findIndex(
                (item) => item.PackOptionId === action.payload.PackOptionId
            );
            if (itemIndex >= 0) {
                let quentity = state.CartItems[itemIndex].cart_Quentity + 1;

                state.CartItems[itemIndex].cart_Quentity = quentity
                state.CartItems[itemIndex].Product_total_Mrp = Number(
                    state.CartItems[itemIndex].Mrp * Number(quentity)
                );
                state.CartItems[itemIndex].Product_total_Price = Number(
                    state.CartItems[itemIndex].Price * Number(quentity)
                );
                state.CartItems[itemIndex].Product_total_Saving = Number(
                    state.CartItems[itemIndex].Mrp * Number(quentity)
                ) - Number(
                    state.CartItems[itemIndex].Price * Number(quentity)
                );

                state.TotalMrp = state.TotalMrp + Number(
                    state.CartItems[itemIndex].Mrp * Number(quentity)
                );
                state.TotalPrice = state.TotalPrice + Number(
                    state.CartItems[itemIndex].Price * Number(quentity)
                );;

                if (state.TotalPrice >= 100) {
                    state.SmallCartFee = 0
                } else {
                    state.SmallCartFee = 30
                }
                if (state.TotalPrice >= 500) {
                    state.DeliveryCharge = 0
                    state.amountToGetfeeDelivery = 0
                    state.amountToGetfeeDeliveryPercentage = 100
                } else {
                    state.DeliveryCharge = 40
                    state.amountToGetfeeDelivery = 500 - state.TotalPrice;
                    state.amountToGetfeeDeliveryPercentage = (state.amountToGetfeeDelivery / 500) * 100
                }
                if (state.rainStatus === true) {
                    state.RainFee = 20
                } else {
                    state.RainFee = 0
                }
                state.TotalAmount = state.TotalPrice + state.SmallCartFee + state.DeliveryCharge + state.RainFee + state.HandllingFee;
                state.Netpayable = state.TotalAmount - state.wallet - state.coupon;
                state.TotalMrp = state.TotalMrp + Number(
                    state.CartItems[itemIndex].Mrp * Number(quentity)
                );
                state.TotalPrice = state.TotalPrice + Number(
                    state.CartItems[itemIndex].Price * Number(quentity)
                );;

                if (state.TotalPrice >= 100) {
                    state.SmallCartFee = 0
                } else {
                    state.SmallCartFee = 30
                }
                if (state.TotalPrice >= 500) {
                    state.DeliveryCharge = 0
                    state.amountToGetfeeDelivery = 0
                    state.amountToGetfeeDeliveryPercentage = 100
                } else {
                    state.DeliveryCharge = 40
                    state.amountToGetfeeDelivery = 500 - state.TotalPrice;
                    state.amountToGetfeeDeliveryPercentage = (state.amountToGetfeeDelivery / 500) * 100
                }
                if (state.rainStatus === true) {
                    state.RainFee = 20
                } else {
                    state.RainFee = 0
                }
                state.TotalAmount = state.TotalPrice + state.SmallCartFee + state.DeliveryCharge + state.RainFee + state.HandllingFee;
                state.Netpayable = state.TotalAmount - state.wallet - state.coupon;
            } else {
                const tempProducts = action.payload;
                state.CartItems.push(tempProducts);

                state.TotalMrp = state.TotalMrp + action.payload.Mrp;
                state.TotalPrice = state.TotalPrice + action.payload.Price;

                if (state.TotalPrice >= 100) {
                    state.SmallCartFee = 0
                } else {
                    state.SmallCartFee = 30
                }
                if (state.TotalPrice >= 500) {
                    state.DeliveryCharge = 0
                    state.amountToGetfeeDelivery = 0
                    state.amountToGetfeeDeliveryPercentage = 100
                } else {
                    state.DeliveryCharge = 40
                    state.amountToGetfeeDelivery = 500 - state.TotalPrice;
                    state.amountToGetfeeDeliveryPercentage = (state.amountToGetfeeDelivery / 500) * 100
                }
                if (state.rainStatus === true) {
                    state.RainFee = 20
                } else {
                    state.RainFee = 0
                }
                state.TotalAmount = state.TotalPrice + state.SmallCartFee + state.DeliveryCharge + state.RainFee + state.HandllingFee;
                state.Netpayable = state.TotalAmount - state.wallet - state.coupon;
            }

            state.Cartloading = !state.Cartloading;
            localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
        },
        decrementCart(state, action) {
            let itemIndex = -1;
            itemIndex = state.CartItems.findIndex(
                (item) => item.PackOptionId === action.payload.PackOptionId
            );

            if (itemIndex >= 0) {
                if (state.CartItems[itemIndex].cart_Quentity > 1) {

                    let quentity = state.CartItems[itemIndex].cart_Quentity - 1;

                    state.CartItems[itemIndex].cart_Quentity = quentity
                    state.CartItems[itemIndex].Product_total_Mrp = Number(
                        state.CartItems[itemIndex].Mrp * Number(quentity)
                    );
                    state.CartItems[itemIndex].Product_total_Price = Number(
                        state.CartItems[itemIndex].Price * Number(quentity)
                    );
                    state.CartItems[itemIndex].Product_total_Saving = Number(
                        state.CartItems[itemIndex].Mrp * Number(quentity)
                    ) - Number(
                        state.CartItems[itemIndex].Price * Number(quentity)
                    );

                    state.TotalMrp = state.TotalMrp + Number(
                        state.CartItems[itemIndex].Mrp * Number(quentity)
                    );
                    state.TotalPrice = state.TotalPrice + Number(
                        state.CartItems[itemIndex].Price * Number(quentity)
                    );;
                } else if (state.CartItems[itemIndex].cart_Quentity === 1) {
                    const nextCartItems = state.CartItems.filter(
                        (cartItem) => cartItem.PackOptionId !== action.payload.PackOptionId
                    );
                    state.CartItems = nextCartItems;
                }


                if (state.TotalPrice >= 100) {
                    state.SmallCartFee = 0
                } else {
                    state.SmallCartFee = 30
                }
                if (state.TotalPrice >= 500) {
                    state.DeliveryCharge = 0
                    state.amountToGetfeeDelivery = 0
                    state.amountToGetfeeDeliveryPercentage = 100
                } else {
                    state.DeliveryCharge = 40
                    state.amountToGetfeeDelivery = 500 - state.TotalPrice;
                    state.amountToGetfeeDeliveryPercentage = (state.amountToGetfeeDelivery / 500) * 100
                }
                if (state.rainStatus === true) {
                    state.RainFee = 20
                } else {
                    state.RainFee = 0
                }
                state.TotalAmount = state.TotalPrice + state.SmallCartFee + state.DeliveryCharge + state.RainFee + state.HandllingFee;
                state.Netpayable = state.TotalAmount - state.wallet - state.coupon;
            }

            state.Cartloading = !state.Cartloading;
            localStorage.setItem("CartItems", JSON.stringify(state.CartItems));



        },
        removefromCart(state, action) {
            const nextCartItems = state.CartItems.filter(
                (cartItem) => cartItem.PackOptionId !== action.payload.PackOptionId
            );
            state.CartItems = nextCartItems;
            state.Cartloading = !state.Cartloading;
            localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
        },
        getCartTotal(state, action) {
            state.TotalMrp = 0;
            state.TotalPrice = 0;
            state.TotalAmount = 0;
            state.Netpayable = 0;
            const totalitem = state.CartItems;

            for (let index = 0; index < totalitem.length; index++) {
                let single_cart_item = state.CartItems[index];
                state.TotalMrp = state.TotalMrp + single_cart_item.Product_total_Mrp
                state.TotalPrice = state.TotalPrice + single_cart_item.Product_total_Price
            }

            if (state.TotalPrice >= 100) {
                state.SmallCartFee = 0
            } else {
                state.SmallCartFee = 30
            }
            if (state.TotalPrice >= 500) {
                state.DeliveryCharge = 0
                state.amountToGetfeeDelivery = 0
                state.amountToGetfeeDeliveryPercentage = 100
            } else {
                state.DeliveryCharge = 40
                state.amountToGetfeeDelivery = 500 - state.TotalPrice;
                state.amountToGetfeeDeliveryPercentage = (state.amountToGetfeeDelivery / 500) * 100
            }
            if (state.rainStatus === true) {
                state.DeliveryCharge = 20
            } else {
                state.DeliveryCharge = 0
            }
            state.TotalAmount = state.TotalPrice + state.SmallCartFee + state.DeliveryCharge + state.DeliveryCharge + state.HandllingFee;
            state.Netpayable = state.TotalAmount - state.wallet - state.coupon;
        },

        clearCart(state, action) {
            state.CartItems = [];
            state.Cartloading = !state.Cartloading;
            localStorage.setItem("CartItems", JSON.stringify(state.CartItems));
        },
        set_all_amount_data(state, action) {
            state.all_amount_data = action.payload
            localStorage.setItem("all_amount_data", JSON.stringify(state.all_amount_data));
        },

    },
});
export const {
    addTocart,
    getCartTotal,
    decrementCart,
    removefromCart,
    clearCart,
    set_all_amount_data
} = CartSlice.actions;
export default CartSlice.reducer;
import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { Autoplay, FreeMode, Mousewheel, Pagination } from "swiper/modules";

import { Avatar, Card } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import SingleProductCat from "../../component/product/SingleProductCat";
import SingleProduct from "../../component/product/SingleProduct";
const { Meta } = Card;

const ProductByCat = ({ products, category }) => {
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    if (products && products.length > 0) {
      let sortedProducts = [...products].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      if (category === "furniture") {
        sortedProducts = sortedProducts.filter(
          (indPro) => indPro.SubCategory === "Living room furniture"
        );
      }
      setProduct(sortedProducts);
      console.log(sortedProducts,'sortedProducts');
    }
  }, [products, category]);
  

  return (
    <>
      <div>
        {product.length > 0 ? (
          <>
            <div className="offernyka">
              <div className="nykacard">
                <div className="heading">
                  <div
                    style={{
                      fontSize: "19px",
                      fontWeight: "700",
                      fontFamily: "Edu VIC WA NT Beginner,cursive",
                      color: "#315999",
                    }}
                  >
                    Latest Products
                  </div>
                </div>
                <div className="grid-products grid-view-items">
                  <div
                    className="row col-row product-options row-cols-lg-5 row-cols-md-3 row-cols-sm-3 row-cols-2"
                    style={{ marginTop: "10px" }}
                  >
                    {product &&
                      product
                        .slice(0, 12)
                        .map((product, index) => (
                          <SingleProduct key={index} product={product} />
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ProductByCat;

import React from 'react'
import SliderHappyCustomer from '../../component/homecomponent/SliderHappyCustomer'

const HappyCustomer = () => {
    return (
        <>

            <section className="section testimonial-slider style1">
                <div className="container">
                    <div className="section-header">
                        <p className="mb-2 mt-0">Happy Customer</p>
                        <h2>Loved By Our Customers</h2>
                    </div>
                    <div className="testimonial-wraper">
                        <div className="testimonial-slider-3items gp15 slick-arrow-dots arwOut5">
                        <SliderHappyCustomer />
                            
                            
                           
                            
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HappyCustomer
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addTocart,
  clearCart,
  decrementCart,
  getCartTotal,
  removefromCart,
} from "../../redux/cart/CartSlice";
import MobViewCart from "./mobile_version/MobViewCart";
import { Radio } from "antd";

const ViewCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const { isAuth, current_address, loginData, paymentType } = useSelector(
    (state) => state.Athentication
  );
  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
    coupon,
    DeliveryCharge,
    rainStatus,
    RainFee,
    Netpayable,
  } = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems]);
  // useEffect(() => {
  //   if (CartItems.length === 0) {
  //     navigate("/");
  //   }
  // }, [CartItems]);

  const addTocartPress = async (e, item) => {
    e.preventDefault();

    const cart = await dispatch(addTocart(item));
    dispatch(getCartTotal());
  };
  const decrementCartQuentity = async (e, item) => {
    e.preventDefault();

    const cart = await dispatch(decrementCart(item));
    dispatch(getCartTotal());
  };
  const removefromCartitem = async (e, item) => {
    e.preventDefault();
    const cart = await dispatch(removefromCart(item));
    dispatch(getCartTotal());
  };
  const clearCartitems = async (e) => {
    e.preventDefault();
    const cart = await dispatch(clearCart());
    dispatch(getCartTotal());
    // navigate("/");
  };

  const handleCheckout = () => {
    navigate("/check-out");
  };

  return (
    <>
      {CartItems.length === 0 ? (
        <>
          <div>
            <div className="emptyimgcontainer">
              <img
                src="/assets/images/empty5.jpeg"
                alt="slideshow"
                title
                height="100px"
              />
            </div>
            <Link to="/">
              <div className="emptybuttoncontainer">
                <button className="btnhomepage">Go To Home Page</button>
              </div>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div id="page-content">
            <div className="container categorcontainer">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 main-col">
                  {amountToGetfeeDelivery === 0 && (
                    <div
                      className="alert alert-success py-2 alert-dismissible fade show cart-alert"
                      role="alert"
                    >
                      <i className="align-middle icon anm anm-truck icon-large me-2" />
                      <strong className="text-uppercase">
                        Congratulations!
                      </strong>{" "}
                      You've got free shipping!
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      />
                    </div>
                  )}

                  <form className="cart-table table-bottom-brd">
                    <table className="table align-middle">
                      {/* <thead className="cart-row cart-header small-hide position-relative">
                        <tr>
                          <th className="action">&nbsp;</th>
                          <th colSpan={2} className="text-start">
                            Product
                          </th>
                          <th className="text-center">Price</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Total</th>
                        </tr>
                      </thead> */}
                      {/* <tbody>
                        {CartItems &&
                          CartItems.map((item, index) => (
                            <tr
                              className="cart-row cart-flex position-relative"
                              key={index}
                            >
                              <td className="cart-delete text-center small-hide">
                                <span
                                  className="cart-remove remove-icon position-static cursor_pointer"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Remove to Cart"
                                  onClick={(e) => removefromCartitem(e, item)}
                                >
                                  <i className="icon anm anm-times-r" />
                                </span>
                              </td>
                              <td className="cart-image cart-flex-item">
                                <span>
                                  <img
                                    className="cart-image rounded-0 blur-up lazyload"
                                    data-src={item.thumbnail}
                                    src={item.thumbnail}
                                    alt={item.packSizeOptionName}
                                    width={120}
                                    height={170}
                                  />
                                </span>
                              </td>
                              <td
                                className="cart-meta small-text-left cart-flex-item"
                                onClick={() => {
                                  if ("slugurl" in item) {
                                    navigate(`/product-info/${item.slugurl}`);
                                  }
                                }}
                              >
                                <div className="list-view-item-title limited-paragraph">
                                  <strong
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {" "}
                                    {item.ProductName}
                                  </strong>
                                </div>
                                <div className="cart-meta-text ">
                                  Size: <b>{item.packSizeOptionName}</b>
                                  <br />
                                  Qty:<b>{item.cart_Quentity}</b>
                                </div>
                                <div className="cart-price d-md-block">
                                  <span className="money fw-500">
                                    {" "}
                                    ₹ <b>{item.Price}</b>
                                  </span>
                                </div>
                              </td>
                              <td className="cart-price cart-flex-item text-center small-hide">
                                <span className="money fw-500">
                                  {" "}
                                  ₹{item.Price}
                                </span>
                              </td>
                              <td className="cart-update-wrapper cart-flex-item text-end text-md-center">
                                <div className="cart-qty d-flex justify-content-end justify-content-md-center">
                                  <div className="qtyField">
                                    <span
                                      className="qtyBtn minus cursor_pointer"
                                      onClick={(e) =>
                                        decrementCartQuentity(e, item)
                                      }
                                    >
                                      <i className="icon anm anm-minus-r" />
                                    </span>
                                    <input
                                      className="cart-qty-input qty"
                                      type="text"
                                      name="updates[]"
                                      defaultValue={1}
                                      contentEditable={false}
                                      value={item.cart_Quentity}
                                    />
                                    <span
                                      className="qtyBtn plus cursor_pointer"
                                      onClick={(e) => addTocartPress(e, item)}
                                    >
                                      <i className="icon anm anm-plus-r" />
                                    </span>
                                  </div>
                                </div>
                                <span
                                  title="Remove"
                                  className="removeMb d-md-none d-inline-block text-decoration-underline mt-2 me-3"
                                  onClick={(e) => removefromCartitem(e, item)}
                                >
                                  Remove
                                </span>
                              </td>
                              <td className="cart-price cart-flex-item text-center small-hide">
                                <span className="money fw-500">
                                  {" "}
                                  ₹{item.Product_total_Price}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody> */}
                      <MobViewCart />
                      <tfoot>
                        <tr>
                          <td colSpan={3} className="text-start">
                            <Link
                              to="/"
                              className="btn btn-outline-secondary btn-sm cart-continue"
                            >
                              <i className="icon anm anm-angle-left-r me-2 d-none" />{" "}
                              Continue shopping
                            </Link>
                          </td>
                          <td colSpan={3} className="text-end">
                            <button
                              name="clear"
                              className="btn btn-outline-secondary btn-sm small-hide"
                              onClick={(e) => clearCartitems(e)}
                            >
                              <i className="icon anm anm-times-r me-2 d-none" />{" "}
                              Clear Shopping Cart
                            </button>
                            {/* <button
                          type="submit"
                          name="update"
                          className="btn btn-secondary btn-sm cart-continue ms-2"
                        >
                          <i className="icon anm anm-sync-ar me-2 d-none" />{" "}
                          Update Cart
                        </button> */}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </form>

                  {/* <div className="row my-4 pt-3">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-12 cart-col">
                  <div className="cart-note mb-4">
                    <h5>Add a note to your order</h5>
                    <label htmlFor="cart-note">
                      Notes about your order, e.g. special notes for delivery.
                    </label>
                    <textarea
                      name="note"
                      id="cart-note"
                      className="form-control cart-note-input"
                      rows={3}
                      required
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-12 cart-col">
                  <div className="cart-discount">
                    <h5>Discount Codes</h5>
                    <form action="#" method="post">
                      <div className="form-group">
                        <label htmlFor="address_zip">
                          Enter your coupon code if you have one.
                        </label>
                        <div className="input-group0">
                          <input
                            className="form-control"
                            type="text"
                            name="coupon"
                            required
                          />
                          <input
                            type="submit"
                            className="btn text-nowrap mt-3"
                            defaultValue="Apply Coupon"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
               
              </div> */}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 cart-footer">
                  <div className="cart-info sidebar-sticky">
                    <div className="cart-order-detail cart-col">
                      <div className="row g-0 border-bottom pb-2">
                        <span className="col-6 col-sm-6 cart-subtotal-title">
                          <strong>Subtotal</strong>
                        </span>
                        <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                          <span className="money"> ₹{TotalPrice}</span>
                        </span>
                      </div>
                      <div className="row g-0 border-bottom py-2">
                        <span className="col-6 col-sm-6 cart-subtotal-title">
                          <strong>Coupon Discount</strong>
                        </span>
                        <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                          <span className="money">- ₹{coupon}</span>
                        </span>
                      </div>

                      <div className="row g-0 border-bottom py-2">
                        <span className="col-6 col-sm-6 cart-subtotal-title">
                          <strong>Handlling Fee</strong>
                        </span>
                        <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                          <span className="money"> ₹{HandllingFee}</span>
                        </span>
                      </div>
                      {rainStatus === true && (
                        <div className="row g-0 border-bottom py-2">
                          <span className="col-6 col-sm-6 cart-subtotal-title">
                            <strong>Rain Fee</strong>
                          </span>
                          <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                            <span className="money"> ₹{RainFee}</span>
                          </span>
                        </div>
                      )}

                      <div className="row g-0 border-bottom py-2">
                        <span className="col-6 col-sm-6 cart-subtotal-title">
                          <strong>Shipping</strong>
                        </span>
                        <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                          {DeliveryCharge === 0 ? (
                            <>
                              <span
                                className="money"
                                style={{ color: "#29a644" }}
                              >
                                Free shipping
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="money">{DeliveryCharge}</span>
                            </>
                          )}
                        </span>
                      </div>
                      <div className="row g-0 pt-2">
                        <span className="col-6 col-sm-6 cart-subtotal-title fs-6">
                          <strong>Netpayable</strong>
                        </span>
                        <span className="col-6 col-sm-6 cart-subtotal-title fs-5 cart-subtotal text-end text-primary">
                          <b className="money"> ₹{Netpayable}</b>
                        </span>
                      </div>
                      {/* <p className="cart-shipping mt-3">
                    Shipping &amp; taxes calculated at checkout
                  </p> */}
                      {/* {DeliveryCharge === 0 && (
                        <p className="cart-shipping fst-normal freeShipclaim">
                          <i className="me-2 align-middle icon anm anm-truck-l" />
                          <b>FREE SHIPPING</b> ELIGIBLE
                        </p>
                      )} */}
                      <div style={{ marginTop: "15px" }}>
                          <Radio
                            checked
                          style={{
                            fontSize: "18px",
                            color: "#000",
                            fontWeight: "600",
                            }}
                           
                        >
                          Cash on Delivery
                        </Radio>
                      </div>

                      {/* <div className="customCheckbox cart-tearm">
                    <input
                      type="checkbox"
                      defaultValue="allen-vela"
                      id="cart-tearm"
                    />
                    <label htmlFor="cart-tearm">
                      I agree with the terms and conditions
                    </label>
                  </div> */}
                      <div
                        id="cartCheckout"
                        className="btn btn-lg my-4 checkout w-100"
                        onClick={() => handleCheckout()}
                      >
                        Proceed To Checkout
                      </div>
                      <div className="paymnet-img text-center">
                        <img
                          src="/assets/images/icons/safepayment.png"
                          alt="Payment"
                          width={299}
                          height={28}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewCart;

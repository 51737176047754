import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SellonPage = () => {
  const [showSeller, setShowSeller] = useState(false);
  // useEffect(()=>{

  //  setShowSeller(!showSeller)
  // },[showSeller])
  const handleSeller = async () => {
    if (showSeller === false) {
      setShowSeller(true);
      setTimeout(() => {
        setShowSeller(false);
        window.open(`https://seller.ewshopping.com`, "_blank");
      }, 1000);
    } else {
      setShowSeller(false);
    }
  };
  return (
    <div className="hovering_Content">
      <div className="mobile_icons">
        <div
          style={{
            borderRadius: "20px",
            top: "410px",
            position: "relative",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            // paddingRight: "5px",
            // right: "10px",
          }}
        >
          {showSeller ? (
            <>
              {/* <div
                className="col-6 col-sm-6 col-md-3 col-lg-4 d-flex align-items-center justify-content-start"
                style={{ textAlign: "center", marginRight: "10px" }}
            

              >
                <div className="select-wrap language-picker float-start buttndesign">
                  <div className="option english" style={{ height: "1px" }}>
                    <span style={{ fontSize: "11px" }}>Sell On Ewshopping</span>
                  </div>
                </div>
              </div> */}
            </>
          ) : (
            <></>
          )}

          <div
            onClick={() =>
              window.open(`https://seller.ewshopping.com`, "_blank")
            }
          >
            <Link className="logoImg">
              <img
                src="/assets/images/giff2.gif"
                alt="EwShopping"
                title="EwShopping"
                style={{ height: "70px", width: "100px" }}
              />
              <div
                className=""
                style={{ textAlign: "center", marginRight: "10px",marginTop:"-40px" }}
                
              >
                <div className="select-wrap language-picker float-start buttndesign">
                  <div className="option english" style={{ height: "1px" }}>
                    <span style={{ fontSize: "10px",fontWeight:"700" }}>Sell On Ewshopping</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellonPage;

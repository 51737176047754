import React from 'react'
import { Link } from "react-router-dom";


const Blog = () => {
    return (
        <>

            <div id="page-content">
                <div className="page-header text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                                <div className="page-title">
                                    <h1>Blog Grid</h1>
                                </div>
                                <div className="breadcrumbs"><Link to="/" title="Back to the home page">Home</Link><span className="title"><i className="icon anm anm-angle-right-l" />Blog</span><span className="main-title fw-bold"><i className="icon anm anm-angle-right-l" />Blog
                                    Grid</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="toolbar toolbar-wrapper blog-toolbar">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-left filters-toolbar-item d-flex justify-content-center justify-content-sm-start">
                                <div className="search-form mb-3 mb-sm-0">
                                    <form className="d-flex" action="#">
                                        <input className="search-input" type="text" placeholder="Blog search..." />
                                        <button type="submit" className="search-btn"><i className="icon anm anm-search-l" /></button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-right filters-toolbar-item d-flex justify-content-between justify-content-sm-end">
                                <div className="filters-item d-flex align-items-center">
                                    <label htmlFor="ShowBy" className="mb-0 me-2">Show:</label>
                                    <select name="ShowBy" id="ShowBy" className="filters-toolbar-sort">
                                        <option value="title-ascending" selected="selected">10</option>
                                        <option>15</option>
                                        <option>20</option>
                                        <option>25</option>
                                        <option>30</option>
                                    </select>
                                </div>
                                <div className="filters-item d-flex align-items-center ms-3">
                                    <label htmlFor="SortBy" className="mb-0 me-2">Sort:</label>
                                    <select name="SortBy" id="SortBy" className="filters-toolbar-sort">
                                        <option value="title-ascending" selected="selected">Featured</option>
                                        <option>Newest</option>
                                        <option>Most comments</option>
                                        <option>Release Date</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blog-grid-view">
                        <div className="row col-row row-cols-lg-3 row-cols-sm-2 row-cols-1">
                            <div className="blog-item col-item">
                                <div className="blog-article zoomscal-hov">
                                    <div className="blog-img">
                                        <Link className="featured-image rounded-0 zoom-scal" to=""><img className="rounded-0 blur-up lazyload" data-src="/assets/images/blog/post-img1.jpg" src="assets/images/blog/post-img1.jpg" alt="New shop collection our shop" width={740} height={410} /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <h2 className="h3"><Link to="">New shop collection our shop</Link></h2>
                                        <ul className="publish-detail d-flex-wrap">
                                            <li><i className="icon anm anm-user-al" /> <span className="opacity-75 me-1">Posted
                                                by:</span> User</li>
                                            <li><i className="icon anm anm-clock-r" /> <time dateTime="2023-01-02">Jan 02,
                                                2023</time></li>
                                            <li><i className="icon anm anm-comments-l" /> <Link to="#">2 Comments</Link></li>
                                        </ul>
                                        <p className="content">Latin literature from 45 BC, making it over 2000 years old.
                                            Richard McClintock, a Latin professor</p>
                                        <Link to="" className="btn btn-secondary btn-sm">Read more</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-item col-item">
                                <div className="blog-article zoomscal-hov">
                                    <div className="blog-img">
                                        <Link className="featured-image rounded-0 zoom-scal" to=""><img className="rounded-0 blur-up lazyload" data-src="/assets/images/blog/post-img2.jpg" src="assets/images/blog/post-img2.jpg" alt="Gift ideas for everyone" width={740} height={410} /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <h2 className="h3"><Link to="">Gift ideas for everyone</Link></h2>
                                        <ul className="publish-detail d-flex-wrap">
                                            <li><i className="icon anm anm-user-al" /> <span className="opacity-75 me-1">Posted
                                                by:</span> User</li>
                                            <li><i className="icon anm anm-clock-r" /> <time dateTime="2023-01-24">Jan 24,
                                                2023</time></li>
                                            <li><i className="icon anm anm-comments-l" /> <Link to="#">0 Comments</Link></li>
                                        </ul>
                                        <p className="content">It is a long established fact that a reader will be distracted by
                                            the readable content of a page</p>
                                        <Link to="" className="btn btn-secondary btn-sm">Read more</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-item col-item">
                                <div className="blog-article zoomscal-hov">
                                    <div className="blog-img">
                                        <Link className="featured-image rounded-0 zoom-scal" to=""><img className="rounded-0 blur-up lazyload" data-src="/assets/images/blog/post-img3.jpg" src="assets/images/blog/post-img3.jpg" alt="Sales with best collection" width={740} height={410} /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <h2 className="h3"><Link to="">Sales with best collection</Link></h2>
                                        <ul className="publish-detail d-flex-wrap">
                                            <li><i className="icon anm anm-user-al" /> <span className="opacity-75 me-1">Posted
                                                by:</span> User</li>
                                            <li><i className="icon anm anm-clock-r" /> <time dateTime="2023-02-14">Feb 14,
                                                2023</time></li>
                                            <li><i className="icon anm anm-comments-l" /> <Link to="#">4 Comments</Link></li>
                                        </ul>
                                        <p className="content">There are many variations of passages of Lorem Ipsum available,
                                            but the majority have suffered alteration.</p>
                                        <Link to="" className="btn btn-secondary btn-sm">Read more</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-item col-item">
                                <div className="blog-article zoomscal-hov">
                                    <div className="blog-img">
                                        <Link className="featured-image rounded-0 zoom-scal" to=""><img className="rounded-0 blur-up lazyload" data-src="/assets/images/blog/post-img4.jpg" src="assets/images/blog/post-img4.jpg" alt="Word in classical literature" width={740} height={410} /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <h2 className="h3"><Link to="">Word in classical literature</Link></h2>
                                        <ul className="publish-detail d-flex-wrap">
                                            <li><i className="icon anm anm-user-al" /> <span className="opacity-75 me-1">Posted
                                                by:</span> User</li>
                                            <li><i className="icon anm anm-clock-r" /> <time dateTime="2023-02-19">Feb 19,
                                                2023</time></li>
                                            <li><i className="icon anm anm-comments-l" /> <Link to="#">18 Comments</Link></li>
                                        </ul>
                                        <p className="content">Contrary to popular belief, Lorem Ipsum is not simply random
                                            text. It has roots in a piece of classical</p>
                                        <Link to="" className="btn btn-secondary btn-sm">Read more</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-item col-item">
                                <div className="blog-article zoomscal-hov">
                                    <div className="blog-img">
                                        <Link className="featured-image rounded-0 zoom-scal" to=""><img className="rounded-0 blur-up lazyload" data-src="/assets/images/blog/post-img5.jpg" src="assets/images/blog/post-img5.jpg" alt="Creative, flexible and affordable" width={740} height={410} /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <h2 className="h3"><Link to="">Creative, flexible and affordable</Link>
                                        </h2>
                                        <ul className="publish-detail d-flex-wrap">
                                            <li><i className="icon anm anm-user-al" /> <span className="opacity-75 me-1">Posted
                                                by:</span> User</li>
                                            <li><i className="icon anm anm-clock-r" /> <time dateTime="2023-03-08">Mar 08,
                                                2023</time></li>
                                            <li><i className="icon anm anm-comments-l" /> <Link to="#">6 Comments</Link></li>
                                        </ul>
                                        <p className="content">There are many variations of passages of Lorem Ipsum available,
                                            but the majority have suffered</p>
                                        <Link to="" className="btn btn-secondary btn-sm">Read more</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-item col-item">
                                <div className="blog-article zoomscal-hov">
                                    <div className="blog-img">
                                        <Link className="featured-image rounded-0 zoom-scal" to=""><img className="rounded-0 blur-up lazyload" data-src="/assets/images/blog/post-img6.jpg" src="assets/images/blog/post-img6.jpg" alt="Contrary to popular belief" width={740} height={410} /></Link>
                                    </div>
                                    <div className="blog-content">
                                        <h2 className="h3"><Link to="">Contrary to popular belief</Link></h2>
                                        <ul className="publish-detail d-flex-wrap">
                                            <li><i className="icon anm anm-user-al" /> <span className="opacity-75 me-1">Posted
                                                by:</span> User</li>
                                            <li><i className="icon anm anm-clock-r" /> <time dateTime="2023-03-28">Mar 28,
                                                2023</time></li>
                                            <li><i className="icon anm anm-comments-l" /> <Link to="#">13 Comments</Link></li>
                                        </ul>
                                        <p className="content">The standard chunk of Lorem Ipsum used since the 1500s is
                                            reproduced below for those interested.</p>
                                        <Link to="" className="btn btn-secondary btn-sm">Read more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Blog
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import HomeBanner from "./home/HomeBanner";
import CallUsAnyTime from "./home/CallUsAnyTime";
import HomeSmallBanner from "./home/HomeSmallBanner";
import ShopByCategory from "./home/ShopByCategory";
import TopProducts from "./home/TopProducts";
import ParallaxBanner from "./home/ParallaxBanner";
import HappyCustomer from "./home/HappyCustomer";
import LatestPost from "./home/LatestPost";
import { setcurrentOrder } from "../redux/order/OrderSlice";
import { set_all_amount_data } from "../redux/cart/CartSlice";
import CallUsAnyTimeMobile from "./home/CallUsAnyTimeMobile";
import ShopBySubCategory from "./home/ShopBySubCategory";
import ElectronicSubCategory from "./home/ElectronicSubCategory";
import ElectroncsNewArrivals from "./home/ElectroncsNewArrivals";
import ElectronicsTopPicup from "./home/ElectronicsTopPicup";
import ElectricalThreeSlider from "./home/ElectricalThreeSlider";
import BeautySubcategory from "./home/BeautySubcategory";
import Footwear_Subcategory from "./home/Footwear_Subcategory";
import Home_Furnitures from "./home/Home_Furnitures";
import Toys_subcategory from "./home/Toys_subcategory";
import ElectricalThreeSliderCopy from "./home/ElectricalThreeSliderCopy";
import Mens_Footwear from "./home/Mens_Footwear";
import Womens_Footwear from "./home/Womens_Footwear";
import Kids_Footwear from "./home/Kids_Footwear";
import FootwearThreeSlider from "./home/FootwearThreeSlider";
import NewCategory from "./category/NewCategory";
import SellonPage from "./home/SellonPage";

const Home = () => {
  const { threeSliderTotal } = useSelector((store) => store.slider);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setcurrentOrder([]));
    dispatch(set_all_amount_data(""));
  }, []);

  return (
    <>
      <HomeBanner />
      <SellonPage />
      {/* <NewCategory /> */}
      <ShopBySubCategory />
      <HomeSmallBanner />
      <TopProducts />
      <ElectricalThreeSliderCopy />
      {/* <ElectricalThreeSlider /> */}
      <ElectronicSubCategory />
      <ElectroncsNewArrivals />
      <ElectronicsTopPicup />
      <BeautySubcategory />
      <FootwearThreeSlider />
      {/* <Footwear_Subcategory /> */}
      <Mens_Footwear />
      <Womens_Footwear />
      <Kids_Footwear />
      <Home_Furnitures />
      <Toys_subcategory />
      <CallUsAnyTime />
      <CallUsAnyTimeMobile />


      {/* <ParallaxBanner /> */}
      {/* <HappyCustomer /> */}
      {/* <LatestPost /> */}
    </>
  );
};

export default Home;

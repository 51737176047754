import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CheckoutAuthentication from "../CheckoutAuthentication";
import { set_checkout_process_steps } from "../../../redux/athentication/Athentication";

const Authentication = () => {
  const dispatch = useDispatch();
  const { isAuth, current_address, loginData } = useSelector(
    (state) => state.Athentication
  );
  return (
    <>
      <div className="tab-pane active" id="steps1">
        <div className="row">
          {isAuth ? (
            <>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-4 mb-md-0">
                <div className="block h-100">
                  <div className="block-content">
                    <h3 className="title">Your an authenticated user</h3>
                    <p className="text-gray">
                      your login with {loginData.Mobile}
                    </p>

                    {/* <button
                                type="submit"
                                name="Continue"
                                className="btn btn-primary"
                              >
                                Continue
                              </button> */}
                    <button
                      type="submit"
                      name="Continue"
                      onClick={() => dispatch(set_checkout_process_steps(1))}
                      className="btn btn-secondary ms-2 btnNext"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <CheckoutAuthentication />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Authentication;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ElectronicSingleProduct from "../../component/product/ElectronicSingleProduct";

const ElectronicsTopPicup = () => {
  const { electronicRecommendedProducts } = useSelector(
    (store) => store.product
  );
  return (
    <>
      {electronicRecommendedProducts.length > 0 && (
        <section
          className="section product-banner-slider"
          style={{ marginTop: "-10px" }}
        >
          <div className="container">
            <div className="section-header style2 d-flex-center justify-content-sm-between">
              <div className="section-header-left text-start w-100">
                <h2 className="TitleHeading"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  //   fontFamily: "Ga Maamli,sans-serif",
                  //   color: "#315999",
                  //   fontSize: "26px",
                  }}
                >
                  THIS WEEK TOP PICKS
                </h2>
                {/* <p>Shop our new arrivals in Electronics</p> */}
              </div>
              {/* <div className="section-header-right text-start text-sm-end mt-3 mt-sm-0">
              <a href="shop-left-sidebar.html" className="btn btn-primary">
                View More <i className="icon anm anm-arw-right ms-2" />
              </a>
            </div> */}
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="grid-products product-slider-3items gp15 arwOut5 hov-arrow circle-arrow arrowlr-0">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    autoplay={{
                      delay: 1500,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      "@0.75": {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      "@1.00": {
                        slidesPerView: 4,
                        spaceBetween: 25,
                      },
                      "@1.50": {
                        slidesPerView: 4,
                        spaceBetween: 25,
                      },
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                    style={{ marginTop: "-30px" }}
                  >
                    {electronicRecommendedProducts.map((product, index) => (
                      <SwiperSlide key={index}>
                        <ElectronicSingleProduct product={product} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ElectronicsTopPicup;

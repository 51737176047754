import React from "react";
import { Button, Result } from "antd";
import { Link, useNavigate } from "react-router-dom";

const NoProductFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Result
        status="404"
        title=""
        subTitle={
          <span className="found_title">Sorry, no products found !</span>
        }
        extra={
          <Button onClick={() => navigate("/")} type="primary">
            Back To Home
          </Button>
        }
      />
    </>
  );
};

export default NoProductFound;

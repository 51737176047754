import React from "react";

const About = () => {
  return (
    <>
      <div id="page-content">
        <div className="page-header text-center">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                <div className="page-title">
                  <h1>About Us</h1>
                </div>
                {/* <div className="breadcrumbs">
                  <a href="" title="Back to the home page">
                    Home
                  </a>
                  <span className="main-title fw-bold">
                    <i className="icon anm anm-angle-right-l" />
                    About Us
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="destination-section section pt-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="about-images">
                  <img
                    className="rounded-0 w-100 blur-up lazyload"
                    data-src="/assets/images/about/about4.jpg"
                    src="assets/images/about/about4.jpg"
                    alt="about"
                    width={700}
                    height={600}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="about-details faqs-style faqs-style2 pt-4">
                  <h2 className="fs-3 mb-3">
                    We Provide Continuous &amp; Kind Service for Customers
                  </h2>
                  <p>
                    EwShopping a brand of <b style={{fontStyle:"italic"}}>Elderwise Shopping India pvt Ltd</b>, is India's most reliable and frictionless
                    commerce ecosystem that creates life-changing experiences
                    for buyers and sellers and leading and national brands for
                    apparel, fragrances, accessories, cosmetics, footwear, home
                    décor, and furnishings catering to the needs of the entire
                    family
                  </p>
                  <div className="accordion" id="accordionFaq">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          Customer Service
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionFaq"
                      >
                        <div className="accordion-body">
                          <p>
                            We aspire to provide our customers with a memorable
                            international shopping experience. We are one of the
                            largest chains of department stores across India.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Online Consultation</button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFaq">
                                                <div className="accordion-body">
                                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
                                                </div>
                                            </div>
                                        </div> */}
                    {/* <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Sales Management</button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFaq">
                                                <div className="accordion-body">
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words discovered source.</p>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <section data-bs-version="5.1" className="content7 cid-sPQDArpEN3">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                  <blockquote>
                    <h5 className="mbr-section-title mbr-fonts-style mb-2 display-7">
                      <strong>Our Vision Is</strong>
                    </h5>
                    <p className="mbr-text mbr-fonts-style display-10">
                      “To be an inspirational and trusted brand, transforming
                      customers' lives through fashion and delightful shopping
                      experience every time” We have a team of professional
                      associates who strive endlessly to provide the best
                      shopping experience to each of our customers. We have
                      adopted a new philosophy of "Start Something New" to give
                      retail a new dimension and innovation is our key driver to
                      attain excellence in customer service.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </section>
          <section data-bs-version="5.1" className="content7 cid-sPQDArpEN3">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                  <blockquote className="w-100">
                    <h5 className="mbr-section-title mbr-fonts-style mb-2 display-7">
                      <strong>WHY SHOP WITH US</strong>
                    </h5>
                    <p className="mbr-text mbr-fonts-style display-10">
                      SIMPLE SHOPPING Whether you shop online or in-store or any
                      of the leading third-party marketplaces you get the same
                      experience in terms of merchandise, price &amp; service.
                      SECURE Shopping on our website is 100% safe and secure as
                      we do not store complete details or payment information of
                      your credit/debit card in our system. This is information
                      is directly transmitted securely to the bank for payment
                      processing. However, caution must always be taken by the
                      customer while making transactions on any online venue.
                      RANGE OF PRODUCTS AND BRANDS We assort the collection from
                      the best brands, trends, colors, fabrics, patterns to
                      bring you a deep fashion selection wide across Men, Women,
                      Kids, Fashion Accessories, and Home categories. FREE
                      RETURNS At EwShopping we follow a policy that is – "We are
                      responsible for what we sell". We offer free and easy
                      returns on most of the merchandise bought online. If you
                      are not completely satisfied with your purchase you can
                      return the items via a courier pick up or exchange them in
                      any of our stores across India. 100% ORIGINAL We are
                      responsible for what we sell and guarantee the originality
                      of the products. All our products are sourced directly
                      from the brands and carry brand warranty and genuineness
                      certificates. FREE ALTERATIONS We offer free alteration at
                      any Store for products purchased online as well. EXPRESS
                      STORE PICK UP We provide the facility of express pick-up
                      service at the store. You can shop online and collect your
                      order from the nearest or preferred EwShopping store at
                      your convenience. PERSONALIZED SHOPPING AT STORE We offer
                      personal shopper service with prior appointments at our
                      store. You can avail the assistance of personal shoppers
                      who are experts to guide you to shop in an exclusive
                      lounge at the store. PERSONAL SHOPPER SERVICE AT HOME We
                      also provide Personal Shopper At Home service and you can
                      shop from the comforts of your home with prior appointment
                      with assistance from our personal shoppers. PERSONALIZED
                      SHOPPING GUIDE We provide personalized shopping assistance
                      by following our Style Hub which is curated with the
                      latest fashion trends and suggestions.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <div className="destination-section section section-color-light">
          <div className="container">
            <div className="row row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-4 text-center">
              <div className="counterup-items">
                <i className="icon anm anm-history fs-3 mb-3" />
                <p className="counterup-number">
                  <span className="counterup" data-count={50}>
                    50
                  </span>
                  <span className="ms-1 text">M+</span>
                </p>
                <h5 className="counterup-title">Years of foundation</h5>
              </div>
              <div className="counterup-items">
                <i className="icon anm anm-users-r fs-3 mb-3" />
                <p className="counterup-number">
                  <span className="counterup" data-count={100}>
                    100
                  </span>
                  <span className="ms-1 text">B+</span>
                </p>
                <h5 className="counterup-title">Skilled team members</h5>
              </div>
              <div className="counterup-items">
                <i className="icon anm anm-handshake-l fs-3 mb-3" />
                <p className="counterup-number">
                  <span className="counterup" data-count={80}>
                    80
                  </span>
                  <span className="ms-1 text">M+</span>
                </p>
                <h5 className="counterup-title">Happy customers</h5>
              </div>
              <div className="counterup-items">
                <i className="icon anm anm-bar-chart-o fs-3 mb-3" />
                <p className="counterup-number">
                  <span className="counterup" data-count={70}>
                    70
                  </span>
                  <span className="ms-1 text">B+</span>
                </p>
                <h5 className="counterup-title">Monthly orders</h5>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="team-section section">
          <div className="container">
            <div className="section-header">
              <p className="mb-2 mt-0">Good leaders make it better</p>
              <h2>Meet Our Leaders</h2>
            </div>
            <div className="team-items row col-row row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 text-center">
              <div className="team-item col-item">
                <div className="team-img zoom-scal rounded-0">
                  <img
                    className="rounded-0 blur-up lazyload w-100"
                    data-src="/assets/images/users/usemen1.png"
                    src="/assets/images/users/usemen1.png"
                    alt="team"
                    width={350}
                    height={350}
                  />
                  <ul className="list-inline social-icons d-flex-justify-center">
                    <li className="list-inline-item">
                      <a
                        className="clr-none"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Facebook"
                      >
                        <i className="icon anm anm-facebook-f" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="clr-none"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Twitter"
                      >
                        <i className="icon anm anm-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="clr-none"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Pinterest"
                      >
                        <i className="icon anm anm-pinterest-p" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="clr-none"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Instagram"
                      >
                        <i className="icon anm anm-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="team-bio pt-3">
                  <h3 className="mb-2">Rupesh Kumar</h3>
                  <p style={{ color: "#000", fontWeight: "700" }}>C.E.O </p>
                </div>
              </div>
             
             
                          
            </div>
          </div>
        </div> */}
        <div className="awards-section section section-color-light">
          <div className="container">
            <div className="row col-row row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2 text-center">
              <div className="awards-items col-item">
                <p className="awards-icon d-flex-justify-center">
                  <i className="icon anm anm-trophy-ar" />
                </p>
                <h2 className="awards-title">
                  Winner Master <br /> Award 2019
                </h2>
              </div>
              <div className="awards-items col-item">
                <p className="awards-icon d-flex-justify-center">
                  <i className="icon anm anm-trophy-ar" />
                </p>
                <h2 className="awards-title">
                  Media Agencies <br /> Partner 2020
                </h2>
              </div>
              <div className="awards-items col-item">
                <p className="awards-icon d-flex-justify-center">
                  <i className="icon anm anm-trophy-ar" />
                </p>
                <h2 className="awards-title">
                  Fastest Growing <br /> Award 2021
                </h2>
              </div>
              <div className="awards-items col-item">
                <p className="awards-icon d-flex-justify-center">
                  <i className="icon anm anm-trophy-ar" />
                </p>
                <h2 className="awards-title">
                  National Excellence <br /> Award 2023
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="video-popup-section section pb-0">
                    <div className="container">
                        <div className="section-header d-none">
                            <h2>EWShopping fashion store</h2>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="video-popup-content position-relative">
                                    <a href="#aboutVideo-modal" className="popup-video d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#aboutVideo_modal" title="View Video">
                                        <img className="w-100 d-block blur-up lazyload" data-src="/assets/images/about/video-popup-bg.jpg" src="assets/images/about/video-popup-bg.jpg" alt="image" title width={1100} height={600} />
                                        <i className="icon anm anm-play-cir" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="content-section text-center col-lg-9 mx-auto mt-4">
                                    <h3 className="fs-4 mb-3">Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      </div>
    </>
  );
};

export default About;

import React from "react";
import { useSelector } from "react-redux";
import Authentication from "./checkoutSteps/Authentication";
import AddAddressCheckout from "./checkoutSteps/AddAddressCheckout";
import OrderSummery from "./checkoutSteps/OrderSummery";
import SelectPayment from "./checkoutSteps/SelectPayment";

const CheckoutStepsPage = () => {
  const { checkout_process_steps } = useSelector(
    (state) => state.Athentication
  );

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <Authentication />;
      case 1:
        return <AddAddressCheckout />;
      case 2:
        return <OrderSummery />;
      case 3:
        return <SelectPayment />;
      default:
        return <Authentication />;
        break;
    }
  };
  return <>{renderSwitch(checkout_process_steps)}</>;
};

export default CheckoutStepsPage;

import React from "react";

const Affiliate = () => {
  return (
    <>
      <div className="page-header text-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
              <div className="page-title">
                <h1>Affiliate</h1>
              </div>
              {/* <div className="breadcrumbs">
                <a to="/" title="Back to the home page">
                  Home
                </a>
                <span className="title">
                  <i className="icon anm anm-angle-right-l" />
                  Pages
                </span>
                <span className="main-title fw-bold">
                  <i className="icon anm anm-angle-right-l" />
                  Information Page
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-content">
          <h3 style={{ color: "#1b59ba", fontWeight: "700", fontSize: "22px" }}>
            Elderwise Shopping India pvt Ltd{" "}
          </h3>

          <h3 style={{textAlign:'center'}}>WANTED: BECOME AN AFFILIATE</h3>

          <h3>Easy Signup</h3>
          <p style={{ textAlign: "justify" }}>
            {" "}
            With a simple application process and one-stop approval, it’s all
            aboard the bullet train to Earningtown.
          </p>

          <h3>Potent Promotions</h3>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Choose the products that click with your audience, use our gorgeous
            creative assets, and take advantage of our lively promo calendar.
            Boom.
          </p>

          <h3>Sweet Rewards</h3>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Earn up to 10% commission with a 30-day attribution window, plus
            bonus incentive opportunities. Wow, right?
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Have we piqued your interest? Find out more in the FAQ below, and
            feel free to hit us up at support.EWShopping@gmail.com
          </p>

          <ul className="">
            <h3 style={{ textAlign: "center" }}>FREQUENTLY ASKED QUESTIONS</h3>
            <h3>What is Impact?</h3>

            <li className="mb-1 ">
              The impact is our affiliate tracking platform that provides
              real-time tracking of clicks, sales, and commission in the
              program, uses to help us connect with our affiliate publishers and
              partners.
            </li>
            <h3>What exactly is an “affiliate?”</h3>

            <li className="mb-1 ">
              An affiliate is someone who posts links to EWShopping on their
              website, blog, mobile app, or other digital channels. If anyone
              from that audience buys anything through those links, the
              affiliate gets some of that money.
            </li>
            <h3>How do I become an affiliate?</h3>

            <li className="mb-1 ">
              You can sign up on our platform. Our team will review your
              application and let you know if we feel that you would be a good
              fit for EWShopping. Unlike many affiliate programs, we don’t
              require multiple approvals from other companies.
            </li>
          </ul>

          <h3 style={{ textAlign: "center" }}>
            OUR TOP CATEGORIES FOR ONLINE SHOPPING
          </h3>
          <p style={{ textAlign: "justify" }}>
            ELECTRONICS: Televisions | Speakers | Headphone & Earphones | Audio
            & Video | Winter Appliances | Washing Machines | Coolers | Fans |
            Grooming Appliances | Small Appliances
          </p>
          <p style={{ textAlign: "justify" }}>
            MOBILE, TABLETS: Mobile Cases & Covers | Mobile Phones | Tablets |
            Mobile Accessories | Wearables & Smartwatches | Tablet Accessories
          </p>
          <p style={{ textAlign: "justify" }}>
            Women's Fashion: Ethnic Wear | Footwear | Western Wear | Winter Wear
            | Handbags & Clutches | Eyewear | Fashion Jewellery | Watches |
            Precious Jewellery | Gold Coins & Bars | Fashion Accessories |
            Sunglasses
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Mens Fashion: Foot Wear | Bags & Luggage | Clothing | Winterwear |
            Sports Wear | Mens Grooming | Eye Wear | Watches | Jewellery &
            Cufflinks | Accessories | Fragrances
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Beauty Health: Beauty | Personal-Care-Grooming | Sexual Wellness |
            Food And Gourmet | Nutrition & Supplements | Health Monitoring
            Devices | General Wellness | Baby Care | Household Essentials
          </p>
          <p style={{ textAlign: "justify" }}>
            Toys Kids Fashion: Toys | Bicycles & Tricycles | Kids Footwear |
            Accessories | Kids Clothing | Girls Clothing | Boys Clothing | Baby
            Clothing | Baby Care | Stationery
          </p>
          <p style={{ textAlign: "justify" }}>
            Home Kitchen: Kitchen Appliances | Kitchenware | Home-Furnishing |
            Furniture | Home Decor | Home Improvement | Tools & Hardware | Pet
            Supplies
          </p>
          <p style={{ textAlign: "justify" }}>
            Sports Fitness Outdoor: Sports & Fitness | Get Fit At Home | Get
            Ready For Gym | Sports | Hiking & Outdoor
          </p>
          <p style={{ textAlign: "justify" }}>
            CAR MOTORBIKE: Automotive Accessories | Car & Vehicle Electronics |
            Car Audio Finder | Car Accessories | Tyre Inflators |Automotive |
            Car Fresheners | Air Purifiers & Ionizers | Biker Gear & Accessories
            | Biker Raincoats | Helmets & Accessories | Parts & Spares | Tyres &
            Alloys | Motorbike Batteries
          </p>
          <p style={{ textAlign: "justify" }}>
            Computers Gaming: Laptops | Printers & Inks | Networking Devices |
            Office Equipment | Storage | Computer Accessories | Desktops |
            Gaming | Computer Components | Software | Monitors | Top Laptop
            Brands
          </p>
          <p style={{ textAlign: "justify" }}>
            BOOKS MEDIA MUSIC: Top Exams| Browse By Genre | Online Education |
            Musical Instruments | Indian Instruments | Pa & Stage | Other
            Instruments | Hobbies New | E-Gift Cards | Physical Gift Cards |
            Digital Subscriptions | Movies & Tv Shows
          </p>
          <p style={{ textAlign: "justify" }}>
            Why Choose EWShopping as the Best Online Shopping Sites in India
          </p>
          <p style={{ textAlign: "justify" }}>
            Worldwide Shipping: Available as Standard or Express delivery
            EWShopping Promise: Products with this tag are quality checked, and
            shipped the same day from certified warehouses. So you get the right
            product, faster. Secure Payments: At EWShopping 100% Secure payment.
            If you have any issues, your money is immediately refunded. Sit back
            and enjoy your shopping. Free Return: Your money is yours! All
            refunds come with a question-asked guarantee for all orders. 24/7
            Dedicated support: Need Help? Click Here. You can also talk to us on
            -------- to resolve your query.
          </p>
          <p style={{ textAlign: "justify" }}>
            India's Best Online Shopping Website for Fashion & Lifestyle
          </p>
          <p style={{ textAlign: "justify" }}>
            EWShopping is the Best Site for Online shopping in India when it
            comes to online clothes shopping for men, women, and kids at the
            lowest prices. The latest fashion trends and styles are showcased
            here, yes at your favorite online shopping destination. Well, if
            fashion is medicine then Beyoung is the chemist shop where you can
            do your online shopping for men and women with ease. EWShopping is
            without a doubt one of the best online shopping sites in India. The
            website is replete with the cool products that you always crave.
            Indeed, online shopping for women, men & kids at Beyoung is such
            hassle-free that in just a few clicks one can purchase whatever he
            or she wants. A one-stop destination for all your shopping needs,
            Beyoung caters to each taste and need for men’s clothing, Women's
            Clothing, and accessories.
          </p>
          <p style={{ textAlign: "justify" }}>
            EWShopping is the Best Site for Online shopping in India when it
            comes to online clothes shopping for men, women, and kids at the
            lowest prices. The latest fashion trends and styles are showcased
            here, yes at your favorite online shopping destination. Well, if
            fashion is medicine then Beyoung is the chemist shop where you can
            do your online shopping for men and women with ease. EWShopping is
            without a doubt one of the best online shopping sites in India. The
            website is replete with the cool products that you always crave.
            Indeed, online shopping for women, men & kids at Beyoung is such
            hassle-free that in just a few clicks one can purchase whatever he
            or she wants. A one-stop destination for all your shopping needs,
            Beyoung caters to each taste and need for men’s clothing, Women's
            Clothing, and accessories.
          </p>
          <br />
        </div>
      </div>
      <div style={{ marginBottom: "50px" }}></div>
    </>
  );
};

export default Affiliate;

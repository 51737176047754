import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Steper from "./Steper";
import { FaDownload } from "react-icons/fa6";
import { AiTwotoneRest } from "react-icons/ai";
import { AiTwotoneThunderbolt } from "react-icons/ai";
import {
  clearCart,
  getCartTotal,
  removefromCart,
  set_all_amount_data,
} from "../../../redux/cart/CartSlice";
import { newOrder, setcurrentOrder } from "../../../redux/order/OrderSlice";
import { set_checkout_process_steps } from "../../../redux/athentication/Athentication";
const MobViewCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth, current_address, loginData } = useSelector(
    (state) => state.Athentication
  );
  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
    coupon,
    DeliveryCharge,
    rainStatus,
    RainFee,
    Netpayable,
    SmallCartFee,
    wallet,
    TotalMrp,
  } = useSelector((state) => state.cart);

  const [buttonPress, setbuttonPress] = useState(false);
  const [orderComment, setorderComment] = useState("");

  const removefromCartitem = async (e, item) => {
    e.preventDefault();
    const cart = await dispatch(removefromCart(item));
    dispatch(getCartTotal());
  };
  const cash_on_delivery_press = async (e) => {
    e.preventDefault();
    setbuttonPress(true);

    try {
      if (CartItems.length > 0 && current_address !== "") {
        const address_values = Object.values(current_address);
        const address_string = address_values.join(",");

        var date = new Date();
        date.setDate(date.getDate() + 4);
        var today_date = new Date();
        today_date.setDate(today_date.getDate());
        let all_post_orders = [];
        for (let index = 0; index < CartItems.length; index++) {
          let cartItem = CartItems[index];
          let order_form = {};
          try {
            order_form = {
              ...cartItem,
              userId: loginData._id,
              UserName: loginData.Name,
              UserEmail: loginData.Email,
              UserMobile: loginData.Mobile,
              Address: address_string,
              TotalMrp: cartItem.Product_total_Mrp,
              TotalPrice: cartItem.Product_total_Price,
              SmallCartFee: SmallCartFee,
              HandllingFee: HandllingFee,
              RainFee: RainFee,
              DeliveryCharge: DeliveryCharge,
              wallet: wallet,
              coupon: coupon,
              Saving: cartItem.Product_total_Mrp - cartItem.Product_total_Price,
              TotalAmount:
                cartItem.Product_total_Price +
                SmallCartFee +
                HandllingFee +
                RainFee +
                DeliveryCharge,
              Netpayable:
                cartItem.Product_total_Price +
                SmallCartFee +
                HandllingFee +
                RainFee +
                DeliveryCharge -
                wallet -
                coupon,
              PaymentMode: "Cash on Delivery",
              PaymentStatus: "Not Paid",
              TxnId: "",
              ExpectedDelDate: date,
              OrderComment: orderComment,
              OrderprocessDate: {
                OrderBookedDate: today_date,
                OrderBookedDateShow: true,
              },
            };

            const order_post_respo = await dispatch(newOrder(order_form));

            if (order_post_respo.payload.success) {
              const order_respo = order_post_respo.payload.order;
              all_post_orders = [...all_post_orders, order_respo];
            }
          } catch (error) {
            setbuttonPress(false);
          }
        }

        const all_price_details = {
          TotalMrp: TotalMrp,
          TotalPrice: TotalPrice,
          SmallCartFee: SmallCartFee,
          HandllingFee: HandllingFee,
          RainFee: RainFee,
          DeliveryCharge: DeliveryCharge,
          wallet: wallet,
          coupon: coupon,
          TotalAmount: TotalAmount,
          Netpayable: Netpayable,
          rainStatus: rainStatus,
        };

        if (all_post_orders.length === CartItems.length) {
          dispatch(setcurrentOrder(all_post_orders));
          dispatch(set_all_amount_data(all_price_details));
          dispatch(set_checkout_process_steps(0));
          navigate("/OrderSuccess");
        } else {
          setbuttonPress(false);
        }
      }
    } catch (error) {
      setbuttonPress(false);
    }
  };
  const handleCheckout = () => {
    navigate("/check-out");
  };

  return (
    <>
      <div className="">
        <div>
          {CartItems.map((item, index) => (
            <div
              style={{ borderBottom: ".5px solid #ccc", paddingBottom: "4px" }}
              key={index}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px",
                }}
                onClick={() => navigate(`/product-info/${item.slugurl}`)}
              >
                <div>
                  <img
                    src={item.thumbnail}
                    alt="user"
                    height="70px"
                    width="100px"
                  />
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <h5>{item.ProductName}</h5>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "150px",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ fontSize: "12px", marginTop: "-15px" }}>
                      Pack size: {item.packSizeOptionName}
                    </p>

                    <h5 style={{ marginTop: "-10px" }}>
                      Qty: {item.cart_Quentity}
                    </h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-9px",
                    }}
                  >
                    {/* <h4 style={{ color: "#29a644", fontSize: "16px" }}>
                    17% off
                  </h4> */}
                    <h3
                      style={{
                        color: "#e96f84",
                        fontSize: "17px",
                      }}
                    >
                      ₹{item.Product_total_Price}
                    </h3>
                    <h5
                      style={{
                        color: "#555",
                        fontSize: "14px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        textDecorationLine: "line-through",
                      }}
                    >
                      ₹{item.Mrp}
                    </h5>
                  </div>
                  <p
                    style={{
                      marginTop: "-15px",
                      fontSize: "12px",
                      color: "#000",
                    }}
                  >
                    + ₹{HandllingFee} Secured Packaging Fee
                  </p>
                  <div style={{ marginTop: "-7px" }}>
                    <span
                      style={{
                        color: "#29a644",
                        fontSize: "13px",
                        paddingRight: "10px",
                        fontWeight: "700",
                      }}
                    >
                      offers not applied
                    </span>
                  </div>
                </div>
              </div>
              <div className="mainaitworemove">
                <div className="aitworemove">
                  <FaDownload className="aiticon" />
                  <h6 className="aittext">Save for later</h6>
                </div>
                <div
                  className="aitworemove"
                  onClick={(e) => removefromCartitem(e, item)}
                >
                  <AiTwotoneRest className="aiticon" />
                  <h6 className="aittext">Remove</h6>
                </div>
                <div className="aitworemove" onClick={() => handleCheckout()}>
                  <AiTwotoneThunderbolt className="aiticon" />
                  <h6 className="aittext">Buy this now</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MobViewCart;

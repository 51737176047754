import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { compress, decompress } from 'lz-string';
import { Baseurl, Baseurl2 } from "../../config/BaseUrl";

const initialState = {
  quickViewProduct: "",
  productTotal: [],
  // productTotal: localStorage.getItem("productTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("productTotal")))
  //   : [],
  productTotalLoading: true,
  isProductTotalAvailable: localStorage.getItem("productTotal")
    ? true
    : false,
  // recommendedProductTotal: localStorage.getItem("recommendedProductTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("recommendedProductTotal")))
  //   : [],
  // recomendproductLoading: true,
  // isRecommendProductAvailable: localStorage.getItem("recommendedProductTotal")
  //   ? true
  //   : false,
  // clothingRecommendedProducts: localStorage.getItem("recommendedProductTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("recommendedProductTotal"))).filter((data) => data.superCategory === "Clothing")
  //   : [],
  // electronicRecommendedProducts: localStorage.getItem("recommendedProductTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("recommendedProductTotal"))).filter((data) => data.superCategory === "Electronics")
  //   : [],
  recommendedProductTotal: [],
  recomendproductLoading: true,
  isRecommendProductAvailable: false,
  clothingRecommendedProducts: [],
  electronicRecommendedProducts: [],

  trendingProductTotal: [],
  trendingproductLoading: true,
  istrendingProductAvailable: false,

  clothingTrendingProduct: [],
  electronicTrendingProduct: [],
  // trendingProductTotal: localStorage.getItem("trendingProductTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("trendingProductTotal")))
  //   : [],
  // trendingproductLoading: true,
  // istrendingProductAvailable: localStorage.getItem("trendingProductTotal")
  //   ? true
  //   : false,

  // clothingTrendingProduct: localStorage.getItem("trendingProductTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("trendingProductTotal"))).filter((data) => data.superCategory === "Clothing")
  //   : [],
  // electronicTrendingProduct: localStorage.getItem("trendingProductTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("trendingProductTotal"))).filter((data) => data.superCategory === "Electronics")
  //   : [],


  hotProductTotal: [],
  clothingHotProduct: [],
  electronicHotProduct: [],
  hotproductLoading: true,
  ishotProductAvailable: false,
  // hotProductTotal: localStorage.getItem("hotProductTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("hotProductTotal")))
  //   : [],
  // clothingHotProduct: localStorage.getItem("hotProductTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("hotProductTotal"))).filter((data) => data.superCategory === "Clothing")
  //   : [],
  // electronicHotProduct: localStorage.getItem("hotProductTotal")
  //   ? JSON.parse(decompress(localStorage.getItem("hotProductTotal"))).filter((data) => data.superCategory === "Electronics")
  //   : [],
  // hotproductLoading: true,
  // ishotProductAvailable: localStorage.getItem("hotProductTotal")
  //   ? true
  //   : false,
};

export const getProduct = createAsyncThunk(
  "Product/getProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/all`;
      const resp = await axios(url);
      return resp.data.product;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getRecommendProduct = createAsyncThunk(
  "Product/getRecommendProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/recommend-products`;
      const resp = await axios(url);
      return resp.data.products;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getTrendingProduct = createAsyncThunk(
  "Product/getTrendingProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/trending-products`;
      const resp = await axios(url);
      return resp.data.products;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const getHotProduct = createAsyncThunk(
  "Product/getHotProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/hot-products`;
      const resp = await axios(url);
      return resp.data.products;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const getSingleProduct = createAsyncThunk(
  "product/getSingleProduct",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new slugurl",
    };
    try {
      const url = `${Baseurl}/api/v1/product/verify-slugurl/${slugurl}`;
      const resp = await axios.get(url);


      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

const ProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setQuickViewProduct(state, action) {
      state.quickViewProduct = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecommendProduct.pending, (state) => {
        state.recomendproductLoading = true;
      })
      .addCase(getRecommendProduct.fulfilled, (state, action) => {
        state.recommendedProductTotal = action.payload;
        state.clothingRecommendedProducts = state.recommendedProductTotal.filter((data) => data.superCategory === "Clothing");
        state.electronicRecommendedProducts = state.recommendedProductTotal.filter((data) => data.superCategory === "Electronics");
        localStorage.setItem(
          "recommendedProductTotal",
          compress(JSON.stringify(state.recommendedProductTotal))
        );
        state.recomendproductLoading = false;
        state.isRecommendProductAvailable = true;
      })
      .addCase(getRecommendProduct.rejected, (state) => {
        state.recomendproductLoading = true;
      });
    builder
      .addCase(getTrendingProduct.pending, (state) => {
        state.trendingproductLoading = true;
      })
      .addCase(getTrendingProduct.fulfilled, (state, action) => {
        state.trendingProductTotal = action.payload;
        state.clothingTrendingProduct = state.trendingProductTotal.filter((data) => data.superCategory === "Clothing");
        state.electronicTrendingProduct = state.trendingProductTotal.filter((data) => data.superCategory === "Electronics");
        localStorage.setItem(
          "trendingProductTotal",
          compress(JSON.stringify(state.trendingProductTotal))
        );
        state.trendingproductLoading = false;
        state.istrendingProductAvailable = true;
      })
      .addCase(getTrendingProduct.rejected, (state) => {
        state.trendingproductLoading = true;
      });
    builder
      .addCase(getHotProduct.pending, (state) => {
        state.hotproductLoading = true;
      })
      .addCase(getHotProduct.fulfilled, (state, action) => {
        state.hotProductTotal = action.payload;
        state.clothingHotProduct = state.hotProductTotal.filter((data) => data.superCategory === "Clothing");
        state.electronicHotProduct = state.hotProductTotal.filter((data) => data.superCategory === "Electronics");
        localStorage.setItem(
          "hotProductTotal",
          compress(JSON.stringify(state.hotProductTotal))
        );
        state.hotproductLoading = false;
        state.ishotProductAvailable = true;
      })
      .addCase(getHotProduct.rejected, (state) => {
        state.hotproductLoading = true;
      });
    builder
      .addCase(getProduct.pending, (state) => {
        state.productTotalLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productTotal = action.payload;

        localStorage.setItem("productTotal", compress(JSON.stringify(state.productTotal)));

        // localStorage.setItem(
        //   "productTotal",
        //   JSON.stringify(state.productTotal)
        // );
        state.productTotalLoading = false;
        state.isProductTotalAvailable = true;
      })
      .addCase(getProduct.rejected, (state) => {
        state.productTotalLoading = true;
      });
  },
});
export const { setQuickViewProduct } = ProductSlice.actions;
export default ProductSlice.reducer;

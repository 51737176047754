import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ElectronicSingleProduct from "../../component/product/ElectronicSingleProduct";

const ElectroncsNewArrivals = () => {
  const { electronicHotProduct } = useSelector((store) => store.product);
  const navigate = useNavigate();
  return (
    <>
      {electronicHotProduct.length > 0 && (
        <section className="section product-banner-slider pt-0">
          <div className="container totaketop">
            <div
              className="section-header style2 d-flex-center justify-content-sm-between"
              style={{ marginTop: "-15px" }}
            >
              <div className="section-header-left text-start w-100">
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    //   fontFamily: "Ga Maamli,sans-serif",
                    //   color: "#315999",
                    //   fontSize: "26px",
                  }}
                  className="TitleHeading"
                >
                  NEW ARRIVALS
                </h2>
                {/* <p  style={{display:'flex',justifyContent:"center",alignItems:"center"}}>Shop our new arrivals in Electronics</p> */}
              </div>
              {/* <div className="section-header-right text-start text-sm-end mt-3 mt-sm-0">
              <a href="shop-left-sidebar.html" className="btn btn-primary">
                View More <i className="icon anm anm-arw-right ms-2" />
              </a>
            </div> */}
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                <div className="grid-products product-slider-3items gp15 arwOut5 hov-arrow circle-arrow arrowlr-0">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      "@0.75": {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      "@1.00": {
                        slidesPerView: 4,
                        spaceBetween: 25,
                      },
                      "@1.50": {
                        slidesPerView: 4,
                        spaceBetween: 25,
                      },
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                    style={{ marginTop: "-30px" }}
                  >
                    {electronicHotProduct &&
                      electronicHotProduct.map((product, index) => (
                        <SwiperSlide key={index}>
                          <ElectronicSingleProduct product={product} />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-3 mt-4 mt-lg-0">
                <div
                  className="ctg-bnr-wrap two position-relative h-100"
                  onClick={() => navigate("/category/mobile--accessories")}
                >
                  <div className="ctg-image ratio ratio-1x1 h-100">
                    <img
                      className="blur-up lazyload"
                      data-src="/assets/images/collection/demo4-ct-img1.jpg"
                      src="/assets/images/collection/demo4-ct-img1.jpg"
                      alt="collection"
                      width={309}
                      height={483}
                    />
                  </div>
                  <div className="ctg-content text-white d-flex-justify-center flex-nowrap flex-column h-100">
                    <h2 className="ctg-title text-black m-0">
                      <span className="d-block"></span> Sale
                    </h2>
                    <p className="saleText text-uppercase mb-0">30% Off</p>
                    <p className="ctg-des mt-1 mb-4 text-black">
                      On all new arrival product
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ElectroncsNewArrivals;

import React from "react";
import SliderHomeBanner from "../../component/homecomponent/SliderHomeBanner";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const HomeBanner = () => {
  const { mainHomeSliderTotal } = useSelector((store) => store.slider);
  const navigate = useNavigate();
  return (
    <>
      <section className="slideshow slideshow-wrapper  mobile-version-to-top">
        <Link to="">
          <div className="home-slideshow slick-arrow-dots">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={true}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
              style={{
                "--swiper-pagination-color": "#FEBD69",
              }}
            >
              {mainHomeSliderTotal &&
                mainHomeSliderTotal.map((slider, index) => (
                  <SwiperSlide key={index}>
                    <Link className="slide" to={`/category/${slider.slugUrl}`}>
                      <div className="slideshow-wrap">
                        <picture>
                          <source
                            media="(max-width:767px)"
                            srcSet={slider.desktopImage}
                            width={1150}
                            height={800}
                          />
                          <img
                            className="blur-up lazyload homebannerimgs homebannerimgs1"
                            srcSet={slider.desktopImage}
                            alt="slideshow"
                            title
                            width={1920}
                            height={795}
                          />
                        </picture>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </Link>
      </section>
    </>
  );
};

export default HomeBanner;

import React, { useRef} from 'react';

const WomensSizeChart = ({ pack, productifo, onClose, line, incm, getsetInCm, getsetLine, getsetLineColor }) => {
  const contentRef = useRef(null);
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <div className="Size-Main">
        <div className="sub-main">
          <div className="top-part">
            <div className="crossicon">
              <span>
                {" "}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill=""
                  onClick={() => onClose()}
                >
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M0 0h24v24H0z"
                      opacity="0.05"
                    ></path>
                    <path
                      fill="#282C3F"
                      d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
            {pack.PackSizeOptions.map(
              (packOption, optionIndex) =>
                packOption.PsnPrime === true ? (
                  <>
                    <div className="Dress-size">
                      <div className="pic">
                        <img
                          src={pack.thumbnail}
                          width={80}
                          alt=""
                        />
                      </div>
                      <div className="pic-desc">
                        <h5>{productifo.name}</h5>
                        {/* <p>
                                        Khushal K Ethnic Motifs Printed Mandarin
                                        Collar Three-Quarter Sleeves Kurta Set
                                      </p> */}
                        <div className="costs">
                          <span id="Actualprice">
                            ₹{packOption.Price}
                          </span>
                          <span id="Mrp-Price">
                            ₹{packOption.Mrp}
                          </span>
                          {packOption.Discount > 0 && (
                            <span id="Discount-Price">
                              ({packOption.Discount}% OFF)
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div key={optionIndex}></div>
                  </>
                )
            )}
            <div className="Size-charts">
              <div className="charts">
                <div
                  className="size1"
                  onClick={() => getsetLine(true)}
                >
                  <h4
                    id="sct"
                    style={
                      line
                        ? { color: "#ff3f6c" }
                        : {
                          color: "#282c3f",
                          fontWeight: "600",
                        }
                    }
                  >
                    Size Chart
                  </h4>

                  {line ? (
                    <div className="underline"></div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="size2"
                  onClick={() => getsetLine(false)}
                >
                  <h4 onClick={scrollToTop}
                    id="htm"
                    style={
                      line
                        ? { color: "black" }
                        : { color: "#ff3f6c" }
                    }
                  >
                    How to measure
                  </h4>
                  {line ? (
                    ""
                  ) : (
                    <div className="underline"></div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className='containersub'>
                <div className='subconatinerborder'>
                    Size Chart
                </div>
                <div className='subconatinerborder2'>
                    How to Measure
                </div>
            </div> */}
            <div className="Btns">
              <button>
                <span
                  id="In"
                  className={incm ? "democolor" : ""}
                  onClick={() => {
                    getsetInCm(true);
                  }}
                >
                  in
                </span>
                &nbsp;
                <span
                  className={incm ? "" : "democolor"}
                  id="Cm"
                  onClick={() => {
                    getsetInCm(false);
                  }}
                >
                  cm
                </span>
              </button>
            </div>

            {incm ? (
              <div className="Table1">
                <table className="table">
                  <tr className="size-heading">
                    <th>Size</th>
                    <th>To Fit Bust</th>
                    <th>Font Length</th>
                    <th>To Fit Waist</th>
                    <th>Outseam Length</th>
                    <th>Inseam Length</th>
                  </tr>
                  <tr
                    className="size-heading1"
                    onClick={() => {
                      getsetLine("S");
                    }}
                  >
                    <td className="dispalyradio">
                      {/* <input
                                              type="radio"
                                              //    value="red" name="color"
                                              //   onChange={() => handleColorChange('red')}
                                            /> */}
                      <span> S</span>
                    </td>
                    <td>36.0</td>
                    <td>46.0</td>
                    <td>27.0</td>
                    <td>38.0</td>
                    <td>26.0</td>
                  </tr>
                  <tr className="size-heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> M</span>
                    </td>
                    <td>38.0</td>
                    <td>46.0</td>
                    <td>29.0</td>
                    <td>38.0</td>
                    <td>26.0</td>
                  </tr>
                  <tr className="size-heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> L</span>
                    </td>
                    <td>40.0</td>
                    <td>46.0</td>
                    <td>31.0</td>
                    <td>38.0</td>
                    <td>26.0</td>
                  </tr>
                  <tr className="size-heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> XL</span>
                    </td>
                    <td>42.0</td>
                    <td>46.0</td>
                    <td>31.0</td>
                    <td>38.0</td>
                    <td>26.0</td>
                  </tr>
                  <tr className="size-heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> XXL</span>
                    </td>
                    <td>44.0</td>
                    <td>46.0</td>
                    <td>31.0</td>
                    <td>38.0</td>
                    <td>26.0</td>
                  </tr>
                </table>
              </div>
            ) : (
              <div className="Table2">
                <table className="table">
                  <tr className="size-heading">
                    <th>Size</th>
                    <th>To Fit Bust</th>
                    <th>Font Length</th>
                    <th>To Fit Waist</th>
                    <th>Outseam Length</th>
                    <th>Inseam Length</th>
                  </tr>
                  <tr className="size-heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> S</span>
                    </td>
                    <td>91.4</td>
                    <td>116.8</td>
                    <td>68.6</td>
                    <td>96.5</td>
                    <td>66.0</td>
                  </tr>
                  <tr className="size-heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> M</span>
                    </td>
                    <td>96.5</td>
                    <td>116.8</td>
                    <td>78.7</td>
                    <td>96.6</td>
                    <td>66.0</td>
                  </tr>
                  <tr className="size-heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> L</span>
                    </td>
                    <td>40.0</td>
                    <td>116.8</td>
                    <td>83.8</td>
                    <td>96.6</td>
                    <td>66.0</td>
                  </tr>
                  <tr className="size-heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> XL</span>
                    </td>
                    <td>42.0</td>
                    <td>116.8</td>
                    <td>88.9</td>
                    <td>96.6</td>
                    <td>66.0</td>
                  </tr>
                  <tr className="size-heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> XXL</span>
                    </td>
                    <td>44.0</td>
                    <td>116.8</td>
                    <td>31.0</td>
                    <td>96.6</td>
                    <td>66.0</td>
                  </tr>
                </table>
              </div>
            )}
          </div>

          <div className="Seller">
            <h5>
              Seller:
              <span className="colorpink">
                Khushal K(M Direct)
              </span>
            </h5>
          </div>
          <div className="Measure" ref={contentRef}>
            <h5>How to measure yourself</h5>
            <img
              src="https://assets.myntassets.com/assets/images/sizechart/2016/10/28/11477640000694-Body_Measurements_size-chart_Image.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default WomensSizeChart

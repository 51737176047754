import React, { useState, useEffect } from "react";
import Steper from "./Steper";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaLocationCrosshairs } from "react-icons/fa6";
import {
  add_Address,
  set_checkout_process_steps,
  set_Current_address,
  update_Address,
} from "../../../redux/athentication/Athentication";
import {
  Button,
  Modal,
  Space,
  Flex,
  Form,
  Select,
  Col,
  Divider,
  Row,
  Typography,
  Radio,
} from "antd";
import { TextField } from "@mui/material";
import axios from "axios";
import AddAddress from "./AddAddress";
import EditAddress from "./EditAddress";

const Address = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginData, current_address, user_address } = useSelector(
    (state) => state.Athentication
  );
  const [info_model, setinfo_model] = useState(false);
  const [edit_address_model, setedit_address_model] = useState(false);

  const close_add_address = () => {
    setinfo_model(false);
  };

  return (
    <>
      <Modal
        title="Add delivery address"
        open={info_model}
        // onOk={() => setinfo_model(false)}
        onCancel={() => setinfo_model(false)}
        footer={null}
        maskClosable={false}
        style={{
          top: 20,
        }}
        className="address_model"
      >
        <AddAddress close_add_address={close_add_address} />
      </Modal>
      <Modal
        title="Edit delivery address"
        open={edit_address_model}
        // onOk={() => setinfo_model(false)}
        onCancel={() => setedit_address_model(false)}
        footer={null}
        maskClosable={false}
        style={{
          top: 20,
        }}
        className="address_model"
      >
        <EditAddress close_add_address={close_add_address} />
      </Modal>
      <div className="">
        <Steper />
        <div className="add_new_mobi_btn" onClick={() => setinfo_model(true)}>
          <span>
            <FaPlus className="padding_ri" />
            Add a new address
          </span>
        </div>

        {/* <div className="address_mob_list_container">
          <div className="address_mob_list_box">
            <div className="add_mb_radio">
                <div className="radio_botton">
                    < input type="radio" />
                </div>
              <div style={{marginLeft:"35px"}}>
                <h5>Ajit Kumar</h5>
                <p>hjjkjk,ameerpet,Hyderabad,Telangana - 500035</p>
                <h6>9556224782</h6>
              </div>
            </div>
            <div className="add_mb_address_dis"></div>
          </div>
        </div> */}
        <div
          style={{
            margin: "1vh 0",
            // backgroundColor:"red"
          }}
        >
          {user_address &&
            user_address.map((address, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                  // borderBottom: ".5px solid #666",
                  // backgroundColor:"yellow"
                  //   background: "red",
                }}
                key={index}
                id="bordershadow"
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <input
                      type="radio"
                      name="address"
                      id="address"
                      onChange={() => dispatch(set_Current_address(address))}
                      checked={current_address._id === address._id}
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <h5 style={{color:"#e96f84"}}>{address.Name}</h5>
                    <p style={{ marginTop: "-15px",fontWeight:"500", fontFamily: "Lora, serif" }}>
                      {address.HNo},{address.Area},{address.sub_locality},{" "}
                      <br />
                      {address.locality},{address.LandMark},{address.City},{" "}
                      <br />
                      {address.State},{address.country}-{address.Pincode}
                    </p>
                    <h6>{address.Mobile}</h6>
                  </div>

                  {/* <div style={{ marginLeft: "10px" }}>
                    <h2>{address.Name}</h2>
                    <div style={{height:}}>
                    <p><span>House Number:</span><span>{address.HNo}</span></p>
                    <p><span>Area :</span><span>{address.Area}</span></p>
                    <p><span>Sub-Locality :</span><span>{address.sub_locality}</span></p>
                    <p><span>Locality :</span><span>{address.locality}</span></p>
                      <p><span>LandMark :</span><span>{address.LandMark}</span></p>
                      <p><span>City :</span><span>{address.City}</span></p>
                      <p><span>State :</span><span>{address.State}</span></p>
                      <p><span>Country :</span><span>{address.country}</span></p>
                      <p><span>Pincode :</span><span>{address.Pincode}</span></p>
                    </div>



                  </div>
 */}

                </div>
                {current_address._id === address._id && (
                  <div
                    style={{
                      height: "30px",
                      width: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // borderRadius:"1px",
                      paddingTop: "18px",
                      // backgroundColor: "#fff",
                      backgroundColor: "#1a75ff",
                      // color:"#ffff",
                      border: "1px solid #1a75ff",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                    // onClick={() => setedit_address_model(true)}
                    onClick={() => navigate(`/updateaddress/${address._id}`)}
                  >
                    <h6 style={{ color: "#ffff" }}>Edit</h6>
                  </div>
                )}
              </div>
            ))}
        </div>
        {current_address !== "" && (
          <div
            className="address_mobile_footer"
            style={{
              marginBottom: "10h"
            }}
            onClick={() => dispatch(set_checkout_process_steps(1))}
          >
            Deliver here
          </div>
        )}
      </div>
    </>
  );
};

export default Address;

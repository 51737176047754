import React from "react";

const Setting = () => {
  return (
    <>
      <div className="tab-pane fade h-100" id="security">
        <div className="settings-card mt-0 h-100">
          <div className="dashboard-title d-flex-justify-center justify-content-between mb-4">
            <h2 className="mb-0">Settings</h2>
          </div>
          <div className="settings-book-section dashboard-detail">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="account-setting">
                  <h4 className="mb-3">Notifications</h4>
                  <div className="account-detail form-group">
                    <div className="customCheckbox clearfix mb-2">
                      <input
                        name="NotificationsAC"
                        id="n1"
                        defaultValue={1}
                        defaultChecked="checked"
                        type="checkbox"
                      />
                      <label htmlFor="n1" className="mb-0">
                        Allow Desktop Notifications
                      </label>
                    </div>
                    <div className="customCheckbox clearfix mb-2">
                      <input
                        name="NotificationsAC"
                        id="n2"
                        defaultValue={2}
                        type="checkbox"
                      />
                      <label htmlFor="n2" className="mb-0">
                        Enable Notifications
                      </label>
                    </div>
                    <div className="customCheckbox clearfix mb-2">
                      <input
                        name="NotificationsAC"
                        id="n3"
                        defaultValue={3}
                        type="checkbox"
                      />
                      <label htmlFor="n3" className="mb-0">
                        Get notification for my own activity
                      </label>
                    </div>
                    <div className="customCheckbox clearfix mb-2">
                      <input
                        name="NotificationsAC"
                        id="n4"
                        defaultValue={4}
                        type="checkbox"
                      />
                      <label htmlFor="n4" className="mb-0">
                        Receive offers from our partners
                      </label>
                    </div>
                    <div className="customCheckbox clearfix mb-2">
                      <input
                        name="NotificationsAC"
                        id="n5"
                        defaultValue={5}
                        type="checkbox"
                      />
                      <label htmlFor="n5" className="mb-0">
                        Sign up for our newsletter
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="account-setting">
                  <h4 className="mb-3">Deactivate account</h4>
                  <div className="account-detail form-group">
                    <div className="customRadio clearfix mb-2">
                      <input
                        name="DeactivateAC"
                        id="d1"
                        defaultValue={1}
                        defaultChecked="checked"
                        type="radio"
                      />
                      <label htmlFor="d1" className="mb-0">
                        I have a privacy concern
                      </label>
                    </div>
                    <div className="customRadio clearfix mb-2">
                      <input
                        name="DeactivateAC"
                        id="d2"
                        defaultValue={2}
                        type="radio"
                      />
                      <label htmlFor="d2" className="mb-0">
                        This is temporary
                      </label>
                    </div>
                    <div className="customRadio clearfix mb-2">
                      <input
                        name="DeactivateAC"
                        id="d3"
                        defaultValue={3}
                        type="radio"
                      />
                      <label htmlFor="d3" className="mb-0">
                        Other
                      </label>
                    </div>
                    <button type="button" className="btn btn-sm my-2">
                      Deactivate Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="account-setting">
              <h4 className="mb-3">Delete account</h4>
              <div className="account-detail form-group">
                <div className="customRadio clearfix mb-2">
                  <input
                    name="DeleteAC"
                    id="da1"
                    defaultValue={1}
                    defaultChecked="checked"
                    type="radio"
                  />
                  <label htmlFor="da1" className="mb-0">
                    No longer usable
                  </label>
                </div>
                <div className="customRadio clearfix mb-2">
                  <input
                    name="DeleteAC"
                    id="da2"
                    defaultValue={2}
                    type="radio"
                  />
                  <label htmlFor="da2" className="mb-0">
                    Want to switch on other account
                  </label>
                </div>
                <div className="customRadio clearfix mb-2">
                  <input
                    name="DeleteAC"
                    id="da3"
                    defaultValue={3}
                    type="radio"
                  />
                  <label htmlFor="da3" className="mb-0">
                    Other
                  </label>
                </div>
                <button type="button" className="btn btn-sm my-2">
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;

import React from "react";

const SavedCards = () => {
  return (
    <>
      <div className="tab-pane fade h-100" id="payment">
        <div className="banks-card mt-0 h-100">
          <div className="top-sec d-flex-justify-center justify-content-between mb-4">
            <h2 className="mb-0">Saved Cards</h2>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#addCardModal"
            >
              <i className="icon anm anm-plus-r me-1" /> Add New
            </button>
            {/* New Card Modal */}
            <div
              className="modal fade"
              id="addCardModal"
              tabIndex={-1}
              aria-labelledby="addCardModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="modal-title" id="addCardModalLabel">
                      Cards details
                    </h2>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form className="add-card-from" method="post" action="#">
                      <div className="form-row">
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                          <label htmlFor="card-number" className="d-none">
                            Card Number
                          </label>
                          <input
                            name="card-number"
                            placeholder="Card Number"
                            defaultValue
                            id="card-number"
                            type="text"
                          />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label htmlFor="bank-card" className="d-none">
                            Card <span className="required">*</span>
                          </label>
                          <select id="bank-card" className="form-control">
                            <option
                              value={0}
                              label="Select a card"
                              selected="selected"
                            >
                              Select a card
                            </option>
                            <option value="visa">Visa</option>
                            <option value="mastercard">Mastercard</option>
                            <option value="american_Express">
                              American Express
                            </option>
                            <option value="discover">Discover</option>
                          </select>
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label htmlFor="cvv-code" className="d-none">
                            CVV Code <span className="required">*</span>
                          </label>
                          <input
                            name="cvv-code"
                            placeholder="CVV Code"
                            defaultValue
                            id="cvv-code"
                            type="text"
                          />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 mb-md-0">
                          <label htmlFor="card-month" className="d-none">
                            Month <span className="required">*</span>
                          </label>
                          <select id="card-month" className="form-control">
                            <option
                              value={0}
                              label="Select a month"
                              selected="selected"
                            >
                              Select a month
                            </option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                          </select>
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 mb-0">
                          <label htmlFor="card-year" className="d-none">
                            Year <span className="required">*</span>
                          </label>
                          <select id="card-year" className="form-control">
                            <option
                              value={0}
                              label="Select a year"
                              selected="selected"
                            >
                              Select a year
                            </option>
                            <option value={2019}>2019</option>
                            <option value={2020}>2020</option>
                            <option value={2021}>2021</option>
                            <option value={2023}>2023</option>
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-primary m-0">
                      <span>Add Card</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* End New Card Modal */}
          </div>
          <div className="bank-book-section">
            <div className="row g-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1">
              <div className="address-select-box active">
                <div className="address-box bg-block">
                  <div className="top bank-logo d-flex-justify-center justify-content-between mb-3">
                    <img
                      src="/assets/images/icons/bank-logo1.png"
                      className="bank-logo"
                      width={40}
                      alt
                    />
                    <img
                      src="/assets/images/icons/visa-logo.png"
                      className="network-logo"
                      width={50}
                      alt
                    />
                  </div>
                  <div className="middle">
                    <div className="card-number mb-3">
                      <h6>Card Number</h6>
                      <p className="text-muted">3742 4545 5400 126</p>
                    </div>
                    <div className="name-validity d-flex-justify-center justify-content-between">
                      <div className="left">
                        <h6>Name on card</h6>
                        <p className="text-muted">Hema Jecno</p>
                      </div>
                      <div className="right">
                        <h6>Validity</h6>
                        <p className="text-muted">XX/XX</p>
                      </div>
                    </div>
                  </div>
                  <div className="bottom d-flex-justify-center justify-content-between">
                    <button
                      type="button"
                      className="bottom-btn btn btn-gray btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#editCardModal"
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="bottom-btn btn btn-gray btn-sm"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div className="address-select-box">
                <div className="address-box bg-block">
                  <div className="top bank-logo d-flex-justify-center justify-content-between mb-3">
                    <img
                      src="/assets/images/icons/bank-logo2.png"
                      className="bank-logo"
                      width={100}
                      alt
                    />
                    <img
                      src="/assets/images/icons/visa-logo.png"
                      className="network-logo"
                      width={50}
                      alt
                    />
                  </div>
                  <div className="middle">
                    <div className="card-number mb-3">
                      <h6>Card Number</h6>
                      <p className="text-muted">3742 4545 5400 126</p>
                    </div>
                    <div className="name-validity d-flex-justify-center justify-content-between">
                      <div className="left">
                        <h6>Name on card</h6>
                        <p className="text-muted">Hema Jecno</p>
                      </div>
                      <div className="right">
                        <h6>Validity</h6>
                        <p className="text-muted">XX/XX</p>
                      </div>
                    </div>
                  </div>
                  <div className="bottom d-flex-justify-center justify-content-between">
                    <button
                      type="button"
                      className="bottom-btn btn btn-gray btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#editCardModal"
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="bottom-btn btn btn-gray btn-sm"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Edit Bank card Modal */}
            <div
              className="modal fade"
              id="editCardModal"
              tabIndex={-1}
              aria-labelledby="editCardModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="modal-title" id="editCardModalLabel">
                      Edit Card details
                    </h2>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form className="add-card-from" method="post" action="#">
                      <div className="form-row">
                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12">
                          <label htmlFor="editCard-number" className="d-none">
                            Card Number
                          </label>
                          <input
                            name="editCard-number"
                            placeholder="Card Number"
                            defaultValue
                            id="editCard-number"
                            type="text"
                          />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label htmlFor="editBank-card" className="d-none">
                            Card <span className="required">*</span>
                          </label>
                          <select id="editBank-card" className="form-control">
                            <option
                              value={0}
                              label="Select a card"
                              selected="selected"
                            >
                              Select a card
                            </option>
                            <option value="visa">Visa</option>
                            <option value="mastercard">Mastercard</option>
                            <option value="american_Express">
                              American Express
                            </option>
                            <option value="discover">Discover</option>
                          </select>
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label htmlFor="editCvv-code" className="d-none">
                            CVV Code
                            <span className="required">*</span>
                          </label>
                          <input
                            name="editCvv-code"
                            placeholder="CVV Code"
                            defaultValue
                            id="editCvv-code"
                            type="text"
                          />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 mb-md-0">
                          <label htmlFor="editCard-month" className="d-none">
                            Month
                            <span className="required">*</span>
                          </label>
                          <select id="editCard-month" className="form-control">
                            <option
                              value={0}
                              label="Select a month"
                              selected="selected"
                            >
                              Select a month
                            </option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                          </select>
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 mb-0">
                          <label htmlFor="editCard-year" className="d-none">
                            Year <span className="required">*</span>
                          </label>
                          <select id="editCard-year" className="form-control">
                            <option
                              value={0}
                              label="Select a year"
                              selected="selected"
                            >
                              Select a year
                            </option>
                            <option value={2019}>2019</option>
                            <option value={2020}>2020</option>
                            <option value={2021}>2021</option>
                            <option value={2023}>2023</option>
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-primary m-0">
                      <span>Save Card</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* End Edit Bank card Modal */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedCards;

import React, { useRef} from 'react';

const MensSizeChart = ({ pack, productifo, onClose, line, incm, getsetInCm, getsetLine, getsetLineColor }) => {
  const contentRef = useRef(null);
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
        <div className="Size-Main">
                              <div className="sub-main">
                                <div className="top-part">
                                  <div className="crossicon">
                                    <span>
                                      {" "}
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill=""
                                        onClick={() => onClose()}
                                      >
                                        <g fill="none" fill-rule="evenodd">
                                          <path
                                            d="M0 0h24v24H0z"
                                            opacity="0.05"
                                          ></path>
                                          <path
                                            fill="#282C3F"
                                            d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </span>
                                  </div>
                                  {pack.PackSizeOptions.map(
                                    (packOption, optionIndex) =>
                                      packOption.PsnPrime === true ? (
                                        <>
                                          <div className="Dress-size">
                                            <div className="pic">
                                              <img
                                                src={pack.thumbnail}
                                                width={80}
                                                alt=""
                                              />
                                            </div>
                                            <div className="pic-desc">
                                              <h5>{productifo.name}</h5>
                                              {/* <p>
                                        Khushal K Ethnic Motifs Printed Mandarin
                                        Collar Three-Quarter Sleeves Kurta Set
                                      </p> */}
                                              <div className="costs">
                                                <span id="Actualprice">
                                                  ₹{packOption.Price}
                                                </span>
                                                <span id="Mrp-Price">
                                                  ₹{packOption.Mrp}
                                                </span>
                                                {packOption.Discount > 0 && (
                                                  <span id="Discount-Price">
                                                    ({packOption.Discount}% OFF)
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div key={optionIndex}></div>
                                        </>
                                      )
                                  )}
                                  <div className="Size-charts">
                                    <div className="charts">
                                      <div
                                        className="size1"
                                        onClick={() => getsetLine(true)}
                                      >
                                        <h4
                                          id="sct"
                                          style={
                                            line
                                              ? { color: "#ff3f6c" }
                                              : {
                                                color: "#282c3f",
                                                fontWeight: "600",
                                              }
                                          }
                                        >
                                          Size Chart
                                        </h4>

                                        {line ? (
                                          <div className="underline"></div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        className="size2"
                                        onClick={() => getsetLine(false)}
                                      >
                                        <h4 onClick={scrollToTop}
                                          id="htm"
                                          style={
                                            line
                                              ? { color: "black" }
                                              : { color: "#ff3f6c" }
                                          }
                                        >
                                          How to measure
                                        </h4>
                                        {line ? (
                                          ""
                                        ) : (
                                          <div className="underline"></div>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className='containersub'>
                <div className='subconatinerborder'>
                    Size Chart
                </div>
                <div className='subconatinerborder2'>
                    How to Measure
                </div>
            </div> */}
                                  <div className="Btns">
                                    <button>
                                      <span
                                        id="In"
                                        className={incm ? "democolor" : ""}
                                        onClick={() => {
                                          getsetInCm(true);
                                        }}
                                      >
                                        in
                                      </span>
                                      &nbsp;
                                      <span
                                        className={incm ? "" : "democolor"}
                                        id="Cm"
                                        onClick={() => {
                                          getsetInCm(false);
                                        }}
                                      >
                                        cm
                                      </span>
                                    </button>
                                  </div>

                                  {incm ? (
                                    <div className="Table1">
                                      <table className="table">
                                        <tr className="size-heading">
                                          <th>Size</th>
                                          <th>Chest(in)</th>
                                          <th>Front Length(in)</th>
                                          <th>Across Shoulder(in)</th>
                                        </tr>
                                        <tr
                                          className="size-heading1"
                                          onClick={() => {
                                            getsetLineColor("S");
                                          }}
                                        >
                                          <td className="dispalyradio">
                                            {/* <input
                                              type="radio"
                                              //    value="red" name="color"
                                              //   onChange={() => handleColorChange('red')}
                                            /> */}
                                            <span> 38</span>
                                          </td>
                                          <td>41.0</td>
                                          <td>29.0</td>
                                          <td>18.0</td>
                                        </tr>
                                        <tr className="size-heading1">
                                          <td className="dispalyradio">
                                            {" "}
                                            {/* <input type="radio" /> */}
                                            <span> 40</span>
                                          </td>
                                          <td>43.0</td>
                                          <td>29.5</td>
                                          <td>19.0</td>
                                        </tr>
                                        <tr className="size-heading1">
                                          <td className="dispalyradio">
                                            {" "}
                                            {/* <input type="radio" /> */}
                                            <span> 42</span>
                                          </td>
                                          <td>45.0</td>
                                          <td>30.0</td>
                                          <td>20.0</td>
                                        </tr>
                                        <tr className="size-heading1">
                                          <td className="dispalyradio">
                                            {" "}
                                            {/* <input type="radio" /> */}
                                            <span> 44</span>
                                          </td>
                                          <td>48.0</td>
                                          <td>30.5</td>
                                          <td>21.0</td>
                                        </tr>
                                        <tr className="size-heading1">
                                          <td className="dispalyradio">
                                            {" "}
                                            {/* <input type="radio" /> */}
                                            <span> 46</span>
                                          </td>
                                          <td>52.0</td>
                                          <td>31.0</td>
                                          <td>22.0</td>
                                        </tr>
                                      </table>
                                    </div>
                                  ) : (
                                    <div className="Table2">
                                      <table className="table">
                                        <tr className="size-heading">
                                        <th>Size</th>
                                        <th>Chest(in)</th>
                                        <th>Front length(in)</th>
                                        <th>Across Shoulder(in)</th>
                                        </tr>
                                        <tr className="size-heading1">
                                          <td className="dispalyradio">
                                            {" "}
                                            {/* <input type="radio" /> */}
                                            <span> 38</span>
                                            </td>
                                            <td>104.1</td>
                                            <td>73.7</td>
                                            <td>45.7</td>
                                        </tr>
                                        <tr className="size-heading1">
                                          <td className="dispalyradio">
                                            {" "}
                                            {/* <input type="radio" /> */}
                                            <span> 40</span>
                                            </td>
                                            <td>109.2</td>
                                            <td>74.9</td>
                                            <td>48.3</td>
                                        </tr>
                                        <tr className="size-heading1">
                                          <td className="dispalyradio">
                                            {" "}
                                            {/* <input type="radio" /> */}
                                            <span> 42</span>
                                           </td>
                                           <td>114.3</td>
                                           <td>76.2</td>
                                           <td>50.8</td>
                                        </tr>
                                        <tr className="size-heading1">
                                          <td className="dispalyradio">
                                            {" "}
                                            {/* <input type="radio" /> */}
                                            <span> 44</span>
                                             </td>
                                             <td>121.9</td>
                                             <td>77.5</td>
                                            <td>53.3</td>
                                        </tr>
                                        <tr className="size-heading1">
                                          <td className="dispalyradio">
                                            {" "}
                                            {/* <input type="radio" /> */}
                                            <span> 46</span>
                                            </td>
                                            <td>132.1</td>
                                            <td>78.7</td>
                                            <td>55.9</td>
                                        </tr>
                                      </table>
                                    </div>
                                  )}
                                </div>

                                <div className="Seller">
                                  <h5>
                                    Seller:
                                    <span className="colorpink">
                                      Khushal K(M Direct)
                                    </span>
                                  </h5>
                                </div>
                                <div className="Measure" ref={contentRef}>
                                  <h5>How to measure yourself</h5>
                                  <img
                                    src="https://i.ebayimg.com/images/g/fjkAAOSw771mTWH2/s-l400.jpg"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
    </>
  )
}

export default MensSizeChart

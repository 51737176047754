import React, { useState } from "react";

import { FaArrowLeft } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import MobileCategogy from "../../category/MobileCategogy";
import { useNavigate } from "react-router-dom";

const SearchProductPage = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const searchchange = (e) => {
    const result = e.target.value;
    setSearch(result);
  };

  const serchclick = () => {
    if (search !== "") {
      navigate(`/product-search/${search}`);
    } else {
      alert("Please type something...");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          padding: "8px",
          backgroundColor: "#fff",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          
        }}
        className="searchmaincontainer"
      >
        <FaArrowLeft size={22}  onClick={()=>navigate('/')}/>
        <div>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              placeholder="Search for brand & products..."
              value={search}
              onChange={searchchange}
              style={{
                borderRadius: "5px",
                width: "300px",
                border: "none",
              }}
            />
          </div>
        </div>

        <span>
          <CiSearch
            size={32}
            color="red"
            style={{ paddingRight: "10px" }}
            onClick={serchclick}
          />
        </span>
      </div>
      <MobileCategogy />
    </>
  );
};

export default SearchProductPage;

// import React from 'react'
import './KidsSizeChart.css'
import React, { useRef} from 'react';


const KidsSizeChart = ({ pack, productifo, onClose, line, incm, getsetInCm, getsetLine, getsetLineColor }) => {
  const contentRef = useRef(null);
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="Size-Main">
       
        <div className="sub-main">
       
          <div className="top-part" >
            <div className="crossicon">
              <span>
                {" "}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill=""
                  onClick={() => onClose()}
                >
                  <g fill="none" fill-rule="evenodd">
                    <path
                      d="M0 0h24v24H0z"
                      opacity="0.05"
                    ></path>
                    <path
                      fill="#282C3F"
                      d="M12.967 12L19.3 5.666a.685.685 0 000-.967.686.686 0 00-.967 0L12 11.033 5.666 4.7a.686.686 0 00-.967 0 .685.685 0 000 .967L11.033 12 4.7 18.334a.685.685 0 000 .967.686.686 0 00.967 0L12 12.967l6.334 6.334a.686.686 0 00.967 0 .685.685 0 000-.967L12.967 12z"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
            {pack.PackSizeOptions.map(
              (packOption, optionIndex) =>
                packOption.PsnPrime === true ? (
                  <>
                    <div className="Dress-size">
                      <div className="pic">
                        <img
                          src={pack.thumbnail}
                          width={80}
                          alt=""
                        />
                      </div>
                      <div className="pic-desc">
                        <h5>{productifo.name}</h5>
                        {/* <p>
                                        Khushal K Ethnic Motifs Printed Mandarin
                                        Collar Three-Quarter Sleeves Kurta Set
                                      </p> */}
                        <div className="costs">
                          <span id="Actualprice">
                            ₹{packOption.Price}
                          </span>
                          <span id="Mrp-Price">
                            ₹{packOption.Mrp}
                          </span>
                          {packOption.Discount > 0 && (
                            <span id="Discount-Price">
                              ({packOption.Discount}% OFF)
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div key={optionIndex}></div>
                  </>
                )
            )}
            <div className="Size-charts">
              <div className="charts">
                <div
                  className="size1"
                  onClick={() => getsetLine(true)}
                >
                  <h4
                    id="sct"
                    style={
                      line
                        ? { color: "#ff3f6c" }
                        : {
                          color: "#282c3f",
                          fontWeight: "600",
                        }
                    }
                  >
                    Size Chart
                  </h4>

                  {line ? (
                    <div className="underline"></div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="size2"
                  onClick={() => getsetLine(false)}
                >
                  <h4 onClick={scrollToTop}
                    id="htm"
                    style={
                      line
                        ? { color: "black" }
                        : { color: "#ff3f6c" }
                    }
                  >
                    {/* {isScrolled ? 'Scroll Back' : 'How to measure'} */}
                    How to measure 
                    
                  </h4>
                  {/* How to measure */}
                  {line ? (
                    ""
                  ) : (
                    <div className="underline"></div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className='containersub'>
                <div className='subconatinerborder'>
                    Size Chart
                </div>
                <div className='subconatinerborder2'>
                    How to Measure
                </div>
            </div> */}
            <div className="Btns">
              <button>
                <span
                  id="In"
                  className={incm ? "democolor" : ""}
                  onClick={() => {
                    getsetInCm(true);
                  }}
                >
                  in
                </span>
                &nbsp;
                <span
                  className={incm ? "" : "democolor"}
                  id="Cm"
                  onClick={() => {
                    getsetInCm(false);
                  }}
                >
                  cm
                </span>
              </button>
            </div>

            {incm ? (
              <div className="Table1">
                <table className="tablee">
                  <tr className="size-Heading">
                    <th className='topheadings'>Size</th>
                    <th>Chest(in)</th>
                    <th>Font Length(in)</th>
                    <th>To Fit Waist(in)</th>
                    <th>Waist(in)</th>
                    <th>Hip(in)</th>
                    <th>Across Shoulders(in)</th>
                    <th>Outseam Length(in)</th>
                    <th>Inseam Length(in)</th>
                  </tr>
                  <tr className="size-Heading1"
                    onClick={() => {
                      getsetLineColor("S");
                    }}
                  >
                    <td className="dispalyradio">
                      {/* <input
                                              type="radio"
                                              //    value="red" name="color"
                                              //   onChange={() => handleColorChange('red')}
                                            /> */}
                      <span> 3-4y</span>
                    </td>
                    <td>24.0</td>
                    <td>25.0</td>
                    <td>17.0</td>
                    <td>23.0</td>
                    <td>33.0</td>
                    <td>9.5</td>
                    <td>26.0</td>
                    <td>16.0</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 5-6y</span>
                    </td>
                    <td>27.0</td>
                    <td>27.0</td>
                    <td>19.0</td>
                    <td>26.0</td>
                    <td>35.0</td>
                    <td>10.5</td>
                    <td>28.0</td>
                    <td>17.0</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 7-8y</span>
                    </td>
                    <td>28.5</td>
                    <td>29.0</td>
                    <td>21.0</td>
                    <td>27.5</td>
                    <td>37.0</td>
                    <td>11.5</td>
                    <td>30.0</td>
                    <td>19.0</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 9-10y</span>
                    </td>
                    <td>30.0</td>
                    <td>32.0</td>
                    <td>23.0</td>
                    <td>29.0</td>
                    <td>39.0</td>
                    <td>11.8</td>
                    <td>32.0</td>
                    <td>20.5</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 11-12y</span>
                    </td>
                    <td>32.5</td>
                    <td>34.0</td>
                    <td>25.0</td>
                    <td>31.5</td>
                    <td>41.0</td>
                    <td>12.5</td>
                    <td>34.0</td>
                    <td>22.0</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 13-14y</span>
                    </td>
                    <td>34.0</td>
                    <td>36.0</td>
                    <td>27.0</td>
                    <td>33.0</td>
                    <td>43.0</td>
                    <td>13.0</td>
                    <td>36.0</td>
                    <td>22.0</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 15-16y</span>
                    </td>
                    <td>36.0</td>
                    <td>36.0</td>
                    <td>28.0</td>
                    <td>35.5</td>
                    <td>45.0</td>
                    <td>14.5</td>
                    <td>38.0</td>
                    <td>23.0</td>
                  </tr>
                </table>
              </div>
            ) : (
              <div className="Table2">
                <table className="tablee">
                  <tr className="size-Heading">
                    <th>Size</th>
                    <th>Chest(cm)</th>
                    <th>Font Length(cm)</th>
                    <th>To Fit Waist(cm)</th>
                    <th>Waist(cm)</th>
                    <th>Hip(cm)</th>
                    <th>Across Shoulders(cm)</th>
                    <th>Outseam Length(cm)</th>
                    <th>Inseam Length(cm)</th>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 3-4y</span>
                    </td>
                    <td>61.0</td>
                    <td>63.5</td>
                    <td>43.2</td>
                    <td>58.4</td>
                    <td>83.8</td>
                    <td>24.1</td>
                    <td>66.0</td>
                    <td>40.0</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 5-6y</span>
                    </td>
                    <td>68.6</td>
                    <td>68.6</td>
                    <td>48.3</td>
                    <td>66.3</td>
                    <td>88.9</td>
                    <td>26.1</td>
                    <td>71.1</td>
                    <td>43.2</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 7-8y</span>
                    </td>
                    <td>72.4</td>
                    <td>73.7</td>
                    <td>53.3</td>
                    <td>69.8</td>
                    <td>94.0</td>
                    <td>27.9</td>
                    <td>76.2</td>
                    <td>45.2</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 9-10y</span>
                    </td>
                    <td>77.5</td>
                    <td>81.3</td>
                    <td>58.4</td>
                    <td>74.9</td>
                    <td>99.1</td>
                    <td>30.0</td>
                    <td>81.3</td>
                    <td>48.3</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 11-12y</span>
                    </td>
                    <td>82.5</td>
                    <td>86.4</td>
                    <td>63.5</td>
                    <td>80.0</td>
                    <td>104.1</td>
                    <td>31.8</td>
                    <td>86.4</td>
                    <td>52.1</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 13-14y</span>
                    </td>
                    <td>86.4</td>
                    <td>91.4</td>
                    <td>68.6</td>
                    <td>83.8</td>
                    <td>109.2</td>
                    <td>33.0</td>
                    <td>91.4</td>
                    <td>55.9</td>
                  </tr>
                  <tr className="size-Heading1">
                    <td className="dispalyradio">
                      {" "}
                      {/* <input type="radio" /> */}
                      <span> 15-16y</span>
                    </td>
                    <td>91.4</td>
                    <td>96.5</td>
                    <td>71.1</td>
                    <td>88.9</td>
                    <td>114.3</td>
                    <td>35.6</td>
                    <td>96.5</td>
                    <td>58.4</td>
                  </tr>
                </table>
              </div>
            )}
          </div>

          <div className="Seller">
            <h5>
              Seller:
              <span className="colorpink">
                Khushal K(M Direct)
              </span>
            </h5>
          </div>
          <div className="Measure" ref={contentRef}>
            <h5>How to measure yourself</h5>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY_Qd9_KPuesiICZXOuybV1oIiwpaIs4Ueqg&s"
              alt=""
              
            />
          </div>
          
        </div>
       {/* <div className="gap"></div> */}
      </div>
    </>
  )
}

export default KidsSizeChart

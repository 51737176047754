import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const Mobile_Accessories = ({
  current_category,
  filterSelectedCloseArray,
  selectCloserFilterPress,
  category_products,
  getfilt_Prod,
  selected_category_tag,
}) => {
  const {
    romanTypeMessurements,
    sleevestype,
    main_trends,
    yes_r_no,
    mobiles_types,
    mobiles_Usage,
    Browse_Type,
    SIM_Type,
    mobile_Display_Size,
    mobile_Resolution,
    mobile_Gpu,
    mobile_Display_Type,
    mobile_operating_system,
    mobile_Processor_brand,
    mobile_Processor_core,
    mobile_primary_clock_speed,
    mobile_secondary_clock_speed,
    mobile_tertiary_clock_speed,
    Internal_storage,
    mobile_ram,
    Total_memory,
    Secodary_camera,
    Primary_camera,
    Flash,
    Frame_rate,
    Dual_lens,
    Network_type,
    Supported_networks,
    Internet_connectivity,
    Bluetooth_version,
    Map_support,
    Touchscreen_type,
    Sim_size,
    User_interface,
    Sensors,
    Browser,
    GPS_type,
    Battery_capacity,
    Mobile_Battery_type,
    mobile_width,
    mobile_height,
    mobile_depth,
    mobile_weight,
    Suitable_for,
    laptop_battery_backup,
    laptop_Ram_type,
    laptop_Graphic_processor,
    laptop_Clock_speed,
    laptop_Expandable_ssd_capacity,
    laptop_Screen_type,
    laptop_Sound_properties,
    Dimensions,
    Web_camera,
    Emmc_storage_capacity,
    Dedicated_graphic_memory_type,
    Dedicated_graphic_memory_capacity,
    laptop_Ssd_capacity,
    Lock_port,
    Ideal_usage,
    Connectivity,
    tablet_Internal_storage,
    laptop_Processor_name,
    tablet_Processor_speed,
    laptop_bag_pack_of,
    Ideal_for,
    Compatible_laptop_size,
    Material,
    Number_of_compartments,
    Capacity,
    Configuration,
    Power_source,
    Power_output,
    Frequency_response,
    Wired_wireless,
    Compatitle_devices,
    Read_speed,
    Net_quantity,
    Maximum_current_rating,
    System_requirements,
    Form_factor,
    Operating_range,
    Frequency,
    Frequency_band,
    Wireless_speed,
    Dsl_modem_speed,
    Lan_wan,
    Antennae,
    Number_of_usb_ports,
    Screen_Size,
    Impedence,
    Micro_wave_types,
    Control_types,
    Video_resolution,
    Number_of_devices_supported,
    Mount_types,
    Rotisserie_types,
    Cavity_Material_type,
    Temparature_control_range,
    Power_consumption,
    Heating_element,
    Body_material,
    Control,
    Blade_material,
    Finish,
    Cord_length,
    Shape,
    Air_suction_capacity,
    Speed_levels,
    Engine_power,
    Slice_capacity,
    Functions,
    No_of_slices,
    Pack_of,
    Total_pan_capacity,
    Motor_speed,
    Number_of_stones,
    Attachment_present,
    Filter_type,
    Membrane_type,
    Filtration_capacity,
    Purification_capacity,
    Number_of_blades,
    Blade_sweep,
    Air_flow,
    Number_of_speeds,
    Blower,
    Star_rating,
    Cooling_coverage_area,
    Speed_control,
    Operating_mode,
    Air_delivery,
    Air_deflection,
    Blower_material,
    Efficiency,
    Output_voltage,
    Output_frequency,
    Depth,
    Motor_suction_power,
    Number_of_stitches,
    Number_of_button_hole_styles,
    sweing_speed,
    Weight,
    Used_for,
    Cooling,
    Minimum_input_power,
    Temperature,
    Heater_material,
    Heater_element_type,
    Power_output_rms,
    Maximum_brightness,
    Remote_features,
    Display_resolution,
    Aspect_ratio,
    Hd_technology_resolution,
    Wifi_type,
    View_angle,
    Led_display_type,
    Refresh_rate,
    Number_of_speakers,
    Speaker_type,
    Sound_mode,
    Supported_Mobile_operating_system,
    Supported_devices_for_casting,
    Power_requirement,
    Number_of_charger_pins,
    Output_current,
    Cable_type,
    Output_wattage,
    Headphone_type,
    Headphone_design,
    Power_supply,
    Video_player_type,
    Operating_system_supported,
    Type_of_tracking,
    Applied_on,
    Number_of_layers,
    Connector_1,
    Connector_2,
    Cable,
    Keyboard_Type,
    Spin_cycle_duration,
    Wash_cycle_duration,
    Wash_program_types,
    Tub_materials,
    Washing_modes,
    Outer_body_materials,
    Fan_Length,
    Number_of_doors,
    Number_of_auto_menu,
    panel_display_types,
    Height_width_x_depth,
    Sensitivity,
    Usb_version,
    Headphone_jack,
    Maximum_Projection_Distances,
    Output_for_speakers,
    Projection_ratio,
    Number_of_batteries,
  } = useSelector((store) => store.Messurents);

  const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);
  const [distinctfield, setdistinctfield] = useState([]);

  const [selected_ram, setselected_ram] = useState([]);
  const [selected_internal_storage, setselected_internal_storage] = useState(
    []
  );
  const [selected_battery_capacity, setselected_battery_capacity] = useState(
    []
  );
  const [selected_screen_size, setselected_screen_size] = useState([]);
  const [selected_primary_camera, setselected_primary_camera] = useState([]);
  const [selected_secondary_camera, setselected_secondary_camera] = useState(
    []
  );
  const [selected_processor_brand, setselected_processor_brand] = useState([]);
  const [selected_display_resolution, setselected_display_resolution] =
    useState([]);
  const [selected_operating_system, setselected_operating_system] = useState(
    []
  );
  const [selected_network_type, setselected_network_type] = useState([]);
  const [selected_sim_type, setselected_sim_type] = useState([]);
  const [
    selected_operating_system_version,
    setselected_operating_system_version,
  ] = useState([]);
  const [selected_refresh_rate, setselected_refresh_rate] = useState([]);

  useEffect(() => {
    if (current_category) {
      const resp = current_category;
      const filterShow = resp.selectedFields.filter(
        (data) => data.status === true
      );
      const distinctNames = [
        ...new Set(filterShow.map((item) => item.field_Name)),
      ];
      setdistinctfield(distinctNames);
    }
  }, [current_category]);

  useEffect(() => {
    if (
      selected_ram.length === 0 &&
      selected_battery_capacity.length === 0 &&
      selected_screen_size.length === 0 &&
      selected_primary_camera.length === 0 &&
      selected_secondary_camera.length === 0 &&
      selected_processor_brand.length === 0 &&
      selected_display_resolution.length === 0 &&
      selected_network_type.length === 0 &&
      selected_sim_type.length === 0 &&
      selected_operating_system_version.length === 0 &&
      selected_operating_system.length === 0 &&
      selected_refresh_rate.length === 0 &&
      selected_internal_storage.length === 0
    ) {
      const products = category_products;
      getfilt_Prod(products);
    } else {
      if (category_products.length > 0) {
        let products = category_products;
        let final_filt_product = category_products;
        if (selected_ram.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_ram.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_internal_storage.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_internal_storage.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_battery_capacity.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_battery_capacity.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_screen_size.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_screen_size.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_primary_camera.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_primary_camera.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_secondary_camera.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_secondary_camera.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_processor_brand.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_processor_brand.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_display_resolution.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_display_resolution.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_operating_system.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_operating_system.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_network_type.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_network_type.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_sim_type.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_sim_type.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_operating_system_version.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_operating_system_version.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_refresh_rate.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_refresh_rate.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }

        if (selected_category_tag.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_category_tag.includes(item.CategoryTag)
          );
          final_filt_product = final_filt_product;
        }
        getfilt_Prod(final_filt_product);
      }
    }
  }, [
    selected_ram,
    selected_internal_storage,
    selected_battery_capacity,
    selected_screen_size,
    selected_primary_camera,
    selected_secondary_camera,
    selected_processor_brand,
    selected_display_resolution,
    selected_operating_system,
    selected_network_type,
    selected_sim_type,
    selected_operating_system_version,
    selected_refresh_rate,
  ]);

  const handleselected_ramPress = (evt, value) => {
    if (selected_ram.includes(value)) {
      setselected_ram(
        selected_ram.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_ram((selected_ram) => [...selected_ram, value]);
    }
  };
  const handleselected_internal_storagePress = (evt, value) => {
    if (selected_internal_storage.includes(value)) {
      setselected_internal_storage(
        selected_internal_storage.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_internal_storage((selected_internal_storage) => [
        ...selected_internal_storage,
        value,
      ]);
    }
  };
  const battery_press = (evt, value) => {
    if (selected_battery_capacity.includes(value)) {
      setselected_battery_capacity(
        selected_battery_capacity.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_battery_capacity((selected_battery_capacity) => [
        ...selected_battery_capacity,
        value,
      ]);
    }
  };
  const screen_size_press = (evt, value) => {
    if (selected_screen_size.includes(value)) {
      setselected_screen_size(
        selected_screen_size.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_screen_size((selected_screen_size) => [
        ...selected_screen_size,
        value,
      ]);
    }
  };
  const primery_cam_press = (evt, value) => {
    if (selected_primary_camera.includes(value)) {
      setselected_primary_camera(
        selected_primary_camera.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_primary_camera((selected_primary_camera) => [
        ...selected_primary_camera,
        value,
      ]);
    }
  };
  const secondry_cam_press = (evt, value) => {
    if (selected_secondary_camera.includes(value)) {
      setselected_secondary_camera(
        selected_secondary_camera.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_secondary_camera((selected_secondary_camera) => [
        ...selected_secondary_camera,
        value,
      ]);
    }
  };

  return (
    <>
      {distinctfield.includes("ram") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("ram")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("ram")}
          >
            <h2>ram</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("ram")
                ? {
                  display: "none",
                }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                mobile_ram &&
                mobile_ram.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`ram${index}`}
                      name="ram"
                      value={data.label}
                      checked={selected_ram.includes(data.label)}
                      onChange={(e) => handleselected_ramPress(e, data.label)}
                    />
                    <label htmlFor={`ram${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("internal_storage") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("internal_storage")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("internal_storage")}
          >
            <h2>internal storage</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("internal_storage")
                ? {
                  display: "none",
                }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                Internal_storage &&
                Internal_storage.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`inst${index}`}
                      name="inst"
                      value={data.label}
                      checked={selected_internal_storage.includes(data.label)}
                      onChange={(e) =>
                        handleselected_internal_storagePress(e, data.label)
                      }
                    />
                    <label htmlFor={`inst${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("battery_capacity") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("battery_capacity")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("battery_capacity")}
          >
            <h2>battery capacity</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("battery_capacity")
                ? {
                  display: "none",
                }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                Battery_capacity &&
                Battery_capacity.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`bat${index}`}
                      name="bat"
                      value={data.label}
                      checked={selected_battery_capacity.includes(data.label)}
                      onChange={(e) => battery_press(e, data.label)}
                    />
                    <label htmlFor={`bat${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("screen_size") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("screen_size")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("screen_size")}
          >
            <h2>screen size</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("screen_size")
                ? {
                  display: "none",
                }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                mobile_Display_Size &&
                mobile_Display_Size.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`screen${index}`}
                      name="screen"
                      value={data.label}
                      checked={selected_screen_size.includes(data.label)}
                      onChange={(e) => screen_size_press(e, data.label)}
                    />
                    <label htmlFor={`screen${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("primary_camera") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("primary_camera")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("primary_camera")}
          >
            <h2>primary camera</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("primary_camera")
                ? {
                  display: "none",
                }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                Secodary_camera &&
                Secodary_camera.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`pricam${index}`}
                      name="pricam"
                      value={data.label}
                      checked={selected_primary_camera.includes(data.label)}
                      onChange={(e) => primery_cam_press(e, data.label)}
                    />
                    <label htmlFor={`pricam${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("secondary_camera") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("secondary_camera")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("secondary_camera")}
          >
            <h2>secondary camera</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("secondary_camera")
                ? {
                  display: "none",
                }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                Primary_camera &&
                Primary_camera.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`seccam${index}`}
                      name="seccam"
                      value={data.label}
                      checked={selected_secondary_camera.includes(data.label)}
                      onChange={(e) => secondry_cam_press(e, data.label)}
                    />
                    <label htmlFor={`seccam${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Mobile_Accessories;

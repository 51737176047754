import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CheckoutStepsPage from "./CheckoutStepsPage";
import {
  getUserByMob,
  LoginOtp,
  send_sms_through_backend,
  set_checkout_authentication_status,
  set_checkout_process_steps,
  signin,
} from "../../redux/athentication/Athentication";
import { Button, Drawer, Radio, Space, Input } from "antd";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Steper from "./mobile_version/Steper";
import Mobile_main_checkout from "./mobile_version/Mobile_main_checkout";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth, current_address, loginData } = useSelector(
    (state) => state.Athentication
  );
  const { CartItems } = useSelector((state) => state.cart);

  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState("");
  const [confirm_number, setconfirm_Number] = useState("");
  const [errormassage, setErrormassage] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);

  const [sentOTP, setsentOTP] = useState("");

  const [show_otp, setshow_otp] = useState(false);

  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [otpValuesErrors, setOtpValuesErrors] = useState([]);
  const inputRefs = useRef([]);

  const [checkout_steps, setcheckout_steps] = useState(0);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isAuth === false) {
      setOpen(true);
    }
  }, [isAuth]);

  useEffect(() => {
    if (CartItems.length === 0) {
      navigate("/");
    }
  }, [CartItems]);

  const chgangeProccessStep1 = () => {
    dispatch(set_checkout_process_steps(0));
  };
  const chgangeProccessStep2 = () => {
    if (isAuth === true) {
      dispatch(set_checkout_process_steps(1));
    }
  };
  const chgangeProccessStep3 = () => {
    if (isAuth === true && current_address !== "") {
      dispatch(set_checkout_process_steps(2));
    }
  };
  const chgangeProccessStep4 = () => {
    if (isAuth === true && current_address !== "") {
      dispatch(set_checkout_process_steps(3));
    }
  };

  const mobilenumberChange = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
    if (value.length === 10) {
      setconfirm_Number(value);
      setErrormassage("");
    } else {
      setconfirm_Number("");
      setErrormassage("Please enter valid mobile number");
    }
  };

  const sendOptPress = async (e) => {
    e.preventDefault();
    if (confirm_number === "") {
      setErrormassage("Required");
    } else {
      setErrormassage("");
    }

    if (confirm_number.length === 10) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      if (number === "8018582135") {
        OTP = "111444";
      }

      setsentOTP(OTP);

      const formdata = {
        mobile: number,
        OTP: OTP,
      };
      await dispatch(send_sms_through_backend(formdata));
      setshow_otp(true);
    }
  };

  useEffect(() => {
    if (buttonPress === true) {
      const allEmpty = otpValues.every((item) => item !== "");
      if (allEmpty === false) {
        let errorarray = [];
        for (let index = 0; index < otpValues.length; index++) {
          let element = otpValues[index];
          let oject = "";
          if (element === "") {
            oject = index;
          } else {
            oject = "";
          }
          errorarray = [...errorarray, oject];
        }
        setOtpValuesErrors([...errorarray]);
      }
    }
  }, [buttonPress, otpValues]);

  const handleOtpChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && !otpValues[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    const allEmpty = otpValues.every((item) => item !== "");
    if (allEmpty === true) {
      const concatenatedString = otpValues.join("");

      if (concatenatedString.length === 6 && concatenatedString === sentOTP) {
        const formData = {
          Mobile: confirm_number,
        };
        const userDetails = await dispatch(getUserByMob(formData));
        if (userDetails.payload.success === true) {
          const user_data = userDetails.payload.client;
          dispatch(
            signin({
              ...user_data,
              isAuth: true,
            })
          );
          setOpen(false);
        } else {
        }
      }
    }
  };

  return (
    <>
      <div
        id="page-content"
        className="laptop_version_pages"
        style={{ marginTop: "20px" }}
      >
        <div className="container categorcontainer">
          <div className="row ">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div id="nav-tabs" className="step-checkout">
                <ul className="nav nav-tabs step-items">
                  <li className="nav-item ">
                    <Link
                      className="nav-link"
                      //   data-bs-toggle="tab"
                      //   to="#steps1"
                      onClick={() => chgangeProccessStep1()}
                    >
                      authentication
                    </Link>
                  </li>
                  <li className="nav-item">
                    <span
                      className="nav-link"
                      //   data-bs-toggle="tab"
                      onClick={() => chgangeProccessStep2()}
                    >
                      Address
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className="nav-link"
                      //   data-bs-toggle="tab"
                      onClick={() => chgangeProccessStep3()}
                    >
                      Order Summary
                    </span>
                  </li>
                  {/* <li className="nav-item">
                    <span
                      className="nav-link"
                      onClick={() => chgangeProccessStep4()}
                      //   data-bs-toggle="tab"
                    >
                      Payment
                    </span>
                  </li> */}
                </ul>
              </div>

              <div className="tab-content checkout-form">
                <CheckoutStepsPage />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile_version_pages">
        <div >
          <Drawer
            title=""
            placement="bottom"
            closable={false}
            maskClosable={true}
            onClose={onClose}
            open={open}
            key="bottom"
            height={250}
            
          >
            {show_otp === false ? (
              <div className="">
                <span className="header_drower ">
                  Log in for the best experience
                </span>
                <p className="drawe_perha">Shop and track your orders easily</p>
                <div className="input_box_mi">
                  <TextField
                    label="Phone Number"
                    className="textfield"
                    type="email"
                    id="text"
                    style={{ width: "100%" }}
                    // error={errormassage !== "" ? true : false}
                    // helperText={errormassage}
                    onChange={(e) => {
                      mobilenumberChange(e);
                    }}
                    value={number}
                    autocomplete="off"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      minLength: 10,
                      maxLength: 10,
                    }}
                  />
                </div>
                <p className="drawe_perha mt-3">
                  By continuing, you agree to EWShopping's{" "}
                  <Link
                    to="/TermsUse"
                    style={{ color: "blue", fontWeight: "500" }}
                  >
                    Terms of Use
                  </Link>{" "}
                  and{" "}
                  <Link
                    to="/PrivacyPolicy"
                    style={{ color: "blue", fontWeight: "500" }}
                  >
                    Privacy Policy
                  </Link>
                </p>

                <div className="buttondiv_drower">
                  {confirm_number.length === 10 ? (
                    <>
                      <div
                        className="active_button_drow"
                        onClick={(e) => sendOptPress(e)}
                      >
                        Continue
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="disable_button_drow">Continue</div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <span className="header_drower">
                  Please enter the verification code we've sent you on +91-
                  {confirm_number}{" "}
                  <span
                    className="mx-2"
                    style={{
                      color: "blue",
                    }}
                    // onClick={() =>
                    //   dispatch(set_checkout_authentication_status(0))
                    // }
                  >
                    Edit
                  </span>
                </span>
                <div className="input_box_otp_mi mt-2">
                  {otpValues.map((value, index) => (
                    <Input
                      key={index}
                      ref={(input) => (inputRefs.current[index] = input)}
                      style={
                        otpValuesErrors.includes(index)
                          ? {
                              width: "50px",
                              marginRight: "10px",
                              border: "1px solid red",
                              backgroundColor: "#ffe6e6",
                              margin: "2.5vh 0",
                            }
                          : {
                              width: "50px",
                              marginRight: "10px",
                              margin: "2.5vh 0",
                            }
                      }
                      maxLength={1}
                      value={value}
                      onChange={(e) =>
                        handleOtpChange(
                          index,
                          e.target.value.replace(/\D/g, "")
                        )
                      }
                      onKeyDown={(e) => handleKeyDown(e, index)}
                    />
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                  className="header_drower"
                >
                  <span
                    className="mx-2"
                    style={{
                      color: "blue",
                      marginBottom: "2vh",
                    }}
                  >
                    Resend code
                  </span>
                </div>
                <div className="buttondiv_drower">
                  <div
                    className="active_button_drow"
                    onClick={(e) => verifyOtp(e)}
                  >
                    Continue
                  </div>
                </div>
              </>
            )}
          </Drawer>
        </div>
        <div className="mobile_version_pages"> 
        <Mobile_main_checkout />
       </div>

       
      </div>
    </>
  );
};

export default Checkout;

import React from "react";

const Electronic_Supercategory = ({ productifo }) => {
  return (
    <>
      <tbody>
        {productifo.electronics?.in_the_box !== "" && (
          <tr>
            <th>In the box</th>
            <td>{productifo.electronics?.in_the_box}</td>
          </tr>
        )}
        {productifo.electronics?.model_number !== "" && (
          <tr>
            <th>model number</th>
            <td>{productifo.electronics?.model_number}</td>
          </tr>
        )}

        {productifo.electronics?.model_name !== "" && (
          <tr>
            <th>model name</th>
            <td>{productifo.electronics?.model_name}</td>
          </tr>
        )}
        {productifo.electronics?.color !== "" && (
          <tr>
            <th>color</th>
            <td>{productifo.electronics?.color}</td>
          </tr>
        )}
        {productifo.electronics?.browse_type !== "" && (
          <tr>
            <th>browse type</th>
            <td>{productifo.electronics?.browse_type}</td>
          </tr>
        )}
        {productifo.electronics?.sim_type !== "" && (
          <tr>
            <th>sim type</th>
            <td>{productifo.electronics?.sim_type}</td>
          </tr>
        )}
        {productifo.electronics?.hybrid_sim_slot !== "" && (
          <tr>
            <th>hybrid sim slot</th>
            <td>{productifo.electronics?.hybrid_sim_slot}</td>
          </tr>
        )}
        {productifo.electronics?.touchscreen !== "" && (
          <tr>
            <th>touch screen</th>
            <td>{productifo.electronics?.touchscreen}</td>
          </tr>
        )}
        {productifo.electronics?.otg_compatible !== "" && (
          <tr>
            <th>Otg compatible</th>
            <td>{productifo.electronics?.otg_compatible}</td>
          </tr>
        )}
        {productifo.electronics?.quick_charging !== "" && (
          <tr>
            <th>Quick charging</th>
            <td>{productifo.electronics?.quick_charging}</td>
          </tr>
        )}
        {productifo.electronics?.sar_value !== "" && (
          <tr>
            <th>Sar value</th>
            <td>{productifo.electronics?.sar_value}</td>
          </tr>
        )}
        {productifo.electronics?.display_size !== "" && (
          <tr>
            <th>Display size</th>
            <td>{productifo.electronics?.display_size}</td>
          </tr>
        )}
        {productifo.electronics?.resolution !== "" && (
          <tr>
            <th>Resolution</th>
            <td>{productifo.electronics?.resolution}</td>
          </tr>
        )}
        {productifo.electronics?.gpu !== "" && (
          <tr>
            <th>gpu</th>
            <td>{productifo.electronics?.gpu}</td>
          </tr>
        )}
        {productifo.electronics?.display_type !== "" && (
          <tr>
            <th>Display type</th>
            <td>{productifo.electronics?.display_type}</td>
          </tr>
        )}
        {productifo.electronics?.other_display_features !== "" && (
          <tr>
            <th>Other display features</th>
            <td>{productifo.electronics?.other_display_features}</td>
          </tr>
        )}
        {productifo.electronics?.operating_system !== "" && (
          <tr>
            <th>operating system</th>
            <td>{productifo.electronics?.operating_system}</td>
          </tr>
        )}
        {productifo.electronics?.processor_brand !== "" && (
          <tr>
            <th>Processor brand</th>
            <td>{productifo.electronics?.processor_brand}</td>
          </tr>
        )}
        {productifo.electronics?.processor_type !== "" && (
          <tr>
            <th>Processor type</th>
            <td>{productifo.electronics?.processor_type}</td>
          </tr>
        )}
        {productifo.electronics?.primary_clock_speed !== "" && (
          <tr>
            <th>Primary clock speed</th>
            <td>{productifo.electronics?.primary_clock_speed}</td>
          </tr>
        )}
        {productifo.electronics?.secondary_clock_speed !== "" && (
          <tr>
            <th>Secondary clock speed</th>
            <td>{productifo.electronics?.secondary_clock_speed}</td>
          </tr>
        )}
        {productifo.electronics?.tertiary_clock_speed !== "" && (
          <tr>
            <th>Tertiary clock speed</th>
            <td>{productifo.electronics?.tertiary_clock_speed}</td>
          </tr>
        )}
        {productifo.electronics?.operating_frequency !== "" && (
          <tr>
            <th>Operating frequency</th>
            <td>{productifo.electronics?.operating_frequency}</td>
          </tr>
        )}
        {productifo.electronics?.internal_storage !== "" && (
          <tr>
            <th>Internal storage</th>
            <td>{productifo.electronics?.internal_storage}</td>
          </tr>
        )}
        {productifo.electronics?.ram !== "" && (
          <tr>
            <th>ram</th>
            <td>{productifo.electronics?.ram}</td>
          </tr>
        )}
        {productifo.electronics?.total_memory !== "" && (
          <tr>
            <th>Total memory</th>
            <td>{productifo.electronics?.total_memory}</td>
          </tr>
        )}
        {productifo.electronics?.call_log_memory !== "" && (
          <tr>
            <th>Call log memory</th>
            <td>{productifo.electronics?.call_log_memory}</td>
          </tr>
        )}
        {productifo.electronics?.primary_camera_available !== "" && (
          <tr>
            <th>Primary camera available</th>
            <td>{productifo.electronics?.primary_camera_available}</td>
          </tr>
        )}
        {productifo.electronics?.primary_camera !== "" && (
          <tr>
            <th>Primary camera</th>
            <td>{productifo.electronics?.primary_camera}</td>
          </tr>
        )}
        {productifo.electronics?.primary_camera_features !== "" && (
          <tr>
            <th>Primary camera features</th>
            <td>{productifo.electronics?.primary_camera_features}</td>
          </tr>
        )}
        {productifo.electronics?.secondary_camera_available !== "" && (
          <tr>
            <th>Secondary camera available</th>
            <td>{productifo.electronics?.secondary_camera_available}</td>
          </tr>
        )}
        {productifo.electronics?.secondary_camera !== "" && (
          <tr>
            <th>Secondary camera</th>
            <td>{productifo.electronics?.secondary_camera}</td>
          </tr>
        )}
        {productifo.electronics?.secondary_camera_features !== "" && (
          <tr>
            <th>Secondary camera features</th>
            <td>{productifo.electronics?.secondary_camera_features}</td>
          </tr>
        )}
        {productifo.electronics?.flash !== "" && (
          <tr>
            <th>Flash</th>
            <td>{productifo.electronics?.flash}</td>
          </tr>
        )}
        {productifo.electronics?.hd_recording !== "" && (
          <tr>
            <th>HD recording</th>
            <td>{productifo.electronics?.hd_recording}</td>
          </tr>
        )}
        {productifo.electronics?.full_hd_recording !== "" && (
          <tr>
            <th>Full hd recording</th>
            <td>{productifo.electronics?.full_hd_recording}</td>
          </tr>
        )}
        {productifo.electronics?.video_recording !== "" && (
          <tr>
            <th>Video recording</th>
            <td>{productifo.electronics?.video_recording}</td>
          </tr>
        )}
        {productifo.electronics?.video_recording_resolution !== "" && (
          <tr>
            <th>Video recording resolution</th>
            <td>{productifo.electronics?.video_recording_resolution}</td>
          </tr>
        )}
        {productifo.electronics?.digital_zoom !== "" && (
          <tr>
            <th>Digital zoom</th>
            <td>{productifo.electronics?.digital_zoom}</td>
          </tr>
        )}
        {productifo.electronics?.frame_rate !== "" && (
          <tr>
            <th>Frame rate</th>
            <td>{productifo.electronics?.frame_rate}</td>
          </tr>
        )}
        {productifo.electronics?.dual_camera_lens !== "" && (
          <tr>
            <th>Dual camera lens</th>
            <td>{productifo.electronics?.dual_camera_lens}</td>
          </tr>
        )}
        {productifo.electronics?.call_wait_or_hold !== "" && (
          <tr>
            <th>Call wait or hold</th>
            <td>{productifo.electronics?.call_wait_or_hold}</td>
          </tr>
        )}
        {productifo.electronics?.conference_call !== "" && (
          <tr>
            <th>Conference call</th>
            <td>{productifo.electronics?.conference_call}</td>
          </tr>
        )}
        {productifo.electronics?.hands_free !== "" && (
          <tr>
            <th>Hands free</th>
            <td>{productifo.electronics?.hands_free}</td>
          </tr>
        )}
        {productifo.electronics?.call_divert !== "" && (
          <tr>
            <th>Call divert</th>
            <td>{productifo.electronics?.call_divert}</td>
          </tr>
        )}
        {productifo.electronics?.phone_book !== "" && (
          <tr>
            <th>Phone book</th>
            <td>{productifo.electronics?.phone_book}</td>
          </tr>
        )}
        {productifo.electronics?.call_timer !== "" && (
          <tr>
            <th>Call timer</th>
            <td>{productifo.electronics?.call_timer}</td>
          </tr>
        )}
        {productifo.electronics?.speaker_phone !== "" && (
          <tr>
            <th>Speaker phone</th>
            <td>{productifo.electronics?.speaker_phone}</td>
          </tr>
        )}
        {productifo.electronics?.call_records !== "" && (
          <tr>
            <th>Call records</th>
            <td>{productifo.electronics?.call_records}</td>
          </tr>
        )}
        {productifo.electronics?.network_type !== "" && (
          <tr>
            <th>Network type</th>
            <td>{productifo.electronics?.network_type}</td>
          </tr>
        )}
        {productifo.electronics?.supported_networks !== "" && (
          <tr>
            <th>Supported networks</th>
            <td>{productifo.electronics?.supported_networks}</td>
          </tr>
        )}
        {productifo.electronics?.internet_connectivity !== "" && (
          <tr>
            <th>Internet connectivity</th>
            <td>{productifo.electronics?.internet_connectivity}</td>
          </tr>
        )}
        {productifo.electronics?._3g !== "" && (
          <tr>
            <th>_3g</th>
            <td>{productifo.electronics?._3g}</td>
          </tr>
        )}
        {productifo.electronics?.gprs !== "" && (
          <tr>
            <th>gprs</th>
            <td>{productifo.electronics?.gprs}</td>
          </tr>
        )}
        {productifo.electronics?.micro_usb_port !== "" && (
          <tr>
            <th>Micro usb port</th>
            <td>{productifo.electronics?.micro_usb_port}</td>
          </tr>
        )}
        {productifo.electronics?.micro_usb_version !== "" && (
          <tr>
            <th>Micro usb version</th>
            <td>{productifo.electronics?.micro_usb_version}</td>
          </tr>
        )}
        {productifo.electronics?.blutooth_support !== "" && (
          <tr>
            <th>Blutooth support</th>
            <td>{productifo.electronics?.blutooth_support}</td>
          </tr>
        )}
        {productifo.electronics?.blutooth_version !== "" && (
          <tr>
            <th>Blutooth version</th>
            <td>{productifo.electronics?.blutooth_version}</td>
          </tr>
        )}
        {productifo.electronics?.wifi !== "" && (
          <tr>
            <th>wifi</th>
            <td>{productifo.electronics?.wifi}</td>
          </tr>
        )}
        {productifo.electronics?.wifi_version !== "" && (
          <tr>
            <th>wifi version</th>
            <td>{productifo.electronics?.wifi_version}</td>
          </tr>
        )}
        {productifo.electronics?.wifi_hotspot !== "" && (
          <tr>
            <th>wifi hotspot</th>
            <td>{productifo.electronics?.wifi_hotspot}</td>
          </tr>
        )}
        {productifo.electronics?.mini_hdmi_port !== "" && (
          <tr>
            <th>Mini hdmi port</th>
            <td>{productifo.electronics?.mini_hdmi_port}</td>
          </tr>
        )}
        {productifo.electronics?.nfc !== "" && (
          <tr>
            <th>nfc</th>
            <td>{productifo.electronics?.nfc}</td>
          </tr>
        )}
        {productifo.electronics?.usb_tethering !== "" && (
          <tr>
            <th>Usb Tethering</th>
            <td>{productifo.electronics?.usb_tethering}</td>
          </tr>
        )}
        {productifo.electronics?.usb_connectivity !== "" && (
          <tr>
            <th>Usb connectivity</th>
            <td>{productifo.electronics?.usb_connectivity}</td>
          </tr>
        )}
        {productifo.electronics?.map_support !== "" && (
          <tr>
            <th>Map Support</th>
            <td>{productifo.electronics?.map_support}</td>
          </tr>
        )}
        {productifo.electronics?.gps_support !== "" && (
          <tr>
            <th>Gps Support</th>
            <td>{productifo.electronics?.gps_support}</td>
          </tr>
        )}
        {productifo.electronics?.smartphone !== "" && (
          <tr>
            <th>smartphone</th>
            <td>{productifo.electronics?.smartphone}</td>
          </tr>
        )}
        {productifo.electronics?.touchscreen_type !== "" && (
          <tr>
            <th>touchscreen type</th>
            <td>{productifo.electronics?.touchscreen_type}</td>
          </tr>
        )}
        {productifo.electronics?.sim_size !== "" && (
          <tr>
            <th>sim size</th>
            <td>{productifo.electronics?.sim_size}</td>
          </tr>
        )}
        {productifo.electronics?.user_interface !== "" && (
          <tr>
            <th>user interface</th>
            <td>{productifo.electronics?.user_interface}</td>
          </tr>
        )}
        {productifo.electronics?.instant_message !== "" && (
          <tr>
            <th>instant message</th>
            <td>{productifo.electronics?.instant_message}</td>
          </tr>
        )}
        {productifo.electronics?.removable_battery !== "" && (
          <tr>
            <th>removable battery</th>
            <td>{productifo.electronics?.removable_battery}</td>
          </tr>
        )}
        {productifo.electronics?.mms !== "" && (
          <tr>
            <th>mms</th>
            <td>{productifo.electronics?.mms}</td>
          </tr>
        )}

        {productifo.electronics?.sms !== "" && (
          <tr>
            <th>sms</th>
            <td>{productifo.electronics?.sms}</td>
          </tr>
        )}
        {productifo.electronics?.keypad !== "" && (
          <tr>
            <th>keypad</th>
            <td>{productifo.electronics?.keypad}</td>
          </tr>
        )}
        {productifo.electronics?.voice_input !== "" && (
          <tr>
            <th>voice input</th>
            <td>{productifo.electronics?.voice_input}</td>
          </tr>
        )}
        {productifo.electronics?.predictive_text_input !== "" && (
          <tr>
            <th>predictive text input</th>
            <td>{productifo.electronics?.predictive_text_input}</td>
          </tr>
        )}
        {productifo.electronics?.user_memory !== "" && (
          <tr>
            <th>user memory</th>
            <td>{productifo.electronics?.user_memory}</td>
          </tr>
        )}
        {productifo.electronics?.sensors !== "" && (
          <tr>
            <th>sensors</th>
            <td>{productifo.electronics?.sensors}</td>
          </tr>
        )}
        {productifo.electronics?.browser !== "" && (
          <tr>
            <th>browser</th>
            <td>{productifo.electronics?.browser}</td>
          </tr>
        )}
        {productifo.electronics?.other_features !== "" && (
          <tr>
            <th>other features</th>
            <td>{productifo.electronics?.other_features}</td>
          </tr>
        )}
        {productifo.electronics?.gps_type !== "" && (
          <tr>
            <th>gps type</th>
            <td>{productifo.electronics?.gps_type}</td>
          </tr>
        )}
        {productifo.electronics?.fm_radio !== "" && (
          <tr>
            <th>fm radio</th>
            <td>{productifo.electronics?.fm_radio}</td>
          </tr>
        )}
        {productifo.electronics?.fm_radio_recording !== "" && (
          <tr>
            <th>fm radio recording</th>
            <td>{productifo.electronics?.fm_radio_recording}</td>
          </tr>
        )}
        {productifo.electronics?.battery_capacity !== "" && (
          <tr>
            <th>battery capacity</th>
            <td>{productifo.electronics?.battery_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.battery_type !== "" && (
          <tr>
            <th>battery type</th>
            <td>{productifo.electronics?.battery_type}</td>
          </tr>
        )}
        {productifo.electronics?.width !== "" && (
          <tr>
            <th>width</th>
            <td>{productifo.electronics?.width}</td>
          </tr>
        )}
        {productifo.electronics?.height !== "" && (
          <tr>
            <th>height</th>
            <td>{productifo.electronics?.height}</td>
          </tr>
        )}
        {productifo.electronics?.depth !== "" && (
          <tr>
            <th>depth</th>
            <td>{productifo.electronics?.depth}</td>
          </tr>
        )}
        {productifo.electronics?.weight !== "" && (
          <tr>
            <th>weight</th>
            <td>{productifo.electronics?.weight}</td>
          </tr>
        )}
        {productifo.electronics?.warranty_summary !== "" && (
          <tr>
            <th>warranty summary</th>
            <td>{productifo.electronics?.warranty_summary}</td>
          </tr>
        )}
        {productifo.electronics?.domestic_warranty !== "" && (
          <tr>
            <th>domestic warranty</th>
            <td>{productifo.electronics?.domestic_warranty}</td>
          </tr>
        )}
        {productifo.electronics?.NumberofItems !== "" && (
          <tr>
            <th>NumberofItems</th>
            <td>{productifo.electronics?.NumberofItems}</td>
          </tr>
        )}
        {productifo.electronics?.function_cooking !== "" && (
          <tr>
            <th>function cooking</th>
            <td>{productifo.electronics?.function_cooking}</td>
          </tr>
        )}
        {productifo.electronics?.steaming !== "" && (
          <tr>
            <th>steaming</th>
            <td>{productifo.electronics?.steaming}</td>
          </tr>
        )}
        {productifo.electronics?.non_stick_cooking_bowl !== "" && (
          <tr>
            <th>non stick cooking bowl</th>
            <td>{productifo.electronics?.non_stick_cooking_bowl}</td>
          </tr>
        )}
        {productifo.electronics?.detachable_power_cord !== "" && (
          <tr>
            <th>detachable power cord</th>
            <td>{productifo.electronics?.detachable_power_cord}</td>
          </tr>
        )}
        {productifo.electronics?.see_through_glass_top !== "" && (
          <tr>
            <th>see through glass top</th>
            <td>{productifo.electronics?.see_through_glass_top}</td>
          </tr>
        )}
        {productifo.electronics?.measuring_cup !== "" && (
          <tr>
            <th>measuring cup</th>
            <td>{productifo.electronics?.measuring_cup}</td>
          </tr>
        )}
        {productifo.electronics?.trivet !== "" && (
          <tr>
            <th>trivet</th>
            <td>{productifo.electronics?.trivet}</td>
          </tr>
        )}
        {productifo.electronics?.temperature_glass_lid !== "" && (
          <tr>
            <th>temperature glass lid</th>
            <td>{productifo.electronics?.temperature_glass_lid}</td>
          </tr>
        )}
        {productifo.electronics?.steaming_basket !== "" && (
          <tr>
            <th>steaming basket</th>
            <td>{productifo.electronics?.steaming_basket}</td>
          </tr>
        )}
        {productifo.electronics?.spatula !== "" && (
          <tr>
            <th>spatula</th>
            <td>{productifo.electronics?.spatula}</td>
          </tr>
        )}
        {productifo.electronics?.cool_touch_handles !== "" && (
          <tr>
            <th>cool touch handles</th>
            <td>{productifo.electronics?.cool_touch_handles}</td>
          </tr>
        )}
        {productifo.electronics?.thermal_fuse !== "" && (
          <tr>
            <th>thermal fuse</th>
            <td>{productifo.electronics?.thermal_fuse}</td>
          </tr>
        )}
        {productifo.electronics?.motor_speed !== "" && (
          <tr>
            <th>motor speed</th>
            <td>{productifo.electronics?.motor_speed}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_stones !== "" && (
          <tr>
            <th>number of stones</th>
            <td>{productifo.electronics?.number_of_stones}</td>
          </tr>
        )}
        {productifo.electronics?.attachment_present !== "" && (
          <tr>
            <th>attachment present</th>
            <td>{productifo.electronics?.attachment_present}</td>
          </tr>
        )}
        {productifo.electronics?.motor_overload_protection !== "" && (
          <tr>
            <th>motor overload protection</th>
            <td>{productifo.electronics?.motor_overload_protection}</td>
          </tr>
        )}
        {productifo.electronics?.auto_off !== "" && (
          <tr>
            <th>auto off</th>
            <td>{productifo.electronics?.auto_off}</td>
          </tr>
        )}
        {productifo.electronics?.safety_lock !== "" && (
          <tr>
            <th>safety lock</th>
            <td>{productifo.electronics?.safety_lock}</td>
          </tr>
        )}
        {productifo.electronics?.processing_bocol !== "" && (
          <tr>
            <th>processing bocol</th>
            <td>{productifo.electronics?.processing_bocol}</td>
          </tr>
        )}
        {productifo.electronics?.processing_jar !== "" && (
          <tr>
            <th>processing jar</th>
            <td>{productifo.electronics?.processing_jar}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_attached_jar !== "" && (
          <tr>
            <th>number of attached jar</th>
            <td>{productifo.electronics?.number_of_attached_jar}</td>
          </tr>
        )}
        {productifo.electronics?.anti_drip_value !== "" && (
          <tr>
            <th>anti drip value</th>
            <td>{productifo.electronics?.anti_drip_value}</td>
          </tr>
        )}
        {productifo.electronics?.brew_strength_control !== "" && (
          <tr>
            <th>brew strength control</th>
            <td>{productifo.electronics?.brew_strength_control}</td>
          </tr>
        )}
        {productifo.electronics?.removable_drip !== "" && (
          <tr>
            <th>removable drip</th>
            <td>{productifo.electronics?.removable_drip}</td>
          </tr>
        )}
        {productifo.electronics?.frother !== "" && (
          <tr>
            <th>frother</th>
            <td>{productifo.electronics?.frother}</td>
          </tr>
        )}
        {productifo.electronics?.illuminated_on_off_switch !== "" && (
          <tr>
            <th>illuminated on off switch</th>
            <td>{productifo.electronics?.illuminated_on_off_switch}</td>
          </tr>
        )}
        {productifo.electronics?.hot_plate !== "" && (
          <tr>
            <th>hot plate</th>
            <td>{productifo.electronics?.hot_plate}</td>
          </tr>
        )}
        {productifo.electronics?.net_quantity !== "" && (
          <tr>
            <th>net quantity</th>
            <td>{productifo.electronics?.net_quantity}</td>
          </tr>
        )}
        {productifo.electronics?.brewing_time !== "" && (
          <tr>
            <th>brewing time</th>
            <td>{productifo.electronics?.brewing_time}</td>
          </tr>
        )}
        {productifo.electronics?.removable_filter !== "" && (
          <tr>
            <th>removable filter</th>
            <td>{productifo.electronics?.removable_filter}</td>
          </tr>
        )}
        {productifo.electronics?.other_dimensions !== "" && (
          <tr>
            <th>other dimensions</th>
            <td>{productifo.electronics?.other_dimensions}</td>
          </tr>
        )}
        {productifo.electronics?.filter_material !== "" && (
          <tr>
            <th>filter material</th>
            <td>{productifo.electronics?.filter_material}</td>
          </tr>
        )}
        {productifo.electronics?.temperature_control !== "" && (
          <tr>
            <th>temperature control</th>
            <td>{productifo.electronics?.temperature_control}</td>
          </tr>
        )}
        {productifo.electronics?.spray !== "" && (
          <tr>
            <th>spray</th>
            <td>{productifo.electronics?.spray}</td>
          </tr>
        )}
        {productifo.electronics?.steam_burst !== "" && (
          <tr>
            <th>steam burst</th>
            <td>{productifo.electronics?.steam_burst}</td>
          </tr>
        )}
        {productifo.electronics?.self_cleaning !== "" && (
          <tr>
            <th>self cleaning</th>
            <td>{productifo.electronics?.self_cleaning}</td>
          </tr>
        )}
        {productifo.electronics?.swivel_cord !== "" && (
          <tr>
            <th>swivel cord</th>
            <td>{productifo.electronics?.swivel_cord}</td>
          </tr>
        )}
        {productifo.electronics?.electric_type !== "" && (
          <tr>
            <th>electric type</th>
            <td>{productifo.electronics?.electric_type}</td>
          </tr>
        )}
        {productifo.electronics?.purification_features !== "" && (
          <tr>
            <th>purification features</th>
            <td>{productifo.electronics?.purification_features}</td>
          </tr>
        )}
        {productifo.electronics?.detachable_storage_tank !== "" && (
          <tr>
            <th>detachable storage tank</th>
            <td>{productifo.electronics?.detachable_storage_tank}</td>
          </tr>
        )}
        {productifo.electronics?.membrane_type !== "" && (
          <tr>
            <th>membrane type</th>
            <td>{productifo.electronics?.membrane_type}</td>
          </tr>
        )}
        {productifo.electronics?.overflow_protection !== "" && (
          <tr>
            <th>overflow protection</th>
            <td>{productifo.electronics?.overflow_protection}</td>
          </tr>
        )}
        {productifo.electronics?.filter_life !== "" && (
          <tr>
            <th>filter life</th>
            <td>{productifo.electronics?.filter_life}</td>
          </tr>
        )}
        {productifo.electronics?.alarms !== "" && (
          <tr>
            <th>alarms</th>
            <td>{productifo.electronics?.alarms}</td>
          </tr>
        )}
        {productifo.electronics?.filtration_capacity !== "" && (
          <tr>
            <th>filtration capacity</th>
            <td>{productifo.electronics?.filtration_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.purification_capacity !== "" && (
          <tr>
            <th>purification capacity</th>
            <td>{productifo.electronics?.purification_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.cold_water_capacity !== "" && (
          <tr>
            <th>cold water capacity</th>
            <td>{productifo.electronics?.cold_water_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.cold_water_dispensor !== "" && (
          <tr>
            <th>cold water dispensor</th>
            <td>{productifo.electronics?.cold_water_dispensor}</td>
          </tr>
        )}
        {productifo.electronics?.hot_water_capacity !== "" && (
          <tr>
            <th>hot water capacity</th>
            <td>{productifo.electronics?.hot_water_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.hot_water_dispensor !== "" && (
          <tr>
            <th>hot water dispensor</th>
            <td>{productifo.electronics?.hot_water_dispensor}</td>
          </tr>
        )}
        {productifo.electronics?.purification_stages !== "" && (
          <tr>
            <th>purification_stages</th>
            <td>{productifo.electronics?.purification_stages}</td>
          </tr>
        )}
        {productifo.electronics?.airflow !== "" && (
          <tr>
            <th>airflow</th>
            <td>{productifo.electronics?.airflow}</td>
          </tr>
        )}
        {productifo.electronics?.blade_sweep !== "" && (
          <tr>
            <th>blade sweep</th>
            <td>{productifo.electronics?.blade_sweep}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_speeds !== "" && (
          <tr>
            <th>number of speeds</th>
            <td>{productifo.electronics?.number_of_speeds}</td>
          </tr>
        )}
        {productifo.electronics?.cooling_coverage_area !== "" && (
          <tr>
            <th>cooling coverage area</th>
            <td>{productifo.electronics?.cooling_coverage_area}</td>
          </tr>
        )}
        {productifo.electronics?.speed_control !== "" && (
          <tr>
            <th>speed control</th>
            <td>{productifo.electronics?.speed_control}</td>
          </tr>
        )}
        {productifo.electronics?.operating_mode !== "" && (
          <tr>
            <th>operating mode</th>
            <td>{productifo.electronics?.operating_mode}</td>
          </tr>
        )}
        {productifo.electronics?.air_delivery !== "" && (
          <tr>
            <th>air delivery</th>
            <td>{productifo.electronics?.air_delivery}</td>
          </tr>
        )}
        {productifo.electronics?.air_throw_distance !== "" && (
          <tr>
            <th>air throw distance</th>
            <td>{productifo.electronics?.air_throw_distance}</td>
          </tr>
        )}
        {productifo.electronics?.air_deflection !== "" && (
          <tr>
            <th>air deflection</th>
            <td>{productifo.electronics?.air_deflection}</td>
          </tr>
        )}
        {productifo.electronics?.blower_material !== "" && (
          <tr>
            <th>blower material</th>
            <td>{productifo.electronics?.blower_material}</td>
          </tr>
        )}
        {productifo.electronics?.ice_chamber !== "" && (
          <tr>
            <th>ice chamber</th>
            <td>{productifo.electronics?.ice_chamber}</td>
          </tr>
        )}
        {productifo.electronics?.caston_wheels !== "" && (
          <tr>
            <th>caston wheels</th>
            <td>{productifo.electronics?.caston_wheels}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_caston_wheels !== "" && (
          <tr>
            <th>number of caston wheels</th>
            <td>{productifo.electronics?.number_of_caston_wheels}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_fan_blades !== "" && (
          <tr>
            <th>number of fan blades</th>
            <td>{productifo.electronics?.number_of_fan_blades}</td>
          </tr>
        )}
        {productifo.electronics?.auto_louver_movement !== "" && (
          <tr>
            <th>auto louver movement</th>
            <td>{productifo.electronics?.auto_louver_movement}</td>
          </tr>
        )}
        {productifo.electronics?.oscillating_function !== "" && (
          <tr>
            <th>oscillating function</th>
            <td>{productifo.electronics?.oscillating_function}</td>
          </tr>
        )}
        {productifo.electronics?.technology_used !== "" && (
          <tr>
            <th>technology used</th>
            <td>{productifo.electronics?.technology_used}</td>
          </tr>
        )}
        {productifo.electronics?.blower !== "" && (
          <tr>
            <th>blower</th>
            <td>{productifo.electronics?.blower}</td>
          </tr>
        )}
        {productifo.electronics?.motor_suction_power !== "" && (
          <tr>
            <th>motor suction power</th>
            <td>{productifo.electronics?.motor_suction_power}</td>
          </tr>
        )}
        {productifo.electronics?.solar_power !== "" && (
          <tr>
            <th>solar power</th>
            <td>{productifo.electronics?.solar_power}</td>
          </tr>
        )}
        {productifo.electronics?.efficiency !== "" && (
          <tr>
            <th>efficiency</th>
            <td>{productifo.electronics?.efficiency}</td>
          </tr>
        )}
        {productifo.electronics?.output_voltage !== "" && (
          <tr>
            <th>output voltage</th>
            <td>{productifo.electronics?.output_voltage}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_stitches !== "" && (
          <tr>
            <th>number of stitches</th>
            <td>{productifo.electronics?.number_of_stitches}</td>
          </tr>
        )}
        {productifo.electronics?.sewing_speed !== "" && (
          <tr>
            <th>sewing speed</th>
            <td>{productifo.electronics?.sewing_speed}</td>
          </tr>
        )}
        {productifo.electronics?.shape !== "" && (
          <tr>
            <th>shape</th>
            <td>{productifo.electronics?.shape}</td>
          </tr>
        )}
        {productifo.electronics?.bobbin_winder !== "" && (
          <tr>
            <th>bobbin winder</th>
            <td>{productifo.electronics?.bobbin_winder}</td>
          </tr>
        )}
        {productifo.electronics?.stitch_out_time_indicator !== "" && (
          <tr>
            <th>stitch out time indicator</th>
            <td>{productifo.electronics?.stitch_out_time_indicator}</td>
          </tr>
        )}
        {productifo.electronics?.led_needle_light !== "" && (
          <tr>
            <th>led needle light</th>
            <td>{productifo.electronics?.led_needle_light}</td>
          </tr>
        )}
        {productifo.electronics?.mount_type !== "" && (
          <tr>
            <th>mount type</th>
            <td>{productifo.electronics?.mount_type}</td>
          </tr>
        )}
        {productifo.electronics?.over_vlot_protection !== "" && (
          <tr>
            <th>over vlot protection</th>
            <td>{productifo.electronics?.over_vlot_protection}</td>
          </tr>
        )}
        {productifo.electronics?.minimum_input_power !== "" && (
          <tr>
            <th>minimum input power</th>
            <td>{productifo.electronics?.minimum_input_power}</td>
          </tr>
        )}
        {productifo.electronics?.suitable_for !== "" && (
          <tr>
            <th>suitable for</th>
            <td>{productifo.electronics?.suitable_for}</td>
          </tr>
        )}
        {productifo.electronics?.star_rating !== "" && (
          <tr>
            <th>star rating</th>
            <td>{productifo.electronics?.star_rating}</td>
          </tr>
        )}
        {productifo.electronics?.heating_element_type !== "" && (
          <tr>
            <th>heating element type</th>
            <td>{productifo.electronics?.heating_element_type}</td>
          </tr>
        )}
        {productifo.electronics?.washing_method !== "" && (
          <tr>
            <th>washing method</th>
            <td>{productifo.electronics?.washing_method}</td>
          </tr>
        )}
        {productifo.electronics?.in_built_heater !== "" && (
          <tr>
            <th>in built heater</th>
            <td>{productifo.electronics?.in_built_heater}</td>
          </tr>
        )}
        {productifo.electronics?.tub_material !== "" && (
          <tr>
            <th>tub material</th>
            <td>{productifo.electronics?.tub_material}</td>
          </tr>
        )}
        {productifo.electronics?.digital_display !== "" && (
          <tr>
            <th>digital display</th>
            <td>{productifo.electronics?.digital_display}</td>
          </tr>
        )}
        {productifo.electronics?.water_level_selector !== "" && (
          <tr>
            <th>water level selector</th>
            <td>{productifo.electronics?.water_level_selector}</td>
          </tr>
        )}
        {productifo.electronics?.wash_cycle_duration !== "" && (
          <tr>
            <th>wash cycle duration</th>
            <td>{productifo.electronics?.wash_cycle_duration}</td>
          </tr>
        )}
        {productifo.electronics?.spin_cycle_duration !== "" && (
          <tr>
            <th>spin cycle duration</th>
            <td>{productifo.electronics?.spin_cycle_duration}</td>
          </tr>
        )}
        {productifo.electronics?.inverter !== "" && (
          <tr>
            <th>inverter</th>
            <td>{productifo.electronics?.inverter}</td>
          </tr>
        )}
        {productifo.electronics?.steam !== "" && (
          <tr>
            <th>steam</th>
            <td>{productifo.electronics?.steam}</td>
          </tr>
        )}
        {productifo.electronics?.quick_wash !== "" && (
          <tr>
            <th>quick wash</th>
            <td>{productifo.electronics?.quick_wash}</td>
          </tr>
        )}
        {productifo.electronics?.lint_filter !== "" && (
          <tr>
            <th>lint filter</th>
            <td>{productifo.electronics?.lint_filter}</td>
          </tr>
        )}
        {productifo.electronics?.child_lock !== "" && (
          <tr>
            <th>child lock</th>
            <td>{productifo.electronics?.child_lock}</td>
          </tr>
        )}
        {productifo.electronics?.wash_program_types !== "" && (
          <tr>
            <th>wash program types</th>
            <td>{productifo.electronics?.wash_program_types}</td>
          </tr>
        )}
        {productifo.electronics?.compressor !== "" && (
          <tr>
            <th>compressor</th>
            <td>{productifo.electronics?.compressor}</td>
          </tr>
        )}
        {productifo.electronics?.dehumidification !== "" && (
          <tr>
            <th>dehumidification</th>
            <td>{productifo.electronics?.dehumidification}</td>
          </tr>
        )}
        {productifo.electronics?.remote_control !== "" && (
          <tr>
            <th>remote_control</th>
            <td>{productifo.electronics?.remote_control}</td>
          </tr>
        )}
        {productifo.electronics?.operating_modes !== "" && (
          <tr>
            <th>operating_modes</th>
            <td>{productifo.electronics?.operating_modes}</td>
          </tr>
        )}
        {productifo.electronics?.condenser_coil !== "" && (
          <tr>
            <th>condenser_coil</th>
            <td>{productifo.electronics?.condenser_coil}</td>
          </tr>
        )}
        {productifo.electronics?.indoor_w_h_d !== "" && (
          <tr>
            <th>indoor_w_h_d</th>
            <td>{productifo.electronics?.indoor_w_h_d}</td>
          </tr>
        )}
        {productifo.electronics?.indoor_unit_weight !== "" && (
          <tr>
            <th>indoor_unit_weight</th>
            <td>{productifo.electronics?.indoor_unit_weight}</td>
          </tr>
        )}
        {productifo.electronics?.auto_air_swing !== "" && (
          <tr>
            <th>auto_air_swing</th>
            <td>{productifo.electronics?.auto_air_swing}</td>
          </tr>
        )}
        {productifo.electronics?.air_flow_direction !== "" && (
          <tr>
            <th>air_flow_direction</th>
            <td>{productifo.electronics?.air_flow_direction}</td>
          </tr>
        )}
        {productifo.electronics?.anti_bacteria_filter !== "" && (
          <tr>
            <th>anti_bacteria_filter</th>
            <td>{productifo.electronics?.anti_bacteria_filter}</td>
          </tr>
        )}
        {productifo.electronics?.dust_filter !== "" && (
          <tr>
            <th>dust_filter</th>
            <td>{productifo.electronics?.dust_filter}</td>
          </tr>
        )}
        {productifo.electronics?.auto_restart !== "" && (
          <tr>
            <th>auto_restart</th>
            <td>{productifo.electronics?.auto_restart}</td>
          </tr>
        )}
        {productifo.electronics?.quit_mode !== "" && (
          <tr>
            <th>quit_mode</th>
            <td>{productifo.electronics?.quit_mode}</td>
          </tr>
        )}
        {productifo.electronics?.sleep_mode !== "" && (
          <tr>
            <th>sleep_mode</th>
            <td>{productifo.electronics?.sleep_mode}</td>
          </tr>
        )}
        {productifo.electronics?.memory_feature !== "" && (
          <tr>
            <th>memory_feature</th>
            <td>{productifo.electronics?.memory_feature}</td>
          </tr>
        )}
        {productifo.electronics?.self_diagosis !== "" && (
          <tr>
            <th>self_diagosis</th>
            <td>{productifo.electronics?.self_diagosis}</td>
          </tr>
        )}
        {productifo.electronics?.electricity_consumption !== "" && (
          <tr>
            <th>electricity_consumption</th>
            <td>{productifo.electronics?.electricity_consumption}</td>
          </tr>
        )}
        {productifo.electronics?.defrosting_type !== "" && (
          <tr>
            <th>defrosting_type</th>
            <td>{productifo.electronics?.defrosting_type}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_doors !== "" && (
          <tr>
            <th>number_of_doors</th>
            <td>{productifo.electronics?.number_of_doors}</td>
          </tr>
        )}
        {productifo.electronics?.coolpad !== "" && (
          <tr>
            <th>coolpad</th>
            <td>{productifo.electronics?.coolpad}</td>
          </tr>
        )}
        {productifo.electronics?.build_in_stabilizer !== "" && (
          <tr>
            <th>build_in_stabilizer</th>
            <td>{productifo.electronics?.build_in_stabilizer}</td>
          </tr>
        )}
        {productifo.electronics?.toughened_glass !== "" && (
          <tr>
            <th>toughened_glass</th>
            <td>{productifo.electronics?.toughened_glass}</td>
          </tr>
        )}
        {productifo.electronics?.moisture_control !== "" && (
          <tr>
            <th>moisture_control</th>
            <td>{productifo.electronics?.moisture_control}</td>
          </tr>
        )}
        {productifo.electronics?.door_lock !== "" && (
          <tr>
            <th>door_lock</th>
            <td>{productifo.electronics?.door_lock}</td>
          </tr>
        )}
        {productifo.electronics?.launch_year !== "" && (
          <tr>
            <th>launch_year</th>
            <td>{productifo.electronics?.launch_year}</td>
          </tr>
        )}
        {productifo.electronics?.output_frequency !== "" && (
          <tr>
            <th>output_frequency</th>
            <td>{productifo.electronics?.output_frequency}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_button_holes_styles !== "" && (
          <tr>
            <th>number_of_button_holes_styles</th>
            <td>{productifo.electronics?.number_of_button_holes_styles}</td>
          </tr>
        )}
        {productifo.electronics?.lcd_screen_display !== "" && (
          <tr>
            <th>lcd_screen_display</th>
            <td>{productifo.electronics?.lcd_screen_display}</td>
          </tr>
        )}
        {productifo.electronics?.shape !== "" && (
          <tr>
            <th>shape</th>
            <td>{productifo.electronics?.shape}</td>
          </tr>
        )}
        {productifo.electronics?.stop_start_button !== "" && (
          <tr>
            <th>stop_start_button</th>
            <td>{productifo.electronics?.stop_start_button}</td>
          </tr>
        )}
        {productifo.electronics?.automatic_thread_cutter !== "" && (
          <tr>
            <th>automatic_thread_cutter</th>
            <td>{productifo.electronics?.automatic_thread_cutter}</td>
          </tr>
        )}
        {productifo.electronics?.planting !== "" && (
          <tr>
            <th>planting</th>
            <td>{productifo.electronics?.planting}</td>
          </tr>
        )}
        {productifo.electronics?.heater_material !== "" && (
          <tr>
            <th>heater_material</th>
            <td>{productifo.electronics?.heater_material}</td>
          </tr>
        )}
        {productifo.electronics?.design !== "" && (
          <tr>
            <th>design</th>
            <td>{productifo.electronics?.design}</td>
          </tr>
        )}
        {productifo.electronics?.wheel_support !== "" && (
          <tr>
            <th>wheel_support</th>
            <td>{productifo.electronics?.wheel_support}</td>
          </tr>
        )}
        {productifo.electronics?.brand !== "" && (
          <tr>
            <th>brand</th>
            <td>{productifo.electronics?.brand}</td>
          </tr>
        )}
        {productifo.electronics?.capacity !== "" && (
          <tr>
            <th>capacity</th>
            <td>{productifo.electronics?.capacity}</td>
          </tr>
        )}
        {productifo.electronics?.manufacturing_year !== "" && (
          <tr>
            <th>manufacturing_year</th>
            <td>{productifo.electronics?.manufacturing_year}</td>
          </tr>
        )}
        {productifo.electronics?.cover_in_warranty !== "" && (
          <tr>
            <th>cover_in_warranty</th>
            <td>{productifo.electronics?.cover_in_warranty}</td>
          </tr>
        )}
        {productifo.electronics?.not_cover_in_warranty !== "" && (
          <tr>
            <th>not_cover_in_warranty</th>
            <td>{productifo.electronics?.not_cover_in_warranty}</td>
          </tr>
        )}
        {productifo.electronics?.warranty_service_type !== "" && (
          <tr>
            <th>warranty_service_type</th>
            <td>{productifo.electronics?.warranty_service_type}</td>
          </tr>
        )}
        {productifo.electronics?.preheat !== "" && (
          <tr>
            <th>preheat</th>
            <td>{productifo.electronics?.preheat}</td>
          </tr>
        )}
        {productifo.electronics?.timer !== "" && (
          <tr>
            <th>timer</th>
            <td>{productifo.electronics?.timer}</td>
          </tr>
        )}
        {productifo.electronics?.auto_cook_menu_available !== "" && (
          <tr>
            <th>auto_cook_menu_available</th>
            <td>{productifo.electronics?.auto_cook_menu_available}</td>
          </tr>
        )}
        {productifo.electronics?.power_output !== "" && (
          <tr>
            <th>power_output</th>
            <td>{productifo.electronics?.power_output}</td>
          </tr>
        )}
        {productifo.electronics?.sales_package !== "" && (
          <tr>
            <th>sales_package</th>
            <td>{productifo.electronics?.sales_package}</td>
          </tr>
        )}
        {productifo.electronics?.rotisserie !== "" && (
          <tr>
            <th>rotisserie</th>
            <td>{productifo.electronics?.rotisserie}</td>
          </tr>
        )}
        {productifo.electronics?.rotisserie_type !== "" && (
          <tr>
            <th>rotisserie_type</th>
            <td>{productifo.electronics?.rotisserie_type}</td>
          </tr>
        )}
        {productifo.electronics?.control_panel !== "" && (
          <tr>
            <th>control_panel</th>
            <td>{productifo.electronics?.control_panel}</td>
          </tr>
        )}
        {productifo.electronics?.cavity_material !== "" && (
          <tr>
            <th>cavity_material</th>
            <td>{productifo.electronics?.cavity_material}</td>
          </tr>
        )}
        {productifo.electronics?.temperature_control_range !== "" && (
          <tr>
            <th>temperature_control_range</th>
            <td>{productifo.electronics?.temperature_control_range}</td>
          </tr>
        )}
        {productifo.electronics?.auto_menu !== "" && (
          <tr>
            <th>auto_menu</th>
            <td>{productifo.electronics?.auto_menu}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_auto_menu !== "" && (
          <tr>
            <th>number_of_auto_menu</th>
            <td>{productifo.electronics?.number_of_auto_menu}</td>
          </tr>
        )}
        {productifo.electronics?.power_requirement !== "" && (
          <tr>
            <th>power_requirement</th>
            <td>{productifo.electronics?.power_requirement}</td>
          </tr>
        )}
        {productifo.electronics?.material !== "" && (
          <tr>
            <th>material</th>
            <td>{productifo.electronics?.material}</td>
          </tr>
        )}
        {productifo.electronics?.dry_grinding !== "" && (
          <tr>
            <th>dry_grinding</th>
            <td>{productifo.electronics?.dry_grinding}</td>
          </tr>
        )}
        {productifo.electronics?.mincing !== "" && (
          <tr>
            <th>mincing</th>
            <td>{productifo.electronics?.mincing}</td>
          </tr>
        )}
        {productifo.electronics?.chutey_grinding !== "" && (
          <tr>
            <th>chutey_grinding</th>
            <td>{productifo.electronics?.chutey_grinding}</td>
          </tr>
        )}
        {productifo.electronics?.liguidizing_jar_capacity !== "" && (
          <tr>
            <th>liguidizing_jar_capacity</th>
            <td>{productifo.electronics?.liguidizing_jar_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.chatey_jar_capacity !== "" && (
          <tr>
            <th>chatey_jar_capacity</th>
            <td>{productifo.electronics?.chatey_jar_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.series !== "" && (
          <tr>
            <th>series</th>
            <td>{productifo.electronics?.series}</td>
          </tr>
        )}
        {productifo.electronics?.locbable_lid !== "" && (
          <tr>
            <th>locbable_lid</th>
            <td>{productifo.electronics?.locbable_lid}</td>
          </tr>
        )}
        {productifo.electronics?.dry_boil_protection !== "" && (
          <tr>
            <th>dry_boil_protection</th>
            <td>{productifo.electronics?.dry_boil_protection}</td>
          </tr>
        )}
        {productifo.electronics?.auto_switch_off !== "" && (
          <tr>
            <th>auto_switch_off</th>
            <td>{productifo.electronics?.auto_switch_off}</td>
          </tr>
        )}
        {productifo.electronics?.heating_element !== "" && (
          <tr>
            <th>heating_element</th>
            <td>{productifo.electronics?.heating_element}</td>
          </tr>
        )}
        {productifo.electronics?.water_level_indicator !== "" && (
          <tr>
            <th>water_level_indicator</th>
            <td>{productifo.electronics?.water_level_indicator}</td>
          </tr>
        )}
        {productifo.electronics?.card_length !== "" && (
          <tr>
            <th>card_length</th>
            <td>{productifo.electronics?.card_length}</td>
          </tr>
        )}
        {productifo.electronics?.body_material !== "" && (
          <tr>
            <th>body_material</th>
            <td>{productifo.electronics?.body_material}</td>
          </tr>
        )}
        {productifo.electronics?.control !== "" && (
          <tr>
            <th>control</th>
            <td>{productifo.electronics?.control}</td>
          </tr>
        )}
        {productifo.electronics?.present_cooking_menu !== "" && (
          <tr>
            <th>present_cooking_menu</th>
            <td>{productifo.electronics?.present_cooking_menu}</td>
          </tr>
        )}
        {productifo.electronics?.elements_on_cooktop !== "" && (
          <tr>
            <th>elements_on_cooktop</th>
            <td>{productifo.electronics?.elements_on_cooktop}</td>
          </tr>
        )}
        {productifo.electronics?.other_body_and_design_features !== "" && (
          <tr>
            <th>other_body_and_design_features</th>
            <td>{productifo.electronics?.other_body_and_design_features}</td>
          </tr>
        )}
        {productifo.electronics?.service_type !== "" && (
          <tr>
            <th>service_type</th>
            <td>{productifo.electronics?.service_type}</td>
          </tr>
        )}
        {productifo.electronics?.installation_and_demo !== "" && (
          <tr>
            <th>installation_and_demo</th>
            <td>{productifo.electronics?.installation_and_demo}</td>
          </tr>
        )}
        {productifo.electronics?.finish !== "" && (
          <tr>
            <th>finish</th>
            <td>{productifo.electronics?.finish}</td>
          </tr>
        )}
        {productifo.electronics?.air_section_capacity !== "" && (
          <tr>
            <th>air_section_capacity</th>
            <td>{productifo.electronics?.air_section_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.ductless !== "" && (
          <tr>
            <th>ductless</th>
            <td>{productifo.electronics?.ductless}</td>
          </tr>
        )}
        {productifo.electronics?.filter_type !== "" && (
          <tr>
            <th>filter_type</th>
            <td>{productifo.electronics?.filter_type}</td>
          </tr>
        )}
        {productifo.electronics?.speed_levels !== "" && (
          <tr>
            <th>speed_levels</th>
            <td>{productifo.electronics?.speed_levels}</td>
          </tr>
        )}
        {productifo.electronics?.filter_block_indicator !== "" && (
          <tr>
            <th>filter_block_indicator</th>
            <td>{productifo.electronics?.filter_block_indicator}</td>
          </tr>
        )}
        {productifo.electronics?.noise_level !== "" && (
          <tr>
            <th>noise_level</th>
            <td>{productifo.electronics?.noise_level}</td>
          </tr>
        )}
        {productifo.electronics?.phase !== "" && (
          <tr>
            <th>phase</th>
            <td>{productifo.electronics?.phase}</td>
          </tr>
        )}
        {productifo.electronics?.engine_power !== "" && (
          <tr>
            <th>engine_power</th>
            <td>{productifo.electronics?.engine_power}</td>
          </tr>
        )}
        {productifo.electronics?.in_built_oil_collector !== "" && (
          <tr>
            <th>in_built_oil_collector</th>
            <td>{productifo.electronics?.in_built_oil_collector}</td>
          </tr>
        )}
        {productifo.electronics?.timer_present !== "" && (
          <tr>
            <th>timer_present</th>
            <td>{productifo.electronics?.timer_present}</td>
          </tr>
        )}
        {productifo.electronics?.dblade_material !== "" && (
          <tr>
            <th>dblade_material</th>
            <td>{productifo.electronics?.dblade_material}</td>
          </tr>
        )}
        {productifo.electronics?.blending !== "" && (
          <tr>
            <th>blending</th>
            <td>{productifo.electronics?.blending}</td>
          </tr>
        )}
        {productifo.electronics?.pureing !== "" && (
          <tr>
            <th>pureing</th>
            <td>{productifo.electronics?.pureing}</td>
          </tr>
        )}
        {productifo.electronics?.stirring !== "" && (
          <tr>
            <th>stirring</th>
            <td>{productifo.electronics?.stirring}</td>
          </tr>
        )}
        {productifo.electronics?.safety_features !== "" && (
          <tr>
            <th>safety_features</th>
            <td>{productifo.electronics?.safety_features}</td>
          </tr>
        )}
        {productifo.electronics?.model_let !== "" && (
          <tr>
            <th>model_let</th>
            <td>{productifo.electronics?.model_let}</td>
          </tr>
        )}
        {productifo.electronics?.slice_capacity !== "" && (
          <tr>
            <th>slice_capacity</th>
            <td>{productifo.electronics?.slice_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.working_plate !== "" && (
          <tr>
            <th>working_plate</th>
            <td>{productifo.electronics?.working_plate}</td>
          </tr>
        )}
        {productifo.electronics?.lid_lock !== "" && (
          <tr>
            <th>lid_lock</th>
            <td>{productifo.electronics?.lid_lock}</td>
          </tr>
        )}
        {productifo.electronics?.no_of_slices !== "" && (
          <tr>
            <th>no_of_slices</th>
            <td>{productifo.electronics?.no_of_slices}</td>
          </tr>
        )}
        {productifo.electronics?.wide_slots_for_thick_breads !== "" && (
          <tr>
            <th>wide_slots_for_thick_breads</th>
            <td>{productifo.electronics?.wide_slots_for_thick_breads}</td>
          </tr>
        )}
        {productifo.electronics?.high_lift_for_small_breads !== "" && (
          <tr>
            <th>high_lift_for_small_breads</th>
            <td>{productifo.electronics?.high_lift_for_small_breads}</td>
          </tr>
        )}
        {productifo.electronics?.auto_pop_up !== "" && (
          <tr>
            <th>auto_pop_up</th>
            <td>{productifo.electronics?.auto_pop_up}</td>
          </tr>
        )}
        {productifo.electronics?.removable_crumbtroy !== "" && (
          <tr>
            <th>removable_crumbtroy</th>
            <td>{productifo.electronics?.removable_crumbtroy}</td>
          </tr>
        )}
        {productifo.electronics?.toasting !== "" && (
          <tr>
            <th>toasting</th>
            <td>{productifo.electronics?.toasting}</td>
          </tr>
        )}
        {productifo.electronics?.warming !== "" && (
          <tr>
            <th>warming</th>
            <td>{productifo.electronics?.warming}</td>
          </tr>
        )}
        {productifo.electronics?.reheat !== "" && (
          <tr>
            <th>reheat</th>
            <td>{productifo.electronics?.reheat}</td>
          </tr>
        )}
        {productifo.electronics?.variable_browing !== "" && (
          <tr>
            <th>variable_browing</th>
            <td>{productifo.electronics?.variable_browing}</td>
          </tr>
        )}
        {productifo.electronics?.defrost !== "" && (
          <tr>
            <th>defrost</th>
            <td>{productifo.electronics?.defrost}</td>
          </tr>
        )}
        {productifo.electronics?.card_storage !== "" && (
          <tr>
            <th>card_storage</th>
            <td>{productifo.electronics?.card_storage}</td>
          </tr>
        )}
        {productifo.electronics?.cool_touch !== "" && (
          <tr>
            <th>cool_touch</th>
            <td>{productifo.electronics?.cool_touch}</td>
          </tr>
        )}
        {productifo.electronics?.length !== "" && (
          <tr>
            <th>length</th>
            <td>{productifo.electronics?.length}</td>
          </tr>
        )}
        {productifo.electronics?.capacity_incups !== "" && (
          <tr>
            <th>capacity_incups</th>
            <td>{productifo.electronics?.capacity_incups}</td>
          </tr>
        )}
        {productifo.electronics?.total_pan_capacity !== "" && (
          <tr>
            <th>total_pan_capacity</th>
            <td>{productifo.electronics?.total_pan_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.functions !== "" && (
          <tr>
            <th>functions</th>
            <td>{productifo.electronics?.functions}</td>
          </tr>
        )}
        {productifo.electronics?.material_lid !== "" && (
          <tr>
            <th>material_lid</th>
            <td>{productifo.electronics?.material_lid}</td>
          </tr>
        )}
        {productifo.electronics?.net_quantity_product_details !== "" && (
          <tr>
            <th>net_quantity_product_details</th>
            <td>{productifo.electronics?.net_quantity_product_details}</td>
          </tr>
        )}
        {productifo.electronics?.autokeep_warm_function_cooking !== "" && (
          <tr>
            <th>autokeep_warm_function_cooking</th>
            <td>{productifo.electronics?.autokeep_warm_function_cooking}</td>
          </tr>
        )}
        {productifo.electronics?.headphone_type !== "" && (
          <tr>
            <th>headphone_type</th>
            <td>{productifo.electronics?.headphone_type}</td>
          </tr>
        )}
        {productifo.electronics?.inline_remote !== "" && (
          <tr>
            <th>inline_remote</th>
            <td>{productifo.electronics?.inline_remote}</td>
          </tr>
        )}
        {productifo.electronics?.connectivity !== "" && (
          <tr>
            <th>connectivity</th>
            <td>{productifo.electronics?.connectivity}</td>
          </tr>
        )}
        {productifo.electronics?.headphone_design !== "" && (
          <tr>
            <th>headphone_design</th>
            <td>{productifo.electronics?.headphone_design}</td>
          </tr>
        )}
        {productifo.electronics?.foldable_or_collapsible !== "" && (
          <tr>
            <th>foldable_or_collapsible</th>
            <td>{productifo.electronics?.foldable_or_collapsible}</td>
          </tr>
        )}
        {productifo.electronics?.deep_bass !== "" && (
          <tr>
            <th>deep_bass</th>
            <td>{productifo.electronics?.deep_bass}</td>
          </tr>
        )}
        {productifo.electronics?.manual !== "" && (
          <tr>
            <th>manual</th>
            <td>{productifo.electronics?.manual}</td>
          </tr>
        )}
        {productifo.electronics?.magnet_type !== "" && (
          <tr>
            <th>magnet_type</th>
            <td>{productifo.electronics?.magnet_type}</td>
          </tr>
        )}
        {productifo.electronics?.driver_type !== "" && (
          <tr>
            <th>driver_type</th>
            <td>{productifo.electronics?.driver_type}</td>
          </tr>
        )}
        {productifo.electronics?.indicators !== "" && (
          <tr>
            <th>indicators</th>
            <td>{productifo.electronics?.indicators}</td>
          </tr>
        )}
        {productifo.electronics?.sensitivity !== "" && (
          <tr>
            <th>sensitivity</th>
            <td>{productifo.electronics?.sensitivity}</td>
          </tr>
        )}
        {productifo.electronics?.impedance !== "" && (
          <tr>
            <th>impedance</th>
            <td>{productifo.electronics?.impedance}</td>
          </tr>
        )}
        {productifo.electronics?.signal_to_noise_ratio !== "" && (
          <tr>
            <th>signal_to_noise_ratio</th>
            <td>{productifo.electronics?.signal_to_noise_ratio}</td>
          </tr>
        )}
        {productifo.electronics?.modulation !== "" && (
          <tr>
            <th>modulation</th>
            <td>{productifo.electronics?.modulation}</td>
          </tr>
        )}
        {productifo.electronics?.minimum_frequency_response !== "" && (
          <tr>
            <th>minimum_frequency_response</th>
            <td>{productifo.electronics?.minimum_frequency_response}</td>
          </tr>
        )}
        {productifo.electronics?.other_sound_features !== "" && (
          <tr>
            <th>other_sound_features</th>
            <td>{productifo.electronics?.other_sound_features}</td>
          </tr>
        )}
        {productifo.electronics?.audio_codec !== "" && (
          <tr>
            <th>audio_codec</th>
            <td>{productifo.electronics?.audio_codec}</td>
          </tr>
        )}
        {productifo.electronics?.blutooth_profile !== "" && (
          <tr>
            <th>blutooth_profile</th>
            <td>{productifo.electronics?.blutooth_profile}</td>
          </tr>
        )}
        {productifo.electronics?.blutooth_range !== "" && (
          <tr>
            <th>blutooth_range</th>
            <td>{productifo.electronics?.blutooth_range}</td>
          </tr>
        )}
        {productifo.electronics?.headphone_power_source !== "" && (
          <tr>
            <th>headphone_power_source</th>
            <td>{productifo.electronics?.headphone_power_source}</td>
          </tr>
        )}
        {productifo.electronics?.power_supply !== "" && (
          <tr>
            <th>power_supply</th>
            <td>{productifo.electronics?.power_supply}</td>
          </tr>
        )}
        {productifo.electronics?.battery_life !== "" && (
          <tr>
            <th>battery_life</th>
            <td>{productifo.electronics?.battery_life}</td>
          </tr>
        )}
        {productifo.electronics?.stand_by_time !== "" && (
          <tr>
            <th>stand_by_time</th>
            <td>{productifo.electronics?.stand_by_time}</td>
          </tr>
        )}
        {productifo.electronics?.applied !== "" && (
          <tr>
            <th>applied</th>
            <td>{productifo.electronics?.applied}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_layers !== "" && (
          <tr>
            <th>number_of_layers</th>
            <td>{productifo.electronics?.number_of_layers}</td>
          </tr>
        )}
        {productifo.electronics?.in_rescidue_free_removal !== "" && (
          <tr>
            <th>in_rescidue_free_removal</th>
            <td>{productifo.electronics?.in_rescidue_free_removal}</td>
          </tr>
        )}
        {productifo.electronics?.tinted !== "" && (
          <tr>
            <th>tinted</th>
            <td>{productifo.electronics?.tinted}</td>
          </tr>
        )}
        {productifo.electronics?.camera_hole_present !== "" && (
          <tr>
            <th>camera_hole_present</th>
            <td>{productifo.electronics?.camera_hole_present}</td>
          </tr>
        )}
        {productifo.electronics?.usage !== "" && (
          <tr>
            <th>usage</th>
            <td>{productifo.electronics?.usage}</td>
          </tr>
        )}
        {productifo.electronics?.operating_system_supported !== "" && (
          <tr>
            <th>operating_system_supported</th>
            <td>{productifo.electronics?.operating_system_supported}</td>
          </tr>
        )}
        {productifo.electronics?.type_of_tracking !== "" && (
          <tr>
            <th>type_of_tracking</th>
            <td>{productifo.electronics?.type_of_tracking}</td>
          </tr>
        )}
        {productifo.electronics?.auto_trasperancy !== "" && (
          <tr>
            <th>auto_trasperancy</th>
            <td>{productifo.electronics?.auto_trasperancy}</td>
          </tr>
        )}
        {productifo.electronics?.display !== "" && (
          <tr>
            <th>display</th>
            <td>{productifo.electronics?.display}</td>
          </tr>
        )}
        {productifo.electronics?.wireless_data_processing !== "" && (
          <tr>
            <th>wireless_data_processing</th>
            <td>{productifo.electronics?.wireless_data_processing}</td>
          </tr>
        )}
        {productifo.electronics?.one_touch_button !== "" && (
          <tr>
            <th>one_touch_button</th>
            <td>{productifo.electronics?.one_touch_button}</td>
          </tr>
        )}
        {productifo.electronics?.touch_control !== "" && (
          <tr>
            <th>touch_control</th>
            <td>{productifo.electronics?.touch_control}</td>
          </tr>
        )}
        {productifo.electronics?.battery_included !== "" && (
          <tr>
            <th>battery_included</th>
            <td>{productifo.electronics?.battery_included}</td>
          </tr>
        )}
        {productifo.electronics?.battery_chargable !== "" && (
          <tr>
            <th>battery_chargable</th>
            <td>{productifo.electronics?.battery_chargable}</td>
          </tr>
        )}
        {productifo.electronics?.water_resistance !== "" && (
          <tr>
            <th>water_resistance</th>
            <td>{productifo.electronics?.water_resistance}</td>
          </tr>
        )}
        {productifo.electronics?.noise_concellation !== "" && (
          <tr>
            <th>noise_concellation</th>
            <td>{productifo.electronics?.noise_concellation}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_contents_in_sales_pack !== "" && (
          <tr>
            <th>number_of_contents_in_sales_pack</th>
            <td>{productifo.electronics?.number_of_contents_in_sales_pack}</td>
          </tr>
        )}
        {productifo.electronics?.cable_type !== "" && (
          <tr>
            <th>cable_type</th>
            <td>{productifo.electronics?.cable_type}</td>
          </tr>
        )}
        {productifo.electronics?.connector1 !== "" && (
          <tr>
            <th>connector1</th>
            <td>{productifo.electronics?.connector1}</td>
          </tr>
        )}
        {productifo.electronics?.connector2 !== "" && (
          <tr>
            <th>connector2</th>
            <td>{productifo.electronics?.connector2}</td>
          </tr>
        )}
        {productifo.electronics?.cable !== "" && (
          <tr>
            <th>cable</th>
            <td>{productifo.electronics?.cable}</td>
          </tr>
        )}
        {productifo.electronics?.compatible_devices !== "" && (
          <tr>
            <th>compatible_devices</th>
            <td>{productifo.electronics?.compatible_devices}</td>
          </tr>
        )}
        {productifo.electronics?.cable_length !== "" && (
          <tr>
            <th>cable_length</th>
            <td>{productifo.electronics?.cable_length}</td>
          </tr>
        )}
        {productifo.electronics?.keyboard !== "" && (
          <tr>
            <th>keyboard</th>
            <td>{productifo.electronics?.keyboard}</td>
          </tr>
        )}
        {productifo.electronics?.security_chip !== "" && (
          <tr>
            <th>security_chip</th>
            <td>{productifo.electronics?.security_chip}</td>
          </tr>
        )}
        {productifo.electronics?.web_camera !== "" && (
          <tr>
            <th>web_camera</th>
            <td>{productifo.electronics?.web_camera}</td>
          </tr>
        )}
        {productifo.electronics?.finger_print_sensor !== "" && (
          <tr>
            <th>finger_print_sensor</th>
            <td>{productifo.electronics?.finger_print_sensor}</td>
          </tr>
        )}
        {productifo.electronics?.processor_variant !== "" && (
          <tr>
            <th>processor_variant</th>
            <td>{productifo.electronics?.processor_variant}</td>
          </tr>
        )}
        {productifo.electronics?.cache !== "" && (
          <tr>
            <th>cache</th>
            <td>{productifo.electronics?.cache}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_cores !== "" && (
          <tr>
            <th>number_of_cores</th>
            <td>{productifo.electronics?.number_of_cores}</td>
          </tr>
        )}
        {productifo.electronics?.os_architecture !== "" && (
          <tr>
            <th>os_architecture</th>
            <td>{productifo.electronics?.os_architecture}</td>
          </tr>
        )}
        {productifo.electronics?.mic_in !== "" && (
          <tr>
            <th>mic_in</th>
            <td>{productifo.electronics?.mic_in}</td>
          </tr>
        )}
        {productifo.electronics?.rj45 !== "" && (
          <tr>
            <th>rj45</th>
            <td>{productifo.electronics?.rj45}</td>
          </tr>
        )}
        {productifo.electronics?.multi_card_slot !== "" && (
          <tr>
            <th>multi_card_slot</th>
            <td>{productifo.electronics?.multi_card_slot}</td>
          </tr>
        )}
        {productifo.electronics?.hardware_interface !== "" && (
          <tr>
            <th>hardware_interface</th>
            <td>{productifo.electronics?.hardware_interface}</td>
          </tr>
        )}
        {productifo.electronics?.disk_drive !== "" && (
          <tr>
            <th>disk_drive</th>
            <td>{productifo.electronics?.disk_drive}</td>
          </tr>
        )}
        {productifo.electronics?.connector_type !== "" && (
          <tr>
            <th>connector_type</th>
            <td>{productifo.electronics?.connector_type}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_devices !== "" && (
          <tr>
            <th>number_of_devices</th>
            <td>{productifo.electronics?.number_of_devices}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_charger_pins !== "" && (
          <tr>
            <th>number_of_charger_pins</th>
            <td>{productifo.electronics?.number_of_charger_pins}</td>
          </tr>
        )}
        {productifo.electronics?.output_current !== "" && (
          <tr>
            <th>output_current</th>
            <td>{productifo.electronics?.output_current}</td>
          </tr>
        )}
        {productifo.electronics?.output_wattage !== "" && (
          <tr>
            <th>output_wattage</th>
            <td>{productifo.electronics?.output_wattage}</td>
          </tr>
        )}
        {productifo.electronics?.compatible_with !== "" && (
          <tr>
            <th>compatible_with</th>
            <td>{productifo.electronics?.compatible_with}</td>
          </tr>
        )}
        {productifo.electronics?.flodable !== "" && (
          <tr>
            <th>flodable</th>
            <td>{productifo.electronics?.flodable}</td>
          </tr>
        )}
        {productifo.electronics?.rpm !== "" && (
          <tr>
            <th>rpm</th>
            <td>{productifo.electronics?.rpm}</td>
          </tr>
        )}
        {productifo.electronics?.graphic_processor !== "" && (
          <tr>
            <th>graphic_processor</th>
            <td>{productifo.electronics?.graphic_processor}</td>
          </tr>
        )}
        {productifo.electronics?.battery_backup !== "" && (
          <tr>
            <th>battery_backup</th>
            <td>{productifo.electronics?.battery_backup}</td>
          </tr>
        )}
        {productifo.electronics?.battery_cell !== "" && (
          <tr>
            <th>battery_cell</th>
            <td>{productifo.electronics?.battery_cell}</td>
          </tr>
        )}
        {productifo.electronics?.ms_office_provided !== "" && (
          <tr>
            <th>ms_office_provided</th>
            <td>{productifo.electronics?.ms_office_provided}</td>
          </tr>
        )}
        {productifo.electronics?.processor_name !== "" && (
          <tr>
            <th>processor_name</th>
            <td>{productifo.electronics?.processor_name}</td>
          </tr>
        )}
        {productifo.electronics?.processor_generation !== "" && (
          <tr>
            <th>processor_generation</th>
            <td>{productifo.electronics?.processor_generation}</td>
          </tr>
        )}
        {productifo.electronics?.ram_type !== "" && (
          <tr>
            <th>ram_type</th>
            <td>{productifo.electronics?.ram_type}</td>
          </tr>
        )}
        {productifo.electronics?.hdd_capacity !== "" && (
          <tr>
            <th>hdd_capacity</th>
            <td>{productifo.electronics?.hdd_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.output_interface !== "" && (
          <tr>
            <th>output_interface</th>
            <td>{productifo.electronics?.output_interface}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_current_rating !== "" && (
          <tr>
            <th>maximum_current_rating</th>
            <td>{productifo.electronics?.maximum_current_rating}</td>
          </tr>
        )}
        {productifo.electronics?.other_material !== "" && (
          <tr>
            <th>other_material</th>
            <td>{productifo.electronics?.other_material}</td>
          </tr>
        )}
        {productifo.electronics?.pointer_device !== "" && (
          <tr>
            <th>pointer_device</th>
            <td>{productifo.electronics?.pointer_device}</td>
          </tr>
        )}
        {productifo.electronics?.emmc_storage_capacity !== "" && (
          <tr>
            <th>emmc_storage_capacity</th>
            <td>{productifo.electronics?.emmc_storage_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.ram_frequency !== "" && (
          <tr>
            <th>ram_frequency</th>
            <td>{productifo.electronics?.ram_frequency}</td>
          </tr>
        )}
        {productifo.electronics?.screen_size !== "" && (
          <tr>
            <th>screen_size</th>
            <td>{productifo.electronics?.screen_size}</td>
          </tr>
        )}
        {productifo.electronics?.screen_type !== "" && (
          <tr>
            <th>screen_type</th>
            <td>{productifo.electronics?.screen_type}</td>
          </tr>
        )}
        {productifo.electronics?.speakers !== "" && (
          <tr>
            <th>speakers</th>
            <td>{productifo.electronics?.speakers}</td>
          </tr>
        )}
        {productifo.electronics?.internal_mic !== "" && (
          <tr>
            <th>internal_mic</th>
            <td>{productifo.electronics?.internal_mic}</td>
          </tr>
        )}
        {productifo.electronics?.wireless_lan !== "" && (
          <tr>
            <th>wireless_lan</th>
            <td>{productifo.electronics?.wireless_lan}</td>
          </tr>
        )}
        {productifo.electronics?.dedicated_graphic_memory_type !== "" && (
          <tr>
            <th>dedicated_graphic_memory_type</th>
            <td>{productifo.electronics?.dedicated_graphic_memory_type}</td>
          </tr>
        )}
        {productifo.electronics?.dedicated_graphic_memory_capacity !== "" && (
          <tr>
            <th>dedicated_graphic_memory_capacity</th>
            <td>{productifo.electronics?.dedicated_graphic_memory_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.ethernet !== "" && (
          <tr>
            <th>ethernet</th>
            <td>{productifo.electronics?.ethernet}</td>
          </tr>
        )}
        {productifo.electronics?.backlit_keyboard !== "" && (
          <tr>
            <th>backlit_keyboard</th>
            <td>{productifo.electronics?.backlit_keyboard}</td>
          </tr>
        )}
        {productifo.electronics?.memory_slot !== "" && (
          <tr>
            <th>memory_slot</th>
            <td>{productifo.electronics?.memory_slot}</td>
          </tr>
        )}
        {productifo.electronics?.expandle_memory !== "" && (
          <tr>
            <th>expandle_memory</th>
            <td>{productifo.electronics?.expandle_memory}</td>
          </tr>
        )}
        {productifo.electronics?.sound_properties !== "" && (
          <tr>
            <th>sound_properties</th>
            <td>{productifo.electronics?.sound_properties}</td>
          </tr>
        )}
        {productifo.electronics?.lock_port !== "" && (
          <tr>
            <th>lock_port</th>
            <td>{productifo.electronics?.lock_port}</td>
          </tr>
        )}
        {productifo.electronics?.operating_system_version !== "" && (
          <tr>
            <th>operating_system_version</th>
            <td>{productifo.electronics?.operating_system_version}</td>
          </tr>
        )}
        {productifo.electronics?.voice_call !== "" && (
          <tr>
            <th>voice_call</th>
            <td>{productifo.electronics?.voice_call}</td>
          </tr>
        )}
        {productifo.electronics?.display_resolution_time !== "" && (
          <tr>
            <th>display_resolution_time</th>
            <td>{productifo.electronics?.display_resolution_time}</td>
          </tr>
        )}
        {productifo.electronics?.display_support_network !== "" && (
          <tr>
            <th>display_support_network</th>
            <td>{productifo.electronics?.display_support_network}</td>
          </tr>
        )}
        {productifo.electronics?.display_resolution !== "" && (
          <tr>
            <th>display_resolution</th>
            <td>{productifo.electronics?.display_resolution}</td>
          </tr>
        )}
        {productifo.electronics?.processor_speed !== "" && (
          <tr>
            <th>processor_speed</th>
            <td>{productifo.electronics?.processor_speed}</td>
          </tr>
        )}
        {productifo.electronics?.headphone_jack !== "" && (
          <tr>
            <th>headphone_jack</th>
            <td>{productifo.electronics?.headphone_jack}</td>
          </tr>
        )}
        {productifo.electronics?.expandable_storage_capacity !== "" && (
          <tr>
            <th>expandable_storage_capacity</th>
            <td>{productifo.electronics?.expandable_storage_capacity}</td>
          </tr>
        )}
        {productifo.electronics?.memory_card_slot_type !== "" && (
          <tr>
            <th>memory_card_slot_type</th>
            <td>{productifo.electronics?.memory_card_slot_type}</td>
          </tr>
        )}
        {productifo.electronics?.memory_card_support !== "" && (
          <tr>
            <th>memory_card_support</th>
            <td>{productifo.electronics?.memory_card_support}</td>
          </tr>
        )}
        {productifo.electronics?.warranty_period !== "" && (
          <tr>
            <th>warranty_period</th>
            <td>{productifo.electronics?.warranty_period}</td>
          </tr>
        )}
        {productifo.electronics?.sound_enhancements !== "" && (
          <tr>
            <th>sound_enhancements</th>
            <td>{productifo.electronics?.sound_enhancements}</td>
          </tr>
        )}
        {productifo.electronics?.optical_zoom !== "" && (
          <tr>
            <th>optical_zoom</th>
            <td>{productifo.electronics?.optical_zoom}</td>
          </tr>
        )}
        {productifo.electronics?.video_call_support !== "" && (
          <tr>
            <th>video_call_support</th>
            <td>{productifo.electronics?.video_call_support}</td>
          </tr>
        )}
        {productifo.electronics?.graphicsppl !== "" && (
          <tr>
            <th>graphicsppl</th>
            <td>{productifo.electronics?.graphicsppl}</td>
          </tr>
        )}
        {productifo.electronics?.supported_languages !== "" && (
          <tr>
            <th>supported_languages</th>
            <td>{productifo.electronics?.supported_languages}</td>
          </tr>
        )}
        {productifo.electronics?.important_apps !== "" && (
          <tr>
            <th>important_apps</th>
            <td>{productifo.electronics?.important_apps}</td>
          </tr>
        )}
        {productifo.electronics?.audio_formats !== "" && (
          <tr>
            <th>audio_formats</th>
            <td>{productifo.electronics?.audio_formats}</td>
          </tr>
        )}
        {productifo.electronics?.video_formats !== "" && (
          <tr>
            <th>video_formats</th>
            <td>{productifo.electronics?.video_formats}</td>
          </tr>
        )}
        {productifo.electronics?.ideal_for !== "" && (
          <tr>
            <th>ideal_for</th>
            <td>{productifo.electronics?.ideal_for}</td>
          </tr>
        )}
        {productifo.electronics?.ideal_usage !== "" && (
          <tr>
            <th>ideal_usage</th>
            <td>{productifo.electronics?.ideal_usage}</td>
          </tr>
        )}
        {productifo.electronics?.loptop_sleeve !== "" && (
          <tr>
            <th>loptop_sleeve</th>
            <td>{productifo.electronics?.loptop_sleeve}</td>
          </tr>
        )}
        {productifo.electronics?.with_rain_cover !== "" && (
          <tr>
            <th>with_rain_cover</th>
            <td>{productifo.electronics?.with_rain_cover}</td>
          </tr>
        )}
        {productifo.electronics?.compatibility_loptop_size !== "" && (
          <tr>
            <th>compatibility_loptop_size</th>
            <td>{productifo.electronics?.compatibility_loptop_size}</td>
          </tr>
        )}
        {productifo.electronics?.style_code !== "" && (
          <tr>
            <th>style_code</th>
            <td>{productifo.electronics?.style_code}</td>
          </tr>
        )}
        {productifo.electronics?.color_code !== "" && (
          <tr>
            <th>color_code</th>
            <td>{productifo.electronics?.color_code}</td>
          </tr>
        )}
        {productifo.electronics?.material_water_proof !== "" && (
          <tr>
            <th>material_water_proof</th>
            <td>{productifo.electronics?.material_water_proof}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_compartments !== "" && (
          <tr>
            <th>number_of_compartments</th>
            <td>{productifo.electronics?.number_of_compartments}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_packets !== "" && (
          <tr>
            <th>number_of_packets</th>
            <td>{productifo.electronics?.number_of_packets}</td>
          </tr>
        )}
        {productifo.electronics?.configuration !== "" && (
          <tr>
            <th>configuration</th>
            <td>{productifo.electronics?.configuration}</td>
          </tr>
        )}
        {productifo.electronics?.frequency_response !== "" && (
          <tr>
            <th>frequency_response</th>
            <td>{productifo.electronics?.frequency_response}</td>
          </tr>
        )}
        {productifo.electronics?.built_in_fm_radio !== "" && (
          <tr>
            <th>built_in_fm_radio</th>
            <td>{productifo.electronics?.built_in_fm_radio}</td>
          </tr>
        )}
        {productifo.electronics?.certifications !== "" && (
          <tr>
            <th>certifications</th>
            <td>{productifo.electronics?.certifications}</td>
          </tr>
        )}
        {productifo.electronics?.charging_time !== "" && (
          <tr>
            <th>charging_time</th>
            <td>{productifo.electronics?.charging_time}</td>
          </tr>
        )}
        {productifo.electronics?.cable_interface !== "" && (
          <tr>
            <th>cable_interface</th>
            <td>{productifo.electronics?.cable_interface}</td>
          </tr>
        )}
        {productifo.electronics?.platform !== "" && (
          <tr>
            <th>platform</th>
            <td>{productifo.electronics?.platform}</td>
          </tr>
        )}
        {productifo.electronics?.read_speed !== "" && (
          <tr>
            <th>read_speed</th>
            <td>{productifo.electronics?.read_speed}</td>
          </tr>
        )}
        {productifo.electronics?.write_speed !== "" && (
          <tr>
            <th>write_speed</th>
            <td>{productifo.electronics?.write_speed}</td>
          </tr>
        )}
        {productifo.electronics?.system_requirements !== "" && (
          <tr>
            <th>system_requirements</th>
            <td>{productifo.electronics?.system_requirements}</td>
          </tr>
        )}
        {productifo.electronics?.form_factor !== "" && (
          <tr>
            <th>form_factor</th>
            <td>{productifo.electronics?.form_factor}</td>
          </tr>
        )}
        {productifo.electronics?.cloud_backup !== "" && (
          <tr>
            <th>cloud_backup</th>
            <td>{productifo.electronics?.cloud_backup}</td>
          </tr>
        )}
        {productifo.electronics?.data_tranfer_rate !== "" && (
          <tr>
            <th>data_tranfer_rate</th>
            <td>{productifo.electronics?.data_tranfer_rate}</td>
          </tr>
        )}
        {productifo.electronics?.smartphone !== "" && (
          <tr>
            <th>smartphone</th>
            <td>{productifo.electronics?.smartphone}</td>
          </tr>
        )}
        {productifo.electronics?.rotational_speed !== "" && (
          <tr>
            <th>rotational_speed</th>
            <td>{productifo.electronics?.rotational_speed}</td>
          </tr>
        )}
        {productifo.electronics?.password_protection !== "" && (
          <tr>
            <th>password_protection</th>
            <td>{productifo.electronics?.password_protection}</td>
          </tr>
        )}
        {productifo.electronics?.power_source !== "" && (
          <tr>
            <th>power_source</th>
            <td>{productifo.electronics?.power_source}</td>
          </tr>
        )}
        {productifo.electronics?.size !== "" && (
          <tr>
            <th>size</th>
            <td>{productifo.electronics?.size}</td>
          </tr>
        )}
        {productifo.electronics?.shock_resistant !== "" && (
          <tr>
            <th>shock_resistant</th>
            <td>{productifo.electronics?.shock_resistant}</td>
          </tr>
        )}
        {productifo.electronics?.water_proof !== "" && (
          <tr>
            <th>water_proof</th>
            <td>{productifo.electronics?.water_proof}</td>
          </tr>
        )}
        {productifo.electronics?.led_indicator !== "" && (
          <tr>
            <th>led_indicator</th>
            <td>{productifo.electronics?.led_indicator}</td>
          </tr>
        )}
        {productifo.electronics?.operating_range !== "" && (
          <tr>
            <th>operating_range</th>
            <td>{productifo.electronics?.operating_range}</td>
          </tr>
        )}
        {productifo.electronics?.frequency !== "" && (
          <tr>
            <th>frequency</th>
            <td>{productifo.electronics?.frequency}</td>
          </tr>
        )}
        {productifo.electronics?.frequency_band !== "" && (
          <tr>
            <th>frequency_band</th>
            <td>{productifo.electronics?.frequency_band}</td>
          </tr>
        )}
        {productifo.electronics?.wireless_speed !== "" && (
          <tr>
            <th>wireless_speed</th>
            <td>{productifo.electronics?.wireless_speed}</td>
          </tr>
        )}
        {productifo.electronics?.dsl_modern_speed !== "" && (
          <tr>
            <th>dsl_modern_speed</th>
            <td>{productifo.electronics?.dsl_modern_speed}</td>
          </tr>
        )}
        {productifo.electronics?.lan_wan !== "" && (
          <tr>
            <th>lan_wan</th>
            <td>{productifo.electronics?.lan_wan}</td>
          </tr>
        )}
        {productifo.electronics?.antennae !== "" && (
          <tr>
            <th>antennae</th>
            <td>{productifo.electronics?.antennae}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_usb_ports !== "" && (
          <tr>
            <th>number_of_usb_ports</th>
            <td>{productifo.electronics?.number_of_usb_ports}</td>
          </tr>
        )}
        {productifo.electronics?.lan !== "" && (
          <tr>
            <th>lan</th>
            <td>{productifo.electronics?.lan}</td>
          </tr>
        )}
        {productifo.electronics?.apparent_field_of_view !== "" && (
          <tr>
            <th>apparent_field_of_view</th>
            <td>{productifo.electronics?.apparent_field_of_view}</td>
          </tr>
        )}
        {productifo.electronics?.magnification !== "" && (
          <tr>
            <th>magnification</th>
            <td>{productifo.electronics?.magnification}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_shelf_life !== "" && (
          <tr>
            <th>maximum_shelf_life</th>
            <td>{productifo.electronics?.maximum_shelf_life}</td>
          </tr>
        )}
        {productifo.electronics?.no_memory_effect !== "" && (
          <tr>
            <th>no_memory_effect</th>
            <td>{productifo.electronics?.no_memory_effect}</td>
          </tr>
        )}
        {productifo.electronics?.hd_resolution !== "" && (
          <tr>
            <th>hd_resolution</th>
            <td>{productifo.electronics?.hd_resolution}</td>
          </tr>
        )}
        {productifo.electronics?.smart_tv !== "" && (
          <tr>
            <th>smart_tv</th>
            <td>{productifo.electronics?.smart_tv}</td>
          </tr>
        )}
        {productifo.electronics?.hdmi !== "" && (
          <tr>
            <th>hdmi</th>
            <td>{productifo.electronics?.hdmi}</td>
          </tr>
        )}
        {productifo.electronics?.usb !== "" && (
          <tr>
            <th>usb</th>
            <td>{productifo.electronics?.usb}</td>
          </tr>
        )}
        {productifo.electronics?.wifi_type !== "" && (
          <tr>
            <th>wifi_type</th>
            <td>{productifo.electronics?.wifi_type}</td>
          </tr>
        )}
        {productifo.electronics?.built_in_wifi !== "" && (
          <tr>
            <th>built_in_wifi</th>
            <td>{productifo.electronics?.built_in_wifi}</td>
          </tr>
        )}
        {productifo.electronics?.wall_mount_included !== "" && (
          <tr>
            <th>wall_mount_included</th>
            <td>{productifo.electronics?.wall_mount_included}</td>
          </tr>
        )}
        {productifo.electronics?.picture_in_picture !== "" && (
          <tr>
            <th>picture_in_picture</th>
            <td>{productifo.electronics?.picture_in_picture}</td>
          </tr>
        )}
        {productifo.electronics?.picture_engine !== "" && (
          <tr>
            <th>picture_engine</th>
            <td>{productifo.electronics?.picture_engine}</td>
          </tr>
        )}
        {productifo.electronics?.analog_tv_reception !== "" && (
          <tr>
            <th>analog_tv_reception</th>
            <td>{productifo.electronics?.analog_tv_reception}</td>
          </tr>
        )}
        {productifo.electronics?.view_angle !== "" && (
          <tr>
            <th>view_angle</th>
            <td>{productifo.electronics?.view_angle}</td>
          </tr>
        )}
        {productifo.electronics?.panel_type !== "" && (
          <tr>
            <th>panel_type</th>
            <td>{productifo.electronics?.panel_type}</td>
          </tr>
        )}
        {productifo.electronics?.digital_noise_filter !== "" && (
          <tr>
            <th>digital_noise_filter</th>
            <td>{productifo.electronics?.digital_noise_filter}</td>
          </tr>
        )}
        {productifo.electronics?.led_display_type !== "" && (
          <tr>
            <th>led_display_type</th>
            <td>{productifo.electronics?.led_display_type}</td>
          </tr>
        )}
        {productifo.electronics?.aspect_ratio !== "" && (
          <tr>
            <th>aspect_ratio</th>
            <td>{productifo.electronics?.aspect_ratio}</td>
          </tr>
        )}
        {productifo.electronics?.refresh_rate !== "" && (
          <tr>
            <th>refresh_rate</th>
            <td>{productifo.electronics?.refresh_rate}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_speakers !== "" && (
          <tr>
            <th>number_of_speakers</th>
            <td>{productifo.electronics?.number_of_speakers}</td>
          </tr>
        )}
        {productifo.electronics?.speaker_type !== "" && (
          <tr>
            <th>speaker_type</th>
            <td>{productifo.electronics?.speaker_type}</td>
          </tr>
        )}
        {productifo.electronics?.sound_technology !== "" && (
          <tr>
            <th>sound_technology</th>
            <td>{productifo.electronics?.sound_technology}</td>
          </tr>
        )}
        {productifo.electronics?.surround_sound !== "" && (
          <tr>
            <th>surround_sound</th>
            <td>{productifo.electronics?.surround_sound}</td>
          </tr>
        )}
        {productifo.electronics?.speaker_output_rms !== "" && (
          <tr>
            <th>speaker_output_rms</th>
            <td>{productifo.electronics?.speaker_output_rms}</td>
          </tr>
        )}
        {productifo.electronics?.sound_mode !== "" && (
          <tr>
            <th>sound_mode</th>
            <td>{productifo.electronics?.sound_mode}</td>
          </tr>
        )}
        {productifo.electronics?.supported_audio_features !== "" && (
          <tr>
            <th>supported_audio_features</th>
            <td>{productifo.electronics?.supported_audio_features}</td>
          </tr>
        )}
        {productifo.electronics?.supported_app_netflix !== "" && (
          <tr>
            <th>supported_app_netflix</th>
            <td>{productifo.electronics?.supported_app_netflix}</td>
          </tr>
        )}
        {productifo.electronics?.supported_app_youtube !== "" && (
          <tr>
            <th>supported_app_youtube</th>
            <td>{productifo.electronics?.supported_app_youtube}</td>
          </tr>
        )}
        {productifo.electronics?.supported_app_disney_hotstar !== "" && (
          <tr>
            <th>supported_app_disney_hotstar</th>
            <td>{productifo.electronics?.supported_app_disney_hotstar}</td>
          </tr>
        )}
        {productifo.electronics?.supported_app_prime_video !== "" && (
          <tr>
            <th>supported_app_prime_video</th>
            <td>{productifo.electronics?.supported_app_prime_video}</td>
          </tr>
        )}
        {productifo.electronics?.supported_mobile_operating_system !== "" && (
          <tr>
            <th>supported_mobile_operating_system</th>
            <td>{productifo.electronics?.supported_mobile_operating_system}</td>
          </tr>
        )}
        {productifo.electronics?.operating_system_present !== "" && (
          <tr>
            <th>operating_system_present</th>
            <td>{productifo.electronics?.operating_system_present}</td>
          </tr>
        )}
        {productifo.electronics?.screen_mirroring !== "" && (
          <tr>
            <th>screen_mirroring</th>
            <td>{productifo.electronics?.screen_mirroring}</td>
          </tr>
        )}
        {productifo.electronics?.app_store_type !== "" && (
          <tr>
            <th>app_store_type</th>
            <td>{productifo.electronics?.app_store_type}</td>
          </tr>
        )}
        {productifo.electronics?.pre_installed_browser !== "" && (
          <tr>
            <th>pre_installed_browser</th>
            <td>{productifo.electronics?.pre_installed_browser}</td>
          </tr>
        )}
        {productifo.electronics?.content_providers !== "" && (
          <tr>
            <th>content_providers</th>
            <td>{productifo.electronics?.content_providers}</td>
          </tr>
        )}
        {productifo.electronics?.supported_devices_for_casting !== "" && (
          <tr>
            <th>supported_devices_for_casting</th>
            <td>{productifo.electronics?.supported_devices_for_casting}</td>
          </tr>
        )}
        {productifo.electronics?.teletext !== "" && (
          <tr>
            <th>teletext</th>
            <td>{productifo.electronics?.teletext}</td>
          </tr>
        )}
        {productifo.electronics?.mobile_high_defination_link !== "" && (
          <tr>
            <th>mobile_high_defination_link</th>
            <td>{productifo.electronics?.mobile_high_defination_link}</td>
          </tr>
        )}
        {productifo.electronics?._3g_dongle_plug_and_play !== "" && (
          <tr>
            <th>_3g_dongle_plug_and_play</th>
            <td>{productifo.electronics?._3g_dongle_plug_and_play}</td>
          </tr>
        )}
        {productifo.electronics?.touch_remote !== "" && (
          <tr>
            <th>touch_remote</th>
            <td>{productifo.electronics?.touch_remote}</td>
          </tr>
        )}
        {productifo.electronics?.smart_remote !== "" && (
          <tr>
            <th>smart_remote</th>
            <td>{productifo.electronics?.smart_remote}</td>
          </tr>
        )}
        {productifo.electronics?.color_screen !== "" && (
          <tr>
            <th>color_screen</th>
            <td>{productifo.electronics?.color_screen}</td>
          </tr>
        )}
        {productifo.electronics?.ir_capable !== "" && (
          <tr>
            <th>ir_capable</th>
            <td>{productifo.electronics?.ir_capable}</td>
          </tr>
        )}
        {productifo.electronics?.dvr_control !== "" && (
          <tr>
            <th>dvr_control</th>
            <td>{productifo.electronics?.dvr_control}</td>
          </tr>
        )}
        {productifo.electronics?.remote_type !== "" && (
          <tr>
            <th>remote_type</th>
            <td>{productifo.electronics?.remote_type}</td>
          </tr>
        )}
        {productifo.electronics?.clock !== "" && (
          <tr>
            <th>clock</th>
            <td>{productifo.electronics?.clock}</td>
          </tr>
        )}
        {productifo.electronics?.on_off_timer !== "" && (
          <tr>
            <th>on_off_timer</th>
            <td>{productifo.electronics?.on_off_timer}</td>
          </tr>
        )}
        {productifo.electronics?.without_stand !== "" && (
          <tr>
            <th>without_stand</th>
            <td>{productifo.electronics?.without_stand}</td>
          </tr>
        )}
        {productifo.electronics?.with_stand !== "" && (
          <tr>
            <th>with_stand</th>
            <td>{productifo.electronics?.with_stand}</td>
          </tr>
        )}
        {productifo.electronics?.stand_features !== "" && (
          <tr>
            <th>stand_features</th>
            <td>{productifo.electronics?.stand_features}</td>
          </tr>
        )}
        {productifo.electronics?.sleep_timer !== "" && (
          <tr>
            <th>sleep_timer</th>
            <td>{productifo.electronics?.sleep_timer}</td>
          </tr>
        )}
        {productifo.electronics?.wired_wireless !== "" && (
          <tr>
            <th>wired_wireless</th>
            <td>{productifo.electronics?.wired_wireless}</td>
          </tr>
        )}
        {productifo.electronics?.outdoor_usage !== "" && (
          <tr>
            <th>outdoor_usage</th>
            <td>{productifo.electronics?.outdoor_usage}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_spl !== "" && (
          <tr>
            <th>maximum_spl</th>
            <td>{productifo.electronics?.maximum_spl}</td>
          </tr>
        )}
        {productifo.electronics?.chassis_material !== "" && (
          <tr>
            <th>chassis_material</th>
            <td>{productifo.electronics?.chassis_material}</td>
          </tr>
        )}
        {productifo.electronics?.international_warranty !== "" && (
          <tr>
            <th>international_warranty</th>
            <td>{productifo.electronics?.international_warranty}</td>
          </tr>
        )}
        {productifo.electronics?.part_number !== "" && (
          <tr>
            <th>part_number</th>
            <td>{productifo.electronics?.part_number}</td>
          </tr>
        )}
        {productifo.electronics?.portable !== "" && (
          <tr>
            <th>portable</th>
            <td>{productifo.electronics?.portable}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_brightness !== "" && (
          <tr>
            <th>maximum_brightness</th>
            <td>{productifo.electronics?.maximum_brightness}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_projection_distances !== "" && (
          <tr>
            <th>maximum_projection_distances</th>
            <td>{productifo.electronics?.maximum_projection_distances}</td>
          </tr>
        )}
        {productifo.electronics?.projection_ratio !== "" && (
          <tr>
            <th>projection_ratio</th>
            <td>{productifo.electronics?.projection_ratio}</td>
          </tr>
        )}
        {productifo.electronics?.adjustable_aspect_ratio !== "" && (
          <tr>
            <th>adjustable_aspect_ratio</th>
            <td>{productifo.electronics?.adjustable_aspect_ratio}</td>
          </tr>
        )}
        {productifo.electronics?.vertical_scanning_frequency !== "" && (
          <tr>
            <th>vertical_scanning_frequency</th>
            <td>{productifo.electronics?.vertical_scanning_frequency}</td>
          </tr>
        )}
        {productifo.electronics?.horizontal_scanning_frequency !== "" && (
          <tr>
            <th>horizontal_scanning_frequency</th>
            <td>{productifo.electronics?.horizontal_scanning_frequency}</td>
          </tr>
        )}
        {productifo.electronics?.signal_type !== "" && (
          <tr>
            <th>signal_type</th>
            <td>{productifo.electronics?.signal_type}</td>
          </tr>
        )}
        {productifo.electronics?.standard_brightness !== "" && (
          <tr>
            <th>standard_brightness</th>
            <td>{productifo.electronics?.standard_brightness}</td>
          </tr>
        )}
        {productifo.electronics?.zoom_ratio !== "" && (
          <tr>
            <th>zoom_ratio</th>
            <td>{productifo.electronics?.zoom_ratio}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_diagonal_projection_size !== "" && (
          <tr>
            <th>maximum_diagonal_projection_size</th>
            <td>{productifo.electronics?.maximum_diagonal_projection_size}</td>
          </tr>
        )}
        {productifo.electronics?.color_support !== "" && (
          <tr>
            <th>color_support</th>
            <td>{productifo.electronics?.color_support}</td>
          </tr>
        )}
        {productifo.electronics?.output_per_speaker !== "" && (
          <tr>
            <th>output_per_speaker</th>
            <td>{productifo.electronics?.output_per_speaker}</td>
          </tr>
        )}
        {productifo.electronics?.audio_type !== "" && (
          <tr>
            <th>audio_type</th>
            <td>{productifo.electronics?.audio_type}</td>
          </tr>
        )}
        {productifo.electronics?.componenr_hd_video_in !== "" && (
          <tr>
            <th>componenr_hd_video_in</th>
            <td>{productifo.electronics?.componenr_hd_video_in}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_attitude !== "" && (
          <tr>
            <th>maximum_attitude</th>
            <td>{productifo.electronics?.maximum_attitude}</td>
          </tr>
        )}
        {productifo.electronics?.standby_noise_level !== "" && (
          <tr>
            <th>standby_noise_level</th>
            <td>{productifo.electronics?.standby_noise_level}</td>
          </tr>
        )}
        {productifo.electronics?.normal_noise_level !== "" && (
          <tr>
            <th>normal_noise_level</th>
            <td>{productifo.electronics?.normal_noise_level}</td>
          </tr>
        )}
        {productifo.electronics?.minimum_aperture !== "" && (
          <tr>
            <th>minimum_aperture</th>
            <td>{productifo.electronics?.minimum_aperture}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_aperture !== "" && (
          <tr>
            <th>maximum_aperture</th>
            <td>{productifo.electronics?.maximum_aperture}</td>
          </tr>
        )}
        {productifo.electronics?.functions_modes !== "" && (
          <tr>
            <th>functions_modes</th>
            <td>{productifo.electronics?.functions_modes}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_usd_languages !== "" && (
          <tr>
            <th>number_of_usd_languages</th>
            <td>{productifo.electronics?.number_of_usd_languages}</td>
          </tr>
        )}
        {productifo.electronics?.security !== "" && (
          <tr>
            <th>security</th>
            <td>{productifo.electronics?.security}</td>
          </tr>
        )}
        {productifo.electronics?.offer !== "" && (
          <tr>
            <th>offer</th>
            <td>{productifo.electronics?.offer}</td>
          </tr>
        )}
        {productifo.electronics?.remote_features !== "" && (
          <tr>
            <th>remote_features</th>
            <td>{productifo.electronics?.remote_features}</td>
          </tr>
        )}
        {productifo.electronics?.control_type !== "" && (
          <tr>
            <th>control_type</th>
            <td>{productifo.electronics?.control_type}</td>
          </tr>
        )}
        {productifo.electronics?.power_adapter !== "" && (
          <tr>
            <th>power_adapter</th>
            <td>{productifo.electronics?.power_adapter}</td>
          </tr>
        )}
        {productifo.electronics?.video_player_type !== "" && (
          <tr>
            <th>video_player_type</th>
            <td>{productifo.electronics?.video_player_type}</td>
          </tr>
        )}
        {productifo.electronics?.svr_varient !== "" && (
          <tr>
            <th>svr_varient</th>
            <td>{productifo.electronics?.svr_varient}</td>
          </tr>
        )}
        {productifo.electronics?.effective_pixels !== "" && (
          <tr>
            <th>effective_pixels</th>
            <td>{productifo.electronics?.effective_pixels}</td>
          </tr>
        )}
        {productifo.electronics?.tripod_socket !== "" && (
          <tr>
            <th>tripod_socket</th>
            <td>{productifo.electronics?.tripod_socket}</td>
          </tr>
        )}
        {productifo.electronics?.sensor_type !== "" && (
          <tr>
            <th>sensor_type</th>
            <td>{productifo.electronics?.sensor_type}</td>
          </tr>
        )}
        {productifo.electronics?.image_sensor_size !== "" && (
          <tr>
            <th>image_sensor_size</th>
            <td>{productifo.electronics?.image_sensor_size}</td>
          </tr>
        )}
        {productifo.electronics?.lens_mount !== "" && (
          <tr>
            <th>lens_mount</th>
            <td>{productifo.electronics?.lens_mount}</td>
          </tr>
        )}
        {productifo.electronics?.dust_reduction !== "" && (
          <tr>
            <th>dust_reduction</th>
            <td>{productifo.electronics?.dust_reduction}</td>
          </tr>
        )}
        {productifo.electronics?.shutter_speed !== "" && (
          <tr>
            <th>shutter_speed</th>
            <td>{productifo.electronics?.shutter_speed}</td>
          </tr>
        )}
        {productifo.electronics?.self_timer !== "" && (
          <tr>
            <th>self_timer</th>
            <td>{productifo.electronics?.self_timer}</td>
          </tr>
        )}
        {productifo.electronics?.built_in_flash !== "" && (
          <tr>
            <th>built_in_flash</th>
            <td>{productifo.electronics?.built_in_flash}</td>
          </tr>
        )}
        {productifo.electronics?.external_flash !== "" && (
          <tr>
            <th>external_flash</th>
            <td>{productifo.electronics?.external_flash}</td>
          </tr>
        )}
        {productifo.electronics?.image_format !== "" && (
          <tr>
            <th>image_format</th>
            <td>{productifo.electronics?.image_format}</td>
          </tr>
        )}
        {productifo.electronics?.video_resolution !== "" && (
          <tr>
            <th>video_resolution</th>
            <td>{productifo.electronics?.video_resolution}</td>
          </tr>
        )}
        {productifo.electronics?.video_quality !== "" && (
          <tr>
            <th>video_quality</th>
            <td>{productifo.electronics?.video_quality}</td>
          </tr>
        )}
        {productifo.electronics?.comapatible_cord !== "" && (
          <tr>
            <th>comapatible_cord</th>
            <td>{productifo.electronics?.comapatible_cord}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_batteries !== "" && (
          <tr>
            <th>number_of_batteries</th>
            <td>{productifo.electronics?.number_of_batteries}</td>
          </tr>
        )}
        {productifo.electronics?.shooting_modes !== "" && (
          <tr>
            <th>shooting_modes</th>
            <td>{productifo.electronics?.shooting_modes}</td>
          </tr>
        )}
        {productifo.electronics?.image_sensor_type !== "" && (
          <tr>
            <th>image_sensor_type</th>
            <td>{productifo.electronics?.image_sensor_type}</td>
          </tr>
        )}
        {productifo.electronics?.iso_rating !== "" && (
          <tr>
            <th>iso_rating</th>
            <td>{productifo.electronics?.iso_rating}</td>
          </tr>
        )}
        {productifo.electronics?.focal_length !== "" && (
          <tr>
            <th>focal_length</th>
            <td>{productifo.electronics?.focal_length}</td>
          </tr>
        )}
        {productifo.electronics?.focus_mode !== "" && (
          <tr>
            <th>focus_mode</th>
            <td>{productifo.electronics?.focus_mode}</td>
          </tr>
        )}
        {productifo.electronics?.focus_range !== "" && (
          <tr>
            <th>focus_range</th>
            <td>{productifo.electronics?.focus_range}</td>
          </tr>
        )}
        {productifo.electronics?.metering_model !== "" && (
          <tr>
            <th>metering_model</th>
            <td>{productifo.electronics?.metering_model}</td>
          </tr>
        )}
        {productifo.electronics?.face_detection !== "" && (
          <tr>
            <th>face_detection</th>
            <td>{productifo.electronics?.face_detection}</td>
          </tr>
        )}
        {productifo.electronics?.temperature !== "" && (
          <tr>
            <th>temperature</th>
            <td>{productifo.electronics?.temperature}</td>
          </tr>
        )}
        {productifo.electronics?.ports !== "" && (
          <tr>
            <th>ports</th>
            <td>{productifo.electronics?.ports}</td>
          </tr>
        )}
        {productifo.electronics?.accessory_shoe !== "" && (
          <tr>
            <th>accessory_shoe</th>
            <td>{productifo.electronics?.accessory_shoe}</td>
          </tr>
        )}
        {productifo.electronics?.lens_type !== "" && (
          <tr>
            <th>lens_type</th>
            <td>{productifo.electronics?.lens_type}</td>
          </tr>
        )}
        {productifo.electronics?.manual_focus !== "" && (
          <tr>
            <th>manual_focus</th>
            <td>{productifo.electronics?.manual_focus}</td>
          </tr>
        )}
        {productifo.electronics?.view_finder !== "" && (
          <tr>
            <th>view_finder</th>
            <td>{productifo.electronics?.view_finder}</td>
          </tr>
        )}
        {productifo.electronics?.auto_focus !== "" && (
          <tr>
            <th>auto_focus</th>
            <td>{productifo.electronics?.auto_focus}</td>
          </tr>
        )}
        {productifo.electronics?.focus_points !== "" && (
          <tr>
            <th>focus_points</th>
            <td>{productifo.electronics?.focus_points}</td>
          </tr>
        )}
        {productifo.electronics?.white_balance !== "" && (
          <tr>
            <th>white_balance</th>
            <td>{productifo.electronics?.white_balance}</td>
          </tr>
        )}
        {productifo.electronics?.scene_modes !== "" && (
          <tr>
            <th>scene_modes</th>
            <td>{productifo.electronics?.scene_modes}</td>
          </tr>
        )}
        {productifo.electronics?.exposure_mode !== "" && (
          <tr>
            <th>exposure_mode</th>
            <td>{productifo.electronics?.exposure_mode}</td>
          </tr>
        )}
        {productifo.electronics?.ae_lock_or_exposure_lock !== "" && (
          <tr>
            <th>ae_lock_or_exposure_lock</th>
            <td>{productifo.electronics?.ae_lock_or_exposure_lock}</td>
          </tr>
        )}
        {productifo.electronics?.exposure_bracketing !== "" && (
          <tr>
            <th>exposure_bracketing</th>
            <td>{productifo.electronics?.exposure_bracketing}</td>
          </tr>
        )}
        {productifo.electronics?.manual_exposure !== "" && (
          <tr>
            <th>manual_exposure</th>
            <td>{productifo.electronics?.manual_exposure}</td>
          </tr>
        )}
        {productifo.electronics?.flash_modes !== "" && (
          <tr>
            <th>flash_modes</th>
            <td>{productifo.electronics?.flash_modes}</td>
          </tr>
        )}
        {productifo.electronics?.flash_compension !== "" && (
          <tr>
            <th>flash_compension</th>
            <td>{productifo.electronics?.flash_compension}</td>
          </tr>
        )}
        {productifo.electronics?.image_size !== "" && (
          <tr>
            <th>image_size</th>
            <td>{productifo.electronics?.image_size}</td>
          </tr>
        )}
        {productifo.electronics?.red_eye_reduction !== "" && (
          <tr>
            <th>red_eye_reduction</th>
            <td>{productifo.electronics?.red_eye_reduction}</td>
          </tr>
        )}
        {productifo.electronics?.live_view_shooting !== "" && (
          <tr>
            <th>live_view_shooting</th>
            <td>{productifo.electronics?.live_view_shooting}</td>
          </tr>
        )}
        {productifo.electronics?.hdr_support !== "" && (
          <tr>
            <th>hdr_support</th>
            <td>{productifo.electronics?.hdr_support}</td>
          </tr>
        )}
        {productifo.electronics?.microphone !== "" && (
          <tr>
            <th>microphone</th>
            <td>{productifo.electronics?.microphone}</td>
          </tr>
        )}
        {productifo.electronics?.upgradeable_memory !== "" && (
          <tr>
            <th>upgradeable_memory</th>
            <td>{productifo.electronics?.upgradeable_memory}</td>
          </tr>
        )}
        {productifo.electronics?.model !== "" && (
          <tr>
            <th>model</th>
            <td>{productifo.electronics?.model}</td>
          </tr>
        )}
        {productifo.electronics?.prime_zoom !== "" && (
          <tr>
            <th>prime_zoom</th>
            <td>{productifo.electronics?.prime_zoom}</td>
          </tr>
        )}
        {productifo.electronics?.designed_for !== "" && (
          <tr>
            <th>designed_for</th>
            <td>{productifo.electronics?.designed_for}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_focal_length !== "" && (
          <tr>
            <th>maximum_focal_length</th>
            <td>{productifo.electronics?.maximum_focal_length}</td>
          </tr>
        )}
        {productifo.electronics?.minimum_focal_length !== "" && (
          <tr>
            <th>minimum_focal_length</th>
            <td>{productifo.electronics?.minimum_focal_length}</td>
          </tr>
        )}
        {productifo.electronics?.aperture_with_max_focal_length !== "" && (
          <tr>
            <th>aperture_with_max_focal_length</th>
            <td>{productifo.electronics?.aperture_with_max_focal_length}</td>
          </tr>
        )}
        {productifo.electronics?.aperture_with_min_focal_length !== "" && (
          <tr>
            <th>aperture_with_min_focal_length</th>
            <td>{productifo.electronics?.aperture_with_min_focal_length}</td>
          </tr>
        )}
        {productifo.electronics?.maximum_magnification !== "" && (
          <tr>
            <th>maximum_magnification</th>
            <td>{productifo.electronics?.maximum_magnification}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_channels !== "" && (
          <tr>
            <th>number_of_channels</th>
            <td>{productifo.electronics?.number_of_channels}</td>
          </tr>
        )}
        {productifo.electronics?.camera_type !== "" && (
          <tr>
            <th>camera_type</th>
            <td>{productifo.electronics?.camera_type}</td>
          </tr>
        )}
        {productifo.electronics?.video_recording_frame_rate !== "" && (
          <tr>
            <th>video_recording_frame_rate</th>
            <td>{productifo.electronics?.video_recording_frame_rate}</td>
          </tr>
        )}
        {productifo.electronics?.hdd_available !== "" && (
          <tr>
            <th>hdd_available</th>
            <td>{productifo.electronics?.hdd_available}</td>
          </tr>
        )}
        {productifo.electronics?.recording_format !== "" && (
          <tr>
            <th>recording_format</th>
            <td>{productifo.electronics?.recording_format}</td>
          </tr>
        )}
        {productifo.electronics?.display_frame_rate !== "" && (
          <tr>
            <th>display_frame_rate</th>
            <td>{productifo.electronics?.display_frame_rate}</td>
          </tr>
        )}
        {productifo.electronics?.image_sensor !== "" && (
          <tr>
            <th>image_sensor</th>
            <td>{productifo.electronics?.image_sensor}</td>
          </tr>
        )}
        {productifo.electronics?.image_resolution !== "" && (
          <tr>
            <th>image_resolution</th>
            <td>{productifo.electronics?.image_resolution}</td>
          </tr>
        )}
        {productifo.electronics?.minimum_illumination !== "" && (
          <tr>
            <th>minimum_illumination</th>
            <td>{productifo.electronics?.minimum_illumination}</td>
          </tr>
        )}
        {productifo.electronics?.ir_distance !== "" && (
          <tr>
            <th>ir_distance</th>
            <td>{productifo.electronics?.ir_distance}</td>
          </tr>
        )}
        {productifo.electronics?.motin_triggered_recording !== "" && (
          <tr>
            <th>motin_triggered_recording</th>
            <td>{productifo.electronics?.motin_triggered_recording}</td>
          </tr>
        )}
        {productifo.electronics?.audio_output !== "" && (
          <tr>
            <th>audio_output</th>
            <td>{productifo.electronics?.audio_output}</td>
          </tr>
        )}
        {productifo.electronics?.network_interface_time !== "" && (
          <tr>
            <th>network_interface_time</th>
            <td>{productifo.electronics?.network_interface_time}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_ethiernet_ports !== "" && (
          <tr>
            <th>number_of_ethiernet_ports</th>
            <td>{productifo.electronics?.number_of_ethiernet_ports}</td>
          </tr>
        )}
        {productifo.electronics?.power_consumption !== "" && (
          <tr>
            <th>power_consumption</th>
            <td>{productifo.electronics?.power_consumption}</td>
          </tr>
        )}
        {productifo.electronics?.alarm_action !== "" && (
          <tr>
            <th>alarm_action</th>
            <td>{productifo.electronics?.alarm_action}</td>
          </tr>
        )}
        {productifo.electronics?.application_support !== "" && (
          <tr>
            <th>application_support</th>
            <td>{productifo.electronics?.application_support}</td>
          </tr>
        )}
        {productifo.electronics?.remote_connectivity !== "" && (
          <tr>
            <th>remote_connectivity</th>
            <td>{productifo.electronics?.remote_connectivity}</td>
          </tr>
        )}
        {productifo.electronics?.closure_mechanism !== "" && (
          <tr>
            <th>closure_mechanism</th>
            <td>{productifo.electronics?.closure_mechanism}</td>
          </tr>
        )}
        {productifo.electronics?.outer_material !== "" && (
          <tr>
            <th>outer_material</th>
            <td>{productifo.electronics?.outer_material}</td>
          </tr>
        )}
        {productifo.electronics?.inner_height !== "" && (
          <tr>
            <th>inner_height</th>
            <td>{productifo.electronics?.inner_height}</td>
          </tr>
        )}
        {productifo.electronics?.inner_width !== "" && (
          <tr>
            <th>inner_width</th>
            <td>{productifo.electronics?.inner_width}</td>
          </tr>
        )}
        {productifo.electronics?.inner_depth !== "" && (
          <tr>
            <th>inner_depth</th>
            <td>{productifo.electronics?.inner_depth}</td>
          </tr>
        )}
        {productifo.electronics?.head_type !== "" && (
          <tr>
            <th>head_type</th>
            <td>{productifo.electronics?.head_type}</td>
          </tr>
        )}
        {productifo.electronics?.leg_lock_type !== "" && (
          <tr>
            <th>leg_lock_type</th>
            <td>{productifo.electronics?.leg_lock_type}</td>
          </tr>
        )}
        {productifo.electronics?.independent_leg_spread !== "" && (
          <tr>
            <th>independent_leg_spread</th>
            <td>{productifo.electronics?.independent_leg_spread}</td>
          </tr>
        )}
        {productifo.electronics?.pack_of !== "" && (
          <tr>
            <th>pack_of</th>
            <td>{productifo.electronics?.pack_of}</td>
          </tr>
        )}
        {productifo.electronics?.floded_length !== "" && (
          <tr>
            <th>floded_length</th>
            <td>{productifo.electronics?.floded_length}</td>
          </tr>
        )}
        {productifo.electronics?.number_of_charging_cells !== "" && (
          <tr>
            <th>number_of_charging_cells</th>
            <td>{productifo.electronics?.number_of_charging_cells}</td>
          </tr>
        )}
        {productifo.electronics?.used_for !== "" && (
          <tr>
            <th>used_for</th>
            <td>{productifo.electronics?.used_for}</td>
          </tr>
        )}
        {productifo.electronics?.generator_compatibility !== "" && (
          <tr>
            <th>generator_compatibility</th>
            <td>{productifo.electronics?.generator_compatibility}</td>
          </tr>
        )}
        {productifo.electronics?.cooling !== "" && (
          <tr>
            <th>cooling</th>
            <td>{productifo.electronics?.cooling}</td>
          </tr>
        )}
        {productifo.electronics?.minimum_operating !== "" && (
          <tr>
            <th>minimum_operating</th>
            <td>{productifo.electronics?.minimum_operating}</td>
          </tr>
        )}
        {productifo.electronics?.under_vlot_protection !== "" && (
          <tr>
            <th>under_vlot_protection</th>
            <td>{productifo.electronics?.under_vlot_protection}</td>
          </tr>
        )}
        {productifo.electronics?.overload_protection !== "" && (
          <tr>
            <th>overload_protection</th>
            <td>{productifo.electronics?.overload_protection}</td>
          </tr>
        )}
        {productifo.electronics?.rated_pressure !== "" && (
          <tr>
            <th>rated_pressure</th>
            <td>{productifo.electronics?.rated_pressure}</td>
          </tr>
        )}
        {productifo.electronics?.thermostot !== "" && (
          <tr>
            <th>thermostot</th>
            <td>{productifo.electronics?.thermostot}</td>
          </tr>
        )}
        {productifo.electronics?.door_finish !== "" && (
          <tr>
            <th>door_finish</th>
            <td>{productifo.electronics?.door_finish}</td>
          </tr>
        )}
        {productifo.electronics?.water_ice_dispenser !== "" && (
          <tr>
            <th>water_ice_dispenser</th>
            <td>{productifo.electronics?.water_ice_dispenser}</td>
          </tr>
        )}
        {productifo.electronics?.stabilizer_required !== "" && (
          <tr>
            <th>stabilizer_required</th>
            <td>{productifo.electronics?.stabilizer_required}</td>
          </tr>
        )}
        {productifo.electronics?.egg_tray !== "" && (
          <tr>
            <th>egg_tray</th>
            <td>{productifo.electronics?.egg_tray}</td>
          </tr>
        )}
        {productifo.electronics?.deodarizer !== "" && (
          <tr>
            <th>deodarizer</th>
            <td>{productifo.electronics?.deodarizer}</td>
          </tr>
        )}
        {productifo.electronics?.ice_cub_tray_type !== "" && (
          <tr>
            <th>ice_cub_tray_type</th>
            <td>{productifo.electronics?.ice_cub_tray_type}</td>
          </tr>
        )}
        {productifo.electronics?.removable_gasket !== "" && (
          <tr>
            <th>removable_gasket</th>
            <td>{productifo.electronics?.removable_gasket}</td>
          </tr>
        )}
        {productifo.electronics?.flexible_rack !== "" && (
          <tr>
            <th>flexible_rack</th>
            <td>{productifo.electronics?.flexible_rack}</td>
          </tr>
        )}
        {productifo.electronics?.removable_rack !== "" && (
          <tr>
            <th>removable_rack</th>
            <td>{productifo.electronics?.removable_rack}</td>
          </tr>
        )}
      </tbody>
    </>
  );
};

export default Electronic_Supercategory;

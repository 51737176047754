import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useSelector, useDispatch } from "react-redux";

const Steper = () => {
  const { checkout_process_steps } = useSelector(
    (state) => state.Athentication
  );
  const steps = ["Address", "Order Summary", "Place Order"];
  return (
    <>
      <div className="mt-2">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={checkout_process_steps} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
    </>
  );
};

export default Steper;

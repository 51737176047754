import React from "react";

const TermsUse = () => {
  return (
    <>
      <div className="page-header text-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
              <div className="page-title">
                <h1>Terms Use</h1>
              </div>
              {/* <div className="breadcrumbs">
                <a to="/" title="Back to the home page">
                  Home
                </a>
                <span className="title">
                  <i className="icon anm anm-angle-right-l" />
                  Pages
                </span>
                <span className="main-title fw-bold">
                  <i className="icon anm anm-angle-right-l" />
                  Information Page
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-content">
          <h3 style={{ color: "#1b59ba",fontWeight:"700",fontSize:"22px" }}>
            Elderwise Shopping India pvt Ltd{" "}
          </h3>

          <h3 style={{ textAlign: "center" }}>Terms of Use</h3>

          <p style={{ textAlign: "justify" }}>
            {" "}
            Creativity. Community. And sweet, sweet cash. Sell your products on
            high-quality products to a global audience. It’s fun, easy, and
            quick to get started. Find your fans Create what you love, and
            connect with people who love it, too. From India, millions of
            visitors come to EWShopping looking for their weirdly meaningful
            thing. How EWShopping works as the best online selling site in India
            You upload your designs to products in your shop Customers find and
            purchase products they love, featuring your designs Products are
            produced to order and shipped around the India Customers get an
            awesome product. And printing to order means less waste, which is
            better for the planet. Join thousands of successful sellers Sellers
            are the heart of the EWShopping marketplace. From the pro, to the
            hobbyist, to the fan with a great idea, thousands of creators are
            earning money on EWShopping every day. Here are just a few of them.
            You’re the boss sell-product-pricing Complete control over what you
            earn with flexible pricing sell-product-protection Anti-piracy and
            watermark features to protect your designs sell-product-support
            World-class support for your customers, available 24/7
          </p>

          <br />

          <h3 style={{ textAlign: "center" }}>
            OUR TOP CATEGORIES FOR ONLINE SHOPPING
          </h3>
          <p style={{ textAlign: "justify" }}>
            ELECTRONICS: Televisions | Speakers | Headphone & Earphones | Audio
            & Video | Winter Appliances | Washing Machines | Coolers | Fans |
            Grooming Appliances | Small Appliances
          </p>
          <p style={{ textAlign: "justify" }}>
            MOBILE, TABLETS: Mobile Cases & Covers | Mobile Phones | Tablets |
            Mobile Accessories | Wearables & Smartwatches | Tablet Accessories
          </p>
          <p style={{ textAlign: "justify" }}>
            Women's Fashion: Ethnic Wear | Footwear | Western Wear | Winter Wear
            | Handbags & Clutches | Eyewear | Fashion Jewellery | Watches |
            Precious Jewellery | Gold Coins & Bars | Fashion Accessories |
            Sunglasses
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Mens Fashion: Foot Wear | Bags & Luggage | Clothing | Winterwear |
            Sports Wear | Mens Grooming | Eye Wear | Watches | Jewellery &
            Cufflinks | Accessories | Fragrances
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Beauty Health: Beauty | Personal-Care-Grooming | Sexual Wellness |
            Food And Gourmet | Nutrition & Supplements | Health Monitoring
            Devices | General Wellness | Baby Care | Household Essentials
          </p>
          <p style={{ textAlign: "justify" }}>
            Toys Kids Fashion: Toys | Bicycles & Tricycles | Kids Footwear |
            Accessories | Kids Clothing | Girls Clothing | Boys Clothing | Baby
            Clothing | Baby Care | Stationery
          </p>
          <p style={{ textAlign: "justify" }}>
            Home Kitchen: Kitchen Appliances | Kitchenware | Home-Furnishing |
            Furniture | Home Decor | Home Improvement | Tools & Hardware | Pet
            Supplies
          </p>
          <p style={{ textAlign: "justify" }}>
            Sports Fitness Outdoor: Sports & Fitness | Get Fit At Home | Get
            Ready For Gym | Sports | Hiking & Outdoor
          </p>
          <p style={{ textAlign: "justify" }}>
            CAR MOTORBIKE: Automotive Accessories | Car & Vehicle Electronics |
            Car Audio Finder | Car Accessories | Tyre Inflators |Automotive |
            Car Fresheners | Air Purifiers & Ionizers | Biker Gear & Accessories
            | Biker Raincoats | Helmets & Accessories | Parts & Spares | Tyres &
            Alloys | Motorbike Batteries
          </p>
          <p style={{ textAlign: "justify" }}>
            Computers Gaming: Laptops | Printers & Inks | Networking Devices |
            Office Equipment | Storage | Computer Accessories | Desktops |
            Gaming | Computer Components | Software | Monitors | Top Laptop
            Brands
          </p>
          <p style={{ textAlign: "justify" }}>
            BOOKS MEDIA MUSIC: Top Exams| Browse By Genre | Online Education |
            Musical Instruments | Indian Instruments | Pa & Stage | Other
            Instruments | Hobbies New | E-Gift Cards | Physical Gift Cards |
            Digital Subscriptions | Movies & Tv Shows
          </p>
          <p style={{ textAlign: "justify" }}>
            Why Choose EWShopping as the Best Online Shopping Sites in India
          </p>
          <p style={{ textAlign: "justify" }}>
            Worldwide Shipping: Available as Standard or Express delivery
            EWShopping Promise: Products with this tag are quality checked, and
            shipped the same day from certified warehouses. So you get the right
            product, faster. Secure Payments: At EWShopping 100% Secure payment.
            If you have any issues, your money is immediately refunded. Sit back
            and enjoy your shopping. Free Return: Your money is yours! All
            refunds come with a question-asked guarantee for all orders. 24/7
            Dedicated support: Need Help? Click Here. You can also talk to us on
            -------- to resolve your query.
          </p>
          <p style={{ textAlign: "justify" }}>
            India's Best Online Shopping Website for Fashion & Lifestyle
          </p>
          <p style={{ textAlign: "justify" }}>
            EWShopping is the Best Site for Online shopping in India when it
            comes to online clothes shopping for men, women, and kids at the
            lowest prices. The latest fashion trends and styles are showcased
            here, yes at your favorite online shopping destination. Well, if
            fashion is medicine then Beyoung is the chemist shop where you can
            do your online shopping for men and women with ease. EWShopping is
            without a doubt one of the best online shopping sites in India. The
            website is replete with the cool products that you always crave.
            Indeed, online shopping for women, men & kids at Beyoung is such
            hassle-free that in just a few clicks one can purchase whatever he
            or she wants. A one-stop destination for all your shopping needs,
            Beyoung caters to each taste and need for men’s clothing, Women's
            Clothing, and accessories.
          </p>
          <p style={{ textAlign: "justify" }}>
            EWShopping is the Best Site for Online shopping in India when it
            comes to online clothes shopping for men, women, and kids at the
            lowest prices. The latest fashion trends and styles are showcased
            here, yes at your favorite online shopping destination. Well, if
            fashion is medicine then Beyoung is the chemist shop where you can
            do your online shopping for men and women with ease. EWShopping is
            without a doubt one of the best online shopping sites in India. The
            website is replete with the cool products that you always crave.
            Indeed, online shopping for women, men & kids at Beyoung is such
            hassle-free that in just a few clicks one can purchase whatever he
            or she wants. A one-stop destination for all your shopping needs,
            Beyoung caters to each taste and need for men’s clothing, Women's
            Clothing, and accessories.
          </p>
          <br />
        </div>
      </div>
      <div style={{marginBottom:'50px'}}></div>

    </>
  );
};

export default TermsUse;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
const Women = ({
  current_category,
  filterSelectedCloseArray,
  selectCloserFilterPress,
  category_products,
  getfilt_Prod,
  selected_category_tag,
}) => {
  const dispatch = useDispatch();

  const {
    romanTypeMessurements,
    sleevestype,
    top_shapes,
    top_types,
    bottom_styles_with_kurti,
    Dupattaavailable,
    pritsandPatterns,
    dress_designs,
    hemline_styles,
    length_styles,
    necktype,
    occasion_styles,
    embellishment_types,
    wave_patterns,
    wave_types,
    pattern_coverage,
    sleeve_styles,
    no_of_item,
    saree_types,
    saree_border_styles,
    fabric_types,
    Blouseavailable,
    fabric_care_instructions,
    fastening_types,
    transparency_levels,
    main_trends,
  } = useSelector((store) => store.Messurents);

  const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);
  const [distinctfield, setdistinctfield] = useState([]);

  const [selected_Occaction_style, setselected_Occaction_style] = useState([]);
  const [selected_Length, setselected_Length] = useState([]);
  const [selected_Fabric, setselected_Fabric] = useState([]);
  const [selected_PrintorPatternType, setselected_PrintorPatternType] =
    useState([]);
  const [selected_Type, setselected_Type] = useState([]);
  const [selected_SleeveLength, setselected_SleeveLength] = useState([]);

  useEffect(() => {
    if (current_category) {
      const resp = current_category;
      const filterShow = resp.selectedFields.filter(
        (data) => data.status === true
      );
      const distinctNames = [
        ...new Set(filterShow.map((item) => item.field_Name)),
      ];
      setdistinctfield(distinctNames);
    }
  }, [current_category]);
  useEffect(() => {
    if (
      selected_Occaction_style.length === 0 &&
      selected_Length.length === 0 &&
      selected_Fabric.length === 0 &&
      selected_PrintorPatternType.length === 0 &&
      selected_SleeveLength.length === 0 &&
      selected_category_tag.length === 0 &&
      selected_Type.length === 0
    ) {
      const products = category_products;
      getfilt_Prod(products);
    } else {
      if (category_products.length > 0) {
        let products = category_products;
        let final_filt_product = category_products;
        if (selected_Occaction_style.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_Occaction_style.includes(item.clothing.Occasion)
          );
          final_filt_product = final_filt_product;
        }

        if (selected_Length.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_Length.includes(item.clothing.Length)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_Fabric.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_Fabric.includes(item.clothing.Fabric)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_PrintorPatternType.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_PrintorPatternType.includes(
              item.clothing.PrintorPatternType
            )
          );
          final_filt_product = final_filt_product;
        }
        if (selected_Type.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_Type.includes(item.clothing.Type)
          );
          final_filt_product = final_filt_product;
        }
        if (selected_SleeveLength.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_SleeveLength.includes(item.clothing.SleeveLength)
          );
          final_filt_product = final_filt_product;
        }

        if (selected_category_tag.length > 0) {
          final_filt_product = final_filt_product.filter((item) =>
            selected_category_tag.includes(item.CategoryTag)
          );
          final_filt_product = final_filt_product;
        }
        getfilt_Prod(final_filt_product);
      }
    }
  }, [
    selected_Occaction_style,
    // category_products,
    // filtered_products,
    selected_Length,
    selected_Fabric,
    selected_PrintorPatternType,
    selected_Type,
    selected_SleeveLength,
    selected_category_tag,
  ]);

  const handletagPress = (evt, value) => {
    if (selected_Occaction_style.includes(value)) {
      setselected_Occaction_style(
        selected_Occaction_style.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_Occaction_style((selected_Occaction_style) => [
        ...selected_Occaction_style,
        value,
      ]);
    }
  };
  const handleLengthPress = (evt, value) => {
    if (selected_Length.includes(value)) {
      setselected_Length(
        selected_Length.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_Length((selected_Length) => [...selected_Length, value]);
    }
  };
  const handleFfabricPress = (evt, value) => {
    if (selected_Fabric.includes(value)) {
      setselected_Fabric(
        selected_Fabric.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_Fabric((selected_Fabric) => [...selected_Fabric, value]);
    }
  };
  const handlePartnerPress = (evt, value) => {
    if (selected_PrintorPatternType.includes(value)) {
      setselected_PrintorPatternType(
        selected_PrintorPatternType.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_PrintorPatternType((selected_PrintorPatternType) => [
        ...selected_PrintorPatternType,
        value,
      ]);
    }
  };
  const handleTypePress = (evt, value) => {
    if (selected_Type.includes(value)) {
      setselected_Type(
        selected_Type.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_Type((selected_Type) => [...selected_Type, value]);
    }
  };
  const handleSleevLengthPress = (evt, value) => {
    if (selected_SleeveLength.includes(value)) {
      setselected_SleeveLength(
        selected_SleeveLength.filter((e) => {
          return e !== value;
        })
      );
    } else {
      setselected_SleeveLength((selected_SleeveLength) => [
        ...selected_SleeveLength,
        value,
      ]);
    }
  };

  return (
    <>
      {distinctfield.includes("Occasion") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("Occasion")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("Occasion")}
          >
            <h2>Occasion</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("Occasion")
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                occasion_styles &&
                occasion_styles.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`avone${index}`}
                      name="avone"
                      value={data.label}
                      checked={selected_Occaction_style.includes(data.label)}
                      onChange={(e) => handletagPress(e, data.label)}
                    />
                    <label htmlFor={`avone${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("Length") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("Length")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("Length")}
          >
            <h2>Length</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("Length")
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                length_styles &&
                length_styles.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`Length${index}`}
                      name="Length"
                      value={data.label}
                      checked={selected_Length.includes(data.label)}
                      onChange={(e) => handleLengthPress(e, data.label)}
                    />
                    <label htmlFor={`Length${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("Fabric") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("Fabric")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("Fabric")}
          >
            <h2>Fabric</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("Fabric")
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                fabric_types &&
                fabric_types.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`Fabric${index}`}
                      name="Fabric"
                      value={data.label}
                      checked={selected_Fabric.includes(data.label)}
                      onChange={(e) => handleFfabricPress(e, data.label)}
                    />
                    <label htmlFor={`Fabric${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("PrintorPatternType") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("PrintorPatternType")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("PrintorPatternType")}
          >
            <h2>Pattern Type</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("PrintorPatternType")
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                pritsandPatterns &&
                pritsandPatterns.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`PrintorPatternType${index}`}
                      name="PrintorPatternType"
                      value={data.label}
                      checked={selected_PrintorPatternType.includes(data.label)}
                      onChange={(e) => handlePartnerPress(e, data.label)}
                    />
                    <label htmlFor={`PrintorPatternType${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("Type") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("Type")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("Type")}
          >
            <h2>Type</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("Type")
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                top_types &&
                top_types.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`Type${index}`}
                      name="Type"
                      value={data.label}
                      checked={selected_Type.includes(data.label)}
                      onChange={(e) => handleTypePress(e, data.label)}
                    />
                    <label htmlFor={`Type${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      {distinctfield.includes("SleeveLength") && (
        <div className="sidebar-widget clearfix categories filterBox filter-widget">
          <div
            className={
              filterSelectedCloseArray.includes("SleeveLength")
                ? "widget-title active"
                : "widget-title"
            }
            onClick={() => selectCloserFilterPress("SleeveLength")}
          >
            <h2>Sleeve Length</h2>
          </div>
          <div
            className="widget-content filterDD"
            style={
              filterSelectedCloseArray.includes("SleeveLength")
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            <ul className="sidebar-categories scrollspy morelist clearfix">
              {current_category &&
                sleevestype &&
                sleevestype.map((data, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={`SleeveLength${index}`}
                      name="SleeveLength"
                      value={data.label}
                      checked={selected_SleeveLength.includes(data.label)}
                      onChange={(e) => handleSleevLengthPress(e, data.label)}
                    />
                    <label htmlFor={`SleeveLength${index}`}>
                      <span />
                      {data.label}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Women;

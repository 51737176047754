import React from "react";

const HomeFurnitureSupercat = ({ productifo }) => {
  return (
    <div className="design1">
      {productifo.homeandfurniture.pack_of !== "" && (
        <div className="des">
          <h5>pack_of</h5>
          <p>{productifo.homeandfurniture.pack_of}</p>
        </div>
      )}
      {productifo.homeandfurniture.brand !== "" && (
        <div className="des">
          <h5>brand</h5>
          <p>{productifo.homeandfurniture.brand}</p>
        </div>
      )}
      {productifo.homeandfurniture.model_name !== "" && (
        <div className="des">
          <h5>model_name</h5>
          <p>{productifo.homeandfurniture.model_name}</p>
        </div>
      )}
      {productifo.homeandfurniture.type !== "" && (
        <div className="des">
          <h5>type</h5>
          <p>{productifo.homeandfurniture.type}</p>
        </div>
      )}
      {productifo.homeandfurniture.size !== "" && (
        <div className="des">
          <h5>size</h5>
          <p>{productifo.homeandfurniture.size}</p>
        </div>
      )}
      {productifo.homeandfurniture.color !== "" && (
        <div className="des">
          <h5>color</h5>
          <p>{productifo.homeandfurniture.color}</p>
        </div>
      )}
      {productifo.homeandfurniture.model_number !== "" && (
        <div className="des">
          <h5>model_number</h5>
          <p>{productifo.homeandfurniture.model_number}</p>
        </div>
      )}
      {productifo.homeandfurniture.material !== "" && (
        <div className="des">
          <h5>material</h5>
          <p>{productifo.homeandfurniture.material}</p>
        </div>
      )}
      {productifo.homeandfurniture.other_features !== "" && (
        <div className="des">
          <h5>other_features</h5>
          <p>{productifo.homeandfurniture.other_features}</p>
        </div>
      )}
      {productifo.homeandfurniture.number_of_scrubs !== "" && (
        <div className="des">
          <h5>number_of_scrubs</h5>
          <p>{productifo.homeandfurniture.number_of_scrubs}</p>
        </div>
      )}
      {productifo.homeandfurniture.net_quantity !== "" && (
        <div className="des">
          <h5>net_quantity</h5>
          <p>{productifo.homeandfurniture.net_quantity}</p>
        </div>
      )}
      {productifo.homeandfurniture.sales_package !== "" && (
        <div className="des">
          <h5>sales_package</h5>
          <p>{productifo.homeandfurniture.sales_package}</p>
        </div>
      )}
      {productifo.homeandfurniture.capacity !== "" && (
        <div className="des">
          <h5>capacity</h5>
          <p>{productifo.homeandfurniture.capacity}</p>
        </div>
      )}
      {productifo.homeandfurniture.pan_type !== "" && (
        <div className="des">
          <h5>pan_type</h5>
          <p>{productifo.homeandfurniture.pan_type}</p>
        </div>
      )}
      {productifo.homeandfurniture.induction_bottom !== "" && (
        <div className="des">
          <h5>induction_bottom</h5>
          <p>{productifo.homeandfurniture.induction_bottom}</p>
        </div>
      )}
      {productifo.homeandfurniture.lid_included !== "" && (
        <div className="des">
          <h5>lid_included</h5>
          <p>{productifo.homeandfurniture.lid_included}</p>
        </div>
      )}
      {productifo.homeandfurniture.non_stick !== "" && (
        <div className="des">
          <h5>non_stick</h5>
          <p>{productifo.homeandfurniture.non_stick}</p>
        </div>
      )}
      {productifo.homeandfurniture.dish_washer_safe !== "" && (
        <div className="des">
          <h5>dish_washer_safe</h5>
          <p>{productifo.homeandfurniture.dish_washer_safe}</p>
        </div>
      )}
      {productifo.homeandfurniture.diameter !== "" && (
        <div className="des">
          <h5>diameter</h5>
          <p>{productifo.homeandfurniture.diameter}</p>
        </div>
      )}
      {productifo.homeandfurniture.lid_locking_mechanism !== "" && (
        <div className="des">
          <h5>lid_locking_mechanism</h5>
          <p>{productifo.homeandfurniture.lid_locking_mechanism}</p>
        </div>
      )}
      {productifo.homeandfurniture.other_body_and_design_features !== "" && (
        <div className="des">
          <h5>other_body_and_design_features</h5>
          <p>{productifo.homeandfurniture.other_body_and_design_features}</p>
        </div>
      )}
      {productifo.homeandfurniture.shade !== "" && (
        <div className="des">
          <h5>shade</h5>
          <p>{productifo.homeandfurniture.shade}</p>
        </div>
      )}
      {productifo.homeandfurniture.suitable_for !== "" && (
        <div className="des">
          <h5>suitable_for</h5>
          <p>{productifo.homeandfurniture.suitable_for}</p>
        </div>
      )}
      {productifo.homeandfurniture.handled_features !== "" && (
        <div className="des">
          <h5>handled_features</h5>
          <p>{productifo.homeandfurniture.handled_features}</p>
        </div>
      )}
      {productifo.homeandfurniture.base_thickness !== "" && (
        <div className="des">
          <h5>base_thickness</h5>
          <p>{productifo.homeandfurniture.base_thickness}</p>
        </div>
      )}
      {productifo.homeandfurniture.weight !== "" && (
        <div className="des">
          <h5>weight</h5>
          <p>{productifo.homeandfurniture.weight}</p>
        </div>
      )}
      {productifo.homeandfurniture.covered_in_warranty !== "" && (
        <div className="des">
          <h5>covered_in_warranty</h5>
          <p>{productifo.homeandfurniture.covered_in_warranty}</p>
        </div>
      )}
      {productifo.homeandfurniture.warranty_summary !== "" && (
        <div className="des">
          <h5>warranty_summary</h5>
          <p>{productifo.homeandfurniture.warranty_summary}</p>
        </div>
      )}
      {productifo.homeandfurniture.not_covered_in_warranty !== "" && (
        <div className="des">
          <h5>not_covered_in_warranty</h5>
          <p>{productifo.homeandfurniture.not_covered_in_warranty}</p>
        </div>
      )}
      {productifo.homeandfurniture.warranty_service_type !== "" && (
        <div className="des">
          <h5>warranty_service_type</h5>
          <p>{productifo.homeandfurniture.warranty_service_type}</p>
        </div>
      )}
      {productifo.homeandfurniture.domestic_warranty !== "" && (
        <div className="des">
          <h5>domestic_warranty</h5>
          <p>{productifo.homeandfurniture.domestic_warranty}</p>
        </div>
      )}
      {productifo.homeandfurniture.in_the_box !== "" && (
        <div className="des">
          <h5>in_the_box</h5>
          <p>{productifo.homeandfurniture.in_the_box}</p>
        </div>
      )}
      {productifo.homeandfurniture.body_material !== "" && (
        <div className="des">
          <h5>body_material</h5>
          <p>{productifo.homeandfurniture.body_material}</p>
        </div>
      )}
      {productifo.homeandfurniture.trivet_material !== "" && (
        <div className="des">
          <h5>trivet_material</h5>
          <p>{productifo.homeandfurniture.trivet_material}</p>
        </div>
      )}
      {productifo.homeandfurniture.burner_material !== "" && (
        <div className="des">
          <h5>burner_material</h5>
          <p>{productifo.homeandfurniture.burner_material}</p>
        </div>
      )}
      {productifo.homeandfurniture.number_of_burners !== "" && (
        <div className="des">
          <h5>number_of_burners</h5>
          <p>{productifo.homeandfurniture.number_of_burners}</p>
        </div>
      )}
      {productifo.homeandfurniture.burner_type !== "" && (
        <div className="des">
          <h5>burner_type</h5>
          <p>{productifo.homeandfurniture.burner_type}</p>
        </div>
      )}
    
    </div>
  );
};

export default HomeFurnitureSupercat;

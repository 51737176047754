import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Affix } from "antd";
import { Link, useNavigate } from "react-router-dom";
import AccountInfo from "./account/AccountInfo";
import AddressBook from "./account/AddressBook";
import Orders from "./account/Orders";
import Mywishlist from "./account/Mywishlist";
import SavedCards from "./account/SavedCards";
import Profile from "./account/Profile";
import Setting from "./account/Setting";
import { signout } from "../../redux/athentication/Athentication";

const MyAccount = () => {
  const { isAuth, current_address, loginData } = useSelector(
    (state) => state.Athentication
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState(80);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [stateValue, setStateValue] = useState(80);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollHeight = window.scrollY;
      setScrollHeight(currentScrollHeight);

      const customHeight = 300;
      if (currentScrollHeight > customHeight) {
        setStateValue(0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <div className="page-header text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                            <div className="page-title">
                                <h1>My Account</h1>
                            </div>
                            <div className="breadcrumbs"><a to="index.html" title="Back to the home page">Home</a><span className="title"><i className="icon anm anm-angle-right-l" />Pages</span><span className="main-title fw-bold"><i className="icon anm anm-angle-right-l" />My
                                Account</span></div>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="container categorcontainer">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 mb-4 mb-lg-0">
            {/* <Affix offsetTop={80}> */}
            <br /><br />
            <div className="dashboard-sidebar bg-block">
            
              <div className="profile-top text-center mb-4 px-3">
                <div className="profile-image mb-3">
                  <img
                    className="rounded-circle blur-up lazyload"
                    data-src="/assets/images/users/usemen1.png"
                    src="/assets/images/users/usemen1.png"
                    alt="user"
                    width={130}
                  />
                </div>
                <div className="profile-detail">
                  <h3 className="mb-1">{loginData.Name}</h3>
                  <p className="text-muted">{loginData.Email}</p>
                </div>
              </div>
              <div className="dashboard-tab">
                <ul
                  className="nav nav-tabs flex-lg-column border-bottom-0"
                  id="top-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#info"
                      className="nav-link active"
                    >
                      Account Info
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#address"
                      className="nav-link"
                    >
                      Address Book
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#orders"
                      className="nav-link"
                    >
                      My Orders
                    </a>
                  </li>
                  {!isAuth ? (
                    <></>
                  ) : (
                    <li
                      className="nav-item"
                      onClick={() => {
                        dispatch(signout());
                        navigate("/");
                      }}
                    >
                      <span className="nav-link">Log Out</span>
                    </li>
                  )}
                  {/* <li className="nav-item">
                    <a
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#orderstracking"
                      className="nav-link"
                    >
                      Orders tracking
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#wishlist"
                      className="nav-link"
                    >
                      My Wishlist
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#payment"
                      className="nav-link"
                    >
                      Saved Cards
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      className="nav-link"
                    >
                      Profile
                    </a>
                  </li> */}
                  {/* <li className="nav-item">
                    <a
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#security"
                      className="nav-link"
                    >
                      Settings
                    </a>{" "}
                  </li> */}
                </ul>
              </div>
            </div>
            {/* </Affix> */}
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-9">
            <div
              className="dashboard-content tab-content h-100"
              id="top-tabContent"
            >
              <AccountInfo />
              <AddressBook />
              <Orders />
              <Mywishlist />
              <SavedCards />
              <Profile />
              <Setting />
            </div>
          </div>
          <div style={{marginBottom:"50px"}}></div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;

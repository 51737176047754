import React from "react";

const Cloh5ingSupercat = ({ productifo }) => {
  return (
    <div className="design1"
      // style={{
      //   display: "flex",
      //   flexWrap: "wrap",
      
      // }}
    >
      {productifo.clothing?.DesignDetails !== "" && (
        <div className="des">
          <h5>Design Details</h5>
          <p>{productifo.clothing?.DesignDetails}</p>
        </div>
      )}
      {productifo.clothing?.topDesign !== "" && (
        <div className="des">
          <h5>top Design</h5>
          <p>{productifo.clothing?.topDesign}</p>
        </div>
      )}
      {productifo.clothing?.bottomDesign !== "" && (
        <div className="des">
          <h5>bottom Design</h5>
          <p>{productifo.clothing?.bottomDesign}</p>
        </div>
      )}
      {productifo.clothing?.SizeAndFit !== "" && (
        <div className="des">
          <h5>Size And Fit</h5>
          <p>{productifo.clothing?.SizeAndFit}</p>
        </div>
      )}
      {productifo.clothing?.MaterialAndCare !== "" && (
        <div className="des">
          <h5>Material And Care</h5>
          <p>{productifo.clothing?.MaterialAndCare}</p>
        </div>
      )}
      {productifo.clothing?.SleeveLength !== "" && (
        <div className="des">
          <h5>Sleeve Length</h5>
          <p>{productifo.clothing?.SleeveLength}</p>
        </div>
      )}
      {productifo.clothing?.SleeveStyling !== "" && (
        <div className="des">
          <h5>Sleeve Styling</h5>
          <p>{productifo.clothing?.SleeveStyling}</p>
        </div >
      )}
      {productifo.clothing?.Shape !== "" && (
        <div className="des">
          <h5>Shape</h5>
          <p>{productifo.clothing?.Shape}</p>
        </div>
      )}
      {productifo.clothing?.Neck !== "" && (
        <div className="des">
          <h5>Neck</h5>
          <p>{productifo.clothing?.Neck}</p>
        </div>
      )}
      {productifo.clothing?.PrintorPatternType !== "" && (
        <div className="des">
          <h5>Printor and Pattern Type</h5>
          <p>{productifo.clothing?.PrintorPatternType}</p>
        </div>
      )}
      {productifo.clothing?.DesignStyling !== "" && (
        <div className="des">
          <h5>Design Styling</h5>
          <p>{productifo.clothing?.DesignStyling}</p>
        </div>
      )}
      {productifo.clothing?.Length !== "" && (
        <div className="des">
          <h5>Length</h5>
          <p>{productifo.clothing?.Length}</p>
        </div>
      )}
      {productifo.clothing?.Hemline !== "" && (
        <div className="des">
          <h5>Hemline</h5>
          <p>{productifo.clothing?.Hemline}</p>
        </div>
      )}
      {productifo.clothing?.WeavePattern !== "" && (
        <div className="des">
          <h5>Weave Pattern</h5>
          <p>{productifo.clothing?.WeavePattern}</p>
        </div>
      )}
      {productifo.clothing?.WeaveType !== "" && (
        <div className="des">
          <h5>Weave Type</h5>
          <p>{productifo.clothing?.WeaveType}</p>
        </div>
      )}
      {productifo.clothing?.Occasion !== "" && (
        <div className="des">
          <h5>Occasion</h5>
          <p>{productifo.clothing?.Occasion}</p>
        </div>
      )}
      {productifo.clothing?.CompleteTheLook !== "" && (
        <div className="des">
          <h5>Complete The Look</h5>
          <p>{productifo.clothing?.CompleteTheLook}</p>
        </div>
      )}
      {productifo.clothing?.Type !== "" && (
        <div className="des">
          <h5>Type</h5>
          <p>{productifo.clothing?.Type}</p>
        </div>
      )}
      {productifo.clothing?.Transparency !== "" && (
        <div className="des">
          <h5>Transparency</h5>
          <p>{productifo.clothing?.Transparency}</p>
        </div>
      )}
      {productifo.clothing?.MainTrend !== "" && (
        <div className="des">
          <h5>MainTrend</h5>
          <p>{productifo.clothing?.MainTrend}</p>
        </div>
      )}
      {productifo.clothing?.Ornamentation !== "" && (
        <div className="des">
          <h5>Ornamentation</h5>
          <p>{productifo.clothing?.Ornamentation}</p>
        </div>
      )}
      {productifo.clothing?.Border !== "" && (
        <div className="des">
          <h5>Border</h5>
          <p>{productifo.clothing?.Border}</p>
        </div>
      )}
      {productifo.clothing?.DupattaBorder !== "" && (
        <div className="des">
          <h5>Dupatta Border</h5>
          <p>{productifo.clothing?.DupattaBorder}</p>
        </div>
      )}
      {productifo.clothing?.blouseBorder !== "" && (
        <div className="des">
          <h5>blouse Border</h5>
          <p>{productifo.clothing?.blouseBorder}</p>
        </div>
      )}
      {productifo.clothing?.Blouse !== "" && (
        <div className="des">
          <h5>Blouse</h5>
          <p>{productifo.clothing?.Blouse}</p>
        </div>
      )}
      {productifo.clothing?.Fabric !== "" && (
        <div className="des">
          <h5>Fabric</h5>
          <p>{productifo.clothing?.Fabric}</p>
        </div>
      )}
      {productifo.clothing?.BlouseFabric !== "" && (
        <div className="des">
          <h5>Blouse Fabric</h5>
          <p>{productifo.clothing?.BlouseFabric}</p>
        </div>
      )}
      {productifo.clothing?.dupattaFabric !== "" && (
        <div className="des">
          <h5>dupatta Fabric</h5>
          <p>{productifo.clothing?.dupattaFabric}</p>
        </div>
      )}
      {productifo.clothing?.BottomType !== "" && (
        <div className="des">
          <h5>Bottom Type</h5>
          <p>{productifo.clothing?.BottomType}</p>
        </div>
      )}
      {productifo.clothing?.BottomPattern !== "" && (
        <div className="des">
          <h5>Bottom Pattern</h5>
          <p>{productifo.clothing?.BottomPattern}</p>
        </div>
      )}
      {productifo.clothing?.TopPattern !== "" && (
        <div className="des">
          <h5>Top Pattern</h5>
          <p>{productifo.clothing?.TopPattern}</p>
        </div>
      )}
      {productifo.clothing?.Dupatta !== "" && (
        <div className="des">
          <h5>Dupatta</h5>
          <p>{productifo.clothing?.Dupatta}</p>
        </div>
      )}
      {productifo.clothing?.DupattaPattern !== "" && (
        <div className="des">
          <h5>Dupatta Pattern</h5>
          <p>{productifo.clothing?.DupattaPattern}</p>
        </div>
      )}
      {productifo.clothing?.topClosure !== "" && (
        <div className="des">
          <h5>top Closure</h5>
          <p>{productifo.clothing?.topClosure}</p>
        </div>
      )}
      {productifo.clothing?.DesignDetails !== "" && (
        <div className="des">
          <h5>DesignDetails</h5>
          <p>{productifo.clothing?.DesignDetails}</p>
        </div>
      )}
      {productifo.clothing?.washcare !== "" && (
        <div className="des">
          <h5>washcare</h5>
          <p>{productifo.clothing?.washcare}</p>
        </div>
      )}
      {productifo.clothing?.fit !== "" && (
        <div className="des">
          <h5>fit</h5>
          <p>{productifo.clothing?.fit}</p>
        </div>
      )}
      {productifo.clothing?.patternCoverage !== "" && (
        <div className="des">
          <h5>pattern Coverage</h5>
          <p>{productifo.clothing?.patternCoverage}</p>
        </div>
      )}
      {productifo.clothing?.multipackset !== "" && (
        <div className="des">
          <h5>multi packset</h5>
          <p>{productifo.clothing?.multipackset}</p>
        </div>
      )}
      {productifo.clothing?.sustainable !== "" && (
        <div className="des">
          <h5>sustainable</h5>
          <p>{productifo.clothing?.sustainable}</p>
        </div>
      )}
      {productifo.clothing?.Collar !== "" && (
        <div className="des">
          <h5>Collar</h5>
          <p>{productifo.clothing?.Collar}</p>
        </div>
      )}
      {productifo.clothing?.NumberofPockets !== "" && (
        <div className="des">
          <h5>Number of Pockets</h5>
          <p>{productifo.clothing?.NumberofPockets}</p>
        </div>
      )}
      {productifo.clothing?.SlitDetail !== "" && (
        <div className="des">
          <h5>Slit Detail</h5>
          <p>{productifo.clothing?.SlitDetail}</p>
        </div>
      )}
      {productifo.clothing?.distress !== "" && (
        <div className="des">
          <h5>distress</h5>
          <p>{productifo.clothing?.distress}</p>
        </div>
      )}
      {productifo.clothing?.waistRise !== "" && (
        <div className="des">
          <h5>waistRise</h5>
          <p>{productifo.clothing?.waistRise}</p>
        </div>
      )}
      {productifo.clothing?.fade !== "" && (
        <div className="des">
          <h5>fade</h5>
          <p>{productifo.clothing?.fade}</p>
        </div>
      )}
      {productifo.clothing?.shade !== "" && (
        <div className="des">
          <h5>shade</h5>
          <p>{productifo.clothing?.shade}</p>
        </div>
      )}
      {productifo.clothing?.strech !== "" && (
        <div className="des">
          <h5>strech</h5>
          <p>{productifo.clothing?.strech}</p>
        </div>
      )}
      {productifo.clothing?.reversible !== "" && (
        <div className="des">
          <h5>reversible</h5>
          <p>{productifo.clothing?.reversible}</p>
        </div>
      )}
      {productifo.clothing?.waistband !== "" && (
        <div className="des">
          <h5>waistband</h5>
          <p>{productifo.clothing?.waistband}</p>
        </div>
      )}
      {productifo.clothing?.BottomFabric !== "" && (
        <div className="des">
          <h5>Bottom Fabric</h5>
          <p>{productifo.clothing?.BottomFabric}</p>
        </div>
      )}
      {productifo.clothing?.BottomClosure !== "" && (
        <div className="des">
          <h5>Bottom Closure</h5>
          <p>{productifo.clothing?.BottomClosure}</p>
        </div>
      )}
      {productifo.clothing?.topStitch !== "" && (
        <div className="des">
          <h5>top Stitch</h5>
          <p>{productifo.clothing?.topStitch}</p>
        </div>
      )}
      {productifo.clothing?.bottomStitch !== "" && (
        <div className="des">
          <h5>bottom Stitch</h5>
          <p>{productifo.clothing?.bottomStitch}</p>
        </div>
      )}
      {productifo.clothing?.Placket !== "" && (
        <div className="des">
          <h5>Placket</h5>
          <p>{productifo.clothing?.Placket}</p>
        </div>
      )}
      {productifo.clothing?.PlacketLength !== "" && (
        <div className="des">
          <h5>Placket Length</h5>
          <p>{productifo.clothing?.PlacketLength}</p>
        </div>
      )}
      {productifo.clothing?.Placket_type !== "" && (
        <div className="des">
          <h5>Placket type</h5>
          <p>{productifo.clothing?.Placket_type}</p>
        </div>
      )}
      {productifo.clothing?.cuff !== "" && (
        <div className="des">
          <h5>cuff</h5>
          <p>{productifo.clothing?.cuff}</p>
        </div>
      )}
      {productifo.clothing?.surfaces_styling !== "" && (
        <div className="des">
          <h5>surfaces styling</h5>
          <p>{productifo.clothing?.surfaces_styling}</p>
        </div>
      )}
      {productifo.clothing?.features !== "" && (
        <div className="des">
          <h5>features</h5>
          <p>{productifo.clothing?.features}</p>
        </div>
      )}
      {productifo.clothing?.border_Fabric !== "" && (
        <div className="des">
          <h5>border_Fabric</h5>
          <p>{productifo.clothing?.border_Fabric}</p>
        </div>
      )}
      {productifo.clothing?.tyepe_of_distress !== "" && (
        <div className="des">
          <h5>tyepe_of_distress</h5>
          <p>{productifo.clothing?.tyepe_of_distress}</p>
        </div>
      )}
      {productifo.clothing?.fly_Type !== "" && (
        <div className="des">
          <h5>fly_Type</h5>
          <p>{productifo.clothing?.fly_Type}</p>
        </div>
      )}
      {productifo.clothing?.assorted !== "" && (
        <div className="des">
          <h5>assorted</h5>
          <p>{productifo.clothing?.assorted}</p>
        </div>
      )}
      {productifo.clothing?.knighr_or_woven !== "" && (
        <div className="des">
          <h5>knighr_or_woven</h5>
          <p>{productifo.clothing?.knighr_or_woven}</p>
        </div>
      )}
      {productifo.clothing?.bottom_length !== "" && (
        <div className="des">
          <h5>bottom length</h5>
          <p>{productifo.clothing?.bottom_length}</p>
        </div>
      )}
      {productifo.clothing?.bottom_border_fabric !== "" && (
        <div className="des">
          <h5>bottom_border_fabric</h5>
          <p>{productifo.clothing?.bottom_border_fabric}</p>
        </div>
      )}
      {productifo.clothing?.type_of_pleat !== "" && (
        <div className="des">
          <h5>type_of_pleat</h5>
          <p>{productifo.clothing?.type_of_pleat}</p>
        </div>
      )}
      {productifo.clothing?.front_Style !== "" && (
        <div className="des">
          <h5>front_Style</h5>
          <p>{productifo.clothing?.front_Style}</p>
        </div>
      )}
      {productifo.clothing?.padding !== "" && (
        <div className="des">
          <h5>padding</h5>
          <p>{productifo.clothing?.padding}</p>
        </div>
      )}
      {productifo.clothing?.seam !== "" && (
        <div className="des">
          <h5>seam</h5>
          <p>{productifo.clothing?.seam}</p>
        </div>
      )}
      {productifo.clothing?.colourfamily !== "" && (
        <div className="des">
          <h5>colourfamily</h5>
          <p>{productifo.clothing?.colourfamily}</p>
        </div>
      )}
      {productifo.clothing?.hexa_Code !== "" && (
        <div className="des">
          <h5>hexa_Code</h5>
          <p>{productifo.clothing?.hexa_Code}</p>
        </div>
      )}
      {productifo.clothing?.finish !== "" && (
        <div className="des">
          <h5>finish</h5>
          <p>{productifo.clothing?.finish}</p>
        </div>
      )}
      {productifo.clothing?.formulation !== "" && (
        <div className="des">
          <h5>formulation</h5>
          <p>{productifo.clothing?.formulation}</p>
        </div>
      )}
      {productifo.clothing?.preferance !== "" && (
        <div className="des">
          <h5>preferance</h5>
          <p>{productifo.clothing?.preferance}</p>
        </div>
      )}
      {productifo.clothing?.concern !== "" && (
        <div className="des">
          <h5>concern</h5>
          <p>{productifo.clothing?.concern}</p>
        </div>
      )}
      {productifo.clothing?.key_ingredient !== "" && (
        <div className="des">
          <h5>key_ingredient</h5>
          <p>{productifo.clothing?.key_ingredient}</p>
        </div>
      )}
      {productifo.clothing?.skin_type !== "" && (
        <div className="des">
          <h5>skin_type</h5>
          <p>{productifo.clothing?.skin_type}</p>
        </div>
      )}
      {productifo.clothing?.stone_Type !== "" && (
        <div className="des">
          <h5>stone_Type</h5>
          <p>{productifo.clothing?.stone_Type}</p>
        </div>
      )}
      {productifo.clothing?.puriety !== "" && (
        <div className="des">
          <h5>puriety</h5>
          <p>{productifo.clothing?.puriety}</p>
        </div>
      )}
    </div>
  );
};

export default Cloh5ingSupercat;

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Login from "./authentication/Login";
import Otp from "./authentication/Otp";
import Registation from "./authentication/Registation";

const Authentication = () => {
  const { checkout_authentication_status } = useSelector(
    (state) => state.Athentication
  );

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <Login />;
      case 1:
        return <Otp />;
      case 2:
        return <Registation />;
      default:
        return <Login />;
        break;
    }
  };

  return <>{renderSwitch(checkout_authentication_status)}</>;
};

export default Authentication;

import React from "react";

const Content1 = () => {


  const handleSubmit =(e)=>{
    e.preventDefault();

  }
  return (
    <>
      <div id="page-content">
        <div className="page-header text-center">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                <div className="breadcrumbs">
                  <span className="main-title fw-bold" style={{fontSize:"20px"}}>
                    {/* <i className="icon anm anm-angle-right-l" /> */}
                    Contact Us
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container contact-style1">
          <div className="contact-form-details section pt-0">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                <div className="formFeilds contact-form form-vertical mb-4 mb-md-0">
                  <div className="section-header">
                    <h2 style={{marginTop:"10px",color:"#1b59ba"}}>Let's Get in touch!</h2>
                  </div>
                  <form
                    // action="php/ajax_sendmail.php"
                    name="contactus"
                    // method="post"
                    id="contact-form"
                    className="contact-form"
                  >
                    <div className="form-row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            id="ContactFormName"
                            name="name"
                            className="form-control"
                            placeholder="Name"
                          />
                          <span className="error_msg" id="name_error" />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <input
                            type="email"
                            id="ContactFormEmail"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                          />
                          <span className="error_msg" id="email_error" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="tel"
                            id="ContactFormPhone"
                            name="phone"
                            pattern="[0-9\-]*"
                            placeholder="Phone Number"
                            minLength={10}
                            maxLength={10}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            id="ContactSubject"
                            name="subject"
                            className="form-control"
                            placeholder="Subject"
                          />
                          <span className="error_msg" id="subject_error" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <textarea
                            id="ContactFormMessage"
                            name="message"
                            className="form-control"
                            rows={5}
                            placeholder="Message"
                            defaultValue={""}
                          />
                          <span className="error_msg" id="message_error" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group mailsendbtn mb-0 w-100">
                          <input
                            className="btn btn-lg"
                            type="submit"
                            onClick={(e)=>{handleSubmit(e)}}
                            name="contactus"
                            defaultValue="Send Message"
                          />
                          <div className="loading">
                            <img
                              className="img-fluid"
                              src="/assets/images/icons/ajax-loader.gif"
                              alt="loading"
                              width={16}
                              height={16}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="response-msg" />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="contact-details bg-block">
                  <h3 className="mb-3 fs-5">Store information</h3>
                  <ul className="list-unstyled">
                    <li className="mb-2 address">
                      <strong className="d-block mb-2">Address :</strong>
                      <p>
                        <i className="icon anm anm-map-marker-al me-2 d-none" />{" "}
                        Ewshopping, Rajendra Palace, New Delhi, Pin -110008
                      </p>
                    </li>
                    <li className="mb-2 phone">
                      <strong>Phone :</strong>
                      <i className="icon anm anm-phone me-2 d-none" />{" "}
                      <a to="tel:+918447282606">+91 8447282606</a>
                    </li>
                    <li className="mb-0 email">
                      <strong>Email :</strong>
                      <i className="icon anm anm-envelope-l me-2 d-none" />{" "}
                      <a to="mailto:info@ewshopping.com">info@ewshopping.com</a>
                    </li>
                  </ul>
                  <hr />
                  {/* <div className="open-hours">
                                        <strong className="d-block mb-2">Opening Hours</strong>
                                        <p className="lh-lg">
                                            Mon - Sat : 9:30 AM - 6:30 PM<br />
                                            Sunday: 11:00 AM - 5:00 PM
                                        </p>
                                    </div> */}
                  <hr />
                  {/* <div className="follow-us">
                                        <label className="d-block mb-3"><strong>Stay Connected</strong></label>
                                        <ul className="list-inline social-icons">
                                            <li className="list-inline-item"><a to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook"><i className="icon anm anm-facebook-f" /></a></li>
                                            <li className="list-inline-item"><a to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Twitter"><i className="icon anm anm-twitter" /></a></li>
                                            <li className="list-inline-item"><a to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Pinterest"><i className="icon anm anm-pinterest-p" /></a></li>
                                            <li className="list-inline-item"><a to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Instagram"><i className="icon anm anm-linkedin-in" /></a></li>
                                            <li className="list-inline-item"><a to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Google plus"><i className="icon anm anm-google-plus-g" /></a></li>
                                            <li className="list-inline-item"><a to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Whatsapp"><i className="icon anm anm-whatsapp" /></a></li>
                                        </ul>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content1;

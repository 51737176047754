

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  categorytotal: localStorage.getItem("categorytotal")
    ? JSON.parse(localStorage.getItem("categorytotal")).sort((a, b) =>
      a.name > b.name ? 1 : -1
    )
    : [],
  categoryShow: localStorage.getItem("categorytotal")
    ? JSON.parse(localStorage.getItem("categorytotal")).filter((data) => data.status === true)
    : [],
  categoryLoading: true,
  isCategoryAvailable: localStorage.getItem("categorytotal")
    ? true
    : false,
};

export const getCategory = createAsyncThunk(
  "category/getCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/category/all`;
      const resp = await axios(url);
      return resp.data.category;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const validateSlugUrl = createAsyncThunk(
  "category/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/category/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);


const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.categorytotal = action.payload.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        state.categoryShow = state.categorytotal.filter((data) => data.status === true);
        localStorage.setItem("categorytotal", JSON.stringify(state.categorytotal));
        state.categoryLoading = false;
        state.isCategoryAvailable = true;

      })
      .addCase(getCategory.rejected, (state) => {
        state.categoryLoading = true;
      });
  },

});
export const { } = CategorySlice.actions;
export default CategorySlice.reducer;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";
import axios from "axios";
import { Baseurl } from "../../../config/BaseUrl";

const OrderTracking = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [orderinfo, setOrderinfo] = useState("");
  const [orderId, setOrderId] = useState("");
  const [tempLoading, settempLoading] = useState(true);

  useEffect(() => {
    const orderUrl = params.orderId;
    const fetchData = async () => {
      try {
        const url = `${Baseurl}/api/v1/order/singleorderbyclient/${orderUrl}`;
        const orderFetch = await axios.get(url);
        try {
          if (orderFetch.data.success) {
            let singleOrderData = orderFetch.data.order;
            const orderId = params.orderId.slice(18);
            setOrderinfo(singleOrderData);
            setOrderId(orderId);
            settempLoading(false);
          }
        } catch (error) {}
      } catch (error) {}
    };

    fetchData();
  }, [params.orderId]);

  return (
    <>
      {tempLoading === true ? (
        <></>
      ) : (
        <>
          <div className="container" style={{marginTop:'20px'}}>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div
                  className="dashboard-content tab-content h-100"
                  id="top-tabContent"
                >
                  <div className="tab-pane active h-100" id="orderstracking">
                    <div className="orders-card mt-0 h-100">
                      <div className="top-sec d-flex-justify-center justify-content-between mb-4">
                        <h2 className="mb-0">Orders tracking</h2>
                      </div>

                      <div className="row mt-2">
                        <div className="col-sm-12">
                          <h3>
                            Status for order no:{" "}
                            <span style={{ textTransform: "uppercase" }}>
                              {orderId}
                            </span>
                          </h3>
                          {/* Status Order */}
                          <div className="row mt-3">
                            <div className="col-lg-2 col-md-3 col-sm-4">
                              <div className="product-img mb-3 mb-sm-0">
                                <img
                                  className="rounded-0 blur-up lazyload"
                                  data-src={orderinfo.thumbnail}
                                  src={orderinfo.thumbnail}
                                  alt="product"
                                  title
                                  width={545}
                                  height={700}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-9 col-sm-8" >
                              <div className="tracking-detail d-flex-center" >
                                <ul>
                                  <li>
                                    <div className="left">
                                      <span>Order name</span>
                                    </div>
                                    <div className="right" >
                                      <span>
                                        {orderinfo.ProductName},
                                        {orderinfo.packSizeName},
                                        {orderinfo.packSizeOptionName}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="left">
                                      <span>customer number</span>
                                    </div>
                                    <div className="right">
                                      <span> {orderinfo.UserMobile}</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="left">
                                      <span>order date</span>
                                    </div>
                                    <div className="right">
                                      <span>
                                        {moment(orderinfo.createdAt).format(
                                          "DD MMM YY"
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="left">
                                      <span>Ship Date</span>
                                    </div>
                                    <div className="right">
                                      <span>
                                        {" "}
                                        {moment(
                                          orderinfo.ExpectedDelDate
                                        ).format("DD MMM YY")}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="left">
                                      <span>shipping address </span>
                                    </div>
                                    <div className="right">
                                      <h6 style={{width:'35%',color:"#666", textAlign:"justify"}}>{orderinfo.Address}</h6>
                                    </div>
                                  </li>
                                  {orderinfo.DeliveryPersonName !== "" &&
                                    orderinfo.DeliveryPersonMobile !== "" && (
                                      <>
                                        <li>
                                          <div className="left">
                                            <span>Carrier</span>
                                          </div>
                                          <div className="right">
                                            <span>
                                              {orderinfo.DeliveryPersonName}
                                            </span>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="left">
                                            <span>carrier number</span>
                                          </div>
                                          <div className="right">
                                            <span>
                                              {orderinfo.DeliveryPersonMobile}
                                            </span>
                                          </div>
                                        </li>
                                      </>
                                    )}
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 mt-4 mt-lg-0">
                              <div className="tracking-map map-section ratio ratio-16x9 h-100">
                                <iframe
                                  src="https://www.google.com/maps/embed?pb="
                                  allowFullScreen
                                  height={650}
                                />
                              </div>
                            </div>
                          </div>
                          {/* End Status Order */}
                          {/* Tracking Steps */}
                          {orderinfo.OrderStatus === 0 ? (
                            <>
                              <div className="tracking-steps nav mt-5 mb-4 clearfix">
                                <div className="step done">
                                  <span>Booked Order</span>
                                </div>
                                <div className="step current">
                                  <span>Cancelled Order</span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="tracking-steps nav mt-5 mb-4 clearfix">
                                <div
                                  className={
                                    orderinfo.OrderStatus === 1
                                      ? "step current"
                                      : orderinfo.OrderStatus > 1
                                      ? "step done"
                                      : "step "
                                  }
                                >
                                  <span>Booked Order</span>
                                </div>

                                <div
                                  className={
                                    orderinfo.OrderStatus === 2
                                      ? "step current"
                                      : orderinfo.OrderStatus > 2
                                      ? "step done"
                                      : "step"
                                  }
                                >
                                  <span>Processing Order</span>
                                </div>
                                <div
                                  className={
                                    orderinfo.OrderStatus === 3
                                      ? "step current"
                                      : orderinfo.OrderStatus > 3
                                      ? "step done"
                                      : "step"
                                  }
                                >
                                  <span>Dispached Order</span>
                                </div>
                                <div
                                  className={
                                    orderinfo.OrderStatus === 4
                                      ? "step current"
                                      : orderinfo.OrderStatus > 4
                                      ? "step done"
                                      : "step"
                                  }
                                >
                                  <span>Out for delivery</span>
                                </div>
                                <div
                                  className={
                                    orderinfo.OrderStatus === 5
                                      ? "step current"
                                      : orderinfo.OrderStatus > 5
                                      ? "step done"
                                      : "step"
                                  }
                                >
                                  <span>Order Delivered</span>
                                </div>
                              </div>
                            </>
                          )}
                          {/* End Tracking Steps */}
                          {/* Order Table */}
                          <div className="table-bottom-brd table-responsive">
                            <table className="table align-middle text-center order-table">
                              <thead>
                                <tr>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Description</th>
                                  {/* <th scope="col">Location</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {orderinfo.OrderprocessDate
                                  .OrderBookedDateShow === true && (
                                  <tr>
                                    <td>
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderBookedDate
                                      ).format("DD MMM YY")}
                                    </td>
                                    <td>
                                      {" "}
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderBookedDate
                                      ).format("h:mm a")}
                                    </td>
                                    <td>
                                      <span className="badge rounded-pill bg-success custom-badge">
                                        Booked Order
                                      </span>
                                    </td>
                                  </tr>
                                )}
                                {orderinfo.OrderprocessDate
                                  .OrderprocessingDateShow === true && (
                                  <tr>
                                    <td>
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderprocessingDate
                                      ).format("DD MMM YY")}
                                    </td>
                                    <td>
                                      {" "}
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderprocessingDate
                                      ).format("h:mm a")}
                                    </td>
                                    <td>
                                      <span className="badge rounded-pill bg-success custom-badge">
                                        Processing Order
                                      </span>
                                    </td>
                                  </tr>
                                )}
                                {orderinfo.OrderprocessDate
                                  .OrderDispatchedDateShow === true && (
                                  <tr>
                                    <td>
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderDispatchedDate
                                      ).format("DD MMM YY")}
                                    </td>
                                    <td>
                                      {" "}
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderDispatchedDate
                                      ).format("h:mm a")}
                                    </td>
                                    <td>
                                      <span className="badge rounded-pill bg-success custom-badge">
                                        Dispached Order
                                      </span>
                                    </td>
                                  </tr>
                                )}
                                {orderinfo.OrderprocessDate
                                  .OutfordeliveryDateShow === true && (
                                  <tr>
                                    <td>
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OutfordeliveryDate
                                      ).format("DD MMM YY")}
                                    </td>
                                    <td>
                                      {" "}
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OutfordeliveryDate
                                      ).format("h:mm a")}
                                    </td>
                                    <td>
                                      <span className="badge rounded-pill bg-success custom-badge">
                                        Out for delivery
                                      </span>
                                    </td>
                                  </tr>
                                )}
                                {orderinfo.OrderprocessDate
                                  .OrderDeliveredDateShow === true && (
                                  <tr>
                                    <td>
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderDeliveredDate
                                      ).format("DD MMM YY")}
                                    </td>
                                    <td>
                                      {" "}
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderDeliveredDate
                                      ).format("h:mm a")}
                                    </td>
                                    <td>
                                      <span className="badge rounded-pill bg-success custom-badge">
                                        Order Delivered
                                      </span>
                                    </td>
                                  </tr>
                                )}
                                {orderinfo.OrderprocessDate
                                  .OrderCancelledDateShow === true && (
                                  <tr>
                                    <td>
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderCancelledDate
                                      ).format("DD MMM YY")}
                                    </td>
                                    <td>
                                      {" "}
                                      {moment(
                                        orderinfo.OrderprocessDate
                                          .OrderCancelledDate
                                      ).format("h:mm a")}
                                    </td>
                                    <td>
                                      <span className="badge rounded-pill bg-success custom-badge">
                                        Cancelled Order
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          {/* End Order Table */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div style={{marginBottom:"50px"}}></div>
        </>
      )}
    </>
  );
};

export default OrderTracking;

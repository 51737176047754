import React from "react";

const Profile = () => {
  return (
    <>
      <div className="tab-pane fade h-100" id="profile">
        <div className="profile-card mt-0 h-100">
          <div className="top-sec d-flex-justify-center justify-content-between mb-4">
            <h2 className="mb-0">Profile</h2>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#editProfileModal"
            >
              <i className="icon anm anm-plus-r me-1" />
              Edit
            </button>
          </div>
          <div className="profile-book-section mb-4">
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Company name</h6>
              </div>
              <div className="right">
                <p>Fashion Store</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Email address</h6>
              </div>
              <div className="right">
                <p>info@example.com</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Phone number</h6>
              </div>
              <div className="right">
                <p>(+40) 123 456 7890</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">City / State</h6>
              </div>
              <div className="right">
                <p>California, Barbados</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Country / Region</h6>
              </div>
              <div className="right">
                <p>United States Of America</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Street address</h6>
              </div>
              <div className="right">
                <p>The Company Name Inc</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Zip code</h6>
              </div>
              <div className="right">
                <p>2356830</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Category</h6>
              </div>
              <div className="right">
                <p>Clothing</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Year established</h6>
              </div>
              <div className="right">
                <p>2023</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Total employees</h6>
              </div>
              <div className="right">
                <p>58 - 100 People</p>
              </div>
            </div>
          </div>
          {/* Edit Profile Modal */}
          <div
            className="modal fade"
            id="editProfileModal"
            tabIndex={-1}
            aria-labelledby="editProfileModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title" id="editProfileModalLabel">
                    Edit Profile details
                  </h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form className="edit-profile-from" method="post" action="#">
                    <div className="form-row">
                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                        <div className="profileImg img-thumbnail shadow bg-white rounded-circle d-flex-justify-center position-relative mx-auto">
                          <img
                            src="/assets/images/users/user-img3.jpg"
                            className="rounded-circle"
                            alt="profile"
                            width={200}
                            height={200}
                          />
                          <div className="thumb-edit">
                            <label
                              htmlFor="profileUpload"
                              className="d-flex-center justify-content-center position-absolute top-0 start-100 translate-middle p-2 rounded-circle shadow btn btn-secondary mt-3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="icon anm anm-pencil-ar an-1x" />
                            </label>
                            <input
                              type="file"
                              id="profileUpload"
                              className="image-upload d-none"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label
                          htmlFor="editProfile-Companyname"
                          className="d-none"
                        >
                          Company name
                        </label>
                        <input
                          name="editProfile-Companyname"
                          placeholder="Company name"
                          defaultValue
                          id="editProfile-Companyname"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label
                          htmlFor="editProfile-Emailaddress"
                          className="d-none"
                        >
                          Email address
                        </label>
                        <input
                          name="editProfile-Emailaddress"
                          placeholder="Email address"
                          defaultValue
                          id="editProfile-Emailaddress"
                          type="email"
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label
                          htmlFor="editProfile-Phonenumber"
                          className="d-none"
                        >
                          Phone number
                        </label>
                        <input
                          name="editProfile-Phonenumber"
                          placeholder="Phone number"
                          defaultValue
                          id="editProfile-Phonenumber"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label htmlFor="editProfile-zone" className="d-none">
                          City / State <span className="required">*</span>
                        </label>
                        <select
                          name="editProfile_zone_id"
                          id="editProfile-zone"
                        >
                          <option value>Select Region / State</option>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                        </select>
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label htmlFor="editProfile-country" className="d-none">
                          Country / Region <span className="required">*</span>
                        </label>
                        <select
                          name="editProfile_country_id"
                          id="editProfile-country"
                        >
                          <option value>Select Country / Region</option>
                          <option value="AI" label="Anguilla">
                            Anguilla
                          </option>
                          <option value="AG" label="Antigua and Barbuda">
                            Antigua and Barbuda
                          </option>
                          <option value="AR" label="Argentina">
                            Argentina
                          </option>
                          <option value="AW" label="Aruba">
                            Aruba
                          </option>
                          <option value="BS" label="Bahamas">
                            Bahamas
                          </option>
                          <option value="BB" label="Barbados">
                            Barbados
                          </option>
                          <option value="BZ" label="Belize">
                            Belize
                          </option>
                          <option value="BM" label="Bermuda">
                            Bermuda
                          </option>
                          <option value="BO" label="Bolivia">
                            Bolivia
                          </option>
                          <option value="BR" label="Brazil">
                            Brazil
                          </option>
                        </select>
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label
                          htmlFor="editProfile-Streetaddress"
                          className="d-none"
                        >
                          Street address
                        </label>
                        <input
                          name="editProfile-Streetaddress"
                          placeholder="Street address"
                          defaultValue
                          id="editProfile-Streetaddress"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label htmlFor="editProfile-Zipcode" className="d-none">
                          Zip code
                        </label>
                        <input
                          name="editProfile-Zipcode"
                          placeholder="Zip code"
                          defaultValue
                          id="editProfile-Zipcode"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12">
                        <label
                          htmlFor="editProfile-Category"
                          className="d-none"
                        >
                          Category
                        </label>
                        <input
                          name="editProfile-Category"
                          placeholder="Phone number"
                          defaultValue
                          id="editProfile-Category"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 mb-md-0">
                        <label
                          htmlFor="editProfile-YearEstablished"
                          className="d-none"
                        >
                          Year Established
                        </label>
                        <input
                          name="editProfile-YearEstablished"
                          placeholder="YearEstablished"
                          defaultValue
                          id="editProfile-YearEstablished"
                          type="text"
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-12 mb-0">
                        <label
                          htmlFor="editProfile-TotalEmployees"
                          className="d-none"
                        >
                          Zip code
                        </label>
                        <input
                          name="editProfile-TotalEmployees"
                          placeholder="Zip code"
                          defaultValue
                          id="editProfile-TotalEmployees"
                          type="text"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer justify-content-center">
                  <button type="submit" className="btn btn-primary m-0">
                    <span>Save Profile</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End Edit Profile Modal */}
          <div className="top-sec d-flex-justify-center justify-content-between mb-4">
            <h2 className="mb-0">Login details</h2>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#editLoginModal"
            >
              <i className="icon anm anm-plus-r me-1" />
              Edit
            </button>
          </div>
          <div className="profile-login-section">
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Email address</h6>
              </div>
              <div className="right">
                <p>info@example.com</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Phone number</h6>
              </div>
              <div className="right">
                <p>(+40) 123 456 7890</p>
              </div>
            </div>
            <div className="details d-flex align-items-center mb-2">
              <div className="left">
                <h6 className="mb-0 body-font fw-500">Password</h6>
              </div>
              <div className="right">
                <p>xxxxxxx</p>
              </div>
            </div>
          </div>
          {/* Edit Login details Modal */}
          <div
            className="modal fade"
            id="editLoginModal"
            tabIndex={-1}
            aria-labelledby="editLoginModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title" id="editLoginModalLabel">
                    Edit Login details
                  </h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form
                    className="edit-Loginprofile-from"
                    method="post"
                    action="#"
                  >
                    <div className="form-row row-cols-lg-1 row-cols-md-1 row-cols-sm-1 row-cols-1">
                      <div className="form-group">
                        <label
                          htmlFor="editLogin-Emailaddress"
                          className="d-none"
                        >
                          Email address <span className="required">*</span>
                        </label>
                        <input
                          name="editLogin-Emailaddress"
                          placeholder="Email address"
                          defaultValue
                          id="editLogin-Emailaddress"
                          type="email"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="editLogin-Phonenumber"
                          className="d-none"
                        >
                          Phone number <span className="required">*</span>
                        </label>
                        <input
                          name="editLogin-Phonenumber"
                          placeholder="Phone number"
                          defaultValue
                          id="editLogin-Phonenumber"
                          type="text"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="editLogin-Password" className="d-none">
                          Current Password <span className="required">*</span>
                        </label>
                        <input
                          name="editLogin-Password"
                          placeholder="Current Password"
                          defaultValue
                          id="editLogin-Password"
                          type="password"
                        />
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="editLogin-NewPassword"
                          className="d-none"
                        >
                          New Password <span className="required">*</span>
                        </label>
                        <input
                          name="editLogin-NewPassword"
                          placeholder="New Password"
                          defaultValue
                          id="editLogin-NewPassword"
                          type="password"
                        />
                        <small className="form-text text-muted">
                          Your password must be 8-20 characters long, contain
                          letters and numbers, and must not contain spaces,
                          special characters.
                        </small>
                      </div>
                      <div className="form-group mb-0">
                        <label htmlFor="editLogin-Verify" className="d-none">
                          Verify
                          <span className="required">*</span>
                        </label>
                        <input
                          name="editLogin-Verify"
                          placeholder="Verify"
                          defaultValue
                          id="editLogin-Verify"
                          type="text"
                        />
                        <small className="form-text text-muted">
                          To confirm, type the new password again.
                        </small>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer justify-content-center">
                  <button type="submit" className="btn btn-primary m-0">
                    <span>Save changes</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* End Edit Login details Modal */}
        </div>
      </div>
    </>
  );
};

export default Profile;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  romanTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "3XS" },
    { label: "XXS" },
    { label: "XS" },
    { label: "XS/S" },
    { label: "S" },
    { label: "XS/M" },
    { label: "S/M" },
    { label: "M" },
    { label: "M/L" },
    { label: "L" },
    { label: "S/L" },
    { label: "L/XL" },
    { label: "XL" },
    { label: "XL/XXL" },
    { label: "XXL" },
    { label: "3XL" },
    { label: "4XL" },
    { label: "4XL/5XL" },
    { label: "5XL" },
    { label: "6XL" },
    { label: "7XL" },
    { label: "8XL" },
    { label: "9XL" },
    { label: "10XL" },
  ],

  yearsTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "1-2Y" },
    { label: "2-3Y" },
    { label: "3-4Y" },
    { label: "4-5Y" },
    { label: "5-6Y" },
    { label: "6-7Y" },
    { label: "7-8Y" },
    { label: "8-9Y" },
    { label: "9-10Y" },
    { label: "10-11Y" },
    { label: "11-12Y" },
    { label: "12-13Y" },
    { label: "13-14Y" },
    { label: "14-15Y" },
    { label: "15-16Y" },
  ],
  RegularTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "24" },
    { label: "25" },
    { label: "26" },
    { label: "27" },
    { label: "28" },
    { label: "29" },
    { label: "30" },
    { label: "31" },
    { label: "32" },
    { label: "33" },
    { label: "34" },
    { label: "35" },
    { label: "36" },
    { label: "37" },
    { label: "38" },
    { label: "39" },
    { label: "40" },
    { label: "41" },
    { label: "42" },
    { label: "43" },
    { label: "44" },
    { label: "45" },
    { label: "46" },
    { label: "47" },
    { label: "48" },
    { label: "49" },
    { label: "50" },
    { label: "51" },
    { label: "52" },
  ],
  innerTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "28A" },
    { label: "28AA" },
    { label: "28B" },
    { label: "28C" },
    { label: "28D" },
    { label: "28E" },
    { label: "30A" },
    { label: "30AA" },
    { label: "30B" },
    { label: "30C" },
    { label: "30D" },
    { label: "30DD" },
    { label: "30E" },
    { label: "30F" },
    { label: "30FF" },
    { label: "30G" },
    { label: "30J" },
    { label: "30JJ" },
    { label: "30K" },
    { label: "32A" },
    { label: "32AA" },
    { label: "32B" },
    { label: "32C" },
    { label: "32D" },
    { label: "32DD" },
    { label: "32DDD" },
    { label: "32E" },
    { label: "32F" },
    { label: "32FF" },
    { label: "32G" },
    { label: "32H" },
    { label: "32J" },
    { label: "32JJ" },
    { label: "32K" },
    { label: "32Z" },
    { label: "34A" },
    { label: "34B" },
    { label: "34C" },
    { label: "34D" },
    { label: "34DD" },
    { label: "34DDD" },
    { label: "34E" },
    { label: "34F" },
    { label: "34FF" },
    { label: "34G" },
    { label: "34GG" },
    { label: "34H" },
    { label: "34JJ" },
    { label: "34K" },
    { label: "34Z" },
    { label: "36A" },
    { label: "36B" },
    { label: "36C" },
    { label: "36D" },
    { label: "36DD" },
    { label: "36DDD" },
    { label: "36E" },
    { label: "36F" },
    { label: "36FF" },
    { label: "36G" },
    { label: "36GG" },
    { label: "36H" },
    { label: "36J" },
    { label: "36JJ" },
    { label: "36K" },
    { label: "36Z" },
    { label: "38A" },
    { label: "38B" },
    { label: "38C" },
    { label: "38D" },
    { label: "38DD" },
    { label: "38E" },
    { label: "38F" },
    { label: "38G" },
    { label: "38H" },
    { label: "38HH" },
    { label: "38J" },
    { label: "38JJ" },
    { label: "38K" },
    { label: "38Z" },
    { label: "39B" },
    { label: "40A" },
    { label: "40B" },
    { label: "40C" },
    { label: "40D" },
    { label: "40DD" },
    { label: "40E" },
    { label: "40F" },
    { label: "40FF" },
    { label: "40G" },
    { label: "40GG" },
    { label: "40H" },
    { label: "40HH" },
    { label: "40JJ" },
    { label: "40K" },
    { label: "40Z" },
    { label: "42A" },
    { label: "42B" },
    { label: "42C" },
    { label: "42D" },
    { label: "42DD" },
    { label: "42E" },
    { label: "42F" },
    { label: "42FF" },
    { label: "42G" },
    { label: "42GG" },
    { label: "42H" },
    { label: "42Z" },
    { label: "43B" },
    { label: "44A" },
    { label: "44B" },
    { label: "44C" },
    { label: "44D" },
    { label: "44DD" },
    { label: "44E" },
    { label: "44F" },
    { label: "44FF" },
    { label: "44G" },
    { label: "44GG" },
    { label: "44H" },
    { label: "44Z" },
    { label: "46A" },
    { label: "46B" },
    { label: "46C" },
    { label: "46D" },
    { label: "46DD" },
    { label: "46E" },
    { label: "46F" },
    { label: "46G" },
    { label: "48A" },
    { label: "48B" },
    { label: "48C" },
    { label: "48D" },
    { label: "48DD" },
    { label: "48E" },
    { label: "50A" },
    { label: "50B" },
    { label: "50C" },
    { label: "50D" },
    { label: "50DD" },
    { label: "50E" },
  ],

  sleevestype: [
    { label: "3/4 Sleeve" },
    { label: "Full Sleeve" },
    { label: "Half Sleeve" },
    { label: "Short Sleeve" },
    { label: "Sleeveless" },
  ],

  sleeve_styles: [
    { label: "Set-In Sleeve" },
    { label: "Raglan Sleeve" },
    { label: "Kimono Sleeve" },
    { label: "Bishop Sleeve" },
    { label: "Bell Sleeve" },
    { label: "Puff Sleeve" },
    { label: "Cold Shoulder Sleeve" },
    { label: "Off-the-Shoulder Sleeve" },
    { label: "Cape Sleeve" },
  ],
  top_shapes: [
    { label: "T-shirt" },
    { label: "Blouse" },
    { label: "Tank Top" },
    { label: "Crop Top" },
    { label: "Peplum Top" },
    { label: "Off-the-Shoulder Top" },
    { label: "Wrap Top" },
    { label: "Halter Top" },
    { label: "Bodysuit" },
    { label: "Tube Top" },
    { label: "High-Low Top" },
    { label: "Poncho Top" },
  ],
  top_types: [
    { label: "Indo-Western Suits" },
    { label: "Saree" },
    { label: "Salwar Kameez" },
    { label: "Anarkali Suit" },
    { label: "Patiala Suit" },
    { label: "Palazzo Suit" },
    { label: "Kurti with Cigarette Pants" },
    { label: "Cape top Saree" },
    { label: "Lehengas" },
    { label: "Kurti and Churidar" },
    { label: "Women’s Sherwani" },
    { label: "Indian Gowns" },
    { label: "Lancha" },
    { label: "Sharara and Gharara Suits" },
    { label: "Maxi Gown" },
    { label: "Straight" },
    { label: "Denim Jacket" },
  ],
  dress_designs: [
    { label: "A-line Dress" },
    { label: "Shift Dress" },
    { label: "Sheath Dress" },
    { label: "Wrap Dress" },
    { label: "Fit and Flare Dress" },
    { label: "Maxi Dress" },
    { label: "Bodycon Dress" },
    { label: "High-Low Dress" },
    { label: "Mermaid Dress" },
    { label: "Empire Waist Dress" },
    { label: "T-shirt Dress" },
    { label: "Pinafore Dress" },
  ],

  necktype: [
    { label: "Asymmetrical" },
    { label: "Boat neckline" },
    { label: "Collar" },
    { label: "Cowl neck" },
    { label: "Halter neckline" },
    { label: "Illusion" },
    { label: "Jewel" },
    { label: "Keyhole neckline" },
    { label: "Off-the-shoulder" },
    { label: "One shoulder" },
    { label: "Plunging" },
    { label: "Queen Anne" },
    { label: "Round neck or Crew neck" },
    { label: "Scalloped neckline" },
    { label: "Scoop neckline" },
    { label: "Spaghetti straps" },
    { label: "Split Crew neck" },
    { label: "Square neck" },
    { label: "Strapless" },
    { label: "Surplice" },
    { label: "Sweetheart neckline" },
    { label: "Tie Bow neckline" },
    { label: "Turtleneck (High Neckline)" },
    { label: "U-Shaped neckline" },
    { label: "V-neck" },
  ],
  pritsandPatterns: [
    { label: "Animal Print" },
    { label: "Applique" },
    { label: "Blocked Printed" },
    { label: "Checkered" },
    { label: "Color Block" },
    { label: "Digital Print" },
    { label: "Dyed" },
    { label: "Embellished" },
    { label: "Embroidered" },
    { label: "Floral Print" },
    { label: "Geometric Print" },
    { label: "Graphic Print" },
    { label: "Hand Painted" },
    { label: "Ombre" },
    { label: "Paisley" },
    { label: "Polka Print" },
    { label: "Printed" },
    { label: "Self Design" },
    { label: "Solid/Plain" },
    { label: "Striped" },
    { label: "Temple Border" },
    { label: "Woven" },
    { label: "Yoke Design" },
  ],
  slit_styles: [
    { label: "Side mini slit" },
    { label: "Multiple slit" },
    { label: "Back high slit" },
    { label: "Back mini slit" },
    { label: "Front mini slit" },
    { label: "Front high slit" },
    { label: "M-slit/Double slit" },
    { label: "Waist slit" },
    { label: "Ruche slit" },
    { label: "Flouncing slit" },
    { label: "Asymmetrical slit" },
    { label: "Symmetrical slit" },
    { label: "Lace up slit" },
    { label: "Tape slit" },
  ],
  length_styles: [
    { label: "ankle-length" },
    { label: "knee-length" },
    { label: "capri" },
    { label: "mid-calf" },
    { label: "stirrup" },
    { label: "athleisure" },
    { label: "faux-leather" },
    { label: "footed" },
    { label: "rugged" },
    { label: "woolen" },
    { label: "Regular" },
    { label: "Calf Length" },
    { label: "Floor Length" },
  ],
  hemline_styles: [
    { label: "A-line Hem" },
    { label: "Asymmetrical Hemline" },
    { label: "Bubble Hem" },
    { label: "Fishtail hem" },
    { label: "Flared hemline" },
    { label: "Handkerchief hem" },
    { label: "High-Low hem" },
    { label: "Raw edge hem" },
    { label: "Ruffled hemline" },
    { label: "Sharkbite hem" },
    { label: "Shirt tail hemline" },
    { label: "Slant hemline" },
    { label: "Slit hemline" },
    { label: "Step hem" },
    { label: "Tiered Bottom Hem" },
  ],
  occasion_styles: [
    { label: "Casual" },
    { label: "Party & Festive" },
    { label: "Wedding" },
    { label: "Wedding & Festive" },
    { label: "Ethnic" },
    { label: "Festive" },
  ],
  fabric_types: [
    { label: "Art Silk" },
    { label: "Brasso" },
    { label: "Chanderi" },
    { label: "Chiffon" },
    { label: "Cotton Blend" },
    { label: "Cotton Jute" },
    { label: "Cotton Linen" },
    { label: "Cotton Silk" },
    { label: "Crepe" },
    { label: "Dupion Silk" },
    { label: "Georgette" },
    { label: "Jacquard" },
    { label: "Jute Silk" },
    { label: "Lace" },
    { label: "Linen" },
    { label: "Lycra Blend" },
    { label: "Muslin" },
    { label: "Net" },
    { label: "Nylon" },
    { label: "Organza" },
    { label: "Polyester" },
    { label: "Pure Cotton" },
    { label: "Pure Silk" },
    { label: "Raw Silk" },
    { label: "Satin" },
    { label: "Silk Blend" },
    { label: "Supernet" },
    { label: "Tissue" },
    { label: "Tussar Silk" },
    { label: "Velvet" },
    { label: "Elastane" },
    { label: "Cotton Rayon" },
    { label: "Viscose Rayon" },
    { label: "Acrylic Blend" },
    { label: "Blended Cotton" },
    { label: "Khadi Cotton" },
    { label: "Khadi Silk" },
    { label: "Hemp" },
    { label: "Hosiery" },
    { label: "Modal" },
    { label: "Polycotton" },
    { label: "Pure Linen" },
    { label: "Pure Wool" },
    { label: "Rayon" },
    { label: "Wool" },
    { label: "Wool Blend" },
  ],
  saree_types: [
    { label: "Arani Pattu" },
    { label: "Assam Silk" },
    { label: "Baluchari" },
    { label: "Banarasi" },
    { label: "Bandhani" },
    { label: "Berhampuri" },
    { label: "Bhagalpuri" },
    { label: "Bollywood" },
    { label: "Chanderi" },
    { label: "Chettinadu" },
    { label: "Chinnalapattu" },
    { label: "Daily Wear" },
    { label: "Dharmavaram" },
    { label: "Dokhona" },
    { label: "Fashion" },
    { label: "Gadwal" },
    { label: "Garad" },
    { label: "Hand Batik" },
    { label: "Handloom" },
    { label: "Ikkat" },
    { label: "Ilkal" },
    { label: "Jamdani" },
    { label: "Kalamkari" },
    { label: "Kanjivaram" },
    { label: "Kantha" },
    { label: "Kasavu" },
    { label: "Konrad" },
    { label: "Kota Doria" },
    { label: "Kovai" },
    { label: "Leheria" },
    { label: "Lucknow Chikankari" },
    { label: "Madhubani" },
    { label: "Madurai" },
    { label: "Maheshwari" },
    { label: "Mangalagiri" },
    { label: "Manipuri" },
    { label: "Mekhela Chador" },
    { label: "Mundum Neriyathum" },
    { label: "Murshidabad" },
    { label: "Mysore" },
    { label: "Narayanpet" },
    { label: "Paithani" },
    { label: "Patola" },
    { label: "Pochampally" },
    { label: "Sambalpuri" },
    { label: "Tant" },
    { label: "Venkatagiri" },
    { label: "Regular" },
  ],
  saree_styles: [
    { label: "9 Yards Sari" },
    { label: "Half & Half Sari" },
    { label: "Lehenga Sari" },
    { label: "Ready to Wear" },
    { label: "Regular Sari" },
    { label: "Stitched Sari" },
  ],
  blouse_styles: [{ label: "Blouse Piece" }, { label: "Unstitched" }],
  saree_border_styles: [
    { label: "Embellished" },
    { label: "Temple Saree Border" },
    { label: "Contrast Saree Border" },
    { label: "Mirror work Saree Border" },
    { label: "Scallop Saree Border" },
    { label: "Check Saree Border" },
    { label: "Velvet Saree Border" },
    { label: "Floral Saree Border" },
    { label: "Taping" },
  ],
  embellishment_types: [
    { label: "lace" },
    { label: "stone" },
    { label: "sequins" },
    { label: "beads" },
    { label: "ribbon" },
    { label: "metallic trims" },
    { label: "Gotta Patti" },
  ],
  fabric_care_instructions: [
    { fabric: "Acetate", label: "Hand Wash and Air Dry." },
    { fabric: "Cotton", label: "Hot Water and Warm Tumble Dry." },
    { fabric: "Linen", label: "Cool Water and Air Dry." },
    { fabric: "Polyester", label: "Cool Wash and Tumble Dry or Air Dry." },
    { fabric: "Silk", label: "Hand Wash/Cool and Cool Tumble Dry or Air Dry." },
    { fabric: "Wool", label: "Hand Wash and Air Dry." },
  ],
  kurti_styles: [
    { label: "Anarkali Kurti" },
    { label: "Straight Cut Kurti" },
    { label: "A-Line Kurti" },
    { label: "Lace Kurti" },
    { label: "Asymmetrical Kurti" },
    { label: "Kaftan Style Kurti" },
    { label: "Shirt Collar Kurti" },
    { label: "Overlay Kurti" },
    { label: "Jacket Style Kurti" },
    { label: "Cape Style Kurti" },
    { label: "Indo-Western Kurti" },
    { label: "Floor Length/Gown Style Kurti" },
    { label: "Color Block Kurti" },
    { label: "Printed Kurti" },
    { label: "Denim Kurti" },
    { label: "Chikankari Kurti" },
    { label: "Angrakha Kurti" },
    { label: "Dhoti Style Kurti" },
    { label: "Layered Kurti" },
    { label: "Flared Kurti" },
    { label: "Tie Dye Kurti" },
    { label: "Bandhani Kurti" },
    { label: "Kashmiri Kurti" },
    { label: "Gathered Kurti" },
    { label: "Peplum Kurti" },
    { label: "Tiered Kurti" },
    { label: "Boat Neck Kurti" },
    { label: "Embroidered Kurta" },
    { label: "Ikat Kurti" },
    { label: "Short Kurtis" },
    { label: "Cowel Neck Kurti" },
  ],
  bottom_styles_with_kurti: [
    { label: "Patiala Pants" },
    { label: "Palazzo or Parallel Pants" },
    { label: "Churidar Bottoms" },
    { label: "Straight Pants" },
    { label: "Sharara Pants" },
    { label: "Salwar" },
    { label: "Dhoti Pants" },
    { label: "Cigarette Pants" },
    { label: "Flared Skirt" },
  ],
  stitching_options: [{ label: "Stitched" }, { label: "Unstitched" }],
  Dupattaavailable: [{ label: "With Dupatta" }, { label: "Without Dupatta" }],
  Blouseavailable: [{ label: "With Blouse" }, { label: "Without Blouse" }],

  fastening_types: [
    { label: "buttons" },
    { label: "snap fasteners" },
    { label: "zippers" },
    { label: "toggle fasteners" },
    { label: "loop closures" },
  ],
  wave_patterns: [
    { label: "Horizontal Waves" },
    { label: "Vertical Waves" },
    { label: "Diagonal Waves" },
    { label: "Chevron Waves" },
    { label: "Curly Waves" },
    { label: "Abstract Waves" },
    { label: "Ocean Waves" },
    { label: "Ripple Waves" },
    { label: "Geometric Waves" },
    { label: "Floral Waves" },
    { label: "Tribal Waves" },
    { label: "Gradient Waves" },
  ],
  wave_types: [
    { label: "Sine Wave" },
    { label: "Sinusoidal Wave" },
    { label: "Square Wave" },
    { label: "Sawtooth Wave" },
    { label: "Triangle Wave" },
    { label: "Pulse Wave" },
    { label: "Cosine Wave" },
    { label: "Pulse Train" },
    { label: "Square Pulse Train" },
    { label: "Damped Wave" },
    { label: "Standing Wave" },
  ],
  pattern_coverage: [
    { label: "All-Over Print" },
    { label: "Placement Print" },
    { label: "Border Print" },
    { label: "Scattered Print" },
    { label: "Stripe Print" },
    { label: "Geometric Print" },
    { label: "Floral Print" },
    { label: "Animal Print" },
    { label: "Abstract Print" },
    { label: "Tribal Print" },
    { label: "Polka Dot Print" },
    { label: "Check Print" },
  ],
  no_of_item: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],
  transparency_levels: [
    { label: "Opaque" },
    { label: "Semi-Transparent" },
    { label: "Sheer" },
    { label: "Mesh" },
    { label: "Lace" },
    { label: "Chiffon" },
    { label: "Organza" },
    { label: "Tulle" },
    { label: "Voile" },
    { label: "Gauze" },
    { label: "Burnout" },
    { label: "Netting" },
  ],
  main_trends: [
    { label: "Bohemian" },
    { label: "Minimalist" },
    { label: "Athleisure" },
    { label: "Vintage-Inspired" },
    { label: "Eclectic" },
    { label: "Feminine" },
    { label: "Streetwear" },
    { label: "Sustainable Fashion" },
    { label: "Modern Classic" },
    { label: "Avant-Garde" },
    { label: "Global-Inspired" },
    { label: "Techwear" },
  ],

  //electronics
  washing_machine_types: [
    { label: "Fully automatic front load" },
    { label: "Semi automatic top load" },
    { label: "Fully automatic top load" },
  ],
  Washing_modes: [{ label: "Gentle wash" }, { label: "Pulsator wash" }],
  yes_r_no: [{ label: "Yes" }, { label: "No" }],
  Tub_materials: [
    { label: "Stainless Steel Tub" },
    { label: "Porcelain-Coated Steel Tub" },
    { label: "Plastic Tub" },
  ],
  Energy_Rating: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Maximum_Spin_Speed: [
    { label: "400" },
    { label: "500" },
    { label: "600" },
    { label: "700" },
    { label: "800" },
    { label: "900" },
    { label: "1000" },
    { label: "1100" },
    { label: "1200" },
    { label: "1300" },
    { label: "1400" },
    { label: "1500" },
    { label: "1600" },
  ],
  Washing_Capacity: [
    { label: "1" },
    { label: "1.5" },
    { label: "2" },
    { label: "2.5" },
    { label: "3" },
    { label: "3.5" },
    { label: "4" },
    { label: "4.5" },
    { label: "5" },
    { label: "5.5" },
    { label: "6" },
    { label: "6.5" },
    { label: "7" },
    { label: "7.5" },
    { label: "8" },
    { label: "8.5" },
    { label: "9" },
    { label: "9.5" },
    { label: "10" },
    { label: "10.5" },
    { label: "11" },
    { label: "11.5" },
    { label: "12" },
    { label: "12.5" },
    { label: "13" },
  ],
  Dryer_Capacity: [
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],
  Wash_program_types: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],

  dryer_types: [
    { label: "Vented Dryer" },
    { label: "Condenser Dryer" },
    { label: "Heat Pump Dryer" },
    { label: "Gas Dryer" },
    { label: "Compact Dryer" },
    { label: "Stackable Dryer" },
    { label: "Portable Dryer" },
    { label: "Electric Dryer" },
  ],
  Outer_body_materials: [
    { label: "Plastic" },
    { label: "Stainless Steel" },
    { label: "Enamel-Coated Steel" },
  ],
  power_ratings: [
    { label: "300" },
    { label: "750" },
    { label: "1400" },
    { label: "1500" },
    { label: "2000" },
    { label: "2250" },
    { label: "2500" },
    { label: "3000" },
    { label: "3500" },
    { label: "4000" },
    { label: "4500" },
    { label: "5000" },
    { label: "5500" },
  ],
  sound_levels: [
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
    { label: "75" },
    { label: "80" },
  ],
  Width: [{ label: "60" }, { label: "65" }, { label: "70" }, { label: "75" }],
  height: [
    { label: "80" },
    { label: "85" },
    { label: "90" },
    { label: "95" },
    { label: "100" },
    { label: "105" },
    { label: "110" },
    { label: "115" },
    { label: "120" },
  ],
  weight: [
    { label: "5" },
    { label: "10" },
    { label: "15" },
    { label: "20" },
    { label: "25" },
    { label: "30" },
    { label: "35" },
    { label: "40" },
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
  ],
  Depth: [
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
    { label: "75" },
    { label: "80" },
    { label: "85" },
    { label: "90" },
  ],
  Spin_cycle_duration: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],

  Wash_cycle_duration: [
    { label: "20" },
    { label: "25" },
    { label: "30" },
    { label: "35" },
    { label: "40" },
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
    { label: "75" },
    { label: "80" },
    { label: "85" },
    { label: "90" },
    { label: "95" },
    { label: "100" },
    { label: "105" },
    { label: "110" },
    { label: "115" },
    { label: "120" },
  ],

  air_conditioner_types: [
    { label: "Window" },
    { label: "Split" },
    { label: "Ductless Mini-Split" },
    { label: "Central" },
    { label: "Portable" },
    { label: "Packaged Terminal" },
    { label: "Packaged" },
  ],
  Capacity_in_tons: [
    { label: "1" },
    { label: "1.5" },
    { label: "2" },
    { label: "2.5" },
    { label: "3" },
    { label: "3.5" },
    { label: "4" },
    { label: "4.5" },
    { label: "5" },
  ],
  compressor_types: [
    { label: "Reciprocating" },
    { label: "Rotary" },
    { label: "Scroll" },
    { label: "Centrifugal" },
    { label: "Inverter" },
  ],
  refrigerant_types: [
    { label: "R-410A" },
    { label: "R-22 (Freon)" },
    { label: "R-134a" },
    { label: "R-32" },
    { label: "R-407C" },
    { label: "R-290 (Propane)" },
    { label: "R-404A" },
    { label: "R-407A" },
  ],
  operating_modes: [
    { label: "Cooling Mode" },
    { label: "Heating Mode (Heat Pump Models)" },
    { label: "Fan Mode" },
    { label: "Dehumidification Mode" },
    { label: "Auto Mode" },
    { label: "Sleep Mode" },
    { label: "Eco Mode" },
    { label: "Turbo Mode" },
    { label: "Timer Mode" },
    { label: "Swing Mode" },
    { label: "Quiet Mode" },
    { label: "Smart Mode" },
    { label: "Auto Clean Mode" },
    { label: "Dry Mode (for reducing humidity without cooling)" },
    { label: "Energy Saver Mode" },
  ],
  condenser_coil_types: [
    { label: "Copper" },
    { label: "Aluminum" },
    { label: "Coated Aluminum" },
  ],
  panel_display_types: [
    { label: "LCD" },
    { label: "LED" },
    { label: "Touch screen" },
    { label: "Digital" },
    { label: "Backlit" },
    { label: "Alphanumeric" },
    { label: "Dot matrix" },
    { label: "Segment display" },
  ],
  condenser_fin_types: [
    { label: "Bare" },
    { label: "Slit" },
    { label: "Wavy" },
    { label: "Microchannel" },
    { label: "Corrugated" },
    { label: "Ripple" },
    { label: "Serrated" },
    { label: "Flat" },
    { label: "Elliptical" },
    { label: "Coated" },
  ],
  air_flow_directions: [{ label: "One-way" }, { label: "Two-way" }],
  Annual_electricity_consumption: [
    { label: "860" },
    { label: "900" },
    { label: "950" },
    { label: "980" },
    { label: "1000" },
  ],
  Operating_current: [
    { label: "5" },
    { label: "10" },
    { label: "15" },
    { label: "20" },
    { label: "25" },
    { label: "30" },
  ],
  Battery_type: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
  ],
  refrigerator_types: [
    { label: "Single Door" },
    { label: "Double Door" },
    { label: "Triple Door" },
  ],
  refrige_types: [
    { label: "Top" },
    { label: "Bottom" },
    { label: "Side-by-Side" },
    { label: "French Door" },
    { label: "Compact / Mini" },
    { label: "Built-in" },
    { label: "Counter-depth" },
    { label: "Freezerless" },
    { label: "Smart" },
    { label: "Retro / Vintage" },
  ],
  Number_of_doors: [
    { label: "Single Door" },
    { label: "Double Door" },
    { label: "Triple Door" },
  ],
  mobiles_types: [{ label: "wire" }, { label: "wireless" }],
  mobiles_Usage: [{ label: "gaming" }, { label: "entertainment" }],
  Browse_Type: [{ label: "chrome" }, { label: "safari" }],
  SIM_Type: [
    { label: "nanao" },
    { label: "micro" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
  ],
  mobile_Display_Size: [
    { label: "5.5" },
    { label: "6.0" },
    { label: "6.5" },
    { label: "5.8" },
    { label: "6.7" },
    { label: "6.3" },
    { label: "6.4" },
    { label: "6.1" },
    { label: "5.7" },
  ],
  mobile_Resolution: [
    { label: "720p" },
    { label: "1080p" },
    { label: "1440p" },
    { label: "2160p" },
    { label: "4k" },
  ],
  mobile_Gpu: [{ label: "Adreno" }, { label: "Mali" }, { label: "Qualcomm" }],
  mobile_Display_Type: [
    { label: "Lcd" },
    { label: "Oled" },
    { label: "Amoled" },
    { label: "IPS" },
    { label: "P-oled" },
    { label: "super Amoled" },
  ],
  mobile_operating_system: [
    { label: "Android" },
    { label: "iOS" },
    { label: "Qualcomm" },
  ],
  mobile_Processor_brand: [
    { label: "Qualcomm" },
    { label: "apple" },
    { label: "MediaTek" },
    { label: "Samsung" },
    { label: "Exynos" },
    { label: "snapdragon" },
  ],
  mobile_Processor_core: [
    { label: "Single-core" },
    { label: "Dual-core" },
    { label: "Quad-core" },
    { label: "Hexa-core" },
    { label: "Octa-core" },
  ],
  mobile_primary_clock_speed: [
    { label: "1 GHz" },
    { label: "1.2 GHz" },
    { label: "1.3 GHz" },
    { label: "1.4 GHz" },
    { label: "1.5 GHz" },
    { label: "2 GHz" },
    { label: "2.2 GHz" },
    { label: "2.4 GHz" },
    { label: "3 GHz" },
    { label: "3.3 GHz" },
    { label: "4 GHz" },
    { label: "5 GHz" },
  ],
  mobile_secondary_clock_speed: [
    { label: "1 GHz" },
    { label: "1.2 GHz" },
    { label: "1.3 GHz" },
    { label: "1.4 GHz" },
    { label: "1.5 GHz" },
    { label: "2 GHz" },
    { label: "2.2 GHz" },
    { label: "2.4 GHz" },
    { label: "3 GHz" },
    { label: "3.3 GHz" },
    { label: "4 GHz" },
    { label: "5 GHz" },
  ],
  mobile_tertiary_clock_speed: [
    { label: "1 GHz" },
    { label: "1.2 GHz" },
    { label: "1.3 GHz" },
    { label: "1.4 GHz" },
    { label: "1.5 GHz" },
    { label: "2 GHz" },
    { label: "2.2 GHz" },
    { label: "2.4 GHz" },
    { label: "3 GHz" },
    { label: "3.3 GHz" },
    { label: "4 GHz" },
    { label: "5 GHz" },
  ],
  Internal_storage: [
    { label: "16 GB" },
    { label: "32 GB" },
    { label: "64 GB" },
    { label: "128 GB" },
    { label: "256 GB" },
    { label: "512 GB" },
    { label: "1TB" },
  ],
  mobile_ram: [
    { label: "1 GB" },
    { label: "2 GB" },
    { label: "3 GB" },
    { label: "4 GB" },
    { label: "6 GB" },
    { label: "8 GB" },
    { label: "12 GB" },
  ],
  Total_memory: [
    { label: "16 GB" },
    { label: "32 GB" },
    { label: "64 GB" },
    { label: "128 GB" },
    { label: "256 GB" },
    { label: "512 GB" },
    { label: "1TB" },
  ],
  Secodary_camera: [
    { label: "5Mp" },
    { label: "8Mp" },
    { label: "8Mp" },
    { label: "12Mp" },
    { label: "16Mp" },
    { label: "32Mp" },
    { label: "48Mp" },
    { label: "64Mp" },
    { label: "50Mp" },
  ],
  Primary_camera: [
    { label: "5Mp" },
    { label: "8Mp" },
    { label: "12Mp" },
    { label: "12.5Mp" },
    { label: "16Mp" },
    { label: "32Mp" },
    { label: "48Mp" },
    { label: "64Mp" },
    { label: "50Mp" },
    { label: "108Mp" },
    { label: "200Mp" },
  ],
  Flash: [{ label: "Front" }, { label: "Rear" }],
  Frame_rate: [
    { label: "30 Hz" },
    { label: "60 Hz" },
    { label: "90 Hz" },
    { label: "120 Hz" },
    { label: "144 Hz" },
    { label: "240 Hz" },
    { label: "360 Hz" },
    { label: "480 Hz" },
    { label: "960 Hz" },
  ],
  Dual_lens: [{ label: "primary" }, { label: "secondary" }],
  Network_type: [
    { label: "2G" },
    { label: "3G" },
    { label: "4G VoLTE" },
    { label: "4G" },
    { label: "5G" },
  ],
  Supported_networks: [
    { label: "4G LTE" },
    { label: "4G VoLTE" },
    { label: "5G" },
    { label: "GSM" },
    { label: "WCDMA" },
  ],
  Internet_connectivity: [
    { label: "4G" },
    { label: "5G" },
    { label: "3G" },
    { label: "WIFI" },
    { label: "EDGE" },
    { label: "GPRS" },
  ],
  Bluetooth_version: [
    { label: "4" },
    { label: "4.2" },
    { label: "4.3" },
    { label: "4.4" },
    { label: "5" },
    { label: "5.2" },
    { label: "5.3" },
    { label: "5.3" },
  ],
  Map_support: [{ label: "Google Maps" }, { label: "Maps" }],
  Touchscreen_type: [
    { label: "capacitive" },
    { label: "resistive" },
    { label: "infrared" },
    { label: "SAW" },
    { label: "optical imaging" },
    { label: "P-CAP" },
    { label: "Multi-Touch" },
    { label: "single-Touch" },
  ],
  Sim_size: [
    { label: "standard sim" },
    { label: "micro sim" },
    { label: "nano sim" },
    { label: "e sim" },
  ],
  User_interface: [
    { label: "One UI" },
    { label: "Oxygen OS" },
    { label: "MIUI" },
    { label: "colorOS" },
    { label: "funtouchOS" },
    { label: "Realme UI" },
    { label: "hyperOS" },
  ],
  Sensors: [
    { label: "Proximity sensor" },
    { label: "Ambient light sensor" },
    { label: "Accelerometer" },
    { label: "Gyroscope" },
    { label: "Magnetometer" },
    { label: "Barometer" },
    { label: "Infrared sensor" },
    { label: "Face recognition sensor" },
    { label: "Heart rate sensor" },
  ],
  Browser: [{ label: "Google chrome" }, { label: "safari" }],
  GPS_type: [
    { label: "GPS" },
    { label: "AGPS" },
    { label: "GLONASS" },
    { label: "BEIDOU" },
    { label: "GALILEO" },
  ],
  Battery_capacity: [
    { label: "2000 mAh" },
    { label: "4000 mAh" },
    { label: "5000 mAh" },
    { label: "6000 mAh" },
    { label: "8000 mAh" },
    { label: "10000 mAh" },
    { label: "12000 mAh" },
  ],
  Mobile_Battery_type: [
    { label: "Lithium-ion[Li-ion]" },
    { label: "Lithium-polymer[Li-po]" },
    { label: "Nickel-metal hydrate" },
    { label: "nickel-cadmium" },
  ],
  mobile_width: [
    { label: "50mm" },
    { label: "55mm" },
    { label: "60mm" },
    { label: "65mm" },
    { label: "68mm" },
    { label: "70mm" },
    { label: "75mm" },
    { label: "80mm" },
    { label: "85mm" },
    { label: "90mm" },
    { label: "95mm" },
    { label: "100mm" },
  ],
  mobile_height: [
    { label: "70mm" },
    { label: "75mm" },
    { label: "80mm" },
    { label: "85mm" },
    { label: "90mm" },
    { label: "95mm" },
    { label: "100mm" },
    { label: "105mm" },
    { label: "110mm" },
    { label: "105mm" },
    { label: "110mm" },
    { label: "150mm" },
    { label: "160mm" },
    { label: "170mm" },
    { label: "180mm" },
    { label: "190mm" },
    { label: "200mm" },
  ],
  mobile_depth: [
    { label: "5mm" },
    { label: "8mm" },
    { label: "10mm" },
    { label: "14mm" },
    { label: "16mm" },
    { label: "12mm" },
    { label: "18mm" },
    { label: "20mm" },
  ],
  mobile_weight: [
    { label: "100g" },
    { label: "150g" },
    { label: "186g" },
    { label: "246g" },
    { label: "283g" },
    { label: "256g" },
    { label: "263g" },
    { label: "293g" },
  ],

  // -------============Laptops/////////////////////////
  Laptop_Type: [
    { label: "Gaming" },
    { label: "Ultrabook " },
    { label: "Business" },
    { label: "Convertible" },
    { label: "Chrommobook " },
    { label: "Thin and Light " },
    { label: "2-in-1" },
  ],
  Suitable_for: [
    { label: "processing" },
    { label: "multitasking" },
    { label: "programming" },
    { label: "multimedia" },
    { label: "travel" },
    { label: "graphic" },
    { label: "design" },
    { label: "everyday use" },
  ],

  laptop_battery_backup: [
    { label: "upto 4hr" },
    { label: "upto 5hr" },
    { label: "upto 6hr" },
    { label: "upto 7hr" },
    { label: "upto 8hr" },
    { label: "upto 9hr" },
    { label: "upto 10hr" },
  ],

  laptop_Processor_brand: [{ label: "Intel" }, { label: "AMD" }],
  laptop_Processor_name: [
    { label: "Intel Core i3" },
    { label: "Intel Core i5" },
    { label: "Intel Core i7" },
    { label: "Intel Core i9" },
    { label: "AMD Ryzen 3" },
    { label: "AMD Ryzen 5" },
    { label: "AMD Ryzen 7" },
    { label: "AMD Ryzen 9 " },
    { label: "Qualcomm snapdragon" },
    { label: "Apple M1”" },
  ],
  laptop_Ram: [
    { label: "4" },
    { label: "8" },
    { label: "16" },
    { label: "32" },
    { label: "64" },
    { label: "128" },
  ],
  laptop_Ram_type: [{ label: "DDR3" }, { label: "DDR4" }, { label: "DDR5" }],
  laptop_Graphic_processor: [
    { label: "Intel HD Graphics" },
    { label: "Intel Iris Xe Graphics" },
    { label: "Intel UHD Graphics" },
    { label: "NVIDIA GeForce GTX" },
    { label: "NVIDIA GeForce RTX" },
    { label: "AMD Radeon RX" },
    { label: "AMD Radeon Pro" },
    { label: " Intel Integrated Graphics" },
    { label: " NVIDIA Quadro" },
    { label: "AMD Radeon Vega" },
  ],
  laptop_Clock_speed: [
    { label: "1GHz" },
    { label: "1.2GHz" },
    { label: "1.4GHz" },
    { label: "2.1GHz" },
    { label: "2.3GHz" },
    { label: "2.5GHz" },
    { label: "2.8GHz" },
    { label: "3Ghz" },
  ],
  laptop_Expandable_ssd_capacity: [
    { label: "upto 512GB" },
    { label: "upto 1TB" },
    { label: "upto 2TB" },
  ],
  laptop_Operating_system: [
    { label: "Windows" },
    { label: "macOs" },
    { label: "Linux" },
    { label: "Chrome OS" },
  ],
  System_archietecture: [{ label: "32-bit" }, { label: "64- bit" }],
  Screen_Size: [
    { label: "11 inch" },
    { label: "12 inch" },
    { label: "13 inch" },
    { label: "14 inch" },
    { label: "15 inch" },
    { label: "16 inch" },
    { label: "17 inch" },
  ],

  laptop_Screen_type: [
    { label: "LCD" },
    { label: "LED" },
    { label: "OLED" },
    { label: "IPS" },
    { label: "TN" },
  ],
  laptop_Sound_properties: [
    { label: "stereo" },
    { label: "built-in speakers" },
    { label: "dolby speakers" },
    { label: "harman Kardon" },
    { label: "noise concellation" },
    { label: "Audio jack" },
  ],
  Dimensions: [
    { label: "250mm  x 150 mm x 10mm" },
    { label: "300mm x 200mm x 20mm" },
    { label: "350mm x 250mm x 30mm" },
    { label: "400mm x 300mm x 50mm" },
  ],
  Weight: [
    { label: "1kg" },
    { label: "2kg" },
    { label: "3kg" },
    { label: "4kg" },
    { label: "5kg" },
  ],
  Web_camera: [
    { label: "HD" },
    { label: "FULL hd" },
    { label: "720 p" },
    { label: "1080p" },
    { label: "4k" },
  ],
  Emmc_storage_capacity: [
    { label: "32gb" },
    { label: "64gb" },
    { label: "128gb" },
  ],
  Dedicated_graphic_memory_type: [
    { label: "GDDR5" },
    { label: "GDDR6X" },
    { label: "GDDR6" },
    { label: "HBM" },
    { label: "HBM2" },
    { label: "HBM2E" },
    { label: "HBM3" },
    { label: "GDDR5X" },
    { label: "GDDR6L" },
    { label: "GDDR7" },
  ],
  Dedicated_graphic_memory_capacity: [
    { label: "2 GB" },
    { label: "4 GB" },
    { label: "6 GB" },
    { label: "8 GB" },
    { label: "16 GB" },
  ],
  laptop_Ssd_capacity: [
    { label: "128GB" },
    { label: "256GB" },
    { label: "512GB" },
    { label: "1TB" },
    { label: "2 TB" },
  ],
  Lock_port: [{ label: "Kensington Lock Slot" }],
  // tablets---------------
  Ideal_usage: [
    { label: "Entertainment" },
    { label: "Gaming" },
    { label: "esim" },
    { label: "Reading " },
    { label: "Browsing" },
    { label: "For Kids " },
    { label: "Business" },
  ],
  Connectivity: [
    { label: "wifi" },
    { label: "Bluetooth" },
    { label: "NFC" },
    { label: "4G/LTE" },
    { label: "5G" },
  ],
  tablets_Os: [{ label: "Android" }, { label: "iOS" }],
  tablets_ram: [
    { label: "2GB" },
    { label: "3GB" },
    { label: "4GB" },
    { label: "6GB" },
    { label: " 8GB" },
  ],
  Supported_network: [
    { label: "Bluetooth" },
    { label: "wifi" },
    { label: "5g" },
    { label: "gsm" },
    { label: "cdma" },
    { label: "hspa" },
    { label: "volte" },
    { label: "dual" },
    { label: "sim" },
  ],
  Processor_type: [
    { label: "Qualcomm Snapdragon" },
    { label: "Apple A-series (iPad)" },
    { label: "Samsung Exynos" },
    { label: " MediaTek" },
  ],
  tablet_Display_size: [
    { label: "7 inches" },
    { label: "8 inches" },
    { label: "9 inches" },
    { label: "10 inches" },
    { label: "11 inches" },
    { label: "12 inches" },
    { label: "13 inches" },
    { label: "14 inches" },
    { label: "15 inches" },
    { label: "16 inches" },
  ],
  Display_resolution: [
    { label: "1280 x 720p" },
    { label: "1920 X 1080p" },
    { label: "2560 X 1440p" },
    { label: "3840 X 2160p" },
  ],

  tablet_Primary_camera: [
    { label: "8Mp" },
    { label: "9Mp" },
    { label: "10Mp" },
    { label: "11Mp" },
    { label: "12Mp" },
    { label: "13Mp" },
    { label: "14Mp" },
    { label: "15Mp" },
    { label: "16Mp" },
  ],
  tablet_Processor_speed: [
    { label: "1.2GHzz" },
    { label: "1.4GHzz" },
    { label: "1.6GHzz" },
    { label: "2GHzz" },
    { label: "2.2GHzz" },
    { label: "2.4GHzz" },
    { label: "3GHzz" },
  ],
  tablet_Internal_storage: [
    { label: " 32 GB" },
    { label: " 64 GB" },
    { label: " 128 GB" },
    { label: " 256 GB " },
    { label: " 512 GB" },
    { label: " 1 TB" },
  ],
  Sensors: [
    { label: "Accelerometer" },
    { label: "Fingerprint Sensor" },
    { label: "Gyro Sensor" },
    { label: "Geomagnetic Sensor" },
    { label: "Hall Sensor" },
    { label: "RGB Light Sensor" },
    { label: "micro" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
  ],
  tablet_Sim_type: [
    { label: "single" },
    { label: "single/Dual" },
    { label: "Dual" },
  ],
  browser: [{ label: "chrome" }, { label: "safari" }],
  tablet_Rom: [
    { label: "32 GB" },
    { label: "64 GB" },
    { label: "128 GB" },
    { label: "256 GB" },
    { label: "512 GB" },
    { label: "1 TB" },
  ],
  Battery_capapcity: [
    { label: "3000-5000mAh" },
    { label: "5000-7000mah" },
    { label: "7000mAh-10000mAh" },
    { label: "above 10000mAh" },
  ],
  Battery_type: [
    { label: "Lithium-ion (Li-ion)" },
    { label: "Lithium-polymer (Li-po)" },
  ],
  Bluetooth_version: [{ label: "4" }, { label: "5" }, { label: "6" }],
  Display_type: [
    { label: "Hd" },
    { label: "FHD" },
    { label: "Lcd" },
    { label: "oled" },
    { label: "amoled" },
    { label: "Lcd" },
    { label: "ips" },
    { label: "tft" },
    { label: "retinal display" },
  ],
  Video_formats_supported: [{ label: "MP4" }],
  Gps_type: [
    { label: "GPS" },
    { label: "Glonass" },
    { label: "Beidou" },
    { label: "Galileo" },
    { label: "QZSS" },
  ],
  mouse_system_requiremnets: [{ label: "laptop" }],
  laptop_bag_pack_of: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  laptop_bag_type: [
    { label: "backpack" },
    { label: "messenger bag" },
    { label: "shoulder bag" },
  ],
  Ideal_for: [{ label: "Men /Women" }],
  Compatible_laptop_size: [
    { label: "11 inches" },
    { label: "12 inches" },
    { label: "13 inches" },
    { label: "14 inches" },
    { label: "15 inches" },
    { label: "16 inches" },
    { label: "17 inches" },
    { label: "18 inches" },
  ],
  Material: [
    { label: "nylon" },
    { label: "polyster" },
    { label: "Leather" },
    { label: "Canvas " },
    { label: "neoprene" },
    { label: "PU Leatehr" },
    { label: "Microfiber" },
  ],
  Number_of_compartments: [{ label: "1" }, { label: "2" }, { label: "3" }],
  Pattern: [
    { label: "solid" },
    { label: "striped" },
    { label: "checkered" },
    { label: "floral" },
    { label: "geometric" },
  ],
  Capacity: [
    { label: "10-15L" },
    { label: "15-20L" },
    { label: "20-25L" },
    { label: "25-30L" },
    { label: "30-35L" },
    { label: "35-40L" },
    { label: "40-45L" },
    { label: "45-50L" },
  ],
  laptop_bag_Dimensions: [
    { label: "15 inches x 10 inches x 3 inches" },
    { label: "18 inches x 12 inches x 4 inches" },
    { label: "20 inches x 14 inches x 6 inches" },
    { label: "22 inches x 16 inches x 9 inches " },
    { label: "25 inches x 18 inches x 18 inches" },
  ],
  pc_speaker_type: [
    { label: "Laptop" },
    { label: "desktop" },
    { label: "Speaker" },
    { label: "stereo" },
    { label: "Bluetooth" },
  ],
  Configuration: [
    { label: "2.0" },
    { label: "2.1" },
    { label: "2.2" },
    { label: "2.3" },
    { label: "2.4" },
    { label: "2.5" },
    { label: "2.6" },
    { label: "2.7" },
    { label: "2.8" },
    { label: "2.9" },
    { label: "3" },
    { label: "3.1" },
    { label: "3.2" },
    { label: "3.3" },
    { label: "3.4" },
    { label: "3.5" },
    { label: "3.6" },
    { label: "3.7" },
    { label: "3.8" },
    { label: "3.9" },
    { label: "4" },
    { label: "4.1" },
    { label: "4.2" },
    { label: "4.3" },
    { label: "4.4" },
    { label: "4.5" },
  ],
  // Power_source: [
  //     { label: "usb" },
  //     { label: "Ac adaptor" },
  // ],
  Power_output: [
    { label: "2w" },
    { label: "5w" },
    { label: "10w" },
    { label: "15w" },
    { label: "20w" },
    { label: "30w" },
    { label: "40w" },
    { label: "50w" },
    { label: "100w" },
    { label: "150w" },
    { label: "180w" },
    { label: "200w" },
    { label: "300w" },
    { label: "400w" },
    { label: "500w" },
  ],
  Frequency_response: [
    { label: "20 Hz - 200 Hz" },
    { label: "200 Hz - 2,000 Hz" },
    { label: "2,000 Hz - 20,000 Hz " },
  ],
  Impedence: [
    { label: "2O" },
    { label: "2.1O" },
    { label: "2.2 O" },
    { label: "2.3 O" },
    { label: "2.4 O" },
    { label: "2.5 O" },
    { label: "2.6 O" },
    { label: "2.7 O" },
    { label: "2.8 O" },
    { label: "2.9 O" },
    { label: "3 O" },
    { label: "3.1 O" },
    { label: "3.2 O" },
    { label: "3.3 O" },
    { label: "3.4 O" },
    { label: "3.5 O" },
    { label: "3.6 O" },
    { label: "3.7 O" },
    { label: "3.8 O" },
    { label: "3.9 O" },
    { label: "4 O" },
    { label: "4.1 O" },
    { label: "4.2 O" },
    { label: "4.3 O" },
    { label: "4.4 O" },
    { label: "4.5 O" },
    { label: "4.6 O" },
    { label: "4.7 O" },
    { label: "4.8 O" },
    { label: "4.9 O" },
    { label: "5 O" },
    { label: "5.1 O" },
    { label: "5.2 O" },
    { label: "5.3 O" },
    { label: "5.4 O" },
    { label: "5.5 O" },
    { label: "5.6 O" },
    { label: "5.7 O" },
    { label: "5.8 O" },
    { label: "5.9 O" },
    { label: "6 O" },
    { label: "6.1 O" },
    { label: "6.2 O" },
    { label: "6.3 O" },
    { label: "6.4 O" },
    { label: "6.5 O" },
    { label: "6.6 O" },
    { label: "6.7 O" },
    { label: "6.8 O" },
    { label: "6.9 O" },
    { label: " 7 O" },
    { label: "7.1 O" },
    { label: "7.2 O" },
    { label: "7.3 O" },
    { label: "7.4 O" },
    { label: "7.5 O" },
    { label: "7.6 O" },
    { label: "7.7 O" },
    { label: "7.8 O" },
    { label: "7.9 O" },
    { label: "8 O" },
    { label: "8.1 O" },
    { label: "8.2 O" },
    { label: "8.3 O" },
    { label: "8.4 O" },
    { label: "8.5 O" },
    { label: "8.6 O" },
    { label: "8.7 O" },
    { label: "8.8 O" },
    { label: "8.9 O" },
    { label: "9 O" },
    { label: "9.1 O" },
    { label: "9.2 O" },
    { label: "9.3 O" },
    { label: "9.4 O" },
    { label: "9.5 O" },
    { label: "9.6 O" },
    { label: "9.7 O" },
    { label: "9.8 O" },
    { label: "9.9 O" },
    { label: "10 O" },

    { label: "10.1 O" },
    { label: "10.2 O" },
    { label: "10.3 O" },
    { label: "10.4 O" },
    { label: "10.5 O" },
    { label: "10.6 O" },
    { label: "10.7 O" },
    { label: "10.8 O" },
    { label: "10.9 O" },
    { label: "11 O" },
    { label: "11.1 O" },
    { label: "11.2 O" },
    { label: "11.3 O" },
    { label: "11.4 O" },
    { label: "11.5 O" },
    { label: "11.6 O" },
    { label: "11.7  O" },
    { label: "11.8 O" },
    { label: "11.9 O" },
    { label: "12 O" },
    { label: "12.1 O" },
    { label: "12.2 O" },
    { label: "12.3 O" },
    { label: "12.4 O" },
    { label: "12.5 O" },
    { label: "12.6 O" },
    { label: "12.7 O" },
    { label: "12.8 O" },
    { label: "12.9 O" },
    { label: "13 O" },
    { label: "13.1 O" },
    { label: "13.2 O" },
    { label: "13.3 O" },
    { label: "13.4 O" },
    { label: "13.5 O" },
    { label: "13.6 O" },
    { label: "13.7 O" },
    { label: "13.8 O" },
    { label: "13.9 O" },
    { label: "14 O" },
    { label: "14.1 O" },
    { label: "14.2 O" },
    { label: "14.3 O" },
    { label: "14.4 O" },
    { label: "14.5 O" },
    { label: "14.6 O" },
    { label: "14.7 O" },
    { label: "14.8 O" },
    { label: "14.9 O" },
    { label: "15 O" },
    { label: "15.1 O" },
    { label: "15.2 O" },
    { label: "15.3 O" },
    { label: "15.4 O" },
    { label: "15.5 O" },
    { label: "15.6 O" },
    { label: "15.7 O" },
    { label: "15.8 O" },
    { label: "15.9 O" },
    { label: "16 O" },
    { label: "16.1 O" },
    { label: "16.2 O" },
    { label: "16.3 O" },
    { label: "16.4 O" },
    { label: "16.5 O" },
    { label: "16.6 O" },
    { label: "16.7 O" },
    { label: "16.8 O" },
    { label: "16.9 O" },
    { label: "17 O" },
    { label: "17.1 O" },
    { label: "17.2 O" },
    { label: "17.3 O" },
    { label: "17.4 O" },
    { label: "17.5 O" },
    { label: "17.6 O" },
    { label: "17.7 O" },
    { label: "17.8 O" },
    { label: "17.9 O" },
    { label: "18 O" },
    { label: "18.1 O" },
    { label: "18.2 O" },
    { label: "18.3 O" },
    { label: "18.4 O" },
    { label: "18.5 O" },
    { label: "18.6 O" },
    { label: "18.7 O" },
    { label: "18.8 O" },
    { label: "18.9 O" },
    { label: "19 O" },
    { label: "19.1 O" },
    { label: "19.2 O" },
    { label: "19.3 O" },
    { label: "19.4 O" },
    { label: "19.5 O" },
    { label: "19.6 O" },
    { label: "19.7 O" },
    { label: "19.8 O" },
    { label: "19.9 O" },
    { label: "20 O" },
  ],
  Wired_wireless: [{ label: "Wired" }, { label: "wireless" }],
  Compatitle_devices: [
    { label: "Pc" },
    { label: "Laptop" },
    { label: "Smartphone" },
    { label: "Laptop " },
    { label: "TV" },
    { label: "MP3 Player" },
    { label: "DVD Player " },
  ],
  Controls: [
    { label: "Volume" },
    { label: "Bass" },
    { label: "Treblle" },
    { label: "Mode" },
    { label: "Input Select" },
  ],
  Width: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
  ],
  height: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
    { label: "21cm" },
    { label: "22cm" },
    { label: "23cm" },
    { label: "24cm" },
    { label: "25cm" },
    { label: "26cm" },
    { label: "27cm" },
    { label: "28cm" },
    { label: "29cm" },
    { label: "30cm" },
  ],
  depth: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
  ],
  pendrive_Opening_mechanism: [
    { label: "nanao" },
    { label: "micro" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
  ],
  Read_speed: [
    { label: "10MB/S" },
    { label: "20MB/S" },
    { label: "30MB/S" },
    { label: "40MB/S" },
    { label: "50MB/S" },
    { label: "60MB/S" },
    { label: "70MB/S" },
    { label: "80MB/S" },
    { label: "90MB/S" },
    { label: "100MB/S" },
  ],
  pendrive_width: [{ label: "1 cm" }, { label: "2 cm" }, { label: "3 cm" }],
  pendrive_height: [
    { label: "1 cm" },
    { label: "2 cm" },
    { label: "3 cm" },
    { label: "4 cm" },
    { label: "5 cm" },
  ],
  pendrive_depth: [{ label: "1 cm" }, { label: "2 cm" }],
  pendrive_weight: [
    { label: "3 g" },
    { label: "4 g" },
    { label: "5 g" },
    { label: "6 g" },
    { label: "7 g" },
    { label: "8 g" },
    { label: "9 g" },
    { label: "10 g" },
  ],
  Net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  hard_drive_type: [{ label: "HDD" }, { label: "SDD" }],
  Capacity: [
    { label: "256GB" },
    { label: "512GB" },
    { label: "1TB" },
    { label: "8TB " },
    { label: "16 TB" },
    { label: "32 TB" },
  ],
  System_requirements: [{ label: "Above Windows 10" }],
  Form_factor: [
    { label: "2.5 – 5 inch " },
    { label: "M.2 " },
    { label: "Portable" },
    { label: "dual sim" },
    { label: "single" },
  ],
  Wired_wireless: [{ label: "wired" }, { label: "wireless" }],
  hard_drive_width: [
    { label: "1 cm" },
    { label: "2 cm" },
    { label: "3 cm" },
    { label: "4 cm" },
    { label: "5 cm" },
    { label: "6 cm" },
    { label: "7 cm" },
    { label: "8 cm" },
    { label: "9 cm" },
    { label: "10 cm" },
  ],
  hard_drive_height: [
    { label: "5 cm" },
    { label: "6 cm" },
    { label: "7 cm" },
    { label: "8 cm" },
    { label: "9 cm" },
    { label: "10 cm" },
    { label: "11 cm" },
    { label: "12 cm" },
    { label: "13 cm" },
    { label: "14 cm" },
    { label: "15 cm" },
    { label: "16 cm" },
    { label: "17 cm" },
    { label: "18 cm" },
    { label: "19 cm" },
    { label: "20 cm" },
  ],
  hard_drive_depth: [
    { label: "5 cm" },
    { label: "6 cm" },
    { label: "7 cm" },
    { label: "8 cm" },
    { label: "9 cm" },
    { label: "10 cm" },
  ],
  hard_drive_weight: [
    { label: "50 g" },
    { label: "60 g" },
    { label: "70 g" },
    { label: "80 g" },
    { label: "90 g" },
    { label: "100 g" },
    { label: "110 g" },
    { label: "120 g" },
    { label: "130 g" },
    { label: "140 g" },
    { label: "150 g" },
    { label: "160 g" },
    { label: "170 g" },
    { label: "180 g" },
    { label: "190 g" },
    { label: "200 g" },
    { label: "210 g" },
    { label: "220 g" },
    { label: "230 g" },
    { label: "240 g" },
    { label: "250 g" },
    { label: "260 g" },
    { label: "270 g" },
    { label: "280 g" },
    { label: "290 g" },
    { label: "300 g" },
  ],
  // Network storage drives:
  Network_storage_Type: [{ label: "with modem" }, { label: "without modem" }],
  net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  operating_system: [
    { label: "above windows 7" },
    { label: "MacOS" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
  ],
  Operating_range: [
    { label: "10m" },
    { label: "20m" },
    { label: "30m" },
    { label: "40m" },
    { label: "50m" },
    { label: "60m" },
    { label: "70m" },
    { label: "80m" },
    { label: "90m" },
    { label: "100m" },
  ],
  Frequency: [{ label: "2.4 GHz " }, { label: "5GHz" }, { label: "Dual band" }],
  Frequency_band: [{ label: "2.4 GHz" }, { label: "5GHz" }],
  Wireless_speed: [
    { label: "1Mbps" },
    { label: "10 Mbps" },
    { label: "100 Mbps" },
    { label: "1 Gbps (1000 Mbps)" },
    { label: "10 Gbps" },
  ],

  Dsl_modem_speed: [
    { label: "0Mbps" },
    { label: "10Mbps" },
    { label: "15Mbps" },
    { label: "20Mbps" },
    { label: "30Mbps" },
    { label: "40Mbps" },
    { label: "50Mbps" },
    { label: "60Mbps" },
    { label: "70Mbps" },
    { label: "80Mbps" },
    { label: "90Mbps" },
    { label: "100 Mbps" },
  ],
  Lan_wan: [{ label: "1-100 Mbps" }, { label: "10-100 Mbps" }],

  Antennae: [{ label: "External" }, { label: "Internal" }],
  Number_of_usb_ports: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],

  //Keyboard
  Keyboard_Type: [
    { label: "desktop" },
    { label: "keyboard" },
    { label: "gaming keyboard" },
    { label: "wireless keyboard" },
    { label: "touchscreen keyboard" },
    { label: "compact keyboard" },
    { label: "mechanical keyboard" },
  ],
  // Solid State Drives:
  solid_state_Type: [
    { label: "SSD" },
    { label: "M.2SATA" },
    { label: "M.2 NVMe" },
    { label: " PCIe " },
    { label: "U.2" },
  ],
  Capacity: [
    { label: "120 GB" },
    { label: "240 GB" },
    { label: "256 GB" },
    { label: "480 GB" },
    { label: "500 GB" },
    { label: "512 GB" },
    { label: "1 TB" },
    { label: "2 TB" },
    { label: "4 TB" },
    { label: "8 TB" },
  ],
  System_Requirements: [
    { label: "Windows" },
    { label: "macOS" },
    { label: "linux" },
    { label: "Android" },
  ],
  Form_factor: [{ label: "Portable" }],
  Number_of_ports: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  Connector_Type: [
    { label: "Type c" },
    { label: "PCIe Connector" },
    { label: "U.2 " },
    { label: "M.2 key M coonector" },
    { label: "SATA data connector" },
    { label: " M.2 Key B connector " },
  ],
  Case_material: [
    { label: "Aluminum " },
    { label: "Plastc " },
    { label: "Metal Alloys" },
    { label: "Composite Materials" },
  ],
  solid_state_width: [
    { label: "20mm " },
    { label: "22mm " },
    { label: "24mm " },
    { label: "26mm " },
    { label: "28mm " },
    { label: "30mm " },
    { label: "32mm " },
    { label: "34mm " },
    { label: "36mm " },
    { label: "38mm " },
    { label: "40mm " },
    { label: "42mm " },
    { label: "44mm " },
    { label: "46mm " },
    { label: "48mm " },
    { label: "50mm " },
    { label: "52mm " },
    { label: "54mm " },
    { label: "56mm " },
    { label: "58mm " },
    { label: "60mm " },
    { label: "62mm " },
    { label: "64mm " },
    { label: "66mm " },
    { label: "68mm " },
    { label: "70mm " },
  ],
  solid_state_height: [
    { label: "2mm " },
    { label: "4mm " },
    { label: "6mm " },
    { label: "8mm " },
    { label: "10mm " },
    { label: "12mm " },
    { label: "14mm " },
    { label: "16mm " },
    { label: "18mm " },
    { label: "20mm " },
    { label: "22mm " },
    { label: "24mm " },
    { label: "26mm " },
    { label: "28mm " },
    { label: "30mm " },
    { label: "32mm " },
    { label: "34mm " },
    { label: "36mm " },
    { label: "38mm " },
    { label: "40mm " },
    { label: "42mm " },
    { label: "44mm " },
    { label: "46mm " },
    { label: "48mm " },
    { label: "50mm " },
    { label: "52mm " },
    { label: "54mm " },
    { label: "56mm " },
    { label: "58mm " },
    { label: "60mm " },
    { label: "62mm " },
    { label: "64mm " },
    { label: "66mm " },
    { label: "68mm " },
    { label: "70mm " },
    { label: "72mm " },
    { label: "74mm " },
    { label: "76mm " },
    { label: "78mm " },
    { label: "80mm " },
    { label: "82mm " },
    { label: "84mm " },
    { label: "86mm " },
    { label: "88mm " },
    { label: "90mm " },
    { label: "92mm " },
    { label: "94mm " },
    { label: "96mm " },
    { label: "98mm " },
    { label: "100mm " },
  ],
  solid_state_depth: [
    { label: "2mm " },
    { label: "4mm " },
    { label: "6mm " },
    { label: "8mm " },
    { label: "10mm " },
    { label: "12mm " },
    { label: "14mm " },
    { label: "16mm " },
    { label: "18mm " },
    { label: "20mm " },
    { label: "22mm " },
    { label: "24mm " },
    { label: "26mm " },
    { label: "28mm " },
    { label: "30mm " },
    { label: "32mm " },
    { label: "34mm " },
    { label: "36mm " },
    { label: "38mm " },
    { label: "40mm " },
    { label: "42mm " },
    { label: "44mm " },
    { label: "46mm " },
    { label: "48mm " },
    { label: "50mm " },
    { label: "52mm " },
    { label: "54mm " },
    { label: "56mm " },
    { label: "58mm " },
    { label: "60mm " },
    { label: "62mm " },
    { label: "64mm " },
    { label: "66mm " },
    { label: "68mm " },
    { label: "70mm " },
    { label: "72mm " },
    { label: "74mm " },
    { label: "76mm " },
    { label: "78mm " },
    { label: "80mm " },
  ],
  solid_state_weight: [
    { label: "5 g" },
    { label: "10 g" },
    { label: "15 g" },
    { label: "20 g" },
    { label: "25 g" },
    { label: "30 g" },
    { label: "35 g" },
    { label: "40 g" },
    { label: "45 g" },
    { label: "50 g" },
    { label: "55 g" },
  ],
  //Micro wave ovens
  Micro_wave_types: [
    { label: "countertop" },
    { label: "drawer" },
    { label: "combination" },
    { label: "grill" },
    { label: "solo" },
    { label: "compact" },
    { label: " retro " },
  ],
  Control_types: [
    { label: "digital" },
    { label: "touch" },
    { label: "dial " },
    { label: "button" },
    { label: "Mechanical" },
  ],
  Mount_types: [
    { label: "wall mount " },
    { label: "countertop" },
    { label: "over the range" },
    { label: "bulit-in" },
    { label: "under cabinet" },
  ],
  //Juicer /Mixer /Grinders
  Juicer_types: [
    { label: "centrifugal" },
    { label: "masticating" },
    { label: "citrus" },
  ],
  Material_types: [
    { label: "plastic" },
    { label: "stainless" },
    { label: "steel" },
    { label: "glass" },
    { label: "ceranic" },
  ],

  //Oven Toaster Grills
  Rotisserie_types: [{ label: "motorized" }, { label: "manual" }],
  Cavity_Material_type: [
    { label: "Stainless steel" },
    { label: "enamel-coated steel" },
    { label: "non-stick coating" },
  ],
  Temparature_control_range: [
    { label: "100C" },
    { label: "200C" },
    { label: "300C" },
  ],
  Number_of_auto_menu: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  Power_requirement: [
    { label: "220v" },
    { label: "230v" },
    { label: "240v" },
    { label: "50Hz" },
  ],
  Power_consumption: [
    { label: "600w" },
    { label: "700w" },
    { label: "800w" },
    { label: "900w" },
    { label: "1000w" },
    { label: "1100w" },
    { label: "1200w" },
    { label: "1300w" },
    { label: "1400w" },
    { label: "1500w" },
    { label: "1600w" },
    { label: "1700w" },
    { label: "1800w" },
    { label: "1900w" },
    { label: "2000w" },
  ],

  //Electric Kettle
  Heating_element: [
    { label: "horse shoe tubular" },
    { label: "Concealed heating element" },
    { label: "Spiral Heating Element " },
    { label: "flat Heating element " },
  ],
  Power_requirement: [
    { label: "AC 220 V" },
    { label: "230 V" },
    { label: "240 V" },
  ],

  //Hand Blenders
  Body_material: [
    { label: "platic" },
    { label: "stainless" },
    { label: "steel and plastic" },
  ],
  Blade_material: [{ label: "stainless steel" }],

  //Induction Cooktops
  Induction_Cooktops_type: [
    { label: "built -in induction cooktops" },
    { label: "free standing induction range" },
  ],
  Body_material: [
    { label: "ceramic glass" },
    { label: "crystal" },
    { label: "stainless steel" },
  ],
  Display: [{ label: "LED display" }],
  Power_levels: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  // Frequency: [{ label: "15 H" }],
  // Power_consumption: [
  //   { label: "1200" },
  //   { label: "1300" },
  //   { label: "1400" },
  //   { label: "1500" },
  //   { label: "1600" },
  //   { label: "1700" },
  //   { label: "1800" },
  //   { label: "1900" },
  //   { label: "2000" },
  //   { label: "2100" },
  //   { label: "2200" },
  //   { label: "2300" },
  //   { label: "2400" },
  //   { label: "2500" },
  //   { label: "2600" },
  //   { label: "2700" },
  //   { label: "2800" },
  //   { label: "2900" },
  //   { label: "3000" },
  //   { label: "3100" },
  //   { label: "3200" },
  //   { label: "3300" },
  //   { label: "3400" },
  //   { label: "3500" },
  //   { label: "3600" },
  //   { label: "3700" },
  // ],
  Power_input: [{ label: "220v" }, { label: "230v" }, { label: "240v" }],
  Cord_length: [
    { label: "100cm" },
    { label: "120cm" },
    { label: "130cm" },
    { label: "140cm" },
    { label: "150cm" },
  ],

  //Chimneys
  Finish: [
    { label: "Stainless steel" },
    { label: "black stainless steel" },
    { label: "white" },
    { label: "matte /glossy" },
  ],
  Shape: [
    { label: "Standard Rectangular" },
    { label: "Boxy" },
    { label: "Curved glass" },
    { label: "pyramidal" },
  ],
  Mount_type: [{ label: "Wall-mounted" }, { label: "island-mounted" }],
  Material_type: [{ label: "Stainless steel" }],
  Air_suction_capacity: [
    { label: "300cmh" },
    { label: "400cmh" },
    { label: "500cmh" },
    { label: "600cmh" },
    { label: "700cmh" },
    { label: "800cmh" },
    { label: "900cmh" },
    { label: "1000cmh" },
  ],
  Control_panel_type: [{ label: "Touch control Motion Sensor" }],
  Speed_levels: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
  ],
  Number_of_lights: [
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
  ],
  Light_type: [{ label: "LED" }],
  Engine_power: [
    { label: "100" },
    { label: "200" },
    { label: "300" },
    { label: "400" },
    { label: "500" },
    { label: "600" },
    { label: "700" },
    { label: "800" },
    { label: "900" },
    { label: "1000" },
  ],

  //Sandwich Makers

  Slice_capacity: [
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
  ],
  Cooking_Plate: [
    { label: "non-stick" },
    { label: "grill" },
    { label: "removable" },
    { label: "flat cooking" },
  ],
  Functions: [
    { label: "Grilling" },
    { label: "adjustable temperature" },
    { label: "interchangable plates" },
    { label: "time" },
    { label: "removable plates" },
    { label: "compact design" },
  ],
  Display_functions: [{ label: "toasting and grilling" }],
  // Power_requirement: [
  //   { label: "Ac 220v" },
  //   { label: "230v" },
  //   { label: "240v" },
  //   { label: "50Hz" },
  // ],
  Power_consumption: [
    { label: "600w" },
    { label: "700w" },
    { label: "800w" },
    { label: "900w" },
    { label: "1000w" },
  ],

  //Pop Up Toaster
  No_of_slices: [
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
  ],
  // Power_consumption: [
  //   { label: "600w" },
  //   { label: "700w" },
  //   { label: "800w" },
  //   { label: "900w" },
  //   { label: "1000w" },
  //   { label: "1100w" },
  //   { label: "1200w" },
  // ],

  //   Electric cooker
  Pack_of: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  Type: [
    { label: "freestanding electric cookers" },
    { label: "induction cookers" },
    { label: "pressure cookers" },
    { label: "rice cookers" },
  ],
  Capacity: [
    { label: "1L" },
    { label: "2L" },
    { label: "3L" },
    { label: "4L" },
    { label: "5L" },
    { label: "6L" },
    { label: "7L" },
    { label: "8L" },
    { label: "9L" },
    { label: "10L" },
  ],
  Total_pan_capacity: [
    { label: "1L" },
    { label: "2L" },
    { label: "3L" },
    { label: "4L" },
    { label: "5L" },
    { label: "6L" },
    { label: "7L" },
    { label: "8L" },
    { label: "9L" },
    { label: "10L" },
  ],
  // Functions: [{ label: "cooking" }, { label: "steaming" }, { label: "baking" }],
  // Material: [
  //   { label: "plastic" },
  //   { label: "stainless steel" },
  //   { label: "aluminimum" },
  // ],
  // Lid_material: [{ label: "stainless steel " }, { label: ",plastic" }],
  Net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  // Power_requirement: [{ label: "220v" }, { label: "230v" }, { label: "240v" }],
  // Power_consumption: [
  //   { label: "300w" },
  //   { label: "400w" },
  //   { label: "500w" },
  //   { label: "600w" },
  //   { label: "700w" },
  //   { label: "800w" },
  //   { label: "900w" },
  //   { label: "1000w" },
  // ],
  // Cooking_modes: [{ label: "cook" }, { label: "warm" }],
  // Cooking_pan: [
  //   { label: "non-stick coated aluminum" },
  //   { label: "stainless steel" },
  // ],

  // Wet grinders
  // Body_material: [{ label: "abs plastic" }, { label: "stainless steel" }],
  Motor_speed: [
    { label: "500rpm" },
    { label: "600rpm" },
    { label: "700rpm" },
    { label: "800rpm" },
    { label: "900rpm" },
    { label: "1000rpm" },
    { label: "1100rpm" },
    { label: "1200rpm" },
    { label: "1300rpm" },
    { label: "1400rpm" },
    { label: "1500rpm" },
    { label: "1600rpm" },
    { label: "1700rpm" },
    { label: "1800rpm" },
    { label: "1900rpm" },
    { label: "2000rpm" },
  ],
  Number_of_stones: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Attachment_present: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
  ],
  // Power_requirement: [{ label: "220v" }, { label: "230v" }, { label: "240v" }],
  // Power_consumption: [
  //   { label: "100w" },
  //   { label: "200w" },
  //   { label: "300w" },
  //   { label: "400w" },
  //   { label: "500w" },
  //   { label: "600w" },
  //   { label: "700w" },
  //   { label: "800w" },
  //   { label: "900w" },
  //   { label: "1000w" },
  // ],

  //   Food processors
  // Body_material: [{ label: "plastic" }, { label: "stainless steel" }],
  // Energy_rating: [
  //   { label: "1" },
  //   { label: "2" },
  //   { label: "3" },
  //   { label: "4" },
  //   { label: "5" },
  // ],
  // Power_requirement: [
  //   { label: "220v" },
  //   { label: "230v" },
  //   { label: "240v" },
  //   { label: "50HZ" },
  // ],
  // Power_consumption: [
  //   { label: "100w" },
  //   { label: "200w" },
  //   { label: "300w" },
  //   { label: "400w" },
  //   { label: "500w" },
  //   { label: "600w" },
  //   { label: "700w" },
  //   { label: "800w" },
  //   { label: "900w" },
  //   { label: "1000w" },
  //   { label: "1100w" },
  //   { label: "1200w" },
  // ],

  //   Caffee makers

  // Small Home Appliances
  // Irons
  Type: [
    { label: "dry" },
    { label: "steam" },
    { label: "steam generator" },
    { label: "travel" },
    { label: "cordless" },
  ],
  Soleplate_type: [
    { label: "stainless-steel" },
    { label: "ceramic" },
    { label: "aluminum" },
    { label: "titanium" },
    { label: "non-stick" },
  ],
  // Cord_length: [{ label: "1m" }, { label: "2m" }, { label: "3m" }],
  // Frequency: [{ label: "50Hz" }],
  // Power_consumption: [
  //   { label: "600w" },
  //   { label: "700w" },
  //   { label: "800w" },
  //   { label: "900w" },
  //   { label: "1000w" },
  //   { label: "1100w" },
  //   { label: "1200w" },
  //   { label: "1300w" },
  //   { label: "1400w" },
  //   { label: "1500w" },
  // ],
  // Power_input: [{ label: "220v" }, { label: "230v" }, { label: "240v" }],

  //   Water purifiers
  Total_capacity: [
    { label: "5L" },
    { label: "10L" },
    { label: "15L" },
    { label: "20L" },
  ],
  Purifying_technology: [
    { label: "RO" },
    { label: "UV" },
    { label: "UF" },
    { label: "MF" },
    { label: "NF" },
    { label: "CF" },
    { label: "Ozone" },
    { label: "electrolysis" },
  ],
  // Power_requirement: [
  //   { label: "220v" },
  //   { label: "230v" },
  //   { label: "240v" },
  //   { label: "50HZ" },
  // ],
  // Power_consumption: [
  //   { label: "10 watts" },
  //   { label: "20 watts" },
  //   { label: "30 watts" },
  //   { label: "40 watts" },
  //   { label: "50 watts" },
  // ],
  Electronic_type: [{ label: "Electrical" }, { label: "non-electrical" }],
  Filter_type: [
    { label: "Sediment filter" },
    { label: "carbon filter" },
    { label: "RO" },
    { label: "UV" },
    { label: "UF" },
    { label: "alkaline filter" },
    { label: "mineral filter" },
    { label: "activated carbon block filter" },
  ],
  Membrane_type: [
    { label: "thin film composite" },
    { label: "cellulose  acetate" },
    { label: "polyamide" },
    { label: "cellulose trisacetate" },
    { label: "vontrone membrane" },
  ],
  // Storage_tank_material: [
  //   { label: "food grade" },
  //   { label: "stainless steel" },
  //   { label: "copper" },
  //   { label: "glass" },
  // ],
  // Indicators: [
  //   { label: "filter change indicator" },
  //   { label: "UV fail alarm" },
  //   { label: "tank full indicator" },
  //   { label: "water level indicator" },
  //   { label: "purification  process indicator" },
  //   { label: "Error indicator" },
  // ],
  Filtration_capacity: [
    { label: "10 L/hr" },
    { label: "20 L/hr" },
    { label: "30 L/hr" },
    { label: "40 L/hr" },
    { label: "50 L/hr" },
  ],
  Purification_capacity: [
    { label: "5000 L" },
    { label: "10000 L" },
    { label: "15000 L" },
    { label: "20000 L" },
    { label: "25000 L" },
    { label: "30000 L" },
  ],
  // Maximum_input_water_temperature: [
  //   { label: "35 degree C" },
  //   { label: "36 degree C" },
  //   { label: "37 degree C" },
  //   { label: "38 degree C" },
  //   { label: "39 degree C" },
  //   { label: "40 degree C" },
  //   { label: "41 degree C" },
  //   { label: "42 degree C" },
  //   { label: "43 degree C" },
  //   { label: "44 degree C" },
  //   { label: "45 degree C" },
  //   { label: "46 degree C" },
  //   { label: "47 degree C" },
  //   { label: "48 degree C" },
  //   { label: "49 degree C" },
  //   { label: "50 degree C" },
  //   { label: "51 degree C" },
  //   { label: "52 degree C" },
  //   { label: "53 degree C" },
  //   { label: "54 degree C" },
  //   { label: "55 degree C" },
  // ],
  // Minimum_input_water_temperature: [
  //   { label: "5 degree C" },
  //   { label: "10 degree C" },
  //   { label: "15 degree C" },
  //   { label: "20 degree C" },
  //   { label: "25 degree C" },
  // ],
  // Installation_type: [
  //   { label: "wall mounted" },
  //   { label: "counter top" },
  //   { label: "under sink" },
  //   { label: "tabletop " },
  //   { label: "floorstanding" },
  // ],
  // Water_flow_rate: [
  //   { label: "0" },
  //   { label: "1 L/min" },
  //   { label: "2 L/min" },
  //   { label: "3 L/min" },
  //   { label: "4 L/min" },
  //   { label: "5 L/min" },
  //   { label: "6 L/min" },
  //   { label: "7 L/min" },
  //   { label: "8 L/min" },
  //   { label: "9 L/min" },
  //   { label: "10 L/min" },
  //   { label: "11 L/min" },
  //   { label: "12 L/min" },
  //   { label: "13 L/min" },
  //   { label: "14 L/min" },
  //   { label: "15 L/min" },
  //   { label: "16 L/min" },
  //   { label: "17 L/min" },
  //   { label: "18 L/min" },
  //   { label: "19 L/min" },
  //   { label: "20 L/min" },
  //   { label: "21 L/min" },
  //   { label: "22 L/min" },
  //   { label: "23 L/min" },
  //   { label: "24 L/min" },
  //   { label: "25 L/min" },
  //   { label: "26 L/min" },
  //   { label: "27 L/min" },
  //   { label: "28 L/min" },
  //   { label: "29 L/min" },
  //   { label: "30 L/min" },
  // ],

  // Fans
  Number_of_blades: [
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
  ],
  Pack_of: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  Projection_ratio :[
    { label: "1.5:1" },
    { label: "2.0:1" },
    { label: "0.5:1" },
    { label: "0.25:1" },
    { label: "1.3:1" },
    
  ],

  Headphone_jack:[
    { label: "3.5mm" },
    { label: "Type-c" },
    { label: "lightning cable" },

  ],
  Maximum_Projection_Distances:[
    { label: "40 inch" },
    { label: "50 inch" },
    { label: "60 inch" },
    { label: "70 inch" },
    { label: "80 inch" },
    { label: "90 inch" },
    { label: "100 inch" },
    { label: "110 inch" },
    { label: "120 inch" },
    { label: "130 inch" },
    { label: "140 inch" },
    { label: "150 inch" },
    { label: "160 inch" },
    { label: "170 inch" },
    { label: "180 inch" },
    { label: "190 inch" },
    { label: "200 inch" },
    { label: "210 inch" },
    { label: "220 inch" },
    { label: "230 inch" },
    { label: "240 inch" },
  ],
  // Power_consumption: [
  //   { label: "10w" },
  //   { label: "15w" },
  //   { label: "20w" },
  //   { label: "25w" },
  //   { label: "30w" },
  //   { label: "35w" },
  //   { label: "40w" },
  //   { label: "45w" },
  //   { label: "50w" },
  //   { label: "55w" },
  //   { label: "60w" },
  //   { label: "65w" },
  //   { label: "70w" },
  //   { label "75w" },
  //   { label: "80w" },
  //   { label: "85w" },
  //   { label: "90w" },
  //   { label: "95w" },
  //   { label: "100w" },
  // ],
  // Power_requirement: [{ label: "220v" }, { label: "230v" }, { label: "240v" }],
  Operational_current: [
    { label: "0.5A" },
    { label: "1A" },
    { label: "1.5A" },
    { label: "2A" },
    { label: "2.5A" },
    { label: "3A" },
    { label: "3.5A" },
    { label: "4A" },
    { label: "4.5A" },
    { label: "5A" },
  ],

  // Suitable_for: [{ label: "indoor" }, { label: "outdoor" }],
  // Finish: [
  //   { label: "Matte" },
  //   { label: "glossy " },
  //   { label: "satin" },
  //   { label: "metallic" },
  //   { label: "textured" },
  // ],
  Blade_sweep: [
    { label: "800 RPM" },
    { label: "900 RPM" },
    { label: "1000 RPM" },
    { label: "1100 RPM" },
    { label: "1200 RPM" },
    { label: "1300 RPM" },
    { label: "1400 RPM" },
    { label: "1500 RPM" },
    { label: "1600 RPM" },
    { label: "1700 RPM" },
    { label: "1800 RPM" },
    { label: "1900 RPM" },
    { label: "2000 RPM" },
    { label: "2100 RPM" },
    { label: "2200 RPM" },
    { label: "2300 RPM" },
    { label: "2400 RPM" },
    { label: "2500 RPM" },
    { label: "2600 RPM" },
    { label: "2700 RPM" },
    { label: "2800 RPM" },
    { label: "2900 RPM" },
    { label: "3000 RPM" },
  ],
  Air_flow: [
    { label: "1000 CFM" },
    { label: "2000 CFM" },
    { label: "3000 CFM" },
    { label: "4000 CFM" },
    { label: "5000 CFM" },
    { label: "6000 CFM" },
    { label: "7000 CFM" },
    { label: "8000 CFM" },
    { label: "9000 CFM" },
    { label: "10000 CFM" },
  ],
  // Blade_pitch: [{ label: "0 degree" }],

  //   Air Coolers
  Type: [{ label: "Room" }, { label: "Tower" }, { label: "Desert Portable " }],
  Number_of_speeds: [{ label: "1" }, { label: "2" }, { label: "3" }],
  Blower: [{ label: "fan" }],
  Star_rating: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Cooling_coverage_area: [
    { label: "150 sq.ft" },
    { label: "200 sq.ft" },
    { label: "250 sq.ft" },
    { label: "300 sq.ft" },
    { label: "350 sq.ft" },
    { label: "400 sq.ft" },
    { label: "450 sq.ft" },
    { label: "500 sq.ft" },
  ],
  Speed_control: [{ label: "High" }, { label: "Medium" }, { label: "low" }],
  Operating_mode: [
    { label: "natural" },
    { label: "turbo" },
    { label: "fan" },
    { label: "swing" },
    { label: "pump" },
  ],
  Air_delivery: [
    { label: "1000 CFM" },
    { label: "2000 CFM" },
    { label: "3000 CFM" },
    { label: "4000 CFM" },
    { label: "5000 CFM" },
  ],
  Air_throw_distance: [
    { label: "10 feet" },
    { label: "20 feet" },
    { label: "30 feet" },
    { label: "40 feet" },
  ],
  // Motor_speed: [
  //   { label: "800 Rpm" },
  //   { label: "900 Rpm" },
  //   { label: "1000 Rpm" },
  //   { label: "1100 Rpm" },
  //   { label: "1200 Rpm" },
  //   { label: "1300 Rpm" },
  //   { label: "1400 Rpm" },
  //   { label: "1500 Rpm" },
  //   { label: "1600 Rpm" },
  //   { label: "1700 Rpm" },
  //   { label: "1800 Rpm" },
  //   { label: "1900 Rpm" },
  //   { label: "2000 Rpm" },
  //   { label: "2100 Rpm" },
  //   { label: "2200 Rpm" },
  //   { label: "2300 Rpm" },
  //   { label: "2400 Rpm" },
  //   { label: "2500 Rpm" },
  // ],
  Air_deflection: [
    { label: "1 way" },
    { label: "2 way" },
    { label: "3 way" },
    { label: "4 way" },
  ],
  // Body_material: [
  //   { label: "Plastic" },
  //   { label: "Metal" },
  //   { label: "Fiber" },
  //   { label: "ABS" },
  //   { label: "Polycornote" },
  //   { label: "Thermoplastic" },
  // ],
  Blower_material: [
    { label: "plastic" },
    { label: "Metal" },
    { label: "fiber" },
    { label: "ABS" },
    { label: "aluminum" },
  ],
  Fan_Length: [
    { label: "100cm" },
    { label: "110cm" },
    { label: "120cm" },
    { label: "130cm" },
    { label: "140cm" },
    { label: "150cm" },
  ],
  // Number_of_fan_blades: [{ label: "3" }, { label: "4" }, { label: "5" }],
  // Power_requirement: [
  //   { label: "220v" },
  //   { label: "230v" },
  //   { label: "240v" },
  //   { label: "50HZ" },
  // ],

  //   Inverters

  // Backup_time: [
  //   { label: "2 hrs" },
  //   { label: "3 hrs" },
  //   { label: "4 hrs" },
  //   { label: "5 hrs" },
  //   { label: "6 hrs" },
  //   { label: "7 hrs" },
  //   { label: "8 hrs" },
  //   { label: "9 hrs" },
  //   { label: "10 hrs" },
  // ],
  Type: [
    { label: "sine wave " },
    { label: "hybrid " },
    { label: "off grid  " },
    { label: "grid tie " },
  ],
  // Material: [
  //   { label: "Plastic" },
  //   { label: "metal" },
  //   { label: "aluminum" },
  //   { label: "copper" },
  //   { label: "steel" },
  // ],
  Efficiency: [{ label: "80%" }, { label: "90%" }, { label: "100%" }],
  Output_voltage: [{ label: "220v" }, { label: "230v" }, { label: "240v" }],
  Output_frequency: [{ label: "50HZ" }, { label: "60HZ" }],
  // Display_type: [{ label: "LED" }, { label: "LCD" }, { label: "digital" }],
  Depth: [
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
    { label: "21cm" },
    { label: "23cm" },
    { label: "24cm" },
    { label: "25cm" },
    { label: "26cm" },
    { label: "27cm" },
    { label: "28cm" },
    { label: "29cm" },
    { label: "30cm" },
    { label: "31cm" },
    { label: "32cm" },
    { label: "33cm" },
    { label: "34cm" },
    { label: "35cm" },
    { label: "36cm" },
    { label: "37cm" },
    { label: "38cm" },
    { label: "39cm" },
    { label: "40cm" },
    { label: "41cm" },
    { label: "42cm" },
    { label: "43cm" },
    { label: "44cm" },
    { label: "45cm" },
    { label: "46cm" },
    { label: "47cm" },
    { label: "48cm" },
    { label: "49cm" },
    { label: "50cm" },
    { label: "51cm" },
  ],

  //   Vaccum cleaners
  Type: [
    { label: "Robot" },
    { label: "stick" },
    { label: "handheld" },
    { label: "Canister" },
    { label: "Upright" },
  ],
  // Suitable_for: [{ label: "tiles" }, { label: "wooden flooring" }],
  Cord_length: [
    { label: "1m" },
    { label: "2m" },
    { label: "3m" },
    { label: "4m" },
    { label: "5m" },
  ],
  Motor_suction_power: [
    { label: "1000 pa" },
    { label: "2000 pa" },
    { label: "3000 pa" },
  ],
  // Power_consumption: [
  //   { label: "0" },
  //   { label: "100w" },
  //   { label: "200w" },
  //   { label: "300w" },
  //   { label: "400w" },
  //   { label: "500w" },
  //   { label: "600w" },
  //   { label: "700w" },
  //   { label: "800w" },
  //   { label: "900w" },
  //   { label: "1000w" },
  // ],

  //   Sewing machine
  type: [
    { label: "mechanical" },
    { label: "electronic" },
    { label: "computerized" },
    { label: "embroidery" },
    { label: "serger" },
  ],
  Number_of_stitches: [
    { label: "5" },
    { label: "10" },
    { label: "15" },
    { label: "20" },
    { label: "25" },
    { label: "30" },
    { label: "35" },
    { label: "40" },
  ],
  Number_of_button_hole_styles: [
    { label: "1" },
    { label: "4" },
    { label: "6" },
    { label: "8" },
    { label: "10" },
  ],
  sweing_speed: [
    { label: "500 spm" },
    { label: "600 spm" },
    { label: "700 spm" },
    { label: "800 spm" },
    { label: "900 spm" },
    { label: "1000 spm" },
    { label: "1100 spm" },
    { label: "1200 spm" },
  ],
  Weight: [
    { label: "5 kg" },
    { label: "6 kg" },
    { label: "7 kg" },
    { label: "8 kg" },
    { label: "9 kg" },
    { label: "10 kg" },
  ],
  //  Voltage stabilizers

  Type: [{ label: "Voltage stabilizer " }],
  Used_for: [
    { label: "refrigerators" },
    { label: "Ac’s" },
    { label: " tvs" },
    { label: "washing machine" },
  ],
  Cooling: [{ label: "Air Cooling" }],
  Material: [
    { label: "plastic" },
    { label: "metal" },
    { label: "steel" },
    { label: "aluminum" },
  ],
  // Mount_type: [
  //   { label: "wall-mount" },
  //   { label: "floor -mount" },
  //   { label: "tabletop" },
  //   { label: "rack-mounted" },
  // ],
  Minimum_input_power: [
    { label: "90v" },
    { label: "100v" },
    { label: "110v" },
    { label: "120v" },
    { label: "130v" },
    { label: "140v" },
    { label: "150v" },
    { label: "160v" },
    { label: "170v" },
    { label: "180v" },
    { label: "190v" },
    { label: "200v" },
  ],
  Temperature: [
    { label: "0 degree C" },
    { label: "1 degree C" },
    { label: "2 degree C" },
    { label: "3 degree C" },
    { label: "4 degree C" },
    { label: "5 degree C" },
    { label: "6 degree C" },
    { label: "7 degree C" },
    { label: "8 degree C" },
    { label: "9 degree C" },
    { label: "10 degree C" },
    { label: "11 degree C" },
    { label: "12 degree C" },
    { label: "14 degree C" },
    { label: "15 degree C" },
    { label: "16 degree C" },
    { label: "17 degree C" },
    { label: "18 degree C" },
    { label: "19 degree C" },
    { label: "20 degree C" },
    { label: "21 degree C" },
    { label: "22 degree C" },
    { label: "23 degree C" },
    { label: "24 degree C" },
    { label: "25 degree C" },
    { label: "26 degree C" },
    { label: "27 degree C" },
    { label: "28 degree C" },
    { label: "29 degree C" },
    { label: "30 degree C" },
    { label: "31 degree C" },
    { label: "32 degree C" },
    { label: "33 degree C" },
    { label: "34 degree C" },
    { label: "35 degree C" },
    { label: "36 degree C" },
    { label: "37 degree C" },
    { label: "38 degree C" },
    { label: "39 degree C" },
    { label: "40 degree C" },
    { label: "41 degree C" },
    { label: "42 degree C" },
    { label: "43 degree C" },
    { label: "44 degree C" },
    { label: "45 degree C" },
  ],
  // Weight: [
  //   { label: "1 kg" },
  //   { label: "2 kg" },
  //   { label: "3 kg" },
  //   { label: "4 kg" },
  //   { label: "5 kg" },
  //   { label: "6 kg" },
  //   { label: "7 kg" },
  //   { label: "8 kg" },
  //   { label: "9 kg" },
  //   { label: "10 kg" },
  // ],

  //   Water geysers
  Capacity: [
    { label: "1 L" },
    { label: "2 L" },
    { label: "3 L" },
    { label: "4 L" },
    { label: "5 L" },
    { label: "6 L" },
    { label: "7 L" },
    { label: "8 L" },
    { label: "9 L" },
    { label: "10 L" },
    { label: "11 L" },
    { label: "12 L" },
    { label: "13 L" },
    { label: "14 L" },
    { label: "15 L" },
    { label: "16 L" },
    { label: "17 L" },
    { label: "18 L" },
    { label: "19 L" },
    { label: "20 L" },
    { label: "21 L" },
    { label: "22 L" },
    { label: "23 L" },
    { label: "24 L" },
    { label: "25 L" },
    { label: "26 L" },
    { label: "27 L" },
    { label: "28 L" },
    { label: "29 L" },
    { label: "30 L" },
    { label: "31 L" },
    { label: "32 L" },
    { label: "33 L" },
    { label: "34 L" },
    { label: "35 L" },
    { label: "36 L" },
    { label: "37 L" },
    { label: "38 L" },
    { label: "39 L" },
    { label: "40 L" },
    { label: "41 L" },
    { label: "42 L" },
    { label: "43 L" },
    { label: "44 L" },
    { label: "45 L" },
    { label: "46 L" },
    { label: "47 L" },
    { label: "48 L" },
    { label: "49 L" },
    { label: "50 L" },
  ],
  // Type: [
  //   { label: "instant" },
  //   { label: "storage" },
  //   { label: "glass" },
  //   { label: "solor" },
  //   { label: "immersion" },
  // ],
  // Mount_type: [{ label: "vertica" }, { label: "horizontal" }],
  Star_rating: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Material: [
    { label: "Stainless steel" },
    { label: "plastic" },
    { label: "glass coated" },
    { label: "copper" },
  ],
  // Power_consumption: [
  //   { label: "1000w" },
  //   { label: "2000w" },
  //   { label: "3000w" },
  //   { label: "4000w" },
  //   { label: "5000w" },
  // ],
  // Power_requirement: [
  //   { label: "220v" },
  //   { label: "230v" },
  //   { label: "240v" },
  //   { label: "50HZ" },
  // ],
  // Weight: [
  //   { label: "1 kg" },
  //   { label: "2 kg" },
  //   { label: "3 kg" },
  //   { label: "4 kg" },
  //   { label: "5 kg" },
  //   { label: "6 kg" },
  //   { label: "7 kg" },
  //   { label: "8 kg" },
  //   { label: "9 kg" },
  //   { label: "10 kg" },
  //   { label: "11 kg" },
  //   { label: "12 kg" },
  //   { label: "13 kg" },
  //   { label: "14 kg" },
  //   { label: "15 kg" },
  //   { label: "16 kg" },
  //   { label: "17 kg" },
  //   { label: "18 kg" },
  //   { label: "19 kg" },
  //   { label: "20 kg" },
  // ],

  //   Immersion rods
  Type: [
    { label: "immersion rod" },
    { label: "immersion heater" },
    { label: "submersible heater" },
  ],
  Heater_material: [
    { label: "copper" },
    { label: "stainless steel" },
    { label: "titanium" },
    { label: "incoloy" },
  ],
  Heater_element_type: [
    { label: "tubular" },
    { label: "copper" },
    { label: "stainless steel" },
    { label: "titanium" },
    { label: ",incoloy" },
  ],
  // Power_requirement: [
  //   { label: "220v" },
  //   { label: "230v" },
  //   { label: "240v" },
  //   { label: "50HZ" },
  // ],
  // Weight: [
  //   { label: "200grams" },
  //   { label: "300grams" },
  //   { label: "400grams" },
  //   { label: "500grams" },
  //   { label: "600grams" },
  //   { label: "700grams" },
  //   { label: "800grams" },
  //   { label: "900grams" },
  //   { label: "1000grams" },
  // ],

  //   Other Home Environment
  //   Energizing Therapies
  Type: [
    { label: "Soundbars" },
    { label: "home theater" },
    { label: "Av Receivers" },
    { label: "Streaming" },
  ],
  Configuration: [
    { label: "4.0" },
    { label: "4.1" },
    { label: "4.2" },
    { label: "5.0" },
    { label: "5.1" },
  ],
  // Power_source: [{ label: "AC Adapter" }],
 
  Number_of_batteries:[
{label : "1"},
{label :"0"}
  ],
 Output_for_speakers :[
  { label: "5w" },
  { label: "10w" },
  { label: "20w" },
  { label: "30w" },
  { label: "40w" },
  { label: "50w" },
  { label: "60w" },
  { label: "70w" },
  { label: "80w" },
  { label: "90w" },
  { label: "100w" },
  { label: "110w" },
  { label: "120w" },
  { label: "130w" },
  { label: "140w" },
  { label: "150w" },
  { label: "160w" },
  { label: "170w" },
  { label: "180w" },
  { label: "190w" },
  { label: "200w" },
  { label: "300w" },
  { label: "400w" },
  { label: "500w" },
 ], 
  Power_output_rms: [
    { label: "10w" },
    { label: "20w" },
    { label: "30w" },
    { label: "40w" },
    { label: "50w" },
    { label: "60w" },
    { label: "70w" },
    { label: "80w" },
    { label: "90w" },
    { label: "100w" },
    { label: "110w" },
    { label: "120w" },
    { label: "130w" },
    { label: "140w" },
    { label: "150w" },
    { label: "160w" },
    { label: "170w" },
    { label: "180w" },
    { label: "190w" },
    { label: "200w" },
  ],
  // Impedance: [{ label: "4 ohms" }, { label: "6 ohms" }, { label: "8 ohms" }],
  // Wired_wireless: [{ label: "wired" }, { label: "wireless" }],
  // Compatable_devices: [
  //   { label: "televsions" },
  //   { label: "laptops" },
  //   { label: "mobiles" },
  //   { label: "tablets" },
  //   { label: "computers" },
  // ],
  // Usb_ports: [{ label: "1 usb ports" }],

  //   Projector & accessories
  Maximum_brightness: [
    { label: "1000Lm" },
    { label: "2000Lm" },
    { label: "3000Lm" },
    { label: "4000Lm" },
    { label: "5000Lm" },
    { label: "6000Lm" },
    { label: "7000Lm" },
    { label: "8000Lm" },
    { label: "9000Lm" },
    { label: "10000Lm" },
  ],

  //   Set top boxes
  Display_resolution: [
    { label: "720p" },
    { label: "1080p" },
    { label: "2160p" },
    { label: "1440p" },
  ],
  Remote_features: [{ label: "universal remote with batteries" }],
  Technology: [
    { label: "MPEG" },
    { label: "RF" },
    { label: "DVB" },
    { label: "HEVC" },
  ],
  // Connectivity: [
  //   { label: "HDMI" },
  //   { label: "RCA" },
  //   { label: "DLNA" },
  //   { label: "CVBS" },
  // ],
  Aspect_ratio: [{ label: "16:9" }, { label: "4:3" }, { label: "21: 9" }],
  // Weight: [
  //   { label: "300g" },
  //   { label: "400g" },
  //   { label: "500g" },
  //   { label: "600g" },
  //   { label: "700g" },
  //   { label: "800g" },
  //   { label: "900g" },
  //   { label: "1000g" },
  // ],

  // ==============================================TV & HOME ENTERTAINMENT======================================//

  Display_size: [
    { label: " 32inch(81 cm )" },
    { label: "40inch(102cm)" },
    { label: "50 inch(127cm)" },
    { label: "55inch (140cm)" },
    { label: "65inch (165cm)" },
  ],
  Screen_type: [
    { label: "LED" },
    { label: "LCD" },
    { label: "OLED" },
    { label: "QLED" },
    { label: "Smart”" },
  ],
  Hd_technology_resolution: [
    { label: "hd-1280x720" },
    { label: "Full Hd-1920x 1080" },
    { label: "ultra-hd/4k – 3840 x 2160" },
    { label: "8k-7680 x 4320" },
  ],

  Wifi_type: [
    { label: "single band" },
    { label: "tri-band" },
    { label: "dual band" },
  ],
  Usb: [{ label: "1" }, { label: "2" }, { label: "3" }],
  View_angle: [{ label: "178 degree" }],

  Led_display_type: [
    { label: "direct LED" },
    { label: "edge LED" },
    { label: "full array LED" },
  ],
  // Aspect_ratio : [
  //   { label: "16:9" },
  //   { label: "21:9" },
  //   { label: "4:3" },
  //   { label: "16:10" },
  // ],
  Refresh_rate: [{ label: "60hz" }, { label: "120Hz" }, { label: "240 Hz" }],
  Number_of_speakers: [{ label: "2" }, { label: "4" }, { label: "6" }],
  Speaker_type: [{ label: "Down- firing" }, { label: "front -firing" }],
  Speaker_output_RMS: [
    { label: "10 w" },
    { label: "11 w" },
    { label: "12 w" },
    { label: "13 w" },
    { label: "14 w" },
    { label: "15 w" },
    { label: "16 w" },
    { label: "17 w" },
    { label: "18 w" },
    { label: "19 w" },
    { label: "20 w" },
    { label: "21 w" },
    { label: "22 w" },
    { label: "23 w" },
    { label: "24 w" },
    { label: "25 w" },
    { label: "26 w" },
    { label: "27 w" },
    { label: "28 w" },
    { label: "29 w" },
    { label: "30 w" },
  ],
  Sensitivity:[
    { label: "85 db" },
    { label: "86 db" },
    { label: "87 db" },
    { label: "88 db" },
    { label: "89 db" },
    { label: "90 db" },
    { label: "92 db" },
    { label: "95 db" },
    { label: "97 db" },
    { label: "98 db" },
    { label: "99 db" },
    { label: "100 db" },

  ],
  Sound_mode: [
    { label: "standard" },
    { label: "music" },
    { label: "movie" },
    { label: "sports" },
    { label: "game" },
  ],
  Processor: [
    { label: "quantum" },
    { label: "Alpha" },
    { label: "X1" },
    { label: "AiPQ" },
    { label: "IQ  active" },
    { label: "Quad core" },
  ],
  Usb_version:[
    { label: "2.0" },
    { label: "3.0" },

  ],
  // Graphic_processor : [
  //   { label: "  Mali-GPU" },
  //   { label: "Aderno-GPU" },
  //   { label: "bravia XR GPU" },
  //   { label: "Dual core GPU" },
  //   { label: "Pixelworks Processor" },
  // ],
  Ram_capacity: [{ label: "1gb" }, { label: "2gb" }],
  Storage_memory: [
    { label: "8 gb" },
    { label: "9 gb" },
    { label: "10 gb" },
    { label: "11 gb" },
    { label: "12 gb" },
    { label: "13 gb" },
    { label: "14 gb" },
    { label: "15 gb" },
    { label: "16 gb" },
  ],
  Supported_Mobile_operating_system: [{ label: "Android " }, { label: "ios" }],
  Operating_system: [{ label: "Google Tv" }],
  Supported_devices_for_casting: [
    { label: "laptop " },
    { label: "mobile " },
    { label: "PC" },
  ],
  // Hdmi : [
  //   { label: "1" },
  //   { label: "2" },
  // ],
  // usb: [
  //   { label: "1" },
  //   { label: "2" },
  // ],
  Battery_type: [{ label: "AAA" }, { label: "Small sized" }],
  Power_requirement: [{ label: "220-240v, 50Hz" }],
  Power_consumption: [
    { label: "10 w" },
    { label: "20 w" },
    { label: "30 w" },
    { label: "40 w" },
    { label: "50 w" },
    { label: "60 w" },
    { label: "70 w" },
    { label: "80 w" },
    { label: "90 w" },
    { label: "100 w" },
  ],
  tv_width: [
    { label: "700 mm" },
    { label: "800 mm" },
    { label: "900 mm" },
    { label: "1000 mm" },
  ],
  tv_height: [{ label: "400 mm" }, { label: "500 mm" }, { label: "600 mm" }],
  tv_weight: [
    { label: "5 kg" },
    { label: "6 kg" },
    { label: "7 kg" },
    { label: "8 kg" },
    { label: "9 kg" },
    { label: "10 kg" },
    { label: "11 kg" },
    { label: "12 kg" },
    { label: "13 kg" },
    { label: "14 kg" },
    { label: "15 kg" },
    { label: "16 kg" },
    { label: "17 kg" },
    { label: "18 kg" },
    { label: "19 kg" },
    { label: "20 kg" },
  ],
  tv_depth: [
    { label: "50 mm" },
    { label: "100 mm" },
    { label: "150 mm" },
    { label: "200 mm" },
  ],
  Stand_type: [{ label: "Table top " }, { label: "wall mount " }],
  //Mobile Charger :
  Designed_for: [{ label: "type-C " }, { label: "type-A" }],
  Number_of_charger_pins: [{ label: "1" }, { label: "2" }, { label: "3" }],
  Mobile_Charger_width: [
    { label: "30 mm" },
    { label: "31 mm" },
    { label: "32 mm" },
    { label: "33 mm" },
    { label: "34 mm" },
    { label: "35 mm" },
    { label: "36 mm" },
    { label: "37 mm" },
    { label: "38 mm" },
    { label: "39 mm" },
    { label: "40 mm" },
    { label: "41 mm" },
    { label: "42 mm" },
    { label: "43 mm" },
    { label: "44 mm" },
    { label: "45 mm" },
    { label: "46 mm" },
    { label: "47 mm" },
    { label: "48 mm" },
    { label: "49 mm" },
    { label: "50 mm" },
  ],
  Mobile_Charger_height: [
    { label: "40 mm" },
    { label: "41 mm" },
    { label: "42 mm" },
    { label: "43 mm" },
    { label: "44 mm" },
    { label: "45 mm" },
    { label: "46 mm" },
    { label: "47 mm" },
    { label: "48 mm" },
    { label: "49 mm" },
    { label: "50 mm" },
    { label: "51 mm" },
    { label: "52 mm" },
    { label: "53 mm" },
    { label: "54 mm" },
    { label: "55 mm" },
    { label: "56 mm" },
    { label: "57 mm" },
    { label: "58 mm" },
    { label: "59 mm" },
    { label: "60 mm" },
    { label: "61 mm" },
    { label: "62 mm" },
    { label: "63 mm" },
    { label: "64 mm" },
    { label: "65 mm" },
    { label: "66 mm" },
    { label: "67 mm" },
    { label: "68 mm" },
    { label: "69 mm" },
    { label: "70 mm" },
    { label: "71 mm" },
    { label: "72 mm" },
    { label: "73 mm" },
    { label: "74 mm" },
    { label: "75 mm" },
    { label: "76 mm" },
    { label: "77 mm" },
    { label: "78 mm" },
    { label: "79 mm" },
    { label: "80 mm" },
  ],
  Mobile_Charger_depth: [
    { label: "20mm" },
    { label: "21mm" },
    { label: "22mm" },
    { label: "23mm" },
    { label: "24mm" },
    { label: "25mm" },
    { label: "26mm" },
    { label: "27mm" },
    { label: "28mm" },
    { label: "29mm" },
    { label: "30 mm" },
    { label: "31 mm" },
    { label: "32 mm" },
    { label: "33 mm" },
    { label: "34 mm" },
    { label: "35 mm" },
    { label: "36 mm" },
    { label: "37 mm" },
    { label: "38 mm" },
    { label: "39 mm" },
    { label: "40 mm" },
  ],
  Mobile_Charger_weight: [
    { label: "10 g" },
    { label: "20 g" },
    { label: "30 g" },
    { label: "40 g" },
    { label: "50 g" },
    { label: "60 g" },
    { label: "70 g" },
    { label: "80 g" },
    { label: "90 g" },
    { label: "100 g" },
    { label: "110 g" },
    { label: "120 g" },
    { label: "130 g" },
    { label: "140 g" },
    { label: "150 g" },
    { label: "160 g" },
    { label: "170 g" },
    { label: "180 g" },
    { label: "190 g" },
    { label: "200 g" },
  ],
  Cable_type: [{ label: "adaptor only" }],
  Power_source: [{ label: "220-240v,50Hz" }],
  Output_current: [
    { label: "2 A" },
    { label: "4 A" },
    { label: "6 A" },
    { label: "8 A" },
    { label: "10 A" },
  ],
  Output_wattage: [
    { label: "18 w" },
    { label: "20 w" },
    { label: "22 w" },
    { label: "24 w" },
    { label: "26 w" },
    { label: "28 w" },
    { label: "30 w" },
    { label: "32 w" },
    { label: "34 w" },
    { label: "36 w" },
    { label: "38 w" },
    { label: "40 w" },
    { label: "42 w" },
    { label: "44 w" },
    { label: "46 w" },
    { label: " 48 w" },
    { label: "50 w" },
    { label: "52 w" },
    { label: "54 w" },
    { label: "56 w" },
    { label: "58 w" },
    { label: "60 w" },
    { label: "62 w" },
    { label: "64 w" },
    { label: "66 w" },
    { label: "68 w" },
    { label: "70 w" },
    { label: "72 w" },
    { label: "74 w" },
    { label: "76 w" },
    { label: "78 w" },
    { label: "80 w" },
    { label: "82 w" },
    { label: "84 w" },
    { label: "86 w" },
    { label: "88 w" },
    { label: "90 w" },
    { label: "92 w" },
    { label: "94 w" },
    { label: "96 w" },
    { label: "98 w" },
    { label: "100 w" },
    { label: "102 w" },
    { label: "104 w" },
    { label: "106 w" },
    { label: "108 w" },
    { label: "110 w" },
    { label: "112 w" },
    { label: "114 w" },
    { label: "116 w" },
    { label: "118 w" },
    { label: "120 w" },
  ],
  //Headphone and headset
  Headphone_type: [
    { label: "in  ear " },
    { label: "over ear " },
    { label: ",on ear" },
  ],
  Connectivity: [
    { label: "blutooth / wire" },
    { label: "wire" },
    { label: "blutooth" },
  ],
  Headphone_design: [
    { label: "over the head" },
    { label: "earbud" },
    { label: "neckband" },
  ],
  Compatable_devices: [
    { label: "audio player" },
    { label: "laptop" },
    { label: "mobile" },
    { label: "tablet" },
    { label: "television" },
  ],
  Wireless_range: [
    { label: "5 m" },
    { label: "6 m" },
    { label: "7 m" },
    { label: "8 m" },
    { label: "9 m" },
    { label: "10 m" },
    { label: "11 m" },
    { label: "12 m" },
    { label: "13 m" },
    { label: "14 m" },
    { label: "15 m" },
  ],
  Bluetooth_Version: [
    { label: "4.0" },
    { label: "4.1" },
    { label: "4.2" },
    { label: "4.3" },
    { label: "4.4" },
    { label: "4.5" },
    { label: "4.6" },
    { label: "4.7" },
    { label: "4.8" },
    { label: "4.9" },
    { label: "5.0" },
    { label: "5.1" },
    { label: "5.2" },
  ],
  Play_Time: [
    { label: "14 hrs" },
    { label: "20 hrs" },
    { label: "26 hrs" },
    { label: "32 hrs" },
    { label: "38 hrs" },
    { label: "44 hrs" },
    { label: "50 hrs" },
    { label: "56 hrs" },
    { label: "62 hrs" },
    { label: "68 hrs" },
    { label: "74 hrs" },
    { label: "80 hrs" },
    { label: "86 hrs" },
    { label: "92 hrs" },
    { label: "98 hrs" },
    { label: "104 hrs" },
    { label: "110 hrs" },
    { label: "116 hrs" },
    { label: "112 hrs" },
    { label: "118 hrs" },
    { label: "120 hrs" },
  ],
  //Power Banks :
  Suitable_device: [{ label: "mobile" }],
  Number_of_output_ports: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Power_supply: [
    { label: " USB-C : 5 V/3 A" },
    { label: "9 V/2 A" },
    { label: "12 V/1.5 A" },
    { label: "Micro-USB: 5 V/2 A" },
    { label: " 9 V/2 A" },
    { label: "12 V/1.5 A" },
  ],
  Output_power: [
    { label: "USB-A: 5 V/2.4 A" },
    { label: "9 V/2 A" },
    { label: "12 V/1.5 A" },
    { label: "USB-C: 5 V/3 A" },
    { label: "9 V/2 A" },
    { label: "12 V/1.5 A" },
    { label: "Triple Port: 5 V/3.6 A" },
  ],
  power_bank_width: [
    { label: "50 mm" },
    { label: "55 mm" },
    { label: "60 mm" },
    { label: "65 mm" },
    { label: "70 mm" },
    { label: "75 mm" },
    { label: "80 mm" },
  ],
  power_bank_height: [
    { label: "100 mm" },
    { label: "105 mm" },
    { label: "110 mm" },
    { label: "115 mm" },
    { label: "120 mm" },
    { label: "125 mm" },
    { label: "130 mm" },
    { label: "135 mm" },
    { label: "140 mm" },
    { label: "145 mm" },
    { label: "150 mm" },
    { label: "155 mm" },
    { label: "160 mm" },
    { label: "165 mm" },
    { label: "170 mm" },
    { label: "175 mm" },
    { label: "180 mm" },
    { label: "185 mm" },
    { label: "190 mm" },
    { label: "195 mm" },
    { label: "200 mm" },
  ],
  power_bank_depth: [
    { label: "1mm" },
    { label: "2 mm" },
    { label: "3 mm" },
    { label: "4 mm" },
    { label: "5 mm" },
    { label: "6 mm" },
    { label: "7 mm" },
    { label: "8 mm" },
    { label: "9 mm" },
    { label: "10 mm" },
  ],
  power_bank_weight: [
    { label: "100 g" },
    { label: "105 g" },
    { label: "110 g" },
    { label: "115 g" },
    { label: "120 g" },
    { label: "125 g" },
    { label: "130 g" },
    { label: "135 g" },
    { label: "140 g" },
    { label: "145 g" },
    { label: "150 g" },
  ],
  //Dvd & blueray players
  Video_player_type: [
    { label: "DVD player" },
    { label: "blu-ray player" },
    { label: "Media Player" },
    { label: "streaming media player" },
    { label: "smart tv" },
  ],

  Display_type: [
    { label: "LCD" },
    { label: "LED" },
    { label: "OLED" },
    { label: "CRT" },
    { label: "Projection display" },
  ],
  Display_resolution: [
    { label: "720 p" },
    { label: "1080 p" },
    { label: "2160 p" },
  ],
  Height_width_x_depth: [
    { label: "20 cm x 5cm x 20cm " },
    { label: "30 cm x 10cm x 30cm " },
    { label: "35 cm x 15cm x 40cm " },
    { label: "40 cm x 20cm x 50cm " },
  ],
  Weight: [
    { label: "1 kg" },
    { label: "2 kg" },
    { label: "3 kg" },
    { label: "4 kg" },
    { label: "5 kg" },
  ],

  //Streaming media players
  streaming_type: [{ label: "hdmi" }],
  Ideal_for: [
    { label: "LED" },
    { label: "LCD" },
    { label: "OLED" },
    { label: "QLED" },
  ],
  Number_of_devices_supported: [{ label: "1" }, { label: "2" }, { label: "3" }],
  Housing_material: [{ label: "plastic" }],
  Control_type: [{ label: "Manual" }, { label: "auto" }],
  Video_resolution: [
    { label: "720 p" },
    { label: "1080 p" },
    { label: "2160 p" },
  ],
  Maximum_refresh_rate_supported: [
    { label: "60hz" },
    { label: "120 hz" },
    { label: "240hz" },
  ],
  Power_requirement: [{ label: "DC 5 -10V" }],
  Weight: [
    { label: "20 g" },
    { label: "21 g" },
    { label: "22 g" },
    { label: "23 g" },
    { label: "24 g" },
    { label: "25 g" },
    { label: "26 g" },
    { label: "27 g" },
    { label: "28 g" },
    { label: "29 g" },
    { label: "31 g" },
    { label: "32 g" },
    { label: "33 g" },
    { label: "34 g" },
    { label: "35 g" },
    { label: "36 g" },
    { label: "37 g" },
    { label: "38 g" },
    { label: "39 g" },
    { label: "40 g" },
    { label: "41 g" },
    { label: "42 g" },
    { label: "43 g" },
    { label: "44 g" },
    { label: "45 g" },
    { label: "46 g" },
    { label: "47 g" },
    { label: "48 g" },
    { label: "49 g" },
    { label: "50 g" },
  ],
  Width_x_height_x_depth: [
    { label: "20 mm x 20mm x 5mm" },
    { label: "25 mm x 20mm x 10mm" },
    { label: "28 mm x 20mm x 15mm" },
    { label: "30 mm x 20mm x 20mm" },
    { label: "35 mm x 20mm x 25mm" },
    { label: "38 mm x 20mm x 30mm" },
    { label: "40 mm x 20mm x 35mm" },
    { label: "45 mm x 20mm x 40mm" },
    { label: "48 mm x 20mm x 45mm" },
    { label: "50 mm x 20mm x 50mm" },
  ],

  Pack_of: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  Width: [
    { label: "10mm" },
    { label: "11mm" },
    { label: "12mm" },
    { label: "13mm" },
    { label: "14mm" },
    { label: "15mm" },
    { label: "16mm" },
    { label: "17mm" },
    { label: "18mm" },
    { label: "19mm" },
    { label: "20mm" },
  ],
  Height: [
    { label: "5mm" },
    { label: "6mm" },
    { label: "7mm" },
    { label: "8mm" },
    { label: "9mm" },
    { label: "10mm" },
    { label: "11mm" },
    { label: "12mm" },
    { label: "13mm" },
    { label: "14mm" },
    { label: "40mm" },
    { label: "50mm" },
    { label: "60mm" },
    { label: "70mm" },
    { label: "80mm" },
    { label: "90mm" },
    { label: "100mm" },
    { label: "110mm" },
    { label: "120mm" },
  ],
  Depth: [
    { label: "0.5mm" },
    { label: "0.6mm" },
    { label: "0.7mm" },
    { label: "0.8mm" },
    { label: "0.9mm" },
    { label: "1mm" },
    { label: "1.1mm" },
    { label: "1.2mm" },
    { label: "1.3mm" },
    { label: "1.4mm" },
    { label: "1.5mm" },
    { label: "1.6mm" },
    { label: "1.7mm" },
    { label: "1.8mm" },
    { label: "1.9mm" },
    { label: "2mm" },
  ],
  Weight: [
    { label: "1g" },
    { label: "2g" },
    { label: "3g" },
    { label: "4g" },
    { label: "5g" },
    { label: "6g" },
    { label: "7g" },
    { label: "8g" },
    { label: "9g" },
    { label: "10g" },
  ],
  Net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],

  //-----------------------Smart headphones---------------------------

  Usage: [{ label: "gaming" }, { label: "entertainment" }],
  Operating_system_supported: [
    { label: "all smart phones" },
    { label: "Laptops" },
  ],
  Type_of_tracking: [
    { label: "fitness" },
    { label: "heartrate" },
    { label: "activity" },
  ],
  Music_time: [
    { label: "14hrs" },
    { label: "22hrs" },
    { label: "30hrs" },
    { label: "36hrs" },
    { label: "42hrs" },
    { label: "48hrs" },
    { label: "54hrs" },
    { label: "60hrs" },
    { label: "66hrs" },
    { label: "72hrs" },
    { label: "78hrs" },
    { label: "84hrs" },
    { label: "90hrs" },
    { label: "96hrs" },
    { label: "102hrs" },
    { label: "108hrs" },
    { label: "114hrs" },
    { label: "120hrs" },
  ],
  Bluetooth_version: [
    { label: "4" },
    { label: "4.1" },
    { label: "4.2" },
    { label: "4.3" },
    { label: "4.4" },
    { label: "4.5" },
    { label: "4.6" },
    { label: "4.7" },
    { label: "4.8" },
    { label: "4.9" },
    { label: "5.1" },
    { label: "5.2" },
    { label: "5.3" },
  ],
  Bluetooth_range: [
    { label: "5m" },
    { label: "10m" },
    { label: "15m" },
    { label: "20m" },
    { label: "25m" },
    { label: "30m" },
  ],

  //-------------------------Mobile holders---------------------------

  Material: [
    { label: "plastic" },
    { label: "aluminum" },
    { label: "polyster" },
    { label: "steel" },
    { label: "silicone" },
  ],

  Compatible_with: [{ label: "Tablets" }, { label: "smartphones" }],

  Type: [{ label: "desktop" }, { label: "hanging" }, { label: "flexible" }],

  Mount_type: [{ label: "clip" }, { label: "Stand" }, { label: "sticky pad" }],

  Width: [
    { label: "50mm" },
    { label: "60mm" },
    { label: "70mm" },
    { label: "80mm" },
    { label: "90mm" },
    { label: "100mm" },
    { label: "110mm" },
    { label: "120mm" },
    { label: "130mm" },
    { label: "140mm" },
    { label: "150mm" },
  ],
  Height: [
    { label: "40mm" },
    { label: "50mm" },
    { label: "60mm" },
    { label: "70mm" },
    { label: "80mm" },
    { label: "90mm" },
    { label: "100mm" },
    { label: "110mm" },
    { label: "120mm" },
  ],
  Weight: [
    { label: "50g" },
    { label: "60g" },
    { label: "70g" },
    { label: "80g" },
    { label: "90g" },
    { label: "100g" },
    { label: "110g" },
    { label: "120g" },
    { label: "130g" },
    { label: "140g" },
    { label: "150g" },
    { label: "160g" },
    { label: "170g" },
    { label: "180g" },
    { label: "190g" },
    { label: "200g" },
  ],

  //--------------------------------Screenguards-----------------------

  Applied_on: [{ label: "front" }, { label: "back" }, { label: "both" }],
  Number_of_layers: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],

  //-------------------------Mobile Cables--------------------------

  Type: [
    { label: "Type c" },
    { label: "USB" },
    { label: "HDMI" },
    { label: "Ethernet cable" },
    { label: "Coaxible cable" },
  ],
  Cable_type: [
    { label: "18w" },
    { label: "21w" },
    { label: "27w" },
    { label: "35w" },
    { label: "45w" },
    { label: "65w" },
    { label: "67w" },
    { label: "78w" },
    { label: "110w" },
    { label: "120w" },
  ],

  Connector_1: [{ label: "type A" }, { label: "3.5mm" }, { label: "type c" }],
  Connector_2: [{ label: "type c" }, { label: "3.5mm" }],
  Connector_material: [{ label: "copper" }, { label: "aluminum" }],

  //----------------------------------Cables ,audio--------------------------

  Cable: [
    { label: "Type c" },
    { label: "USB" },
    { label: "HDMI" },
    { label: "Ethernet cable" },
    { label: "Coaxible cable" },
  ],
  Cable_type: [
    { label: "18w" },
    { label: "21w" },
    { label: "27w" },
    { label: "35w" },
    { label: "45w" },
    { label: "65w" },
    { label: "67w" },
    { label: "78w" },
    { label: "110w" },
    { label: "120w" },
  ],
  Connector_1: [{ label: "type A" }, { label: "3.5mm" }, { label: "type c" }],
  Connector_2: [{ label: "type c" }, { label: "3.5mm" }],
  Connector_material: [{ label: "copper" }, { label: "aluminum" }],
  Compatible_devices: [
    { label: "mobiles" },
    { label: "laptop" },
    { label: "powerbank" },
    { label: "wireless earphones" },
    { label: "speaker" },
    { label: "trimmer" },
    { label: "tablet" },
  ],
  Connector: [{ label: "copper" }, { label: "aluminum" }],
  Core: [{ label: "copper" }, { label: "aluminum" }],
  Cord_length: [{ label: "1m" }, { label: "2m" }, { label: "3m" }],
  Interface: [{ label: "1" }, { label: "2" }, { label: "3" }],
  Net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Maximum_current_rating: [
    { label: "5A" },
    { label: "10A" },
    { label: "15A" },
    { label: "20A" },
  ],

  //-----------------------------------Mobile Cases------------------------
  Net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  Pack_of: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  // ------------FootWear------------------------
  Outer_material: [
    { label: "mesh" },
    { label: "polyester" },
    { label: "nylon" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "jogging" },
    { label: "walking" },
    { label: " gym workouts" },
  ],
  Occasion: [
    { label: "casual outings" },
    { label: "exercise" },
    { label: "sports activities" },
    { label: "informal occasions" },
  ],
  Type_of_sports: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "hiking" },
    { label: "weightlifting" },
    { label: "cross-training" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "TPU (thermoplastic polyurethane)" },
  ],
  Closure: [
    { label: "laces" },
    { label: "hook-and-loop straps (Velcro)" },
    { label: "zippers" },
    { label: "slip-on designs" },
  ],
  Upper_pattern: [
    { label: "mesh panels" },
    { label: "overlays" },
    { label: "stitching patterns" },
    { label: "designed to provide support" },
    { label: "breathability" },
    { label: "flexibility" },
    { label: "aesthetic appeal" },
  ],
  Season: [{ label: "summer" }, { label: "rainy" }, { label: "snow" }],
  // ----------------------Casual Shoes----------------------------------
  Outer_material: [
    { label: "leather" },
    { label: "canvas" },
    { label: "suede" },
    { label: "synthetic fabrics" },
  ],
  Ideal_for: [
    { label: "everyday wear" },
    { label: "social gatherings" },
    { label: "informal outings" },
    { label: "relaxed settings" },
  ],
  Occasion: [
    { label: "hanging out with friends" },
    { label: "going to casual dinners or parties" },
    { label: "running errands" },
    { label: "simply enjoying leisure time" },
  ],
  Type_of_casual: [
    { label: "sneakers" },
    { label: "loafers" },
    { label: "boat shoes" },
    { label: "moccasins" },
    { label: "slip-ons" },
    { label: "sandals" },
    { label: "espadrilles" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "TPU (thermoplastic polyurethane)" },
    { label: "synthetic compounds" },
  ],
  Closure: [
    { label: "laces" },
    { label: "hook-and-loop straps (Velcro)" },
    { label: "buckles" },
    { label: "elastic bands" },
    { label: "slip-on designs" },
  ],
  Upper_pattern: [
    { label: "stitching patterns" },
    { label: "overlays" },
    { label: "perforations" },
    { label: "embossed designs" },
  ],
  Tip_shape: [{ label: "rounded to pointed" }, { label: "even square" }],

  // ---------------formal shoes--------------------------------------

  Outer_material: [{ label: "leather" }, { label: "synthetic leather" }],
  Ideal_for: [
    { label: "professional environments" },
    { label: "business meetings" },
    { label: "formal events" },
  ],
  Occasion: [
    { label: "weddings" },
    { label: "business meetings" },
    { label: "job interviews" },
    { label: "gala dinners" },
  ],
  Type_of_formal: [
    { label: "Oxford shoes" },
    { label: "Derby shoes" },
    { label: "Brogues" },
    { label: "Monk straps" },
    { label: "Loafers" },
    { label: "Dress boots" },
  ],
  Closure: [
    { label: "laces" },
    { label: "buckles" },
    { label: "slip-on designs" },
  ],
  Season: [{ label: "summer" }, { label: "rainy" }, { label: "winter" }],

  //-----------------------------Sandles & Floaters ---------------------------

  Type: [
    { label: "Flip-flops" },
    { label: "Slides" },
    { label: "Gladiator sandals" },
    { label: "Espadrilles" },
    { label: "Sport sandals" },
    { label: "Floaters" },
  ],
  Closure: [
    { label: "Straps with buckles" },
    { label: " Velcro straps" },
    { label: "slip-on designs" },
    { label: "Elastic bands" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "synthetic compounds" },
  ],

  // ------------------------Flip- flops  -----------------------------------

  Ideal_for: [
    { label: "casual wear" },
    { label: "beach outings" },
    { label: "poolside relaxation" },
    { label: "any laid-back occasion" },
  ],

  // -------------------------------Loafers  ------------------------------

  Outer_material: [{ label: "leather" }, { label: "suede" }],
  Type_of_casual: [
    { label: "slip-on design" },
    { label: "low heels" },
    { label: "a moccasin-style construction" },
  ],
  Ideal_for: [
    { label: "social gatherings" },
    { label: "weekend outings" },
    { label: "relaxed workplaces" },
  ],
  Occasion: [
    { label: "Casual Fridays" },
    { label: "Weekend outings" },
    { label: "Barbecues or picnics" },
    { label: "Casual dinners or dates" },
    { label: "Shopping trips or errands" },
    { label: "Traveling" },
    { label: "sightseeing" },
  ],

  // -------------------------Boots------------------------------------

  Outer_material: [
    { label: "leather genuine" },
    { label: "synthetic leather" },
    { label: "suede" },
    { label: "canvas" },
    { label: "rubber" },
  ],
  Ideal_for: [{ label: "hiking" }, { label: "camping" }, { label: "hunting" }],
  Occasion: [
    { label: "Casual outings" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Work" },
  ],
  Type_of_casual: [
    { label: "Chelsea boots" },
    { label: "Chukka boots" },
    { label: "Desert boots" },
    { label: "Ankle boots" },
    { label: "Combat boots" },
    { label: "Hiking boots" },
    { label: "Work boots" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "polyurethane (PU)" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "thermoplastic polyurethane (TPU)" },
    { label: "tleather" },
  ],

  // ----------------------Running Shoes-----------------------------------

  Outer_material: [
    { label: "mesh" },
    { label: "polyester" },
    { label: "nylon" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "jogging" },
    { label: "walking" },
    { label: " gym workouts" },
  ],
  Occasion: [
    { label: "casual outings" },
    { label: "exercise" },
    { label: "sports activities" },
    { label: "informal occasions" },
  ],
  Type_of_sports: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "hiking" },
    { label: "weightlifting" },
    { label: "cross-training" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "TPU (thermoplastic polyurethane)" },
  ],
  Closure: [
    { label: "laces" },
    { label: "hook-and-loop straps (Velcro)" },
    { label: "zippers" },
    { label: "slip-on designs" },
  ],
  Upper_pattern: [
    { label: "mesh panels" },
    { label: "overlays" },
    { label: "stitching patterns" },
    { label: "designed to provide support" },
    { label: "breathability" },
    { label: "flexibility" },
    { label: "aesthetic appeal" },
  ],
  Weight: [
    { label: "100g" },
    { label: "200g" },
    { label: "300g" },
    { label: "400g" },
    { label: "500g" },
    { label: "600g" },
    { label: "700g" },
  ],
  Season: [{ label: "summer" }, { label: "rainy" }, { label: "snow" }],

  // ------------------------Sneakers---------------------------------

  Outer_material: [
    { label: "leather" },
    { label: "canvas" },
    { label: "suede" },
    { label: "synthetic fabrics" },
  ],
  Ideal_for: [
    { label: "everyday wear" },
    { label: "social gatherings" },
    { label: "informal outings" },
    { label: "relaxed settings" },
  ],
  Occasion: [
    { label: "hanging out with friends" },
    { label: "going to casual dinners or parties" },
    { label: "running errands" },
    { label: "simply enjoying leisure time" },
  ],
  Type_of_casual: [
    { label: "sneakers" },
    { label: "loafers" },
    { label: "boat shoes" },
    { label: "moccasins" },
    { label: "slip-ons" },
    { label: "sandals" },
    { label: "espadrilles" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "TPU (thermoplastic polyurethane)" },
    { label: "synthetic compounds" },
  ],
  Closure: [
    { label: "laces" },
    { label: "hook-and-loop straps (Velcro)" },
    { label: "buckles" },
    { label: "slip-on designs" },
    { label: "elastic bands" },
  ],
  Upper_pattern: [
    { label: "stitching patterns" },
    { label: "overlays" },
    { label: "stitching patterns" },
    { label: "perforations" },
    { label: "embossed designs" },
  ],
  Tip_shape: [{ label: "rounded to pointed" }, { label: "even square" }],

  // -------------------------WOMENS-FLATS---------------------------------

  Type: [
    { label: "Ballet flats" },
    { label: "loafers" },
    { label: "espadrilles" },
    { label: "moccasins" },
    { label: "D’Orsay Flats" },
    { label: "Pointed-Toe Flats" },
    { label: "Mary jane flats" },
    { label: "slingback flates" },
    { label: "mules" },
    { label: "T-strap Flats" },
  ],
  Type_of_flats: [
    { label: "Ballet flats" },
    { label: "loafers" },
    { label: "espadrilles" },
    { label: " moccasins D’orsay" },
    { label: "pointed –Toe flats" },
    { label: "Mary jane flats" },
    { label: "slingback flats" },
    { label: "Mules" },
    { label: "T-strap flats" },
  ],

  // ----------------------------------Heels--------------------------

  Type: [
    { label: "Stiletto" },
    { label: "block" },
    { label: "kitten" },
    { label: "wedge" },
    { label: "platform" },
    { label: "cone" },
    { label: "slingback" },
    { label: "peep-top" },
    { label: "ankle strap" },
    { label: "chunky heels" },
    { label: "sandals" },
  ],
  Type_of_heels: [
    { label: "1 inch" },
    { label: "1.5 inch" },
    { label: "2 inch" },
    { label: "2.5 inch" },
    { label: "3 inch" },
    { label: "3.5 inch" },
    { label: "4 inch" },
    { label: "4.5 inch" },
    { label: "5 inch" },
    { label: "5.5 inch" },
    { label: "6 inch" },
  ],
  Upper_pattern: [
    { label: "solid" },
    { label: "plain" },
    { label: "textured" },
    { label: "printed" },
    { label: "embellished" },
    { label: "perforated" },
    { label: "cut-out" },
    { label: "striped" },
    { label: "checkered/plaid" },
  ],

  // ----------------------------------Wedges-----------------------------------

  Type: [
    { label: "pumps" },
    { label: "platform" },
    { label: "cork" },
    { label: "espadrille" },
    { label: "wood" },
    { label: "peep-top" },
    { label: "slingback" },
    { label: "ankle" },
    { label: "lace-up" },
    { label: "T-Strap" },
  ],
  Type_of_Wedges: [
    { label: "Stiletto" },
    { label: "block" },
    { label: "kitten" },
    { label: "wedge" },
    { label: "platform" },
    { label: "cone" },
    { label: "slingback" },
    { label: "peep-top" },
    { label: "ankle strap" },
    { label: "chunky heels" },
    { label: "sandals" },
  ],

  // ------------------------------------Sports Shoes------------------------------

  Type: [
    { label: "Running" },
    { label: "training" },
    { label: "basketball" },
    { label: "Soccer cleats" },
    { label: "Tennis" },
    { label: "Golf" },
    { label: "Cross-training" },
    { label: "trail running" },
    { label: "walkin" },
    { label: "hikin boots" },
  ],
  Outer_Material: [
    { label: "Mesh" },
    { label: "synthetic leather" },
    { label: "leather" },
    { label: "knit" },
    { label: "synthetic" },
    { label: "textile" },
    { label: "rubber" },
    { label: "nylon" },
    { label: "polyster" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "tennsi" },
    { label: "golf" },
    { label: "crodd –training" },
    { label: "trail running" },
    { label: "walking shoes" },
    { label: "hiking boots" },
  ],
  Occasion: [
    { label: "regular" },
    { label: "everyday" },
    { label: "athletic" },
    { label: "outdoor" },
    { label: "casual" },
    { label: "formal occasions" },
  ],
  Type_of_sports: [
    { label: "Basketball" },
    { label: "soccer" },
    { label: "tennis" },
    { label: "golf" },
    { label: "running" },
    { label: "cycling" },
    { label: "swimming" },
    { label: "volleyball" },
    { label: "baseball" },
    { label: "surfing" },
    { label: "gymnastics" },
    { label: "skiing" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "TPU" },
    { label: "Phylon" },
    { label: "Carbon Fiber" },
    { label: "Gum Rubber" },
  ],
  Closure: [
    { label: "laces" },
    { label: "Velcro starps" },
    { label: "zippers" },
    { label: "buckles" },
    { label: "slip –on" },
    { label: "boa system" },
    { label: "Elastic" },
  ],
  Season: [
    { label: "Spring" },
    { label: "summer" },
    { label: "fall" },
    { label: "snow" },
    { label: "year-round" },
  ],

  // --------------------------------Causual Shoes---------------------------------

  Outer_material: [
    { label: "Leather" },
    { label: "Canvas" },
    { label: "suede" },
    { label: "synthetic materials" },
    { label: "mesh" },
    { label: "textile" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "tennsi" },
    { label: "golf" },
    { label: "crodd –training" },
    { label: "trail running" },
    { label: "walking shoes" },
    { label: "hiking boots" },
  ],
  Occasion: [
    { label: "regular" },
    { label: "everyday" },
    { label: "athletic" },
    { label: "outdoor" },
    { label: "casual" },
    { label: "formal occasions" },
  ],
  Type_of_causual: [
    { label: "sneakers" },
    { label: "loafers" },
    { label: "boat shoes" },
    { label: "espadrilles" },
    { label: "moccasins" },
    { label: "derby shoes" },
  ],
  Upper_pattern: [
    { label: "solid" },
    { label: "plain" },
    { label: "textured" },
    { label: "printed" },
    { label: "embellished" },
    { label: "perforated" },
  ],
  Tip_shape: [
    { label: "Round" },
    { label: "square" },
    { label: "pointed" },
    { label: "almond" },
    { label: "chisel" },
  ],

  // -------------------------------Boots-------------------------------

  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "fabric" },
    { label: "rubber" },
    { label: "suede" },
    { label: "gore-tex" },
    { label: "textile" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "tennsi" },
    { label: "golf" },
    { label: "crodd –training" },
    { label: "trail running" },
    { label: "walking shoes" },
    { label: "hiking boots" },
  ],
  Occasion: [
    { label: "regular" },
    { label: "everyday" },
    { label: "athletic" },
    { label: "outdoor" },
    { label: "casual" },
    { label: "formal occasions" },
  ],
  Type_of_causual: [
    { label: "sneakers" },
    { label: "loafers" },
    { label: "boat shoes" },
    { label: "espadrilles" },
    { label: "moccasins" },
    { label: "derby shoes" },
  ],
  Season: [
    { label: "Spring" },
    { label: "summer" },
    { label: "fall" },
    { label: "snow" },
    { label: "year-round" },
  ],

  //---------------------- KIDS WEAR-Boys Footwear----------------------------
  //--------------------------------Sandals-------------------------------------

  Number_of_pairs: [
    { label: "1 pairs" },
    { label: "2 pairs" },
    { label: "3 pairs" },
  ],
  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Base_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "leather" },
    { label: "PU" },
    { label: "Foam" },
  ],
  Strap_material: [
    { label: "leather" },
    { label: "synthetic leather" },
    { label: "fabric" },
    { label: "nylon" },
    { label: "rubber" },
    { label: "PU" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],

  // --------------------------------Sports shoes----------------------------------

  Number_of_pairs: [
    { label: "1 pairs" },
    { label: "2 pairs" },
    { label: "3 pairs" },
  ],
  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Type: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "football" },
    { label: "tennis" },
    { label: "hiking" },
    { label: "shoes" },
    { label: "gym shoes" },
  ],
  Sub_type: [
    { label: "Trail Running shoes" },
    { label: "Cross Training" },
    { label: "Racing Flats" },
    { label: "track spikes" },
    { label: "cycling shoes" },
    { label: "wrestling shoes" },
    { label: "golf shoes" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "fabric" },
    { label: "rubber" },
    { label: "suede" },
    { label: "gore-tex" },
    { label: "textile" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],

  // ---------------------Girls footwear Flats & bellies---------------------------

  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Heel_type: [
    { label: "stiletto" },
    { label: "Block" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "platform" },
    { label: "cone" },
    { label: "spool" },
    { label: "louis" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],
  Length: [
    { label: "1 years" },
    { label: "2 years" },
    { label: "3 years" },
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],

  //----------------------------Sports shoes------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],
  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Type: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "football" },
    { label: "tennis" },
    { label: "hiking" },
    { label: "shoes" },
    { label: "gym shoes" },
  ],
  Sub_type: [
    { label: "Trail Running shoes" },
    { label: "Cross Training" },
    { label: "Racing Flats" },
    { label: "track spikes" },
    { label: "cycling shoes" },
    { label: "wrestling shoes" },
    { label: "golf shoes" },
  ],
  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "mesh" },
    { label: "canvas" },
    { label: "suede" },
    { label: "rubber" },
    { label: "knit" },
    { label: "textile" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],
  Heel_design: [
    { label: "classic pump" },
    { label: "chuncky" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "spool" },
    { label: "Louis" },
    { label: "cone" },
  ],
  Heel_type: [
    { label: "stiletto" },
    { label: "Block" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "platform" },
    { label: "cone" },
    { label: "spool" },
    { label: "louis" },
  ],

  //-------------------------------Character shoes------------------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],
  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Base_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "leather" },
    { label: "PU" },
    { label: "Foam" },
  ],
  Length: [
    { label: "1 years" },
    { label: "2 years" },
    { label: "3 years" },
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],

  //-----------------------------------Sports shoes--------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],

  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Type: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "football" },
    { label: "tennis" },
    { label: "hiking" },
    { label: "shoes" },
    { label: "gym shoes" },
  ],
  Sub_type: [
    { label: "Trail Running shoes" },
    { label: "Cross Training" },
    { label: "Racing Flats" },
    { label: "track spikes" },
    { label: "cycling shoes" },
    { label: "wrestling shoes" },
    { label: "golf shoes" },
  ],
  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "mesh" },
    { label: "canvas" },
    { label: "suede" },
    { label: "rubber" },
    { label: "knit" },
    { label: "textile" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],
  Heel_design: [
    { label: "classic pump" },
    { label: "chuncky" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "spool" },
    { label: "Louis" },
    { label: "cone" },
  ],
  Heel_type: [
    { label: "stiletto" },
    { label: "Block" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "platform" },
    { label: "cone" },
    { label: "spool" },
    { label: "louis" },
  ],

  //-------------------------------Character shoes-----------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],

  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Base_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "leather" },
    { label: "PU" },
    { label: "Foam" },
  ],
  Length: [
    { label: "3 years" },
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
  ],

  //-----------------------------------Infant footwear-------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],
  Size: [
    { label: "1 Month" },
    { label: "2 Month" },
    { label: "3 Month" },
    { label: "4 Month" },
    { label: "5 Month" },
    { label: "6 Month" },
    { label: "7 Month" },
    { label: "8 Month" },
    { label: "9 Month" },
    { label: "10 Month" },
    { label: "11 Month" },
    { label: "1 year" },
    { label: "2 year" },
    { label: "3 year" },
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Type: [
    { label: "soft-soled" },
    { label: "crib" },
    { label: "booties" },
    { label: "pre-walker" },
    { label: "sandal style" },
  ],
  Sub_type: [
    { label: "Booties" },
    { label: "Crib shoes" },
    { label: "Soft-soled shoes" },
    { label: "Pre-walkers" },
    { label: "Sandals" },
  ],
  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "canvas" },
    { label: "textile" },
    { label: "mesh" },
    { label: "suede" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],
  Heel_design: [
    { label: "Flat heel" },
    { label: "soft sole" },
    { label: "elastic heel" },
    { label: "no heel" },
  ],
  Insole_material: [
    { label: "soft fabric" },
    { label: "cotton" },
    { label: "memory foam" },
    { label: "EVA" },
    { label: "Leather" },
    { label: "textile" },
  ],
  Character: [
    { label: "low heel" },
    { label: "straps" },
    { label: "sturdy construction" },
    { label: "variety of styles" },
  ],
  Length: [
    { label: "1 Month" },
    { label: "2 Month" },
    { label: "3 Month" },
    { label: "4 Month" },
    { label: "5 Month" },
    { label: "6 Month" },
    { label: "7 Month" },
    { label: "8 Month" },
    { label: "9 Month" },
    { label: "10 Month" },
    { label: "11 Month" },
    { label: "12 Month" },
    { label: "13 Month" },
    { label: "14 Month" },
    { label: "15 Month" },
    { label: "16 Month" },
    { label: "17 Month" },
    { label: "18 Month" },
    { label: "19 Month" },
    { label: "20 Month" },
    { label: "21 Month" },
    { label: "22 Month" },
    { label: "23 Month" },
    { label: "24 Month" },
  ],
  Warranty: [
    { label: "1 Month" },
    { label: "2 Month" },
    { label: "3 Month" },
    { label: "4 Month" },
    { label: "5 Month" },
    { label: "6 Month" },
    { label: "1 Year" },
  ],
};

const MessurentsSlice = createSlice({
  name: "Messurents",
  initialState,
});

export const {} = MessurentsSlice.actions;
export default MessurentsSlice.reducer;

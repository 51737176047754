import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  subcategorytotal: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal"))
    : [],
  subcategoryShow: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) => data.status === true
    )
    : [],
  clothing_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) => data.status === true && data.superCategory === "Clothing"
    )
    : [],
  electronic_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) => data.status === true && data.superCategory === "Electronics"
    )
    : [],
  beauty_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) => data.status === true && data.superCategory === "Beauty"
    )
    : [],
  homeNeeds_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) =>
        data.status === true && data.superCategory === "Home and furniture"
    )
    : [],
  footwear_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) => data.status === true && data.superCategory === "Footwear"
    )
    : [],
  Toys_games_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) => data.status === true && data.superCategory === "Toys & games"
    )
    : [],
  mens_footwear_subcat: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) =>
        data.status === true &&
        data.superCategory === "Footwear" &&
        data.category === "Men's Footwear"
    )
    : [],
  woMens_footwear_subcat: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) =>
        data.status === true &&
        data.superCategory === "Footwear" &&
        data.category === "Women's Footwear"
    )
    : [],
  kids_footwear_subcat: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter(
      (data) =>
        data.status === true &&
        data.superCategory === "Footwear" &&
        data.category === "Kid's Footwear"
    )
    : [],

  isSubCategoryAvailable: localStorage.getItem("categorytotal") ? true : false,
  subCategoryLoading: true,
};

export const getSubCategory = createAsyncThunk(
  "SubCategory/getSubCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/all`;
      const resp = await axios(url);

      return resp.data.subcategory;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const validateSlugUrl = createAsyncThunk(
  "subcategory/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/subcategory/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

const SubCategorySlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getSubCategory.pending, (state) => {
        state.subCategoryLoading = true;
      })
      .addCase(getSubCategory.fulfilled, (state, action) => {
        state.subcategorytotal = action.payload;
        state.subcategoryShow = state.subcategorytotal.filter(
          (data) => data.status === true
        );
        state.clothing_subcategory = state.subcategoryShow.filter(
          (data) => data.superCategory === "Clothing"
        );
        state.electronic_subcategory = state.subcategoryShow.filter(
          (data) => data.superCategory === "Electronics"
        );
        state.beauty_subcategory = state.subcategoryShow.filter(
          (data) => data.superCategory === "Beauty"
        );
        state.homeNeeds_subcategory = state.subcategoryShow.filter(
          (data) => data.superCategory === "Home and furniture"
        );
        state.footwear_subcategory = state.subcategoryShow.filter(
          (data) => data.superCategory === "Footwear"
        );
        state.Toys_games_subcategory = state.subcategoryShow.filter(
          (data) => data.superCategory === "Toys & games"
        );

        state.mens_footwear_subcat = state.footwear_subcategory.filter(
          (data) => data.category === "Men's Footwear"
        );
        state.woMens_footwear_subcat = state.footwear_subcategory.filter(
          (data) => data.category === "Women's Footwear"
        );
        state.kids_footwear_subcat = state.footwear_subcategory.filter(
          (data) => data.category === "Kid's Footwear"
        );

        localStorage.setItem(
          "subcategorytotal",
          JSON.stringify(state.subcategorytotal)
        );

        state.subCategoryLoading = false;
        state.isSubCategoryAvailable = true;
      })
      .addCase(getSubCategory.rejected, (state) => {
        state.subCategoryLoading = true;
      });
  },
});
export const { } = SubCategorySlice.actions;
export default SubCategorySlice.reducer;

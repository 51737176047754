import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  superCatTotal: localStorage.getItem("superCatTotal")
    ? JSON.parse(localStorage.getItem("superCatTotal"))
    : [],
  superCatShow: localStorage.getItem("superCatTotal")
    ? JSON.parse(localStorage.getItem("superCatTotal")).filter((data) => data.showProducts === true)
    : [],
  isLoading: localStorage.getItem("superCatTotal") ? false : true,
  supercatLoading: true,
  delSupercatLoading: true,
  checkSlugurl: true,
};

export const getSuperCategory = createAsyncThunk(
  "SuperCategory/getSuperCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/supercategory/all`;
      const resp = await axios(url);
      return resp.data.supercategories;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategories Not Found");
    }
  }
);
export const postSuperCategory = createAsyncThunk(
  "superCategory/postSuperCategory",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/supercategory/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("SuperCategory Not create");
    }
  }
);
export const updateSuperCategory = createAsyncThunk(
  "SuperCategory/updateSuperCategory",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/supercategory/${formData.supercatid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategory Not create");
    }
  }
);
export const superCategoryDelete = createAsyncThunk(
  "superCategory/superCategoryDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/supercategory/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      }
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategory Not create");
    }
  }
);

export const validateSuperCatSlugUrl = createAsyncThunk(
  "supercat/validateSuperCatSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    }
    try {
      const url = `${Baseurl}/api/v1/supercategory/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

const SuperCategorySlice = createSlice({
  name: "superCategory",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSuperCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSuperCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.superCatTotal = action.payload;
        state.superCatShow = state.superCatTotal.filter((data) => data.showProducts === true);
        localStorage.setItem("superCatTotal", JSON.stringify(state.superCatTotal));

      })
      .addCase(getSuperCategory.rejected, (state) => {
        state.isLoading = true;
      });
    builder
      .addCase(postSuperCategory.pending, (state) => {
        state.supercatLoading = true;
      })
      .addCase(postSuperCategory.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.superCatTotal = [...state.superCatTotal, action.payload.supercategory];
          state.superCatShow = state.superCatTotal.filter((data) => data.showProducts === true);
          localStorage.setItem("superCatTotal", JSON.stringify(state.superCatTotal));
        }
        state.supercatLoading = false;
        state.checkSlugurl = false;

      })
      .addCase(postSuperCategory.rejected, (state) => {
        state.supercatLoading = true;
      });
    builder
      .addCase(updateSuperCategory.pending, (state) => {
        state.supercatLoading = true;
      })
      .addCase(updateSuperCategory.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.superCatTotal = state.superCatTotal.filter((supercategory) => supercategory._id !== action.payload.supercategory._id);
          state.superCatTotal = [...state.superCatTotal, action.payload.supercategory];
          state.superCatShow = state.superCatTotal.filter((data) => data.showProducts === true);
          localStorage.setItem("superCatTotal", JSON.stringify(state.superCatTotal));
        }
        state.supercatLoading = false;

      })
      .addCase(updateSuperCategory.rejected, (state) => {
        state.supercatLoading = true;
      });
    builder
      .addCase(superCategoryDelete.pending, (state) => {
        state.supercatLoading = true;
      })
      .addCase(superCategoryDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.superCatTotal = state.superCatTotal.filter((supercategory) => supercategory._id !== action.payload.id);
          state.superCatShow = state.superCatTotal.filter((data) => data.showProducts === true);
        }
        state.delSupercatLoading = false;

      })
      .addCase(superCategoryDelete.rejected, (state) => {
        state.supercatLoading = true;
      });
  },
});

export default SuperCategorySlice.reducer;
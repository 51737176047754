import React from "react";

const ElecdivonicSubcat = ({ productifo }) => {


  return (
    <div className="design1">
      {productifo.electronics?.in_the_box !== "" && (
        <div className="des">
          <h5>In the box</h5>
          <p>{productifo.electronics?.in_the_box}</p>
        </div>
      )}
      {productifo.electronics?.model_number !== "" && (
        <div className="des">
          <h5>model number</h5>
          <p>{productifo.electronics?.model_number}</p>
        </div>
      )}

      {productifo.electronics?.model_name !== "" && (
        <div className="des">
          <h5>model name</h5>
          <p>{productifo.electronics?.model_name}</p>
        </div>
      )}
      {productifo.electronics?.color !== "" && (
        <div className="des">
          <h5>color</h5>
          <p>{productifo.electronics?.color}</p>
        </div>
      )}
      {productifo.electronics?.browse_type !== "" && (
        <div className="des">
          <h5>browse type</h5>
          <p>{productifo.electronics?.browse_type}</p>
        </div>
      )}
      {productifo.electronics?.sim_type !== "" && (
        <div className="des">
          <h5>sim type</h5>
          <p>{productifo.electronics?.sim_type}</p>
        </div>
      )}
      {productifo.electronics?.hybrid_sim_slot !== "" && (
        <div className="des">
          <h5>hybrid sim slot</h5>
          <p>{productifo.electronics?.hybrid_sim_slot}</p>
        </div>
      )}
      {productifo.electronics?.touchscreen !== "" && (
        <div className="des">
          <h5>touch screen</h5>
          <p>{productifo.electronics?.touchscreen}</p>
        </div>
      )}
      {productifo.electronics?.otg_compatible !== "" && (
        <div className="des">
          <h5>Otg compatible</h5>
          <p>{productifo.electronics?.otg_compatible}</p>
        </div>
      )}
      {productifo.electronics?.quick_charging !== "" && (
        <div className="des">
          <h5>Quick charging</h5>
          <p>{productifo.electronics?.quick_charging}</p>
        </div>
      )}
      {productifo.electronics?.sar_value !== "" && (
        <div className="des">
          <h5>Sar value</h5>
          <p>{productifo.electronics?.sar_value}</p>
        </div>
      )}
      {productifo.electronics?.display_size !== "" && (
        <div className="des">
          <h5>Display size</h5>
          <p>{productifo.electronics?.display_size}</p>
        </div>
      )}
      {productifo.electronics?.resolution !== "" && (
        <div className="des">
          <h5>Resolution</h5>
          <p>{productifo.electronics?.resolution}</p>
        </div>
      )}
      {productifo.electronics?.gpu !== "" && (
        <div className="des">
          <h5>gpu</h5>
          <p>{productifo.electronics?.gpu}</p>
        </div>
      )}
      {productifo.electronics?.display_type !== "" && (
        <div className="des">
          <h5>Display type</h5>
          <p>{productifo.electronics?.display_type}</p>
        </div>
      )}
      {productifo.electronics?.other_display_features !== "" && (
        <div className="des">
          <h5>Other display features</h5>
          <p>{productifo.electronics?.other_display_features}</p>
        </div>
      )}
      {productifo.electronics?.operating_system !== "" && (
        <div className="des">
          <h5>operating system</h5>
          <p>{productifo.electronics?.operating_system}</p>
        </div>
      )}
      {productifo.electronics?.processor_brand !== "" && (
        <div className="des">
          <h5>Processor brand</h5>
          <p>{productifo.electronics?.processor_brand}</p>
        </div>
      )}
      {productifo.electronics?.processor_type !== "" && (
        <div className="des">
          <h5>Processor type</h5>
          <p>{productifo.electronics?.processor_type}</p>
        </div>
      )}
      {productifo.electronics?.primary_clock_speed !== "" && (
        <div className="des">
          <h5>Primary clock speed</h5>
          <p>{productifo.electronics?.primary_clock_speed}</p>
        </div>
      )}
      {productifo.electronics?.secondary_clock_speed !== "" && (
        <div className="des">
          <h5>Secondary clock speed</h5>
          <p>{productifo.electronics?.secondary_clock_speed}</p>
        </div>
      )}
      {productifo.electronics?.tertiary_clock_speed !== "" && (
        <div className="des">
          <h5>Tertiary clock speed</h5>
          <p>{productifo.electronics?.tertiary_clock_speed}</p>
        </div>
      )}
      {productifo.electronics?.operating_frequency !== "" && (
        <div className="des">
          <h5>Operating frequency</h5>
          <p>{productifo.electronics?.operating_frequency}</p>
        </div>
      )}
      {productifo.electronics?.internal_storage !== "" && (
        <div className="des">
          <h5>Internal storage</h5>
          <p>{productifo.electronics?.internal_storage}</p>
        </div>
      )}
      {productifo.electronics?.ram !== "" && (
        <div className="des">
          <h5>ram</h5>
          <p>{productifo.electronics?.ram}</p>
        </div>
      )}
      {productifo.electronics?.total_memory !== "" && (
        <div className="des">
          <h5>Total memory</h5>
          <p>{productifo.electronics?.total_memory}</p>
        </div>
      )}
      {productifo.electronics?.call_log_memory !== "" && (
        <div className="des">
          <h5>Call log memory</h5>
          <p>{productifo.electronics?.call_log_memory}</p>
        </div>
      )}
      {productifo.electronics?.primary_camera_available !== "" && (
        <div className="des">
          <h5>Primary camera available</h5>
          <p>{productifo.electronics?.primary_camera_available}</p>
        </div>
      )}
      {productifo.electronics?.primary_camera !== "" && (
        <div className="des">
          <h5>Primary camera</h5>
          <p>{productifo.electronics?.primary_camera}</p>
        </div>
      )}
      {productifo.electronics?.primary_camera_features !== "" && (
        <div className="des">
          <h5>Primary camera features</h5>
          <p>{productifo.electronics?.primary_camera_features}</p>
        </div>
      )}
      {productifo.electronics?.secondary_camera_available !== "" && (
        <div className="des">
          <h5>Secondary camera available</h5>
          <p>{productifo.electronics?.secondary_camera_available}</p>
        </div>
      )}
      {productifo.electronics?.secondary_camera !== "" && (
        <div className="des">
          <h5>Secondary camera</h5>
          <p>{productifo.electronics?.secondary_camera}</p>
        </div>
      )}
      {productifo.electronics?.secondary_camera_features !== "" && (
        <div className="des">
          <h5>Secondary camera features</h5>
          <p>{productifo.electronics?.secondary_camera_features}</p>
        </div>
      )}
      {productifo.electronics?.flash !== "" && (
        <div className="des">
          <h5>Flash</h5>
          <p>{productifo.electronics?.flash}</p>
        </div>
      )}
      {productifo.electronics?.hd_recording !== "" && (
        <div className="des">
          <h5>HD recording</h5>
          <p>{productifo.electronics?.hd_recording}</p>
        </div>
      )}
      {productifo.electronics?.full_hd_recording !== "" && (
        <div className="des">
          <h5>Full hd recording</h5>
          <p>{productifo.electronics?.full_hd_recording}</p>
        </div>
      )}
      {productifo.electronics?.video_recording !== "" && (
        <div className="des">
          <h5>Video recording</h5>
          <p>{productifo.electronics?.video_recording}</p>
        </div>
      )}
      {productifo.electronics?.video_recording_resolution !== "" && (
        <div className="des">
          <h5>Video recording resolution</h5>
          <p>{productifo.electronics?.video_recording_resolution}</p>
        </div>
      )}
      {productifo.electronics?.digital_zoom !== "" && (
        <div className="des">
          <h5>Digital zoom</h5>
          <p>{productifo.electronics?.digital_zoom}</p>
        </div>
      )}
      {productifo.electronics?.frame_rate !== "" && (
        <div className="des">
          <h5>Frame rate</h5>
          <p>{productifo.electronics?.frame_rate}</p>
        </div>
      )}
      {productifo.electronics?.dual_camera_lens !== "" && (
        <div className="des">
          <h5>Dual camera lens</h5>
          <p>{productifo.electronics?.dual_camera_lens}</p>
        </div>
      )}
      {productifo.electronics?.call_wait_or_hold !== "" && (
        <div className="des">
          <h5>Call wait or hold</h5>
          <p>{productifo.electronics?.call_wait_or_hold}</p>
        </div>
      )}
      {productifo.electronics?.conference_call !== "" && (
        <div className="des">
          <h5>Conference call</h5>
          <p>{productifo.electronics?.conference_call}</p>
        </div>
      )}
      {productifo.electronics?.hands_free !== "" && (
        <div className="des">
          <h5>Hands free</h5>
          <p>{productifo.electronics?.hands_free}</p>
        </div>
      )}
      {productifo.electronics?.call_divert !== "" && (
        <div className="des">
          <h5>Call divert</h5>
          <p>{productifo.electronics?.call_divert}</p>
        </div>
      )}
      {productifo.electronics?.phone_book !== "" && (
        <div className="des">
          <h5>Phone book</h5>
          <p>{productifo.electronics?.phone_book}</p>
        </div>
      )}
      {productifo.electronics?.call_timer !== "" && (
        <div className="des">
          <h5>Call timer</h5>
          <p>{productifo.electronics?.call_timer}</p>
        </div>
      )}
      {productifo.electronics?.speaker_phone !== "" && (
        <div className="des">
          <h5>Speaker phone</h5>
          <p>{productifo.electronics?.speaker_phone}</p>
        </div>
      )}
      {productifo.electronics?.call_records !== "" && (
        <div className="des">
          <h5>Call records</h5>
          <p>{productifo.electronics?.call_records}</p>
        </div>
      )}
      {productifo.electronics?.network_type !== "" && (
        <div className="des">
          <h5>Network type</h5>
          <p>{productifo.electronics?.network_type}</p>
        </div>
      )}
      {productifo.electronics?.supported_networks !== "" && (
        <div className="des">
          <h5>Supported networks</h5>
          <p>{productifo.electronics?.supported_networks}</p>
        </div>
      )}
      {productifo.electronics?.internet_connectivity !== "" && (
        <div className="des">
          <h5>Internet connectivity</h5>
          <p>{productifo.electronics?.internet_connectivity}</p>
        </div>
      )}
      {productifo.electronics?._3g !== "" && (
        <div className="des">
          <h5>_3g</h5>
          <p>{productifo.electronics?._3g}</p>
        </div>
      )}
      {productifo.electronics?.gprs !== "" && (
        <div className="des">
          <h5>gprs</h5>
          <p>{productifo.electronics?.gprs}</p>
        </div>
      )}
      {productifo.electronics?.micro_usb_port !== "" && (
        <div className="des">
          <h5>Micro usb port</h5>
          <p>{productifo.electronics?.micro_usb_port}</p>
        </div>
      )}
      {productifo.electronics?.micro_usb_version !== "" && (
        <div className="des">
          <h5>Micro usb version</h5>
          <p>{productifo.electronics?.micro_usb_version}</p>
        </div>
      )}
      {productifo.electronics?.blutooth_support !== "" && (
        <div className="des">
          <h5>Blutooth support</h5>
          <p>{productifo.electronics?.blutooth_support}</p>
        </div>
      )}
      {productifo.electronics?.blutooth_version !== "" && (
        <div className="des">
          <h5>Blutooth version</h5>
          <p>{productifo.electronics?.blutooth_version}</p>
        </div>
      )}
      {productifo.electronics?.wifi !== "" && (
        <div className="des">
          <h5>wifi</h5>
          <p>{productifo.electronics?.wifi}</p>
        </div>
      )}
      {productifo.electronics?.wifi_version !== "" && (
        <div className="des">
          <h5>wifi version</h5>
          <p>{productifo.electronics?.wifi_version}</p>
        </div>
      )}
      {productifo.electronics?.wifi_hotspot !== "" && (
        <div className="des">
          <h5>wifi hotspot</h5>
          <p>{productifo.electronics?.wifi_hotspot}</p>
        </div>
      )}
      {productifo.electronics?.mini_hdmi_port !== "" && (
        <div className="des">
          <h5>Mini hdmi port</h5>
          <p>{productifo.electronics?.mini_hdmi_port}</p>
        </div>
      )}
      {productifo.electronics?.nfc !== "" && (
        <div className="des">
          <h5>nfc</h5>
          <p>{productifo.electronics?.nfc}</p>
        </div>
      )}
      {productifo.electronics?.usb_tethering !== "" && (
        <div className="des">
          <h5>Usb Tethering</h5>
          <p>{productifo.electronics?.usb_tethering}</p>
        </div>
      )}
      {productifo.electronics?.usb_connectivity !== "" && (
        <div className="des">
          <h5>Usb connectivity</h5>
          <p>{productifo.electronics?.usb_connectivity}</p>
        </div>
      )}
      {productifo.electronics?.map_support !== "" && (
        <div className="des">
          <h5>Map Support</h5>
          <p>{productifo.electronics?.map_support}</p>
        </div>
      )}
      {productifo.electronics?.gps_support !== "" && (
        <div className="des">
          <h5>Gps Support</h5>
          <p>{productifo.electronics?.gps_support}</p>
        </div>
      )}
      {productifo.electronics?.smartphone !== "" && (
        <div className="des">
          <h5>smartphone</h5>
          <p>{productifo.electronics?.smartphone}</p>
        </div>
      )}
      {productifo.electronics?.touchscreen_type !== "" && (
        <div className="des">
          <h5>touchscreen type</h5>
          <p>{productifo.electronics?.touchscreen_type}</p>
        </div>
      )}
      {productifo.electronics?.sim_size !== "" && (
        <div className="des">
          <h5>sim size</h5>
          <p>{productifo.electronics?.sim_size}</p>
        </div>
      )}
      {productifo.electronics?.user_interface !== "" && (
        <div className="des">
          <h5>user interface</h5>
          <p>{productifo.electronics?.user_interface}</p>
        </div>
      )}
      {productifo.electronics?.instant_message !== "" && (
        <div className="des">
          <h5>instant message</h5>
          <p>{productifo.electronics?.instant_message}</p>
        </div>
      )}
      {productifo.electronics?.removable_battery !== "" && (
        <div className="des">
          <h5>removable battery</h5>
          <p>{productifo.electronics?.removable_battery}</p>
        </div>
      )}
      {productifo.electronics?.mms !== "" && (
        <div className="des">
          <h5>mms</h5>
          <p>{productifo.electronics?.mms}</p>
        </div>
      )}

      {productifo.electronics?.sms !== "" && (
        <div className="des">
          <h5>sms</h5>
          <p>{productifo.electronics?.sms}</p>
        </div>
      )}
      {productifo.electronics?.keypad !== "" && (
        <div className="des">
          <h5>keypad</h5>
          <p>{productifo.electronics?.keypad}</p>
        </div>
      )}
      {productifo.electronics?.voice_input !== "" && (
        <div className="des">
          <h5>voice input</h5>
          <p>{productifo.electronics?.voice_input}</p>
        </div>
      )}
      {productifo.electronics?.predictive_text_input !== "" && (
        <div className="des">
          <h5>predictive text input</h5>
          <p>{productifo.electronics?.predictive_text_input}</p>
        </div>
      )}
      {productifo.electronics?.user_memory !== "" && (
        <div className="des">
          <h5>user memory</h5>
          <p>{productifo.electronics?.user_memory}</p>
        </div>
      )}
      {productifo.electronics?.sensors !== "" && (
        <div className="des">
          <h5>sensors</h5>
          <p>{productifo.electronics?.sensors}</p>
        </div>
      )}
      {productifo.electronics?.browser !== "" && (
        <div className="des">
          <h5>browser</h5>
          <p>{productifo.electronics?.browser}</p>
        </div>
      )}
      {productifo.electronics?.other_features !== "" && (
        <div className="des">
          <h5>other features</h5>
          <p>{productifo.electronics?.other_features}</p>
        </div>
      )}
      {productifo.electronics?.gps_type !== "" && (
        <div className="des">
          <h5>gps type</h5>
          <p>{productifo.electronics?.gps_type}</p>
        </div>
      )}
      {productifo.electronics?.fm_radio !== "" && (
        <div className="des">
          <h5>fm radio</h5>
          <p>{productifo.electronics?.fm_radio}</p>
        </div>
      )}
      {productifo.electronics?.fm_radio_recording !== "" && (
        <div className="des">
          <h5>fm radio recording</h5>
          <p>{productifo.electronics?.fm_radio_recording}</p>
        </div>
      )}
      {productifo.electronics?.battery_capacity !== "" && (
        <div className="des">
          <h5>battery capacity</h5>
          <p>{productifo.electronics?.battery_capacity}</p>
        </div>
      )}
      {productifo.electronics?.battery_type !== "" && (
        <div className="des">
          <h5>battery type</h5>
          <p>{productifo.electronics?.battery_type}</p>
        </div>
      )}
      {productifo.electronics?.width !== "" && (
        <div className="des">
          <h5>width</h5>
          <p>{productifo.electronics?.width}</p>
        </div>
      )}
      {productifo.electronics?.height !== "" && (
        <div className="des">
          <h5>height</h5>
          <p>{productifo.electronics?.height}</p>
        </div>
      )}
      {productifo.electronics?.depth !== "" && (
        <div className="des">
          <h5>depth</h5>
          <p>{productifo.electronics?.depth}</p>
        </div>
      )}
      {productifo.electronics?.weight !== "" && (
        <div className="des">
          <h5>weight</h5>
          <p>{productifo.electronics?.weight}</p>
        </div>
      )}
      {productifo.electronics?.warranty_summary !== "" && (
        <div className="des">
          <h5>warranty summary</h5>
          <p>{productifo.electronics?.warranty_summary}</p>
        </div>
      )}
      {productifo.electronics?.domestic_warranty !== "" && (
        <div className="des">
          <h5>domestic warranty</h5>
          <p>{productifo.electronics?.domestic_warranty}</p>
        </div>
      )}
      {productifo.electronics?.NumberofItems !== "" && (
        <div className="des">
          <h5>NumberofItems</h5>
          <p>{productifo.electronics?.NumberofItems}</p>
        </div>
      )}
      {productifo.electronics?.function_cooking !== "" && (
        <div className="des">
          <h5>function cooking</h5>
          <p>{productifo.electronics?.function_cooking}</p>
        </div>
      )}
      {productifo.electronics?.steaming !== "" && (
        <div className="des">
          <h5>steaming</h5>
          <p>{productifo.electronics?.steaming}</p>
        </div>
      )}
      {productifo.electronics?.non_stick_cooking_bowl !== "" && (
        <div className="des">
          <h5>non stick cooking bowl</h5>
          <p>{productifo.electronics?.non_stick_cooking_bowl}</p>
        </div>
      )}
      {productifo.electronics?.detachable_power_cord !== "" && (
        <div className="des">
          <h5>detachable power cord</h5>
          <p>{productifo.electronics?.detachable_power_cord}</p>
        </div>
      )}
      {productifo.electronics?.see_through_glass_top !== "" && (
        <div className="des">
          <h5>see through glass top</h5>
          <p>{productifo.electronics?.see_through_glass_top}</p>
        </div>
      )}
      {productifo.electronics?.measuring_cup !== "" && (
        <div className="des">
          <h5>measuring cup</h5>
          <p>{productifo.electronics?.measuring_cup}</p>
        </div>
      )}
      {productifo.electronics?.trivet !== "" && (
        <div className="des">
          <h5>trivet</h5>
          <p>{productifo.electronics?.trivet}</p>
        </div>
      )}
      {productifo.electronics?.temperature_glass_lid !== "" && (
        <div className="des">
          <h5>temperature glass lid</h5>
          <p>{productifo.electronics?.temperature_glass_lid}</p>
        </div>
      )}
      {productifo.electronics?.steaming_basket !== "" && (
        <div className="des">
          <h5>steaming basket</h5>
          <p>{productifo.electronics?.steaming_basket}</p>
        </div>
      )}
      {productifo.electronics?.spatula !== "" && (
        <div className="des">
          <h5>spatula</h5>
          <p>{productifo.electronics?.spatula}</p>
        </div>
      )}
      {productifo.electronics?.cool_touch_handles !== "" && (
        <div className="des">
          <h5>cool touch handles</h5>
          <p>{productifo.electronics?.cool_touch_handles}</p>
        </div>
      )}
      {productifo.electronics?.thermal_fuse !== "" && (
        <div className="des">
          <h5>thermal fuse</h5>
          <p>{productifo.electronics?.thermal_fuse}</p>
        </div>
      )}
      {productifo.electronics?.motor_speed !== "" && (
        <div className="des">
          <h5>motor speed</h5>
          <p>{productifo.electronics?.motor_speed}</p>
        </div>
      )}
      {productifo.electronics?.number_of_stones !== "" && (
        <div className="des">
          <h5>number of stones</h5>
          <p>{productifo.electronics?.number_of_stones}</p>
        </div>
      )}
      {productifo.electronics?.attachment_present !== "" && (
        <div className="des">
          <h5>attachment present</h5>
          <p>{productifo.electronics?.attachment_present}</p>
        </div>
      )}
      {productifo.electronics?.motor_overload_protection !== "" && (
        <div className="des">
          <h5>motor overload protection</h5>
          <p>{productifo.electronics?.motor_overload_protection}</p>
        </div>
      )}
      {productifo.electronics?.auto_off !== "" && (
        <div className="des">
          <h5>auto off</h5>
          <p>{productifo.electronics?.auto_off}</p>
        </div>
      )}
      {productifo.electronics?.safety_lock !== "" && (
        <div className="des">
          <h5>safety lock</h5>
          <p>{productifo.electronics?.safety_lock}</p>
        </div>
      )}
      {productifo.electronics?.processing_bocol !== "" && (
        <div className="des">
          <h5>processing bocol</h5>
          <p>{productifo.electronics?.processing_bocol}</p>
        </div>
      )}
      {productifo.electronics?.processing_jar !== "" && (
        <div className="des">
          <h5>processing jar</h5>
          <p>{productifo.electronics?.processing_jar}</p>
        </div>
      )}
      {productifo.electronics?.number_of_attached_jar !== "" && (
        <div className="des">
          <h5>number of attached jar</h5>
          <p>{productifo.electronics?.number_of_attached_jar}</p>
        </div>
      )}
      {productifo.electronics?.anti_drip_value !== "" && (
        <div className="des">
          <h5>anti drip value</h5>
          <p>{productifo.electronics?.anti_drip_value}</p>
        </div>
      )}
      {productifo.electronics?.brew_strength_control !== "" && (
        <div className="des">
          <h5>brew strength control</h5>
          <p>{productifo.electronics?.brew_strength_control}</p>
        </div>
      )}
      {productifo.electronics?.removable_drip !== "" && (
        <div className="des">
          <h5>removable drip</h5>
          <p>{productifo.electronics?.removable_drip}</p>
        </div>
      )}
      {productifo.electronics?.frother !== "" && (
        <div className="des">
          <h5>frother</h5>
          <p>{productifo.electronics?.frother}</p>
        </div>
      )}
      {productifo.electronics?.illuminated_on_off_switch !== "" && (
        <div className="des">
          <h5>illuminated on off switch</h5>
          <p>{productifo.electronics?.illuminated_on_off_switch}</p>
        </div>
      )}
      {productifo.electronics?.hot_plate !== "" && (
        <div className="des">
          <h5>hot plate</h5>
          <p>{productifo.electronics?.hot_plate}</p>
        </div>
      )}
      {productifo.electronics?.net_quantity !== "" && (
        <div className="des">
          <h5>net quantity</h5>
          <p>{productifo.electronics?.net_quantity}</p>
        </div>
      )}
      {productifo.electronics?.brewing_time !== "" && (
        <div className="des">
          <h5>brewing time</h5>
          <p>{productifo.electronics?.brewing_time}</p>
        </div>
      )}
      {productifo.electronics?.removable_filter !== "" && (
        <div className="des">
          <h5>removable filter</h5>
          <p>{productifo.electronics?.removable_filter}</p>
        </div>
      )}
      {productifo.electronics?.other_dimensions !== "" && (
        <div className="des">
          <h5>other dimensions</h5>
          <p>{productifo.electronics?.other_dimensions}</p>
        </div>
      )}
      {productifo.electronics?.filter_material !== "" && (
        <div className="des">
          <h5>filter material</h5>
          <p>{productifo.electronics?.filter_material}</p>
        </div>
      )}
      {productifo.electronics?.temperature_control !== "" && (
        <div className="des">
          <h5>temperature control</h5>
          <p>{productifo.electronics?.temperature_control}</p>
        </div>
      )}
      {productifo.electronics?.spray !== "" && (
        <div className="des">
          <h5>spray</h5>
          <p>{productifo.electronics?.spray}</p>
        </div>
      )}
      {productifo.electronics?.steam_burst !== "" && (
        <div className="des">
          <h5>steam burst</h5>
          <p>{productifo.electronics?.steam_burst}</p>
        </div>
      )}
      {productifo.electronics?.self_cleaning !== "" && (
        <div className="des">
          <h5>self cleaning</h5>
          <p>{productifo.electronics?.self_cleaning}</p>
        </div>
      )}
      {productifo.electronics?.swivel_cord !== "" && (
        <div className="des">
          <h5>swivel cord</h5>
          <p>{productifo.electronics?.swivel_cord}</p>
        </div>
      )}
      {productifo.electronics?.electric_type !== "" && (
        <div className="des">
          <h5>electric type</h5>
          <p>{productifo.electronics?.electric_type}</p>
        </div>
      )}
      {productifo.electronics?.purification_features !== "" && (
        <div className="des">
          <h5>purification features</h5>
          <p>{productifo.electronics?.purification_features}</p>
        </div>
      )}
      {productifo.electronics?.detachable_storage_tank !== "" && (
        <div className="des">
          <h5>detachable storage tank</h5>
          <p>{productifo.electronics?.detachable_storage_tank}</p>
        </div>
      )}
      {productifo.electronics?.membrane_type !== "" && (
        <div className="des">
          <h5>membrane type</h5>
          <p>{productifo.electronics?.membrane_type}</p>
        </div>
      )}
      {productifo.electronics?.overflow_protection !== "" && (
        <div className="des">
          <h5>overflow protection</h5>
          <p>{productifo.electronics?.overflow_protection}</p>
        </div>
      )}
      {productifo.electronics?.filter_life !== "" && (
        <div className="des">
          <h5>filter life</h5>
          <p>{productifo.electronics?.filter_life}</p>
        </div>
      )}
      {productifo.electronics?.alarms !== "" && (
        <div className="des">
          <h5>alarms</h5>
          <p>{productifo.electronics?.alarms}</p>
        </div>
      )}
      {productifo.electronics?.filtration_capacity !== "" && (
        <div className="des">
          <h5>filtration capacity</h5>
          <p>{productifo.electronics?.filtration_capacity}</p>
        </div>
      )}
      {productifo.electronics?.purification_capacity !== "" && (
        <div className="des">
          <h5>purification capacity</h5>
          <p>{productifo.electronics?.purification_capacity}</p>
        </div>
      )}
      {productifo.electronics?.cold_water_capacity !== "" && (
        <div className="des">
          <h5>cold water capacity</h5>
          <p>{productifo.electronics?.cold_water_capacity}</p>
        </div>
      )}
      {productifo.electronics?.cold_water_dispensor !== "" && (
        <div className="des">
          <h5>cold water dispensor</h5>
          <p>{productifo.electronics?.cold_water_dispensor}</p>
        </div>
      )}
      {productifo.electronics?.hot_water_capacity !== "" && (
        <div className="des">
          <h5>hot water capacity</h5>
          <p>{productifo.electronics?.hot_water_capacity}</p>
        </div>
      )}
      {productifo.electronics?.hot_water_dispensor !== "" && (
        <div className="des">
          <h5>hot water dispensor</h5>
          <p>{productifo.electronics?.hot_water_dispensor}</p>
        </div>
      )}
      {productifo.electronics?.purification_stages !== "" && (
        <div className="des">
          <h5>purification_stages</h5>
          <p>{productifo.electronics?.purification_stages}</p>
        </div>
      )}
      {productifo.electronics?.airflow !== "" && (
        <div className="des">
          <h5>airflow</h5>
          <p>{productifo.electronics?.airflow}</p>
        </div>
      )}
      {productifo.electronics?.blade_sweep !== "" && (
        <div className="des">
          <h5>blade sweep</h5>
          <p>{productifo.electronics?.blade_sweep}</p>
        </div>
      )}
      {productifo.electronics?.number_of_speeds !== "" && (
        <div className="des">
          <h5>number of speeds</h5>
          <p>{productifo.electronics?.number_of_speeds}</p>
        </div>
      )}
      {productifo.electronics?.cooling_coverage_area !== "" && (
        <div className="des">
          <h5>cooling coverage area</h5>
          <p>{productifo.electronics?.cooling_coverage_area}</p>
        </div>
      )}
      {productifo.electronics?.speed_control !== "" && (
        <div className="des">
          <h5>speed control</h5>
          <p>{productifo.electronics?.speed_control}</p>
        </div>
      )}
      {productifo.electronics?.operating_mode !== "" && (
        <div className="des">
          <h5>operating mode</h5>
          <p>{productifo.electronics?.operating_mode}</p>
        </div>
      )}
      {productifo.electronics?.air_delivery !== "" && (
        <div className="des">
          <h5>air delivery</h5>
          <p>{productifo.electronics?.air_delivery}</p>
        </div>
      )}
      {productifo.electronics?.air_throw_distance !== "" && (
        <div className="des">
          <h5>air throw distance</h5>
          <p>{productifo.electronics?.air_throw_distance}</p>
        </div>
      )}
      {productifo.electronics?.air_deflection !== "" && (
        <div className="des">
          <h5>air deflection</h5>
          <p>{productifo.electronics?.air_deflection}</p>
        </div>
      )}
      {productifo.electronics?.blower_material !== "" && (
        <div className="des">
          <h5>blower material</h5>
          <p>{productifo.electronics?.blower_material}</p>
        </div>
      )}
      {productifo.electronics?.ice_chamber !== "" && (
        <div className="des">
          <h5>ice chamber</h5>
          <p>{productifo.electronics?.ice_chamber}</p>
        </div>
      )}
      {productifo.electronics?.caston_wheels !== "" && (
        <div className="des">
          <h5>caston wheels</h5>
          <p>{productifo.electronics?.caston_wheels}</p>
        </div>
      )}
      {productifo.electronics?.number_of_caston_wheels !== "" && (
        <div className="des">
          <h5>number of caston wheels</h5>
          <p>{productifo.electronics?.number_of_caston_wheels}</p>
        </div>
      )}
      {productifo.electronics?.number_of_fan_blades !== "" && (
        <div className="des">
          <h5>number of fan blades</h5>
          <p>{productifo.electronics?.number_of_fan_blades}</p>
        </div>
      )}
      {productifo.electronics?.auto_louver_movement !== "" && (
        <div className="des">
          <h5>auto louver movement</h5>
          <p>{productifo.electronics?.auto_louver_movement}</p>
        </div>
      )}
      {productifo.electronics?.oscillating_function !== "" && (
        <div className="des">
          <h5>oscillating function</h5>
          <p>{productifo.electronics?.oscillating_function}</p>
        </div>
      )}
      {productifo.electronics?.technology_used !== "" && (
        <div className="des">
          <h5>technology used</h5>
          <p>{productifo.electronics?.technology_used}</p>
        </div>
      )}
      {productifo.electronics?.blower !== "" && (
        <div className="des">
          <h5>blower</h5>
          <p>{productifo.electronics?.blower}</p>
        </div>
      )}
      {productifo.electronics?.motor_suction_power !== "" && (
        <div className="des">
          <h5>motor suction power</h5>
          <p>{productifo.electronics?.motor_suction_power}</p>
        </div>
      )}
      {productifo.electronics?.solar_power !== "" && (
        <div className="des">
          <h5>solar power</h5>
          <p>{productifo.electronics?.solar_power}</p>
        </div>
      )}
      {productifo.electronics?.efficiency !== "" && (
        <div className="des">
          <h5>efficiency</h5>
          <p>{productifo.electronics?.efficiency}</p>
        </div>
      )}
      {productifo.electronics?.output_voltage !== "" && (
        <div className="des">
          <h5>output voltage</h5>
          <p>{productifo.electronics?.output_voltage}</p>
        </div>
      )}
      {productifo.electronics?.number_of_stitches !== "" && (
        <div className="des">
          <h5>number of stitches</h5>
          <p>{productifo.electronics?.number_of_stitches}</p>
        </div>
      )}
      {productifo.electronics?.sewing_speed !== "" && (
        <div className="des">
          <h5>sewing speed</h5>
          <p>{productifo.electronics?.sewing_speed}</p>
        </div>
      )}
      {productifo.electronics?.shape !== "" && (
        <div className="des">
          <h5>shape</h5>
          <p>{productifo.electronics?.shape}</p>
        </div>
      )}
      {productifo.electronics?.bobbin_winder !== "" && (
        <div className="des">
          <h5>bobbin winder</h5>
          <p>{productifo.electronics?.bobbin_winder}</p>
        </div>
      )}
      {productifo.electronics?.stitch_out_time_indicator !== "" && (
        <div className="des">
          <h5>stitch out time indicator</h5>
          <p>{productifo.electronics?.stitch_out_time_indicator}</p>
        </div>
      )}
      {productifo.electronics?.led_needle_light !== "" && (
        <div className="des">
          <h5>led needle light</h5>
          <p>{productifo.electronics?.led_needle_light}</p>
        </div>
      )}
      {productifo.electronics?.mount_type !== "" && (
        <div className="des">
          <h5>mount type</h5>
          <p>{productifo.electronics?.mount_type}</p>
        </div>
      )}
      {productifo.electronics?.over_vlot_protection !== "" && (
        <div className="des">
          <h5>over vlot protection</h5>
          <p>{productifo.electronics?.over_vlot_protection}</p>
        </div>
      )}
      {productifo.electronics?.minimum_input_power !== "" && (
        <div className="des">
          <h5>minimum input power</h5>
          <p>{productifo.electronics?.minimum_input_power}</p>
        </div>
      )}
      {productifo.electronics?.suitable_for !== "" && (
        <div className="des">
          <h5>suitable for</h5>
          <p>{productifo.electronics?.suitable_for}</p>
        </div>
      )}
      {productifo.electronics?.star_rating !== "" && (
        <div className="des">
          <h5>star rating</h5>
          <p>{productifo.electronics?.star_rating}</p>
        </div>
      )}
      {productifo.electronics?.heating_element_type !== "" && (
        <div className="des">
          <h5>heating element type</h5>
          <p>{productifo.electronics?.heating_element_type}</p>
        </div>
      )}
      {productifo.electronics?.washing_method !== "" && (
        <div className="des">
          <h5>washing method</h5>
          <p>{productifo.electronics?.washing_method}</p>
        </div>
      )}
      {productifo.electronics?.in_built_heater !== "" && (
        <div className="des">
          <h5>in built heater</h5>
          <p>{productifo.electronics?.in_built_heater}</p>
        </div>
      )}
      {productifo.electronics?.tub_material !== "" && (
        <div className="des">
          <h5>tub material</h5>
          <p>{productifo.electronics?.tub_material}</p>
        </div>
      )}
      {productifo.electronics?.digital_display !== "" && (
        <div className="des">
          <h5>digital display</h5>
          <p>{productifo.electronics?.digital_display}</p>
        </div>
      )}
      {productifo.electronics?.water_level_selector !== "" && (
        <div className="des">
          <h5>water level selector</h5>
          <p>{productifo.electronics?.water_level_selector}</p>
        </div>
      )}
      {productifo.electronics?.wash_cycle_duration !== "" && (
        <div className="des">
          <h5>wash cycle duration</h5>
          <p>{productifo.electronics?.wash_cycle_duration}</p>
        </div>
      )}
      {productifo.electronics?.spin_cycle_duration !== "" && (
        <div className="des">
          <h5>spin cycle duration</h5>
          <p>{productifo.electronics?.spin_cycle_duration}</p>
        </div>
      )}
      {productifo.electronics?.inverter !== "" && (
        <div className="des">
          <h5>inverter</h5>
          <p>{productifo.electronics?.inverter}</p>
        </div>
      )}
      {productifo.electronics?.steam !== "" && (
        <div className="des">
          <h5>steam</h5>
          <p>{productifo.electronics?.steam}</p>
        </div>
      )}
      {productifo.electronics?.quick_wash !== "" && (
        <div className="des">
          <h5>quick wash</h5>
          <p>{productifo.electronics?.quick_wash}</p>
        </div>
      )}
      {productifo.electronics?.lint_filter !== "" && (
        <div className="des">
          <h5>lint filter</h5>
          <p>{productifo.electronics?.lint_filter}</p>
        </div>
      )}
      {productifo.electronics?.child_lock !== "" && (
        <div className="des">
          <h5>child lock</h5>
          <p>{productifo.electronics?.child_lock}</p>
        </div>
      )}
      {productifo.electronics?.wash_program_types !== "" && (
        <div className="des">
          <h5>wash program types</h5>
          <p>{productifo.electronics?.wash_program_types}</p>
        </div>
      )}
      {productifo.electronics?.compressor !== "" && (
        <div className="des">
          <h5>compressor</h5>
          <p>{productifo.electronics?.compressor}</p>
        </div>
      )}
      {productifo.electronics?.dehumidification !== "" && (
        <div className="des">
          <h5>dehumidification</h5>
          <p>{productifo.electronics?.dehumidification}</p>
        </div>
      )}
      {productifo.electronics?.remote_control !== "" && (
        <div className="des">
          <h5>remote_control</h5>
          <p>{productifo.electronics?.remote_control}</p>
        </div>
      )}
      {productifo.electronics?.operating_modes !== "" && (
        <div className="des">
          <h5>operating_modes</h5>
          <p>{productifo.electronics?.operating_modes}</p>
        </div>
      )}
      {productifo.electronics?.condenser_coil !== "" && (
        <div className="des">
          <h5>condenser_coil</h5>
          <p>{productifo.electronics?.condenser_coil}</p>
        </div>
      )}
      {productifo.electronics?.indoor_w_h_d !== "" && (
        <div className="des">
          <h5>indoor_w_h_d</h5>
          <p>{productifo.electronics?.indoor_w_h_d}</p>
        </div>
      )}
      {productifo.electronics?.indoor_unit_weight !== "" && (
        <div className="des">
          <h5>indoor_unit_weight</h5>
          <p>{productifo.electronics?.indoor_unit_weight}</p>
        </div>
      )}
      {productifo.electronics?.auto_air_swing !== "" && (
        <div className="des">
          <h5>auto_air_swing</h5>
          <p>{productifo.electronics?.auto_air_swing}</p>
        </div>
      )}
      {productifo.electronics?.air_flow_direction !== "" && (
        <div className="des">
          <h5>air_flow_direction</h5>
          <p>{productifo.electronics?.air_flow_direction}</p>
        </div>
      )}
      {productifo.electronics?.anti_bacteria_filter !== "" && (
        <div className="des">
          <h5>anti_bacteria_filter</h5>
          <p>{productifo.electronics?.anti_bacteria_filter}</p>
        </div>
      )}
      {productifo.electronics?.dust_filter !== "" && (
        <div className="des">
          <h5>dust_filter</h5>
          <p>{productifo.electronics?.dust_filter}</p>
        </div>
      )}
      {productifo.electronics?.auto_restart !== "" && (
        <div className="des">
          <h5>auto_restart</h5>
          <p>{productifo.electronics?.auto_restart}</p>
        </div>
      )}
      {productifo.electronics?.quit_mode !== "" && (
        <div className="des">
          <h5>quit_mode</h5>
          <p>{productifo.electronics?.quit_mode}</p>
        </div>
      )}
      {productifo.electronics?.sleep_mode !== "" && (
        <div className="des">
          <h5>sleep_mode</h5>
          <p>{productifo.electronics?.sleep_mode}</p>
        </div>
      )}
      {productifo.electronics?.memory_feature !== "" && (
        <div className="des">
          <h5>memory_feature</h5>
          <p>{productifo.electronics?.memory_feature}</p>
        </div>
      )}
      {productifo.electronics?.self_diagosis !== "" && (
        <div className="des">
          <h5>self_diagosis</h5>
          <p>{productifo.electronics?.self_diagosis}</p>
        </div>
      )}
      {productifo.electronics?.electricity_consumption !== "" && (
        <div className="des">
          <h5>electricity_consumption</h5>
          <p>{productifo.electronics?.electricity_consumption}</p>
        </div>
      )}
      {productifo.electronics?.defrosting_type !== "" && (
        <div className="des">
          <h5>defrosting_type</h5>
          <p>{productifo.electronics?.defrosting_type}</p>
        </div>
      )}
      {productifo.electronics?.number_of_doors !== "" && (
        <div className="des">
          <h5>number_of_doors</h5>
          <p>{productifo.electronics?.number_of_doors}</p>
        </div>
      )}
      {productifo.electronics?.coolpad !== "" && (
        <div className="des">
          <h5>coolpad</h5>
          <p>{productifo.electronics?.coolpad}</p>
        </div>
      )}
      {productifo.electronics?.build_in_stabilizer !== "" && (
        <div className="des">
          <h5>build_in_stabilizer</h5>
          <p>{productifo.electronics?.build_in_stabilizer}</p>
        </div>
      )}
      {productifo.electronics?.toughened_glass !== "" && (
        <div className="des">
          <h5>toughened_glass</h5>
          <p>{productifo.electronics?.toughened_glass}</p>
        </div>
      )}
      {productifo.electronics?.moisture_control !== "" && (
        <div className="des">
          <h5>moisture_control</h5>
          <p>{productifo.electronics?.moisture_control}</p>
        </div>
      )}
      {productifo.electronics?.door_lock !== "" && (
        <div className="des">
          <h5>door_lock</h5>
          <p>{productifo.electronics?.door_lock}</p>
        </div>
      )}
      {productifo.electronics?.launch_year !== "" && (
        <div className="des">
          <h5>launch_year</h5>
          <p>{productifo.electronics?.launch_year}</p>
        </div>
      )}
      {productifo.electronics?.output_frequency !== "" && (
        <div className="des">
          <h5>output_frequency</h5>
          <p>{productifo.electronics?.output_frequency}</p>
        </div>
      )}
      {productifo.electronics?.number_of_button_holes_styles !== "" && (
        <div className="des">
          <h5>number_of_button_holes_styles</h5>
          <p>{productifo.electronics?.number_of_button_holes_styles}</p>
        </div>
      )}
      {productifo.electronics?.lcd_screen_display !== "" && (
        <div className="des">
          <h5>lcd_screen_display</h5>
          <p>{productifo.electronics?.lcd_screen_display}</p>
        </div>
      )}
      {productifo.electronics?.shape !== "" && (
        <div className="des">
          <h5>shape</h5>
          <p>{productifo.electronics?.shape}</p>
        </div>
      )}
      {productifo.electronics?.stop_start_button !== "" && (
        <div className="des">
          <h5>stop_start_button</h5>
          <p>{productifo.electronics?.stop_start_button}</p>
        </div>
      )}
      {productifo.electronics?.automatic_thread_cutter !== "" && (
        <div className="des">
          <h5>automatic_thread_cutter</h5>
          <p>{productifo.electronics?.automatic_thread_cutter}</p>
        </div>
      )}
      {productifo.electronics?.planting !== "" && (
        <div className="des">
          <h5>planting</h5>
          <p>{productifo.electronics?.planting}</p>
        </div>
      )}
      {productifo.electronics?.heater_material !== "" && (
        <div className="des">
          <h5>heater_material</h5>
          <p>{productifo.electronics?.heater_material}</p>
        </div>
      )}
      {productifo.electronics?.design !== "" && (
        <div className="des">
          <h5>design</h5>
          <p>{productifo.electronics?.design}</p>
        </div>
      )}
      {productifo.electronics?.wheel_support !== "" && (
        <div className="des">
          <h5>wheel_support</h5>
          <p>{productifo.electronics?.wheel_support}</p>
        </div>
      )}
      {productifo.electronics?.brand !== "" && (
        <div className="des">
          <h5>brand</h5>
          <p>{productifo.electronics?.brand}</p>
        </div>
      )}
      {productifo.electronics?.capacity !== "" && (
        <div className="des">
          <h5>capacity</h5>
          <p>{productifo.electronics?.capacity}</p>
        </div>
      )}
      {productifo.electronics?.manufacturing_year !== "" && (
        <div className="des">
          <h5>manufacturing_year</h5>
          <p>{productifo.electronics?.manufacturing_year}</p>
        </div>
      )}
      {productifo.electronics?.cover_in_warranty !== "" && (
        <div className="des">
          <h5>cover_in_warranty</h5>
          <p>{productifo.electronics?.cover_in_warranty}</p>
        </div>
      )}
      {productifo.electronics?.not_cover_in_warranty !== "" && (
        <div className="des">
          <h5>not_cover_in_warranty</h5>
          <p>{productifo.electronics?.not_cover_in_warranty}</p>
        </div>
      )}
      {productifo.electronics?.warranty_service_type !== "" && (
        <div className="des">
          <h5>warranty_service_type</h5>
          <p>{productifo.electronics?.warranty_service_type}</p>
        </div>
      )}
      {productifo.electronics?.preheat !== "" && (
        <div className="des">
          <h5>preheat</h5>
          <p>{productifo.electronics?.preheat}</p>
        </div>
      )}
      {productifo.electronics?.timer !== "" && (
        <div className="des">
          <h5>timer</h5>
          <p>{productifo.electronics?.timer}</p>
        </div>
      )}
      {productifo.electronics?.auto_cook_menu_available !== "" && (
        <div className="des">
          <h5>auto_cook_menu_available</h5>
          <p>{productifo.electronics?.auto_cook_menu_available}</p>
        </div>
      )}
      {productifo.electronics?.power_output !== "" && (
        <div className="des">
          <h5>power_output</h5>
          <p>{productifo.electronics?.power_output}</p>
        </div>
      )}
      {productifo.electronics?.sales_package !== "" && (
        <div className="des">
          <h5>sales_package</h5>
          <p>{productifo.electronics?.sales_package}</p>
        </div>
      )}
      {productifo.electronics?.rotisserie !== "" && (
        <div className="des">
          <h5>rotisserie</h5>
          <p>{productifo.electronics?.rotisserie}</p>
        </div>
      )}
      {productifo.electronics?.rotisserie_type !== "" && (
        <div className="des">
          <h5>rotisserie_type</h5>
          <p>{productifo.electronics?.rotisserie_type}</p>
        </div>
      )}
      {productifo.electronics?.control_panel !== "" && (
        <div className="des">
          <h5>control_panel</h5>
          <p>{productifo.electronics?.control_panel}</p>
        </div>
      )}
      {productifo.electronics?.cavity_material !== "" && (
        <div className="des">
          <h5>cavity_material</h5>
          <p>{productifo.electronics?.cavity_material}</p>
        </div>
      )}
      {productifo.electronics?.temperature_control_range !== "" && (
        <div className="des">
          <h5>temperature_control_range</h5>
          <p>{productifo.electronics?.temperature_control_range}</p>
        </div>
      )}
      {productifo.electronics?.auto_menu !== "" && (
        <div className="des">
          <h5>auto_menu</h5>
          <p>{productifo.electronics?.auto_menu}</p>
        </div>
      )}
      {productifo.electronics?.number_of_auto_menu !== "" && (
        <div className="des">
          <h5>number_of_auto_menu</h5>
          <p>{productifo.electronics?.number_of_auto_menu}</p>
        </div>
      )}
      {productifo.electronics?.power_requirement !== "" && (
        <div className="des">
          <h5>power_requirement</h5>
          <p>{productifo.electronics?.power_requirement}</p>
        </div>
      )}
      {productifo.electronics?.material !== "" && (
        <div className="des">
          <h5>material</h5>
          <p>{productifo.electronics?.material}</p>
        </div>
      )}
      {productifo.electronics?.dry_grinding !== "" && (
        <div className="des">
          <h5>dry_grinding</h5>
          <p>{productifo.electronics?.dry_grinding}</p>
        </div>
      )}
      {productifo.electronics?.mincing !== "" && (
        <div className="des">
          <h5>mincing</h5>
          <p>{productifo.electronics?.mincing}</p>
        </div>
      )}
      {productifo.electronics?.chutey_grinding !== "" && (
        <div className="des">
          <h5>chutey_grinding</h5>
          <p>{productifo.electronics?.chutey_grinding}</p>
        </div>
      )}
      {productifo.electronics?.liguidizing_jar_capacity !== "" && (
        <div className="des">
          <h5>liguidizing_jar_capacity</h5>
          <p>{productifo.electronics?.liguidizing_jar_capacity}</p>
        </div>
      )}
      {productifo.electronics?.chatey_jar_capacity !== "" && (
        <div className="des">
          <h5>chatey_jar_capacity</h5>
          <p>{productifo.electronics?.chatey_jar_capacity}</p>
        </div>
      )}
      {productifo.electronics?.series !== "" && (
        <div className="des">
          <h5>series</h5>
          <p>{productifo.electronics?.series}</p>
        </div>
      )}
      {productifo.electronics?.locbable_lid !== "" && (
        <div className="des">
          <h5>locbable_lid</h5>
          <p>{productifo.electronics?.locbable_lid}</p>
        </div>
      )}
      {productifo.electronics?.dry_boil_protection !== "" && (
        <div className="des">
          <h5>dry_boil_protection</h5>
          <p>{productifo.electronics?.dry_boil_protection}</p>
        </div>
      )}
      {productifo.electronics?.auto_switch_off !== "" && (
        <div className="des">
          <h5>auto_switch_off</h5>
          <p>{productifo.electronics?.auto_switch_off}</p>
        </div>
      )}
      {productifo.electronics?.heating_element !== "" && (
        <div className="des">
          <h5>heating_element</h5>
          <p>{productifo.electronics?.heating_element}</p>
        </div>
      )}
      {productifo.electronics?.water_level_indicator !== "" && (
        <div className="des">
          <h5>water_level_indicator</h5>
          <p>{productifo.electronics?.water_level_indicator}</p>
        </div>
      )}
      {productifo.electronics?.card_length !== "" && (
        <div className="des">
          <h5>card_length</h5>
          <p>{productifo.electronics?.card_length}</p>
        </div>
      )}
      {productifo.electronics?.body_material !== "" && (
        <div className="des">
          <h5>body_material</h5>
          <p>{productifo.electronics?.body_material}</p>
        </div>
      )}
      {productifo.electronics?.control !== "" && (
        <div className="des">
          <h5>control</h5>
          <p>{productifo.electronics?.control}</p>
        </div>
      )}
      {productifo.electronics?.present_cooking_menu !== "" && (
        <div className="des">
          <h5>present_cooking_menu</h5>
          <p>{productifo.electronics?.present_cooking_menu}</p>
        </div>
      )}
      {productifo.electronics?.elements_on_cooktop !== "" && (
        <div className="des">
          <h5>elements_on_cooktop</h5>
          <p>{productifo.electronics?.elements_on_cooktop}</p>
        </div>
      )}
      {productifo.electronics?.other_body_and_design_features !== "" && (
        <div className="des">
          <h5>other_body_and_design_features</h5>
          <p>{productifo.electronics?.other_body_and_design_features}</p>
        </div>
      )}
      {productifo.electronics?.service_type !== "" && (
        <div className="des">
          <h5>service_type</h5>
          <p>{productifo.electronics?.service_type}</p>
        </div>
      )}
      {productifo.electronics?.installation_and_demo !== "" && (
        <div className="des">
          <h5>installation_and_demo</h5>
          <p>{productifo.electronics?.installation_and_demo}</p>
        </div>
      )}
      {productifo.electronics?.finish !== "" && (
        <div className="des">
          <h5>finish</h5>
          <p>{productifo.electronics?.finish}</p>
        </div>
      )}
      {productifo.electronics?.air_section_capacity !== "" && (
        <div className="des">
          <h5>air_section_capacity</h5>
          <p>{productifo.electronics?.air_section_capacity}</p>
        </div>
      )}
      {productifo.electronics?.ductless !== "" && (
        <div className="des">
          <h5>ductless</h5>
          <p>{productifo.electronics?.ductless}</p>
        </div>
      )}
      {productifo.electronics?.filter_type !== "" && (
        <div className="des">
          <h5>filter_type</h5>
          <p>{productifo.electronics?.filter_type}</p>
        </div>
      )}
      {productifo.electronics?.speed_levels !== "" && (
        <div className="des">
          <h5>speed_levels</h5>
          <p>{productifo.electronics?.speed_levels}</p>
        </div>
      )}
      {productifo.electronics?.filter_block_indicator !== "" && (
        <div className="des">
          <h5>filter_block_indicator</h5>
          <p>{productifo.electronics?.filter_block_indicator}</p>
        </div>
      )}
      {productifo.electronics?.noise_level !== "" && (
        <div className="des">
          <h5>noise_level</h5>
          <p>{productifo.electronics?.noise_level}</p>
        </div>
      )}
      {productifo.electronics?.phase !== "" && (
        <div className="des">
          <h5>phase</h5>
          <p>{productifo.electronics?.phase}</p>
        </div>
      )}
      {productifo.electronics?.engine_power !== "" && (
        <div className="des">
          <h5>engine_power</h5>
          <p>{productifo.electronics?.engine_power}</p>
        </div>
      )}
      {productifo.electronics?.in_built_oil_collector !== "" && (
        <div className="des">
          <h5>in_built_oil_collector</h5>
          <p>{productifo.electronics?.in_built_oil_collector}</p>
        </div>
      )}
      {productifo.electronics?.timer_present !== "" && (
        <div className="des">
          <h5>timer_present</h5>
          <p>{productifo.electronics?.timer_present}</p>
        </div>
      )}
      {productifo.electronics?.dblade_material !== "" && (
        <div className="des">
          <h5>dblade_material</h5>
          <p>{productifo.electronics?.dblade_material}</p>
        </div>
      )}
      {productifo.electronics?.blending !== "" && (
        <div className="des">
          <h5>blending</h5>
          <p>{productifo.electronics?.blending}</p>
        </div>
      )}
      {productifo.electronics?.pureing !== "" && (
        <div className="des">
          <h5>pureing</h5>
          <p>{productifo.electronics?.pureing}</p>
        </div>
      )}
      {productifo.electronics?.stirring !== "" && (
        <div className="des">
          <h5>stirring</h5>
          <p>{productifo.electronics?.stirring}</p>
        </div>
      )}
      {productifo.electronics?.safety_features !== "" && (
        <div className="des">
          <h5>safety_features</h5>
          <p>{productifo.electronics?.safety_features}</p>
        </div>
      )}
      {productifo.electronics?.model_let !== "" && (
        <div className="des">
          <h5>model_let</h5>
          <p>{productifo.electronics?.model_let}</p>
        </div>
      )}
      {productifo.electronics?.slice_capacity !== "" && (
        <div className="des">
          <h5>slice_capacity</h5>
          <p>{productifo.electronics?.slice_capacity}</p>
        </div>
      )}
      {productifo.electronics?.working_plate !== "" && (
        <div className="des">
          <h5>working_plate</h5>
          <p>{productifo.electronics?.working_plate}</p>
        </div>
      )}
      {productifo.electronics?.lid_lock !== "" && (
        <div className="des">
          <h5>lid_lock</h5>
          <p>{productifo.electronics?.lid_lock}</p>
        </div>
      )}
      {productifo.electronics?.no_of_slices !== "" && (
        <div className="des">
          <h5>no_of_slices</h5>
          <p>{productifo.electronics?.no_of_slices}</p>
        </div>
      )}
      {productifo.electronics?.wide_slots_for_thick_breads !== "" && (
        <div className="des">
          <h5>wide_slots_for_thick_breads</h5>
          <p>{productifo.electronics?.wide_slots_for_thick_breads}</p>
        </div>
      )}
      {productifo.electronics?.high_lift_for_small_breads !== "" && (
        <div className="des">
          <h5>high_lift_for_small_breads</h5>
          <p>{productifo.electronics?.high_lift_for_small_breads}</p>
        </div>
      )}
      {productifo.electronics?.auto_pop_up !== "" && (
        <div className="des">
          <h5>auto_pop_up</h5>
          <p>{productifo.electronics?.auto_pop_up}</p>
        </div>
      )}
      {productifo.electronics?.removable_crumbtroy !== "" && (
        <div className="des">
          <h5>removable_crumbtroy</h5>
          <p>{productifo.electronics?.removable_crumbtroy}</p>
        </div>
      )}
      {productifo.electronics?.toasting !== "" && (
        <div className="des">
          <h5>toasting</h5>
          <p>{productifo.electronics?.toasting}</p>
        </div>
      )}
      {productifo.electronics?.warming !== "" && (
        <div className="des">
          <h5>warming</h5>
          <p>{productifo.electronics?.warming}</p>
        </div>
      )}
      {productifo.electronics?.reheat !== "" && (
        <div className="des">
          <h5>reheat</h5>
          <p>{productifo.electronics?.reheat}</p>
        </div>
      )}
      {productifo.electronics?.variable_browing !== "" && (
        <div className="des">
          <h5>variable_browing</h5>
          <p>{productifo.electronics?.variable_browing}</p>
        </div>
      )}
      {productifo.electronics?.defrost !== "" && (
        <div className="des">
          <h5>defrost</h5>
          <p>{productifo.electronics?.defrost}</p>
        </div>
      )}
      {productifo.electronics?.card_storage !== "" && (
        <div className="des">
          <h5>card_storage</h5>
          <p>{productifo.electronics?.card_storage}</p>
        </div>
      )}
      {productifo.electronics?.cool_touch !== "" && (
        <div className="des">
          <h5>cool_touch</h5>
          <p>{productifo.electronics?.cool_touch}</p>
        </div>
      )}
      {productifo.electronics?.length !== "" && (
        <div className="des">
          <h5>length</h5>
          <p>{productifo.electronics?.length}</p>
        </div>
      )}
      {productifo.electronics?.capacity_incups !== "" && (
        <div className="des">
          <h5>capacity_incups</h5>
          <p>{productifo.electronics?.capacity_incups}</p>
        </div>
      )}
      {productifo.electronics?.total_pan_capacity !== "" && (
        <div className="des">
          <h5>total_pan_capacity</h5>
          <p>{productifo.electronics?.total_pan_capacity}</p>
        </div>
      )}
      {productifo.electronics?.functions !== "" && (
        <div className="des">
          <h5>functions</h5>
          <p>{productifo.electronics?.functions}</p>
        </div>
      )}
      {productifo.electronics?.material_lid !== "" && (
        <div className="des">
          <h5>material_lid</h5>
          <p>{productifo.electronics?.material_lid}</p>
        </div>
      )}
      {productifo.electronics?.net_quantity_product_details !== "" && (
        <div className="des">
          <h5>net_quantity_product_details</h5>
          <p>{productifo.electronics?.net_quantity_product_details}</p>
        </div>
      )}
      {productifo.electronics?.autokeep_warm_function_cooking !== "" && (
        <div className="des">
          <h5>autokeep_warm_function_cooking</h5>
          <p>{productifo.electronics?.autokeep_warm_function_cooking}</p>
        </div>
      )}
      {productifo.electronics?.headphone_type !== "" && (
        <div className="des">
          <h5>headphone_type</h5>
          <p>{productifo.electronics?.headphone_type}</p>
        </div>
      )}
      {productifo.electronics?.inline_remote !== "" && (
        <div className="des">
          <h5>inline_remote</h5>
          <p>{productifo.electronics?.inline_remote}</p>
        </div>
      )}
      {productifo.electronics?.connectivity !== "" && (
        <div className="des">
          <h5>connectivity</h5>
          <p>{productifo.electronics?.connectivity}</p>
        </div>
      )}
      {productifo.electronics?.headphone_design !== "" && (
        <div className="des">
          <h5>headphone_design</h5>
          <p>{productifo.electronics?.headphone_design}</p>
        </div>
      )}
      {productifo.electronics?.foldable_or_collapsible !== "" && (
        <div className="des">
          <h5>foldable_or_collapsible</h5>
          <p>{productifo.electronics?.foldable_or_collapsible}</p>
        </div>
      )}
      {productifo.electronics?.deep_bass !== "" && (
        <div className="des">
          <h5>deep_bass</h5>
          <p>{productifo.electronics?.deep_bass}</p>
        </div>
      )}
      {productifo.electronics?.manual !== "" && (
        <div className="des">
          <h5>manual</h5>
          <p>{productifo.electronics?.manual}</p>
        </div>
      )}
      {productifo.electronics?.magnet_type !== "" && (
        <div className="des">
          <h5>magnet_type</h5>
          <p>{productifo.electronics?.magnet_type}</p>
        </div>
      )}
      {productifo.electronics?.driver_type !== "" && (
        <div className="des">
          <h5>driver_type</h5>
          <p>{productifo.electronics?.driver_type}</p>
        </div>
      )}
      {productifo.electronics?.indicators !== "" && (
        <div className="des">
          <h5>indicators</h5>
          <p>{productifo.electronics?.indicators}</p>
        </div>
      )}
      {productifo.electronics?.sensitivity !== "" && (
        <div className="des">
          <h5>sensitivity</h5>
          <p>{productifo.electronics?.sensitivity}</p>
        </div>
      )}
      {productifo.electronics?.impedance !== "" && (
        <div className="des">
          <h5>impedance</h5>
          <p>{productifo.electronics?.impedance}</p>
        </div>
      )}
      {productifo.electronics?.signal_to_noise_ratio !== "" && (
        <div className="des">
          <h5>signal_to_noise_ratio</h5>
          <p>{productifo.electronics?.signal_to_noise_ratio}</p>
        </div>
      )}
      {productifo.electronics?.modulation !== "" && (
        <div className="des">
          <h5>modulation</h5>
          <p>{productifo.electronics?.modulation}</p>
        </div>
      )}
      {productifo.electronics?.minimum_frequency_response !== "" && (
        <div className="des">
          <h5>minimum_frequency_response</h5>
          <p>{productifo.electronics?.minimum_frequency_response}</p>
        </div>
      )}
      {productifo.electronics?.other_sound_features !== "" && (
        <div className="des">
          <h5>other_sound_features</h5>
          <p>{productifo.electronics?.other_sound_features}</p>
        </div>
      )}
      {productifo.electronics?.audio_codec !== "" && (
        <div className="des">
          <h5>audio_codec</h5>
          <p>{productifo.electronics?.audio_codec}</p>
        </div>
      )}
      {productifo.electronics?.blutooth_profile !== "" && (
        <div className="des">
          <h5>blutooth_profile</h5>
          <p>{productifo.electronics?.blutooth_profile}</p>
        </div>
      )}
      {productifo.electronics?.blutooth_range !== "" && (
        <div className="des">
          <h5>blutooth_range</h5>
          <p>{productifo.electronics?.blutooth_range}</p>
        </div>
      )}
      {productifo.electronics?.headphone_power_source !== "" && (
        <div className="des">
          <h5>headphone_power_source</h5>
          <p>{productifo.electronics?.headphone_power_source}</p>
        </div>
      )}
      {productifo.electronics?.power_supply !== "" && (
        <div className="des">
          <h5>power_supply</h5>
          <p>{productifo.electronics?.power_supply}</p>
        </div>
      )}
      {productifo.electronics?.battery_life !== "" && (
        <div className="des">
          <h5>battery_life</h5>
          <p>{productifo.electronics?.battery_life}</p>
        </div>
      )}
      {productifo.electronics?.stand_by_time !== "" && (
        <div className="des">
          <h5>stand_by_time</h5>
          <p>{productifo.electronics?.stand_by_time}</p>
        </div>
      )}
      {productifo.electronics?.applied !== "" && (
        <div className="des">
          <h5>applied</h5>
          <p>{productifo.electronics?.applied}</p>
        </div>
      )}
      {productifo.electronics?.number_of_layers !== "" && (
        <div className="des">
          <h5>number_of_layers</h5>
          <p>{productifo.electronics?.number_of_layers}</p>
        </div>
      )}
      {productifo.electronics?.in_rescidue_free_removal !== "" && (
        <div className="des">
          <h5>in_rescidue_free_removal</h5>
          <p>{productifo.electronics?.in_rescidue_free_removal}</p>
        </div>
      )}
      {productifo.electronics?.tinted !== "" && (
        <div className="des">
          <h5>tinted</h5>
          <p>{productifo.electronics?.tinted}</p>
        </div>
      )}
      {productifo.electronics?.camera_hole_present !== "" && (
        <div className="des">
          <h5>camera_hole_present</h5>
          <p>{productifo.electronics?.camera_hole_present}</p>
        </div>
      )}
      {productifo.electronics?.usage !== "" && (
        <div className="des">
          <h5>usage</h5>
          <p>{productifo.electronics?.usage}</p>
        </div>
      )}
      {productifo.electronics?.operating_system_supported !== "" && (
        <div className="des">
          <h5>operating_system_supported</h5>
          <p>{productifo.electronics?.operating_system_supported}</p>
        </div>
      )}
      {productifo.electronics?.type_of_tracking !== "" && (
        <div className="des">
          <h5>type_of_tracking</h5>
          <p>{productifo.electronics?.type_of_tracking}</p>
        </div>
      )}
      {productifo.electronics?.auto_trasperancy !== "" && (
        <div className="des">
          <h5>auto_trasperancy</h5>
          <p>{productifo.electronics?.auto_trasperancy}</p>
        </div>
      )}
      {productifo.electronics?.display !== "" && (
        <div className="des">
          <h5>display</h5>
          <p>{productifo.electronics?.display}</p>
        </div>
      )}
      {productifo.electronics?.wireless_data_processing !== "" && (
        <div className="des">
          <h5>wireless_data_processing</h5>
          <p>{productifo.electronics?.wireless_data_processing}</p>
        </div>
      )}
      {productifo.electronics?.one_touch_button !== "" && (
        <div className="des">
          <h5>one_touch_button</h5>
          <p>{productifo.electronics?.one_touch_button}</p>
        </div>
      )}
      {productifo.electronics?.touch_control !== "" && (
        <div className="des">
          <h5>touch_control</h5>
          <p>{productifo.electronics?.touch_control}</p>
        </div>
      )}
      {productifo.electronics?.battery_included !== "" && (
        <div className="des">
          <h5>battery_included</h5>
          <p>{productifo.electronics?.battery_included}</p>
        </div>
      )}
      {productifo.electronics?.battery_chargable !== "" && (
        <div className="des">
          <h5>battery_chargable</h5>
          <p>{productifo.electronics?.battery_chargable}</p>
        </div>
      )}
      {productifo.electronics?.water_resistance !== "" && (
        <div className="des">
          <h5>water_resistance</h5>
          <p>{productifo.electronics?.water_resistance}</p>
        </div>
      )}
      {productifo.electronics?.noise_concellation !== "" && (
        <div className="des">
          <h5>noise_concellation</h5>
          <p>{productifo.electronics?.noise_concellation}</p>
        </div>
      )}
      {productifo.electronics?.number_of_contents_in_sales_pack !== "" && (
        <div className="des">
          <h5>number_of_contents_in_sales_pack</h5>
          <p>{productifo.electronics?.number_of_contents_in_sales_pack}</p>
        </div>
      )}
      {productifo.electronics?.cable_type !== "" && (
        <div className="des">
          <h5>cable_type</h5>
          <p>{productifo.electronics?.cable_type}</p>
        </div>
      )}
      {productifo.electronics?.connector1 !== "" && (
        <div className="des">
          <h5>connector1</h5>
          <p>{productifo.electronics?.connector1}</p>
        </div>
      )}
      {productifo.electronics?.connector2 !== "" && (
        <div className="des">
          <h5>connector2</h5>
          <p>{productifo.electronics?.connector2}</p>
        </div>
      )}
      {productifo.electronics?.cable !== "" && (
        <div className="des">
          <h5>cable</h5>
          <p>{productifo.electronics?.cable}</p>
        </div>
      )}
      {productifo.electronics?.compatible_devices !== "" && (
        <div className="des">
          <h5>compatible_devices</h5>
          <p>{productifo.electronics?.compatible_devices}</p>
        </div>
      )}
      {productifo.electronics?.cable_length !== "" && (
        <div className="des">
          <h5>cable_length</h5>
          <p>{productifo.electronics?.cable_length}</p>
        </div>
      )}
      {productifo.electronics?.keyboard !== "" && (
        <div className="des">
          <h5>keyboard</h5>
          <p>{productifo.electronics?.keyboard}</p>
        </div>
      )}
      {productifo.electronics?.security_chip !== "" && (
        <div className="des">
          <h5>security_chip</h5>
          <p>{productifo.electronics?.security_chip}</p>
        </div>
      )}
      {productifo.electronics?.web_camera !== "" && (
        <div className="des">
          <h5>web_camera</h5>
          <p>{productifo.electronics?.web_camera}</p>
        </div>
      )}
      {productifo.electronics?.finger_print_sensor !== "" && (
        <div className="des">
          <h5>finger_print_sensor</h5>
          <p>{productifo.electronics?.finger_print_sensor}</p>
        </div>
      )}
      {productifo.electronics?.processor_variant !== "" && (
        <div className="des">
          <h5>processor_variant</h5>
          <p>{productifo.electronics?.processor_variant}</p>
        </div>
      )}
      {productifo.electronics?.cache !== "" && (
        <div className="des">
          <h5>cache</h5>
          <p>{productifo.electronics?.cache}</p>
        </div>
      )}
      {productifo.electronics?.number_of_cores !== "" && (
        <div className="des">
          <h5>number_of_cores</h5>
          <p>{productifo.electronics?.number_of_cores}</p>
        </div>
      )}
      {productifo.electronics?.os_architecture !== "" && (
        <div className="des">
          <h5>os_architecture</h5>
          <p>{productifo.electronics?.os_architecture}</p>
        </div>
      )}
      {productifo.electronics?.mic_in !== "" && (
        <div className="des">
          <h5>mic_in</h5>
          <p>{productifo.electronics?.mic_in}</p>
        </div>
      )}
      {productifo.electronics?.rj45 !== "" && (
        <div className="des">
          <h5>rj45</h5>
          <p>{productifo.electronics?.rj45}</p>
        </div>
      )}
      {productifo.electronics?.multi_card_slot !== "" && (
        <div className="des">
          <h5>multi_card_slot</h5>
          <p>{productifo.electronics?.multi_card_slot}</p>
        </div>
      )}
      {productifo.electronics?.hardware_interface !== "" && (
        <div className="des">
          <h5>hardware_interface</h5>
          <p>{productifo.electronics?.hardware_interface}</p>
        </div>
      )}
      {productifo.electronics?.disk_drive !== "" && (
        <div className="des">
          <h5>disk_drive</h5>
          <p>{productifo.electronics?.disk_drive}</p>
        </div>
      )}
      {productifo.electronics?.connector_type !== "" && (
        <div className="des">
          <h5>connector_type</h5>
          <p>{productifo.electronics?.connector_type}</p>
        </div>
      )}
      {productifo.electronics?.number_of_devices !== "" && (
        <div className="des">
          <h5>number_of_devices</h5>
          <p>{productifo.electronics?.number_of_devices}</p>
        </div>
      )}
      {productifo.electronics?.number_of_charger_pins !== "" && (
        <div className="des">
          <h5>number_of_charger_pins</h5>
          <p>{productifo.electronics?.number_of_charger_pins}</p>
        </div>
      )}
      {productifo.electronics?.output_current !== "" && (
        <div className="des">
          <h5>output_current</h5>
          <p>{productifo.electronics?.output_current}</p>
        </div>
      )}
      {productifo.electronics?.output_wattage !== "" && (
        <div className="des">
          <h5>output_wattage</h5>
          <p>{productifo.electronics?.output_wattage}</p>
        </div>
      )}
      {productifo.electronics?.compatible_with !== "" && (
        <div className="des">
          <h5>compatible_with</h5>
          <p>{productifo.electronics?.compatible_with}</p>
        </div>
      )}
      {productifo.electronics?.flodable !== "" && (
        <div className="des">
          <h5>flodable</h5>
          <p>{productifo.electronics?.flodable}</p>
        </div>
      )}
      {productifo.electronics?.rpm !== "" && (
        <div className="des">
          <h5>rpm</h5>
          <p>{productifo.electronics?.rpm}</p>
        </div>
      )}
      {productifo.electronics?.graphic_processor !== "" && (
        <div className="des">
          <h5>graphic_processor</h5>
          <p>{productifo.electronics?.graphic_processor}</p>
        </div>
      )}
      {productifo.electronics?.battery_backup !== "" && (
        <div className="des">
          <h5>battery_backup</h5>
          <p>{productifo.electronics?.battery_backup}</p>
        </div>
      )}
      {productifo.electronics?.battery_cell !== "" && (
        <div className="des">
          <h5>battery_cell</h5>
          <p>{productifo.electronics?.battery_cell}</p>
        </div>
      )}
      {productifo.electronics?.ms_office_provided !== "" && (
        <div className="des">
          <h5>ms_office_provided</h5>
          <p>{productifo.electronics?.ms_office_provided}</p>
        </div>
      )}
      {productifo.electronics?.processor_name !== "" && (
        <div className="des">
          <h5>processor_name</h5>
          <p>{productifo.electronics?.processor_name}</p>
        </div>
      )}
      {productifo.electronics?.processor_generation !== "" && (
        <div className="des">
          <h5>processor_generation</h5>
          <p>{productifo.electronics?.processor_generation}</p>
        </div>
      )}
      {productifo.electronics?.ram_type !== "" && (
        <div className="des">
          <h5>ram_type</h5>
          <p>{productifo.electronics?.ram_type}</p>
        </div>
      )}
      {productifo.electronics?.hdd_capacity !== "" && (
        <div className="des">
          <h5>hdd_capacity</h5>
          <p>{productifo.electronics?.hdd_capacity}</p>
        </div>
      )}
      {productifo.electronics?.output_interface !== "" && (
        <div className="des">
          <h5>output_interface</h5>
          <p>{productifo.electronics?.output_interface}</p>
        </div>
      )}
      {productifo.electronics?.maximum_current_rating !== "" && (
        <div className="des">
          <h5>maximum_current_rating</h5>
          <p>{productifo.electronics?.maximum_current_rating}</p>
        </div>
      )}
      {productifo.electronics?.other_material !== "" && (
        <div className="des">
          <h5>other_material</h5>
          <p>{productifo.electronics?.other_material}</p>
        </div>
      )}
      {productifo.electronics?.pointer_device !== "" && (
        <div className="des">
          <h5>pointer_device</h5>
          <p>{productifo.electronics?.pointer_device}</p>
        </div>
      )}
      {productifo.electronics?.emmc_storage_capacity !== "" && (
        <div className="des">
          <h5>emmc_storage_capacity</h5>
          <p>{productifo.electronics?.emmc_storage_capacity}</p>
        </div>
      )}
      {productifo.electronics?.ram_frequency !== "" && (
        <div className="des">
          <h5>ram_frequency</h5>
          <p>{productifo.electronics?.ram_frequency}</p>
        </div>
      )}
      {productifo.electronics?.screen_size !== "" && (
        <div className="des">
          <h5>screen_size</h5>
          <p>{productifo.electronics?.screen_size}</p>
        </div>
      )}
      {productifo.electronics?.screen_type !== "" && (
        <div className="des">
          <h5>screen_type</h5>
          <p>{productifo.electronics?.screen_type}</p>
        </div>
      )}
      {productifo.electronics?.speakers !== "" && (
        <div className="des">
          <h5>speakers</h5>
          <p>{productifo.electronics?.speakers}</p>
        </div>
      )}
      {productifo.electronics?.internal_mic !== "" && (
        <div className="des">
          <h5>internal_mic</h5>
          <p>{productifo.electronics?.internal_mic}</p>
        </div>
      )}
      {productifo.electronics?.wireless_lan !== "" && (
        <div className="des">
          <h5>wireless_lan</h5>
          <p>{productifo.electronics?.wireless_lan}</p>
        </div>
      )}
      {productifo.electronics?.dedicated_graphic_memory_type !== "" && (
        <div className="des">
          <h5>dedicated_graphic_memory_type</h5>
          <p>{productifo.electronics?.dedicated_graphic_memory_type}</p>
        </div>
      )}
      {productifo.electronics?.dedicated_graphic_memory_capacity !== "" && (
        <div className="des">
          <h5>dedicated_graphic_memory_capacity</h5>
          <p>{productifo.electronics?.dedicated_graphic_memory_capacity}</p>
        </div>
      )}
      {productifo.electronics?.ethernet !== "" && (
        <div className="des">
          <h5>ethernet</h5>
          <p>{productifo.electronics?.ethernet}</p>
        </div>
      )}
      {productifo.electronics?.backlit_keyboard !== "" && (
        <div className="des">
          <h5>backlit_keyboard</h5>
          <p>{productifo.electronics?.backlit_keyboard}</p>
        </div>
      )}
      {productifo.electronics?.memory_slot !== "" && (
        <div className="des">
          <h5>memory_slot</h5>
          <p>{productifo.electronics?.memory_slot}</p>
        </div>
      )}
      {productifo.electronics?.expandle_memory !== "" && (
        <div className="des">
          <h5>expandle_memory</h5>
          <p>{productifo.electronics?.expandle_memory}</p>
        </div>
      )}
      {productifo.electronics?.sound_properties !== "" && (
        <div className="des">
          <h5>sound_properties</h5>
          <p>{productifo.electronics?.sound_properties}</p>
        </div>
      )}
      {productifo.electronics?.lock_port !== "" && (
        <div className="des">
          <h5>lock_port</h5>
          <p>{productifo.electronics?.lock_port}</p>
        </div>
      )}
      {productifo.electronics?.operating_system_version !== "" && (
        <div className="des">
          <h5>operating_system_version</h5>
          <p>{productifo.electronics?.operating_system_version}</p>
        </div>
      )}
      {productifo.electronics?.voice_call !== "" && (
        <div className="des">
          <h5>voice_call</h5>
          <p>{productifo.electronics?.voice_call}</p>
        </div>
      )}
      {productifo.electronics?.display_resolution_time !== "" && (
        <div className="des">
          <h5>display_resolution_time</h5>
          <p>{productifo.electronics?.display_resolution_time}</p>
        </div>
      )}
      {productifo.electronics?.display_support_network !== "" && (
        <div className="des">
          <h5>display_support_network</h5>
          <p>{productifo.electronics?.display_support_network}</p>
        </div>
      )}
      {productifo.electronics?.display_resolution !== "" && (
        <div className="des">
          <h5>display_resolution</h5>
          <p>{productifo.electronics?.display_resolution}</p>
        </div>
      )}
      {productifo.electronics?.processor_speed !== "" && (
        <div className="des">
          <h5>processor_speed</h5>
          <p>{productifo.electronics?.processor_speed}</p>
        </div>
      )}
      {productifo.electronics?.headphone_jack !== "" && (
        <div className="des">
          <h5>headphone_jack</h5>
          <p>{productifo.electronics?.headphone_jack}</p>
        </div>
      )}
      {productifo.electronics?.expandable_storage_capacity !== "" && (
        <div className="des">
          <h5>expandable_storage_capacity</h5>
          <p>{productifo.electronics?.expandable_storage_capacity}</p>
        </div>
      )}
      {productifo.electronics?.memory_card_slot_type !== "" && (
        <div className="des">
          <h5>memory_card_slot_type</h5>
          <p>{productifo.electronics?.memory_card_slot_type}</p>
        </div>
      )}
      {productifo.electronics?.memory_card_support !== "" && (
        <div className="des">
          <h5>memory_card_support</h5>
          <p>{productifo.electronics?.memory_card_support}</p>
        </div>
      )}
      {productifo.electronics?.warranty_period !== "" && (
        <div className="des">
          <h5>warranty_period</h5>
          <p>{productifo.electronics?.warranty_period}</p>
        </div>
      )}
      {productifo.electronics?.sound_enhancements !== "" && (
        <div className="des">
          <h5>sound_enhancements</h5>
          <p>{productifo.electronics?.sound_enhancements}</p>
        </div>
      )}
      {productifo.electronics?.optical_zoom !== "" && (
        <div className="des">
          <h5>optical_zoom</h5>
          <p>{productifo.electronics?.optical_zoom}</p>
        </div>
      )}
      {productifo.electronics?.video_call_support !== "" && (
        <div className="des">
          <h5>video_call_support</h5>
          <p>{productifo.electronics?.video_call_support}</p>
        </div>
      )}
      {productifo.electronics?.graphicsppl !== "" && (
        <div className="des">
          <h5>graphicsppl</h5>
          <p>{productifo.electronics?.graphicsppl}</p>
        </div>
      )}
      {productifo.electronics?.supported_languages !== "" && (
        <div className="des">
          <h5>supported_languages</h5>
          <p>{productifo.electronics?.supported_languages}</p>
        </div>
      )}
      {productifo.electronics?.important_apps !== "" && (
        <div className="des">
          <h5>important_apps</h5>
          <p>{productifo.electronics?.important_apps}</p>
        </div>
      )}
      {productifo.electronics?.audio_formats !== "" && (
        <div className="des">
          <h5>audio_formats</h5>
          <p>{productifo.electronics?.audio_formats}</p>
        </div>
      )}
      {productifo.electronics?.video_formats !== "" && (
        <div className="des">
          <h5>video_formats</h5>
          <p>{productifo.electronics?.video_formats}</p>
        </div>
      )}
      {productifo.electronics?.ideal_for !== "" && (
        <div className="des">
          <h5>ideal_for</h5>
          <p>{productifo.electronics?.ideal_for}</p>
        </div>
      )}
      {productifo.electronics?.ideal_usage !== "" && (
        <div className="des">
          <h5>ideal_usage</h5>
          <p>{productifo.electronics?.ideal_usage}</p>
        </div>
      )}
      {productifo.electronics?.loptop_sleeve !== "" && (
        <div className="des">
          <h5>loptop_sleeve</h5>
          <p>{productifo.electronics?.loptop_sleeve}</p>
        </div>
      )}
      {productifo.electronics?.with_rain_cover !== "" && (
        <div className="des">
          <h5>with_rain_cover</h5>
          <p>{productifo.electronics?.with_rain_cover}</p>
        </div>
      )}
      {productifo.electronics?.compatibility_loptop_size !== "" && (
        <div className="des">
          <h5>compatibility_loptop_size</h5>
          <p>{productifo.electronics?.compatibility_loptop_size}</p>
        </div>
      )}
      {productifo.electronics?.style_code !== "" && (
        <div className="des">
          <h5>style_code</h5>
          <p>{productifo.electronics?.style_code}</p>
        </div>
      )}
      {productifo.electronics?.color_code !== "" && (
        <div className="des">
          <h5>color_code</h5>
          <p>{productifo.electronics?.color_code}</p>
        </div>
      )}
      {productifo.electronics?.material_water_proof !== "" && (
        <div className="des">
          <h5>material_water_proof</h5>
          <p>{productifo.electronics?.material_water_proof}</p>
        </div>
      )}
      {productifo.electronics?.number_of_compartments !== "" && (
        <div className="des">
          <h5>number_of_compartments</h5>
          <p>{productifo.electronics?.number_of_compartments}</p>
        </div>
      )}
      {productifo.electronics?.number_of_packets !== "" && (
        <div className="des">
          <h5>number_of_packets</h5>
          <p>{productifo.electronics?.number_of_packets}</p>
        </div>
      )}
      {productifo.electronics?.configuration !== "" && (
        <div className="des">
          <h5>configuration</h5>
          <p>{productifo.electronics?.configuration}</p>
        </div>
      )}
      {productifo.electronics?.frequency_response !== "" && (
        <div className="des">
          <h5>frequency_response</h5>
          <p>{productifo.electronics?.frequency_response}</p>
        </div>
      )}
      {productifo.electronics?.built_in_fm_radio !== "" && (
        <div className="des">
          <h5>built_in_fm_radio</h5>
          <p>{productifo.electronics?.built_in_fm_radio}</p>
        </div>
      )}
      {productifo.electronics?.certifications !== "" && (
        <div className="des">
          <h5>certifications</h5>
          <p>{productifo.electronics?.certifications}</p>
        </div>
      )}
      {productifo.electronics?.charging_time !== "" && (
        <div className="des">
          <h5>charging_time</h5>
          <p>{productifo.electronics?.charging_time}</p>
        </div>
      )}
      {productifo.electronics?.cable_interface !== "" && (
        <div className="des">
          <h5>cable_interface</h5>
          <p>{productifo.electronics?.cable_interface}</p>
        </div>
      )}
      {productifo.electronics?.platform !== "" && (
        <div className="des">
          <h5>platform</h5>
          <p>{productifo.electronics?.platform}</p>
        </div>
      )}
      {productifo.electronics?.read_speed !== "" && (
        <div className="des">
          <h5>read_speed</h5>
          <p>{productifo.electronics?.read_speed}</p>
        </div>
      )}
      {productifo.electronics?.write_speed !== "" && (
        <div className="des">
          <h5>write_speed</h5>
          <p>{productifo.electronics?.write_speed}</p>
        </div>
      )}
      {productifo.electronics?.system_requirements !== "" && (
        <div className="des">
          <h5>system_requirements</h5>
          <p>{productifo.electronics?.system_requirements}</p>
        </div>
      )}
      {productifo.electronics?.form_factor !== "" && (
        <div className="des">
          <h5>form_factor</h5>
          <p>{productifo.electronics?.form_factor}</p>
        </div>
      )}
      {productifo.electronics?.cloud_backup !== "" && (
        <div className="des">
          <h5>cloud_backup</h5>
          <p>{productifo.electronics?.cloud_backup}</p>
        </div>
      )}
      {productifo.electronics?.data_tranfer_rate !== "" && (
        <div className="des">
          <h5>data_tranfer_rate</h5>
          <p>{productifo.electronics?.data_tranfer_rate}</p>
        </div>
      )}
      {productifo.electronics?.smartphone !== "" && (
        <div className="des">
          <h5>smartphone</h5>
          <p>{productifo.electronics?.smartphone}</p>
        </div>
      )}
      {productifo.electronics?.rotational_speed !== "" && (
        <div className="des">
          <h5>rotational_speed</h5>
          <p>{productifo.electronics?.rotational_speed}</p>
        </div>
      )}
      {productifo.electronics?.password_protection !== "" && (
        <div className="des">
          <h5>password_protection</h5>
          <p>{productifo.electronics?.password_protection}</p>
        </div>
      )}
      {productifo.electronics?.power_source !== "" && (
        <div className="des">
          <h5>power_source</h5>
          <p>{productifo.electronics?.power_source}</p>
        </div>
      )}
      {productifo.electronics?.size !== "" && (
        <div className="des">
          <h5>size</h5>
          <p>{productifo.electronics?.size}</p>
        </div>
      )}
      {productifo.electronics?.shock_resistant !== "" && (
        <div className="des">
          <h5>shock_resistant</h5>
          <p>{productifo.electronics?.shock_resistant}</p>
        </div>
      )}
      {productifo.electronics?.water_proof !== "" && (
        <div className="des">
          <h5>water_proof</h5>
          <p>{productifo.electronics?.water_proof}</p>
        </div>
      )}
      {productifo.electronics?.led_indicator !== "" && (
        <div className="des">
          <h5>led_indicator</h5>
          <p>{productifo.electronics?.led_indicator}</p>
        </div>
      )}
      {productifo.electronics?.operating_range !== "" && (
        <div className="des">
          <h5>operating_range</h5>
          <p>{productifo.electronics?.operating_range}</p>
        </div>
      )}
      {productifo.electronics?.frequency !== "" && (
        <div className="des">
          <h5>frequency</h5>
          <p>{productifo.electronics?.frequency}</p>
        </div>
      )}
      {productifo.electronics?.frequency_band !== "" && (
        <div className="des">
          <h5>frequency_band</h5>
          <p>{productifo.electronics?.frequency_band}</p>
        </div>
      )}
      {productifo.electronics?.wireless_speed !== "" && (
        <div className="des">
          <h5>wireless_speed</h5>
          <p>{productifo.electronics?.wireless_speed}</p>
        </div>
      )}
      {productifo.electronics?.dsl_modern_speed !== "" && (
        <div className="des">
          <h5>dsl_modern_speed</h5>
          <p>{productifo.electronics?.dsl_modern_speed}</p>
        </div>
      )}
      {productifo.electronics?.lan_wan !== "" && (
        <div className="des">
          <h5>lan_wan</h5>
          <p>{productifo.electronics?.lan_wan}</p>
        </div>
      )}
      {productifo.electronics?.antennae !== "" && (
        <div className="des">
          <h5>antennae</h5>
          <p>{productifo.electronics?.antennae}</p>
        </div>
      )}
      {productifo.electronics?.number_of_usb_ports !== "" && (
        <div className="des">
          <h5>number_of_usb_ports</h5>
          <p>{productifo.electronics?.number_of_usb_ports}</p>
        </div>
      )}
      {productifo.electronics?.lan !== "" && (
        <div className="des">
          <h5>lan</h5>
          <p>{productifo.electronics?.lan}</p>
        </div>
      )}
      {productifo.electronics?.apparent_field_of_view !== "" && (
        <div className="des">
          <h5>apparent_field_of_view</h5>
          <p>{productifo.electronics?.apparent_field_of_view}</p>
        </div>
      )}
      {productifo.electronics?.magnification !== "" && (
        <div className="des">
          <h5>magnification</h5>
          <p>{productifo.electronics?.magnification}</p>
        </div>
      )}
      {productifo.electronics?.maximum_shelf_life !== "" && (
        <div className="des">
          <h5>maximum_shelf_life</h5>
          <p>{productifo.electronics?.maximum_shelf_life}</p>
        </div>
      )}
      {productifo.electronics?.no_memory_effect !== "" && (
        <div className="des">
          <h5>no_memory_effect</h5>
          <p>{productifo.electronics?.no_memory_effect}</p>
        </div>
      )}
      {productifo.electronics?.hd_resolution !== "" && (
        <div className="des">
          <h5>hd_resolution</h5>
          <p>{productifo.electronics?.hd_resolution}</p>
        </div>
      )}
      {productifo.electronics?.smart_tv !== "" && (
        <div className="des">
          <h5>smart_tv</h5>
          <p>{productifo.electronics?.smart_tv}</p>
        </div>
      )}
      {productifo.electronics?.hdmi !== "" && (
        <div className="des">
          <h5>hdmi</h5>
          <p>{productifo.electronics?.hdmi}</p>
        </div>
      )}
      {productifo.electronics?.usb !== "" && (
        <div className="des">
          <h5>usb</h5>
          <p>{productifo.electronics?.usb}</p>
        </div>
      )}
      {productifo.electronics?.wifi_type !== "" && (
        <div className="des">
          <h5>wifi_type</h5>
          <p>{productifo.electronics?.wifi_type}</p>
        </div>
      )}
      {productifo.electronics?.built_in_wifi !== "" && (
        <div className="des">
          <h5>built_in_wifi</h5>
          <p>{productifo.electronics?.built_in_wifi}</p>
        </div>
      )}
      {productifo.electronics?.wall_mount_included !== "" && (
        <div className="des">
          <h5>wall_mount_included</h5>
          <p>{productifo.electronics?.wall_mount_included}</p>
        </div>
      )}
      {productifo.electronics?.picture_in_picture !== "" && (
        <div className="des">
          <h5>picture_in_picture</h5>
          <p>{productifo.electronics?.picture_in_picture}</p>
        </div>
      )}
      {productifo.electronics?.picture_engine !== "" && (
        <div className="des">
          <h5>picture_engine</h5>
          <p>{productifo.electronics?.picture_engine}</p>
        </div>
      )}
      {productifo.electronics?.analog_tv_reception !== "" && (
        <div className="des">
          <h5>analog_tv_reception</h5>
          <p>{productifo.electronics?.analog_tv_reception}</p>
        </div>
      )}
      {productifo.electronics?.view_angle !== "" && (
        <div className="des">
          <h5>view_angle</h5>
          <p>{productifo.electronics?.view_angle}</p>
        </div>
      )}
      {productifo.electronics?.panel_type !== "" && (
        <div className="des">
          <h5>panel_type</h5>
          <p>{productifo.electronics?.panel_type}</p>
        </div>
      )}
      {productifo.electronics?.digital_noise_filter !== "" && (
        <div className="des">
          <h5>digital_noise_filter</h5>
          <p>{productifo.electronics?.digital_noise_filter}</p>
        </div>
      )}
      {productifo.electronics?.led_display_type !== "" && (
        <div className="des">
          <h5>led_display_type</h5>
          <p>{productifo.electronics?.led_display_type}</p>
        </div>
      )}
      {productifo.electronics?.aspect_ratio !== "" && (
        <div className="des">
          <h5>aspect_ratio</h5>
          <p>{productifo.electronics?.aspect_ratio}</p>
        </div>
      )}
      {productifo.electronics?.refresh_rate !== "" && (
        <div className="des">
          <h5>refresh_rate</h5>
          <p>{productifo.electronics?.refresh_rate}</p>
        </div>
      )}
      {productifo.electronics?.number_of_speakers !== "" && (
        <div className="des">
          <h5>number_of_speakers</h5>
          <p>{productifo.electronics?.number_of_speakers}</p>
        </div>
      )}
      {productifo.electronics?.speaker_type !== "" && (
        <div className="des">
          <h5>speaker_type</h5>
          <p>{productifo.electronics?.speaker_type}</p>
        </div>
      )}
      {productifo.electronics?.sound_technology !== "" && (
        <div className="des">
          <h5>sound_technology</h5>
          <p>{productifo.electronics?.sound_technology}</p>
        </div>
      )}
      {productifo.electronics?.surround_sound !== "" && (
        <div className="des">
          <h5>surround_sound</h5>
          <p>{productifo.electronics?.surround_sound}</p>
        </div>
      )}
      {productifo.electronics?.speaker_output_rms !== "" && (
        <div className="des">
          <h5>speaker_output_rms</h5>
          <p>{productifo.electronics?.speaker_output_rms}</p>
        </div>
      )}
      {productifo.electronics?.sound_mode !== "" && (
        <div className="des">
          <h5>sound_mode</h5>
          <p>{productifo.electronics?.sound_mode}</p>
        </div>
      )}
      {productifo.electronics?.supported_audio_features !== "" && (
        <div className="des">
          <h5>supported_audio_features</h5>
          <p>{productifo.electronics?.supported_audio_features}</p>
        </div>
      )}
      {productifo.electronics?.supported_app_netflix !== "" && (
        <div className="des">
          <h5>supported_app_netflix</h5>
          <p>{productifo.electronics?.supported_app_netflix}</p>
        </div>
      )}
      {productifo.electronics?.supported_app_youtube !== "" && (
        <div className="des">
          <h5>supported_app_youtube</h5>
          <p>{productifo.electronics?.supported_app_youtube}</p>
        </div>
      )}
      {productifo.electronics?.supported_app_disney_hotstar !== "" && (
        <div className="des">
          <h5>supported_app_disney_hotstar</h5>
          <p>{productifo.electronics?.supported_app_disney_hotstar}</p>
        </div>
      )}
      {productifo.electronics?.supported_app_prime_video !== "" && (
        <div className="des">
          <h5>supported_app_prime_video</h5>
          <p>{productifo.electronics?.supported_app_prime_video}</p>
        </div>
      )}
      {productifo.electronics?.supported_mobile_operating_system !== "" && (
        <div className="des">
          <h5>supported_mobile_operating_system</h5>
          <p>{productifo.electronics?.supported_mobile_operating_system}</p>
        </div>
      )}
      {productifo.electronics?.operating_system_present !== "" && (
        <div className="des">
          <h5>operating_system_present</h5>
          <p>{productifo.electronics?.operating_system_present}</p>
        </div>
      )}
      {productifo.electronics?.screen_mirroring !== "" && (
        <div className="des">
          <h5>screen_mirroring</h5>
          <p>{productifo.electronics?.screen_mirroring}</p>
        </div>
      )}
      {productifo.electronics?.app_store_type !== "" && (
        <div className="des">
          <h5>app_store_type</h5>
          <p>{productifo.electronics?.app_store_type}</p>
        </div>
      )}
      {productifo.electronics?.pre_installed_browser !== "" && (
        <div className="des">
          <h5>pre_installed_browser</h5>
          <p>{productifo.electronics?.pre_installed_browser}</p>
        </div>
      )}
      {productifo.electronics?.content_providers !== "" && (
        <div className="des">
          <h5>content_providers</h5>
          <p>{productifo.electronics?.content_providers}</p>
        </div>
      )}
      {productifo.electronics?.supported_devices_for_casting !== "" && (
        <div className="des">
          <h5>supported_devices_for_casting</h5>
          <p>{productifo.electronics?.supported_devices_for_casting}</p>
        </div>
      )}
      {productifo.electronics?.teletext !== "" && (
        <div className="des">
          <h5>teletext</h5>
          <p>{productifo.electronics?.teletext}</p>
        </div>
      )}
      {productifo.electronics?.mobile_high_defination_link !== "" && (
        <div className="des">
          <h5>mobile_high_defination_link</h5>
          <p>{productifo.electronics?.mobile_high_defination_link}</p>
        </div>
      )}
      {productifo.electronics?._3g_dongle_plug_and_play !== "" && (
        <div className="des">
          <h5>_3g_dongle_plug_and_play</h5>
          <p>{productifo.electronics?._3g_dongle_plug_and_play}</p>
        </div>
      )}
      {productifo.electronics?.touch_remote !== "" && (
        <div className="des">
          <h5>touch_remote</h5>
          <p>{productifo.electronics?.touch_remote}</p>
        </div>
      )}
      {productifo.electronics?.smart_remote !== "" && (
        <div className="des">
          <h5>smart_remote</h5>
          <p>{productifo.electronics?.smart_remote}</p>
        </div>
      )}
      {productifo.electronics?.color_screen !== "" && (
        <div className="des">
          <h5>color_screen</h5>
          <p>{productifo.electronics?.color_screen}</p>
        </div>
      )}
      {productifo.electronics?.ir_capable !== "" && (
        <div className="des">
          <h5>ir_capable</h5>
          <p>{productifo.electronics?.ir_capable}</p>
        </div>
      )}
      {productifo.electronics?.dvr_control !== "" && (
        <div className="des">
          <h5>dvr_control</h5>
          <p>{productifo.electronics?.dvr_control}</p>
        </div>
      )}
      {productifo.electronics?.remote_type !== "" && (
        <div className="des">
          <h5>remote_type</h5>
          <p>{productifo.electronics?.remote_type}</p>
        </div>
      )}
      {productifo.electronics?.clock !== "" && (
        <div className="des">
          <h5>clock</h5>
          <p>{productifo.electronics?.clock}</p>
        </div>
      )}
      {productifo.electronics?.on_off_timer !== "" && (
        <div className="des">
          <h5>on_off_timer</h5>
          <p>{productifo.electronics?.on_off_timer}</p>
        </div>
      )}
      {productifo.electronics?.without_stand !== "" && (
        <div className="des">
          <h5>without_stand</h5>
          <p>{productifo.electronics?.without_stand}</p>
        </div>
      )}
      {productifo.electronics?.with_stand !== "" && (
        <div className="des">
          <h5>with_stand</h5>
          <p>{productifo.electronics?.with_stand}</p>
        </div>
      )}
      {productifo.electronics?.stand_features !== "" && (
        <div className="des">
          <h5>stand_features</h5>
          <p>{productifo.electronics?.stand_features}</p>
        </div>
      )}
      {productifo.electronics?.sleep_timer !== "" && (
        <div className="des">
          <h5>sleep_timer</h5>
          <p>{productifo.electronics?.sleep_timer}</p>
        </div>
      )}
      {productifo.electronics?.wired_wireless !== "" && (
        <div className="des">
          <h5>wired_wireless</h5>
          <p>{productifo.electronics?.wired_wireless}</p>
        </div>
      )}
      {productifo.electronics?.outdoor_usage !== "" && (
        <div className="des">
          <h5>outdoor_usage</h5>
          <p>{productifo.electronics?.outdoor_usage}</p>
        </div>
      )}
      {productifo.electronics?.maximum_spl !== "" && (
        <div className="des">
          <h5>maximum_spl</h5>
          <p>{productifo.electronics?.maximum_spl}</p>
        </div>
      )}
      {productifo.electronics?.chassis_material !== "" && (
        <div className="des">
          <h5>chassis_material</h5>
          <p>{productifo.electronics?.chassis_material}</p>
        </div>
      )}
      {productifo.electronics?.international_warranty !== "" && (
        <div className="des">
          <h5>international_warranty</h5>
          <p>{productifo.electronics?.international_warranty}</p>
        </div>
      )}
      {productifo.electronics?.part_number !== "" && (
        <div className="des">
          <h5>part_number</h5>
          <p>{productifo.electronics?.part_number}</p>
        </div>
      )}
      {productifo.electronics?.portable !== "" && (
        <div className="des">
          <h5>portable</h5>
          <p>{productifo.electronics?.portable}</p>
        </div>
      )}
      {productifo.electronics?.maximum_brightness !== "" && (
        <div className="des">
          <h5>maximum_brightness</h5>
          <p>{productifo.electronics?.maximum_brightness}</p>
        </div>
      )}
      {productifo.electronics?.maximum_projection_distances !== "" && (
        <div className="des">
          <h5>maximum_projection_distances</h5>
          <p>{productifo.electronics?.maximum_projection_distances}</p>
        </div>
      )}
      {productifo.electronics?.projection_ratio !== "" && (
        <div className="des">
          <h5>projection_ratio</h5>
          <p>{productifo.electronics?.projection_ratio}</p>
        </div>
      )}
      {productifo.electronics?.adjustable_aspect_ratio !== "" && (
        <div className="des">
          <h5>adjustable_aspect_ratio</h5>
          <p>{productifo.electronics?.adjustable_aspect_ratio}</p>
        </div>
      )}
      {productifo.electronics?.vertical_scanning_frequency !== "" && (
        <div className="des">
          <h5>vertical_scanning_frequency</h5>
          <p>{productifo.electronics?.vertical_scanning_frequency}</p>
        </div>
      )}
      {productifo.electronics?.horizontal_scanning_frequency !== "" && (
        <div className="des">
          <h5>horizontal_scanning_frequency</h5>
          <p>{productifo.electronics?.horizontal_scanning_frequency}</p>
        </div>
      )}
      {productifo.electronics?.signal_type !== "" && (
        <div className="des">
          <h5>signal_type</h5>
          <p>{productifo.electronics?.signal_type}</p>
        </div>
      )}
      {productifo.electronics?.standard_brightness !== "" && (
        <div className="des">
          <h5>standard_brightness</h5>
          <p>{productifo.electronics?.standard_brightness}</p>
        </div>
      )}
      {productifo.electronics?.zoom_ratio !== "" && (
        <div className="des">
          <h5>zoom_ratio</h5>
          <p>{productifo.electronics?.zoom_ratio}</p>
        </div>
      )}
      {productifo.electronics?.maximum_diagonal_projection_size !== "" && (
        <div className="des">
          <h5>maximum_diagonal_projection_size</h5>
          <p>{productifo.electronics?.maximum_diagonal_projection_size}</p>
        </div>
      )}
      {productifo.electronics?.color_support !== "" && (
        <div className="des">
          <h5>color_support</h5>
          <p>{productifo.electronics?.color_support}</p>
        </div>
      )}
      {productifo.electronics?.output_per_speaker !== "" && (
        <div className="des">
          <h5>output_per_speaker</h5>
          <p>{productifo.electronics?.output_per_speaker}</p>
        </div>
      )}
      {productifo.electronics?.audio_type !== "" && (
        <div className="des">
          <h5>audio_type</h5>
          <p>{productifo.electronics?.audio_type}</p>
        </div>
      )}
      {productifo.electronics?.componenr_hd_video_in !== "" && (
        <div className="des">
          <h5>componenr_hd_video_in</h5>
          <p>{productifo.electronics?.componenr_hd_video_in}</p>
        </div>
      )}
      {productifo.electronics?.maximum_attitude !== "" && (
        <div className="des">
          <h5>maximum_attitude</h5>
          <p>{productifo.electronics?.maximum_attitude}</p>
        </div>
      )}
      {productifo.electronics?.standby_noise_level !== "" && (
        <div className="des">
          <h5>standby_noise_level</h5>
          <p>{productifo.electronics?.standby_noise_level}</p>
        </div>
      )}
      {productifo.electronics?.normal_noise_level !== "" && (
        <div className="des">
          <h5>normal_noise_level</h5>
          <p>{productifo.electronics?.normal_noise_level}</p>
        </div>
      )}
      {productifo.electronics?.minimum_aperture !== "" && (
        <div className="des">
          <h5>minimum_aperture</h5>
          <p>{productifo.electronics?.minimum_aperture}</p>
        </div>
      )}
      {productifo.electronics?.maximum_aperture !== "" && (
        <div className="des">
          <h5>maximum_aperture</h5>
          <p>{productifo.electronics?.maximum_aperture}</p>
        </div>
      )}
      {productifo.electronics?.functions_modes !== "" && (
        <div className="des">
          <h5>functions_modes</h5>
          <p>{productifo.electronics?.functions_modes}</p>
        </div>
      )}
      {productifo.electronics?.number_of_usd_languages !== "" && (
        <div className="des">
          <h5>number_of_usd_languages</h5>
          <p>{productifo.electronics?.number_of_usd_languages}</p>
        </div>
      )}
      {productifo.electronics?.security !== "" && (
        <div className="des">
          <h5>security</h5>
          <p>{productifo.electronics?.security}</p>
        </div>
      )}
      {productifo.electronics?.offer !== "" && (
        <div className="des">
          <h5>offer</h5>
          <p>{productifo.electronics?.offer}</p>
        </div>
      )}
      {productifo.electronics?.remote_features !== "" && (
        <div className="des">
          <h5>remote_features</h5>
          <p>{productifo.electronics?.remote_features}</p>
        </div>
      )}
      {productifo.electronics?.control_type !== "" && (
        <div className="des">
          <h5>control_type</h5>
          <p>{productifo.electronics?.control_type}</p>
        </div>
      )}
      {productifo.electronics?.power_adapter !== "" && (
        <div className="des">
          <h5>power_adapter</h5>
          <p>{productifo.electronics?.power_adapter}</p>
        </div>
      )}
      {productifo.electronics?.video_player_type !== "" && (
        <div className="des">
          <h5>video_player_type</h5>
          <p>{productifo.electronics?.video_player_type}</p>
        </div>
      )}
      {productifo.electronics?.svr_varient !== "" && (
        <div className="des">
          <h5>svr_varient</h5>
          <p>{productifo.electronics?.svr_varient}</p>
        </div>
      )}
      {productifo.electronics?.effective_pixels !== "" && (
        <div className="des">
          <h5>effective_pixels</h5>
          <p>{productifo.electronics?.effective_pixels}</p>
        </div>
      )}
      {productifo.electronics?.tripod_socket !== "" && (
        <div className="des">
          <h5>tripod_socket</h5>
          <p>{productifo.electronics?.tripod_socket}</p>
        </div>
      )}
      {productifo.electronics?.sensor_type !== "" && (
        <div className="des">
          <h5>sensor_type</h5>
          <p>{productifo.electronics?.sensor_type}</p>
        </div>
      )}
      {productifo.electronics?.image_sensor_size !== "" && (
        <div className="des">
          <h5>image_sensor_size</h5>
          <p>{productifo.electronics?.image_sensor_size}</p>
        </div>
      )}
      {productifo.electronics?.lens_mount !== "" && (
        <div className="des">
          <h5>lens_mount</h5>
          <p>{productifo.electronics?.lens_mount}</p>
        </div>
      )}
      {productifo.electronics?.dust_reduction !== "" && (
        <div className="des">
          <h5>dust_reduction</h5>
          <p>{productifo.electronics?.dust_reduction}</p>
        </div>
      )}
      {productifo.electronics?.shutter_speed !== "" && (
        <div className="des">
          <h5>shutter_speed</h5>
          <p>{productifo.electronics?.shutter_speed}</p>
        </div>
      )}
      {productifo.electronics?.self_timer !== "" && (
        <div className="des">
          <h5>self_timer</h5>
          <p>{productifo.electronics?.self_timer}</p>
        </div>
      )}
      {productifo.electronics?.built_in_flash !== "" && (
        <div className="des">
          <h5>built_in_flash</h5>
          <p>{productifo.electronics?.built_in_flash}</p>
        </div>
      )}
      {productifo.electronics?.external_flash !== "" && (
        <div className="des">
          <h5>external_flash</h5>
          <p>{productifo.electronics?.external_flash}</p>
        </div>
      )}
      {productifo.electronics?.image_format !== "" && (
        <div className="des">
          <h5>image_format</h5>
          <p>{productifo.electronics?.image_format}</p>
        </div>
      )}
      {productifo.electronics?.video_resolution !== "" && (
        <div className="des">
          <h5>video_resolution</h5>
          <p>{productifo.electronics?.video_resolution}</p>
        </div>
      )}
      {productifo.electronics?.video_quality !== "" && (
        <div className="des">
          <h5>video_quality</h5>
          <p>{productifo.electronics?.video_quality}</p>
        </div>
      )}
      {productifo.electronics?.comapatible_cord !== "" && (
        <div className="des">
          <h5>comapatible_cord</h5>
          <p>{productifo.electronics?.comapatible_cord}</p>
        </div>
      )}
      {productifo.electronics?.number_of_batteries !== "" && (
        <div className="des">
          <h5>number_of_batteries</h5>
          <p>{productifo.electronics?.number_of_batteries}</p>
        </div>
      )}
      {productifo.electronics?.shooting_modes !== "" && (
        <div className="des">
          <h5>shooting_modes</h5>
          <p>{productifo.electronics?.shooting_modes}</p>
        </div>
      )}
      {productifo.electronics?.image_sensor_type !== "" && (
        <div className="des">
          <h5>image_sensor_type</h5>
          <p>{productifo.electronics?.image_sensor_type}</p>
        </div>
      )}
      {productifo.electronics?.iso_rating !== "" && (
        <div className="des">
          <h5>iso_rating</h5>
          <p>{productifo.electronics?.iso_rating}</p>
        </div>
      )}
      {productifo.electronics?.focal_length !== "" && (
        <div className="des">
          <h5>focal_length</h5>
          <p>{productifo.electronics?.focal_length}</p>
        </div>
      )}
      {productifo.electronics?.focus_mode !== "" && (
        <div className="des">
          <h5>focus_mode</h5>
          <p>{productifo.electronics?.focus_mode}</p>
        </div>
      )}
      {productifo.electronics?.focus_range !== "" && (
        <div className="des">
          <h5>focus_range</h5>
          <p>{productifo.electronics?.focus_range}</p>
        </div>
      )}
      {productifo.electronics?.metering_model !== "" && (
        <div className="des">
          <h5>metering_model</h5>
          <p>{productifo.electronics?.metering_model}</p>
        </div>
      )}
      {productifo.electronics?.face_detection !== "" && (
        <div className="des">
          <h5>face_detection</h5>
          <p>{productifo.electronics?.face_detection}</p>
        </div>
      )}
      {productifo.electronics?.temperature !== "" && (
        <div className="des">
          <h5>temperature</h5>
          <p>{productifo.electronics?.temperature}</p>
        </div>
      )}
      {productifo.electronics?.ports !== "" && (
        <div className="des">
          <h5>ports</h5>
          <p>{productifo.electronics?.ports}</p>
        </div>
      )}
      {productifo.electronics?.accessory_shoe !== "" && (
        <div className="des">
          <h5>accessory_shoe</h5>
          <p>{productifo.electronics?.accessory_shoe}</p>
        </div>
      )}
      {productifo.electronics?.lens_type !== "" && (
        <div className="des">
          <h5>lens_type</h5>
          <p>{productifo.electronics?.lens_type}</p>
        </div>
      )}
      {productifo.electronics?.manual_focus !== "" && (
        <div className="des">
          <h5>manual_focus</h5>
          <p>{productifo.electronics?.manual_focus}</p>
        </div>
      )}
      {productifo.electronics?.view_finder !== "" && (
        <div className="des">
          <h5>view_finder</h5>
          <p>{productifo.electronics?.view_finder}</p>
        </div>
      )}
      {productifo.electronics?.auto_focus !== "" && (
        <div className="des">
          <h5>auto_focus</h5>
          <p>{productifo.electronics?.auto_focus}</p>
        </div>
      )}
      {productifo.electronics?.focus_points !== "" && (
        <div className="des">
          <h5>focus_points</h5>
          <p>{productifo.electronics?.focus_points}</p>
        </div>
      )}
      {productifo.electronics?.white_balance !== "" && (
        <div className="des">
          <h5>white_balance</h5>
          <p>{productifo.electronics?.white_balance}</p>
        </div>
      )}
      {productifo.electronics?.scene_modes !== "" && (
        <div className="des">
          <h5>scene_modes</h5>
          <p>{productifo.electronics?.scene_modes}</p>
        </div>
      )}
      {productifo.electronics?.exposure_mode !== "" && (
        <div className="des">
          <h5>exposure_mode</h5>
          <p>{productifo.electronics?.exposure_mode}</p>
        </div>
      )}
      {productifo.electronics?.ae_lock_or_exposure_lock !== "" && (
        <div className="des">
          <h5>ae_lock_or_exposure_lock</h5>
          <p>{productifo.electronics?.ae_lock_or_exposure_lock}</p>
        </div>
      )}
      {productifo.electronics?.exposure_bracketing !== "" && (
        <div className="des">
          <h5>exposure_bracketing</h5>
          <p>{productifo.electronics?.exposure_bracketing}</p>
        </div>
      )}
      {productifo.electronics?.manual_exposure !== "" && (
        <div className="des">
          <h5>manual_exposure</h5>
          <p>{productifo.electronics?.manual_exposure}</p>
        </div>
      )}
      {productifo.electronics?.flash_modes !== "" && (
        <div className="des">
          <h5>flash_modes</h5>
          <p>{productifo.electronics?.flash_modes}</p>
        </div>
      )}
      {productifo.electronics?.flash_compension !== "" && (
        <div className="des">
          <h5>flash_compension</h5>
          <p>{productifo.electronics?.flash_compension}</p>
        </div>
      )}
      {productifo.electronics?.image_size !== "" && (
        <div className="des">
          <h5>image_size</h5>
          <p>{productifo.electronics?.image_size}</p>
        </div>
      )}
      {productifo.electronics?.red_eye_reduction !== "" && (
        <div className="des">
          <h5>red_eye_reduction</h5>
          <p>{productifo.electronics?.red_eye_reduction}</p>
        </div>
      )}
      {productifo.electronics?.live_view_shooting !== "" && (
        <div className="des">
          <h5>live_view_shooting</h5>
          <p>{productifo.electronics?.live_view_shooting}</p>
        </div>
      )}
      {productifo.electronics?.hdr_support !== "" && (
        <div className="des">
          <h5>hdr_support</h5>
          <p>{productifo.electronics?.hdr_support}</p>
        </div>
      )}
      {productifo.electronics?.microphone !== "" && (
        <div className="des">
          <h5>microphone</h5>
          <p>{productifo.electronics?.microphone}</p>
        </div>
      )}
      {productifo.electronics?.upgradeable_memory !== "" && (
        <div className="des">
          <h5>upgradeable_memory</h5>
          <p>{productifo.electronics?.upgradeable_memory}</p>
        </div>
      )}
      {productifo.electronics?.model !== "" && (
        <div className="des">
          <h5>model</h5>
          <p>{productifo.electronics?.model}</p>
        </div>
      )}
      {productifo.electronics?.prime_zoom !== "" && (
        <div className="des">
          <h5>prime_zoom</h5>
          <p>{productifo.electronics?.prime_zoom}</p>
        </div>
      )}
      {productifo.electronics?.designed_for !== "" && (
        <div className="des">
          <h5>designed_for</h5>
          <p>{productifo.electronics?.designed_for}</p>
        </div>
      )}
      {productifo.electronics?.maximum_focal_length !== "" && (
        <div className="des">
          <h5>maximum_focal_length</h5>
          <p>{productifo.electronics?.maximum_focal_length}</p>
        </div>
      )}
      {productifo.electronics?.minimum_focal_length !== "" && (
        <div className="des">
          <h5>minimum_focal_length</h5>
          <p>{productifo.electronics?.minimum_focal_length}</p>
        </div>
      )}
      {productifo.electronics?.aperture_with_max_focal_length !== "" && (
        <div className="des">
          <h5>aperture_with_max_focal_length</h5>
          <p>{productifo.electronics?.aperture_with_max_focal_length}</p>
        </div>
      )}
      {productifo.electronics?.aperture_with_min_focal_length !== "" && (
        <div className="des">
          <h5>aperture_with_min_focal_length</h5>
          <p>{productifo.electronics?.aperture_with_min_focal_length}</p>
        </div>
      )}
      {productifo.electronics?.maximum_magnification !== "" && (
        <div className="des">
          <h5>maximum_magnification</h5>
          <p>{productifo.electronics?.maximum_magnification}</p>
        </div>
      )}
      {productifo.electronics?.number_of_channels !== "" && (
        <div className="des">
          <h5>number_of_channels</h5>
          <p>{productifo.electronics?.number_of_channels}</p>
        </div>
      )}
      {productifo.electronics?.camera_type !== "" && (
        <div className="des">
          <h5>camera_type</h5>
          <p>{productifo.electronics?.camera_type}</p>
        </div>
      )}
      {productifo.electronics?.video_recording_frame_rate !== "" && (
        <div className="des">
          <h5>video_recording_frame_rate</h5>
          <p>{productifo.electronics?.video_recording_frame_rate}</p>
        </div>
      )}
      {productifo.electronics?.hdd_available !== "" && (
        <div className="des">
          <h5>hdd_available</h5>
          <p>{productifo.electronics?.hdd_available}</p>
        </div>
      )}
      {productifo.electronics?.recording_format !== "" && (
        <div className="des">
          <h5>recording_format</h5>
          <p>{productifo.electronics?.recording_format}</p>
        </div>
      )}
      {productifo.electronics?.display_frame_rate !== "" && (
        <div className="des">
          <h5>display_frame_rate</h5>
          <p>{productifo.electronics?.display_frame_rate}</p>
        </div>
      )}
      {productifo.electronics?.image_sensor !== "" && (
        <div className="des">
          <h5>image_sensor</h5>
          <p>{productifo.electronics?.image_sensor}</p>
        </div>
      )}
      {productifo.electronics?.image_resolution !== "" && (
        <div className="des">
          <h5>image_resolution</h5>
          <p>{productifo.electronics?.image_resolution}</p>
        </div>
      )}
      {productifo.electronics?.minimum_illumination !== "" && (
        <div className="des">
          <h5>minimum_illumination</h5>
          <p>{productifo.electronics?.minimum_illumination}</p>
        </div>
      )}
      {productifo.electronics?.ir_distance !== "" && (
        <div className="des">
          <h5>ir_distance</h5>
          <p>{productifo.electronics?.ir_distance}</p>
        </div>
      )}
      {productifo.electronics?.motin_triggered_recording !== "" && (
        <div className="des">
          <h5>motin_triggered_recording</h5>
          <p>{productifo.electronics?.motin_triggered_recording}</p>
        </div>
      )}
      {productifo.electronics?.audio_output !== "" && (
        <div className="des">
          <h5>audio_output</h5>
          <p>{productifo.electronics?.audio_output}</p>
        </div>
      )}
      {productifo.electronics?.network_interface_time !== "" && (
        <div className="des">
          <h5>network_interface_time</h5>
          <p>{productifo.electronics?.network_interface_time}</p>
        </div>
      )}
      {productifo.electronics?.number_of_ethiernet_ports !== "" && (
        <div className="des">
          <h5>number_of_ethiernet_ports</h5>
          <p>{productifo.electronics?.number_of_ethiernet_ports}</p>
        </div>
      )}
      {productifo.electronics?.power_consumption !== "" && (
        <div className="des">
          <h5>power_consumption</h5>
          <p>{productifo.electronics?.power_consumption}</p>
        </div>
      )}
      {productifo.electronics?.alarm_action !== "" && (
        <div className="des">
          <h5>alarm_action</h5>
          <p>{productifo.electronics?.alarm_action}</p>
        </div>
      )}
      {productifo.electronics?.application_support !== "" && (
        <div className="des">
          <h5>application_support</h5>
          <p>{productifo.electronics?.application_support}</p>
        </div>
      )}
      {productifo.electronics?.remote_connectivity !== "" && (
        <div className="des">
          <h5>remote_connectivity</h5>
          <p>{productifo.electronics?.remote_connectivity}</p>
        </div>
      )}
      {productifo.electronics?.closure_mechanism !== "" && (
        <div className="des">
          <h5>closure_mechanism</h5>
          <p>{productifo.electronics?.closure_mechanism}</p>
        </div>
      )}
      {productifo.electronics?.outer_material !== "" && (
        <div className="des">
          <h5>outer_material</h5>
          <p>{productifo.electronics?.outer_material}</p>
        </div>
      )}
      {productifo.electronics?.inner_height !== "" && (
        <div className="des">
          <h5>inner_height</h5>
          <p>{productifo.electronics?.inner_height}</p>
        </div>
      )}
      {productifo.electronics?.inner_width !== "" && (
        <div className="des">
          <h5>inner_width</h5>
          <p>{productifo.electronics?.inner_width}</p>
        </div>
      )}
      {productifo.electronics?.inner_depth !== "" && (
        <div className="des">
          <h5>inner_depth</h5>
          <p>{productifo.electronics?.inner_depth}</p>
        </div>
      )}
      {productifo.electronics?.head_type !== "" && (
        <div className="des">
          <h5>head_type</h5>
          <p>{productifo.electronics?.head_type}</p>
        </div>
      )}
      {productifo.electronics?.leg_lock_type !== "" && (
        <div className="des">
          <h5>leg_lock_type</h5>
          <p>{productifo.electronics?.leg_lock_type}</p>
        </div>
      )}
      {productifo.electronics?.independent_leg_spread !== "" && (
        <div className="des">
          <h5>independent_leg_spread</h5>
          <p>{productifo.electronics?.independent_leg_spread}</p>
        </div>
      )}
      {productifo.electronics?.pack_of !== "" && (
        <div className="des">
          <h5>pack_of</h5>
          <p>{productifo.electronics?.pack_of}</p>
        </div>
      )}
      {productifo.electronics?.floded_length !== "" && (
        <div className="des">
          <h5>floded_length</h5>
          <p>{productifo.electronics?.floded_length}</p>
        </div>
      )}
      {productifo.electronics?.number_of_charging_cells !== "" && (
        <div className="des">
          <h5>number_of_charging_cells</h5>
          <p>{productifo.electronics?.number_of_charging_cells}</p>
        </div>
      )}
      {productifo.electronics?.used_for !== "" && (
        <div className="des">
          <h5>used_for</h5>
          <p>{productifo.electronics?.used_for}</p>
        </div>
      )}
      {productifo.electronics?.generator_compatibility !== "" && (
        <div className="des">
          <h5>generator_compatibility</h5>
          <p>{productifo.electronics?.generator_compatibility}</p>
        </div>
      )}
      {productifo.electronics?.cooling !== "" && (
        <div className="des">
          <h5>cooling</h5>
          <p>{productifo.electronics?.cooling}</p>
        </div>
      )}
      {productifo.electronics?.minimum_operating !== "" && (
        <div className="des">
          <h5>minimum_operating</h5>
          <p>{productifo.electronics?.minimum_operating}</p>
        </div>
      )}
      {productifo.electronics?.under_vlot_protection !== "" && (
        <div className="des">
          <h5>under_vlot_protection</h5>
          <p>{productifo.electronics?.under_vlot_protection}</p>
        </div>
      )}
      {productifo.electronics?.overload_protection !== "" && (
        <div className="des">
          <h5>overload_protection</h5>
          <p>{productifo.electronics?.overload_protection}</p>
        </div>
      )}
      {productifo.electronics?.rated_pressure !== "" && (
        <div className="des">
          <h5>rated_pressure</h5>
          <p>{productifo.electronics?.rated_pressure}</p>
        </div>
      )}
      {productifo.electronics?.thermostot !== "" && (
        <div className="des">
          <h5>thermostot</h5>
          <p>{productifo.electronics?.thermostot}</p>
        </div>
      )}
      {productifo.electronics?.door_finish !== "" && (
        <div className="des">
          <h5>door_finish</h5>
          <p>{productifo.electronics?.door_finish}</p>
        </div>
      )}
      {productifo.electronics?.water_ice_dispenser !== "" && (
        <div className="des">
          <h5>water_ice_dispenser</h5>
          <p>{productifo.electronics?.water_ice_dispenser}</p>
        </div>
      )}
      {productifo.electronics?.stabilizer_required !== "" && (
        <div className="des">
          <h5>stabilizer_required</h5>
          <p>{productifo.electronics?.stabilizer_required}</p>
        </div>
      )}
      {productifo.electronics?.egg_tray !== "" && (
        <div className="des">
          <h5>egg_tray</h5>
          <p>{productifo.electronics?.egg_tray}</p>
        </div>
      )}
      {productifo.electronics?.deodarizer !== "" && (
        <div className="des">
          <h5>deodarizer</h5>
          <p>{productifo.electronics?.deodarizer}</p>
        </div>
      )}
      {productifo.electronics?.ice_cub_tray_type !== "" && (
        <div className="des">
          <h5>ice_cub_tray_type</h5>
          <p>{productifo.electronics?.ice_cub_tray_type}</p>
        </div>
      )}
      {productifo.electronics?.removable_gasket !== "" && (
        <div className="des">
          <h5>removable_gasket</h5>
          <p>{productifo.electronics?.removable_gasket}</p>
        </div>
      )}
      {productifo.electronics?.flexible_rack !== "" && (
        <div className="des">
          <h5>flexible_rack</h5>
          <p>{productifo.electronics?.flexible_rack}</p>
        </div>
      )}
      {productifo.electronics?.removable_rack !== "" && (
        <div className="des">
          <h5>removable_rack</h5>
          <p>{productifo.electronics?.removable_rack}</p>
        </div>
      )}
    </div>
  );
};

export default ElecdivonicSubcat;

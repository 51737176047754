import React from 'react'

const BeautySupercat = ({ productifo }) => {
  return (
    <div className="design1">
      {productifo.beauty?.skin_type !== "" && (
        <div className="des">
          <h5>skin type</h5>
          <p>{productifo.beauty?.skin_type}</p>
        </div>
      )}
      {productifo.beauty?.pack_size !== "" && (
        <div className="des">
          <h5>pack size</h5>
          <p>{productifo.beauty?.pack_size}</p>
        </div>
      )}
      {productifo.beauty?.pack_of !== "" && (
        <div className="des">
          <h5>pack of</h5>
          <p>{productifo.beauty?.pack_of}</p>
        </div>
      )}
      {productifo.beauty?.gender !== "" && (
        <div className="des">
          <h5>Gender</h5>
          <p>{productifo.beauty?.gender}</p>
        </div>
      )}
    </div>
  )
}

export default BeautySupercat

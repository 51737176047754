import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getSingleProduct } from "../../redux/product/productSlice";
import { BsShop } from "react-icons/bs";
import {
  set_checkout_authentication_status,
  signout,
} from "../../redux/athentication/Athentication";
import { FaSellcast } from "react-icons/fa";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { quickViewProduct } = useSelector((store) => store.product);
  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
  } = useSelector((state) => state.cart);
  const { isAuth, current_address, loginData } = useSelector(
    (state) => state.Athentication
  );

  const [productifo, setProductinfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [protest, setProtest] = useState(false);
  const [footerDisplay, setfooterDisplay] = useState("");

  useEffect(() => {
    if (quickViewProduct !== "") {
      const getProduct = async () => {
        const respoProduct = await dispatch(
          getSingleProduct(quickViewProduct.slugUrl)
        );

        if (respoProduct.payload.success) {
          const respo = respoProduct.payload.product;
          let update_Product = respo;

          update_Product.packSize = update_Product.packSize.map((pack) => {
            pack.PackSizeOptions = pack.PackSizeOptions.map((pack_option) => {
              pack_option = {
                ...pack_option,
                cart_Quentity: 0,
              };
              return pack_option;
            });
            return pack;
          });

          let product_info = update_Product;
          CartItems.forEach((cartitem) => {
            let packIndex = -1;
            packIndex = product_info.packSize.findIndex(
              (pack) => String(pack._id) === String(cartitem.PackId)
            );

            if (packIndex >= 0) {
              let packOptionIndex = -1;

              packOptionIndex = product_info.packSize[
                packIndex
              ].PackSizeOptions.findIndex(
                (pack_option) =>
                  String(pack_option._id) === String(cartitem.PackOptionId)
              );

              const pack_option_Id =
                product_info.packSize[packIndex].PackSizeOptions[
                  packOptionIndex
                ]._id;

              let cart_index = -1;
              cart_index = CartItems.findIndex(
                (item) => String(item.PackOptionId) === String(pack_option_Id)
              );

              product_info.packSize[packIndex].PackSizeOptions[
                packOptionIndex
              ].cart_Quentity = CartItems[cart_index].cart_Quentity;
            }
          });

          setProductinfo(product_info);
          setLoading(false);
        }
      };
      getProduct();
    }
  }, [quickViewProduct]);

  const signoutClick = () => {
    dispatch(signout());
    navigate("/");
  };

  return (
    <>
      <div className="footer laptop_version_pages">
        {/* <div className="newsletterbg clearfix">
          <div className="container">
            <form action="#" method="post" className="footer-newsletter">
              <div className="row align-items-center">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-0">
                  <label className="h3 mb-1 clr-none">
                    Sign Up Our Newsletter &amp; Get 10% OFF
                  </label>
                  <p>
                    Sign up to stay in the loop. Receive updates, access to
                    exclusive deals, and more.
                  </p>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control input-group-field newsletter-input"
                      name="email"
                      placeholder="Enter your email address..."
                    />
                    <button
                      type="submit"
                      className="input-group-btn btn btn-secondary newsletter-submit"
                      name="commit"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div> */}
        <div className="footer-top clearfix">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 footer-links">
                <h4
                  className={footerDisplay === "About" ? "h4 active" : "h4"}
                  onClick={() => {
                    if (footerDisplay === "" || footerDisplay !== "About") {
                      setfooterDisplay("About");
                    } else if (footerDisplay === "About") {
                      setfooterDisplay("");
                    }
                  }}
                >
                  About
                </h4>
                <ul
                  style={
                    footerDisplay === "About"
                      ? {
                          display: "block",
                        }
                      : {}
                  }
                >
                  <li>
                    <Link to="/About">About Us</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Career">Careers</Link>
                  </li>
                  <li>
                    <Link to="/Content1">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 footer-links">
                <h4
                  className={footerDisplay === "Account" ? "h4 active" : "h4"}
                  onClick={() => {
                    if (footerDisplay === "" || footerDisplay !== "Account") {
                      setfooterDisplay("Account");
                    } else if (footerDisplay === "Account") {
                      setfooterDisplay("");
                    }
                  }}
                >
                  My Account
                </h4>
                <ul
                  style={
                    footerDisplay === "Account"
                      ? {
                          display: "block",
                        }
                      : {}
                  }
                >
                  {isAuth === true ? (
                    <>
                      <li onClick={() => signoutClick()}>
                        <Link>Sign out</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link to="/login">Sign in</Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link to="/cart">View Cart</Link>
                  </li>
                  {/* <li>
                    <Link to="/wishlist">My Wishlist</Link>
                  </li> */}
                  {isAuth ? (
                    <>
                      <li>
                        <Link to="/my-account">Order</Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 footer-links">
                <h4
                  className={footerDisplay === "Services" ? "h4 active" : "h4"}
                  onClick={() => {
                    if (footerDisplay === "" || footerDisplay !== "Services") {
                      setfooterDisplay("Services");
                    } else if (footerDisplay === "Services") {
                      setfooterDisplay("");
                    }
                  }}
                >
                  Customer Services
                </h4>
                <ul
                  style={
                    footerDisplay === "Services"
                      ? {
                          display: "block",
                        }
                      : {}
                  }
                >
                  <li>
                    <Link to="/Affiliate">Affiliate</Link>
                  </li>
                  <li>
                    <Link to="/Faq">Faq</Link>
                  </li>
                  <li>
                    <Link to="/RefundPolicy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/TermsUse">Terms Of Use</Link>
                  </li>
                  <li>
                    <Link to="/TermsSale">Terms & Of Sale</Link>
                  </li>
                  <li>
                    <Link to="/CancellationPolicy">Cancellation Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 footer-contact">
                <h4 className="h4">Contact Us</h4>
                <p className="address d-flex">
                  <i className="icon anm anm-map-marker-al pt-1" /> Ewshopping,
                  Rajendra Palace, New Delhi, Pin -110008
                </p>
                <p className="phone d-flex align-items-center">
                  <i className="icon anm anm-phone-l" />{" "}
                  <b className="me-1 d-none">Phone:</b>{" "}
                  <Link to="tel:+918447282606">+91 8447282606</Link>
                </p>
                <p className="email d-flex align-items-center">
                  <i className="icon anm anm-envelope-l" />{" "}
                  <b className="me-1 d-none">Email:</b>{" "}
                  <Link to="mailto:info@example.com">info@ewshopping.com</Link>
                </p>
                <ul className="list-inline social-icons mt-3">
                  <li className="list-inline-item">
                    <Link
                      to="https://www.facebook.com/ewshopping"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Facebook"
                      target="_blank"
                    >
                      <i className="icon anm anm-facebook-f" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Twitter"
                    >
                      <i className="icon anm anm-twitter" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to="https://in.pinterest.com/EWShoppingPortal/"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Pinterest"
                      target="_blank"
                    >
                      <i className="icon anm anm-pinterest-p" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Linkedin"
                    >
                      <i className="icon anm anm-linkedin-in" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to="https://www.instagram.com/ewshoppingofficial/"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Instagram"
                      target="_blank"
                    >
                      <i className="icon anm anm-instagram" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Youtube"
                    >
                      <i className="icon anm anm-youtube" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#2F415D", padding: "10px", color: "#fff" }}
        >
          <h3 style={{ color: "#fff",fontSize:'16px' }}>
            OUR TOP CATEGORIES FOR ONLINE SHOPPING
          </h3>
          <Link to="/shop/televisions">
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff" }}
            >
              ELECTRONICS: Televisions | Speakers | Headphone & Earphones |
              Audio & Video | Winter Appliances | Washing Machines | Coolers |
              Fans | Grooming Appliances | Small Appliances
            </p>
          </Link>
          <Link to="/category/mobile--accessories">
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff",lineHeight:"30px" }}
            >
              MOBILE, TABLETS: Mobile Cases & Covers | Mobile Phones | Tablets |
              Mobile Accessories | Wearables & Smartwatches | Tablet Accessories
            </p>
          </Link>

          <Link to="/category/women">
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff",lineHeight:"30px" }}
            >
              Women's Fashion: Ethnic Wear | Footwear | Western Wear | Winter
              Wear | Handbags & Clutches | Eyewear | Fashion Jewellery | Watches
              | Precious Jewellery | Gold Coins & Bars | Fashion Accessories |
              Sunglasses
            </p>
          </Link>
          <Link to="/category/men">
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff",lineHeight:"30px" }}
            >
              Mens Fashion: Foot Wear | Bags & Luggage | Clothing | Winterwear |
              Sports Wear | Mens Grooming | Eye Wear | Watches | Jewellery &
              Cufflinks | Accessories | Fragrances
            </p>
          </Link>
          <Link to="/shop/skincare,-bath--body">
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff",lineHeight:"30px" }}
            >
              Beauty Health: Beauty | Personal-Care-Grooming | Sexual Wellness |
              Food And Gourmet | Nutrition & Supplements | Health Monitoring
              Devices | General Wellness | Baby Care | Household Essentials
            </p>
          </Link>
          <Link to="/category/kids">
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff",lineHeight:"30px" }}
            >
              Toys Kids Fashion: Toys | Bicycles & Tricycles | Kids Footwear |
              Accessories | Kids Clothing | Girls Clothing | Boys Clothing |
              Baby Clothing | Baby Care | Stationery
            </p>
          </Link>
          <Link to="/shop/kitchen-table-linen">
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff",lineHeight:"30px" }}
            >
              Home Kitchen: Kitchen Appliances | Kitchenware | Home-Furnishing |
              Furniture | Home Decor | Home Improvement | Tools & Hardware | Pet
              Supplies
            </p>
          </Link>
          <Link>
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff",lineHeight:"30px" }}
            >
              Sports Fitness Outdoor: Sports & Fitness | Get Fit At Home | Get
              Ready For Gym | Sports | Hiking & Outdoor
            </p>
          </Link>
          <Link>
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff",lineHeight:"30px" }}
            >
              CAR MOTORBIKE: Automotive Accessories | Car & Vehicle Electronics
              | Car Audio Finder | Car Accessories | Tyre Inflators |Automotive
              | Car Fresheners | Air Purifiers & Ionizers | Biker Gear &
              Accessories | Biker Raincoats | Helmets & Accessories | Parts &
              Spares | Tyres & Alloys | Motorbike Batteries
            </p>
          </Link>
          <Link>
            <p
              style={{ textAlign: "justify", cursor: "pointer", color: "#fff" }}
            >
              Computers Gaming: Laptops | Printers & Inks | Networking Devices |
              Office Equipment | Storage | Computer Accessories | Desktops |
              Gaming | Computer Components | Software | Monitors | Top Laptop
              Brands
            </p>
          </Link>
          <p style={{ textAlign: "justify" }}>
            BOOKS MEDIA MUSIC: Top Exams| Browse By Genre | Online Education |
            Musical Instruments | Indian Instruments | Pa & Stage | Other
            Instruments | Hobbies New | E-Gift Cards | Physical Gift Cards |
            Digital Subscriptions | Movies & Tv Shows
          </p>
          <p style={{ textAlign: "justify" }}>
            Why Choose EWShopping as the Best Online Shopping Sites in India
          </p>
          <p style={{ textAlign: "justify" }}>
            Worldwide Shipping: Available as Standard or Express delivery
            EWShopping Promise: Products with this tag are quality checked, and
            shipped the same day from certified warehouses. So you get the right
            product, faster. Secure Payments: At EWShopping 100% Secure payment.
            If you have any issues, your money is immediately refunded. Sit back
            and enjoy your shopping. Free Return: Your money is yours! All
            refunds come with a question-asked guarantee for all orders. 24/7
            Dedicated support: Need Help? Click Here. You can also talk to us on
            -------- to resolve your query.
          </p>
          <p style={{ textAlign: "justify" }}>
            India's Best Online Shopping Website for Fashion & Lifestyle
          </p>
          <p style={{ textAlign: "justify" }}>
            EWShopping is the Best Site for Online shopping in India when it
            comes to online clothes shopping for men, women, and kids at the
            lowest prices. The latest fashion trends and styles are showcased
            here, yes at your favorite online shopping destination. Well, if
            fashion is medicine then Beyoung is the chemist shop where you can
            do your online shopping for men and women with ease. EWShopping is
            without a doubt one of the best online shopping sites in India. The
            website is replete with the cool products that you always crave.
            Indeed, online shopping for women, men & kids at Beyoung is such
            hassle-free that in just a few clicks one can purchase whatever he
            or she wants. A one-stop destination for all your shopping needs,
            Beyoung caters to each taste and need for men’s clothing, Women's
            Clothing, and accessories.
          </p>
          <p style={{ textAlign: "justify" }}>
            EWShopping is the Best Site for Online shopping in India when it
            comes to online clothes shopping for men, women, and kids at the
            lowest prices. The latest fashion trends and styles are showcased
            here, yes at your favorite online shopping destination. Well, if
            fashion is medicine then Beyoung is the chemist shop where you can
            do your online shopping for men and women with ease. EWShopping is
            without a doubt one of the best online shopping sites in India. The
            website is replete with the cool products that you always crave.
            Indeed, online shopping for women, men & kids at Beyoung is such
            hassle-free that in just a few clicks one can purchase whatever he
            or she wants. A one-stop destination for all your shopping needs,
            Beyoung caters to each taste and need for men’s clothing, Women's
            Clothing, and accessories.
          </p>
        </div>
        <div className="footer-bottom clearfix">
          <div className="container">
            <div className="d-flex-center flex-column justify-content-md-between flex-md-row-reverse">
              <ul className="payment-icons d-flex-center mb-2 mb-md-0">
                <li>
                  <i className="icon anm anm-cc-visa" />
                </li>
                <li>
                  <i className="icon anm anm-cc-mastercard" />
                </li>
                <li>
                  <i className="icon anm anm-cc-amex" />
                </li>
                <li>
                  <i className="icon anm anm-cc-paypal" />
                </li>
                <li>
                  <i className="icon anm anm-cc-discover" />
                </li>
                <li>
                  <i className="icon anm anm-cc-stripe" />
                </li>
                <li>
                  <i className="icon anm anm-cc-jcb" />
                </li>
              </ul>
              <div className="copytext">
                © 2023 EW Shopping | Design by Dexterous Technology.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="menubar-mobile d-flex align-items-center justify-content-between d-lg-none">
        <div className="menubar-shop menubar-item">
          <Link to="/">
            <div className="shop_img">
              <img src="/assets/images/mobile_logo.png" alt="" />
            </div>
          </Link>
        </div>
        {isAuth === true ? (
          <>
            <div className="menubar-account menubar-item">
              <Link to="/my-account">
                <i className="menubar-icon icon anm anm-user-al" />
                <span className="menubar-label">Account</span>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="menubar-account menubar-item">
              <Link to="/login">
                <i className="menubar-icon icon anm anm-user-al" />
                <span className="menubar-label">Account</span>
              </Link>
            </div>
          </>
        )}
        <div className="menubar-shop menubar-item">
          <Link to="/mobcategory">
            <i className="menubar-icon anm anm-th-large-l" />
            <span className="menubar-label">Categories</span>
          </Link>
        </div>

        {/* <div className="menubar-wish menubar-item">
          <Link to="/Wishlist">
            <span className="span-count position-relative text-center">
              <i className="menubar-icon icon anm anm-heart-l" />
              <span className="wishlist-count counter menubar-count">0</span>
            </span>
            <span className="menubar-label">Wishlist</span>
          </Link>
        </div> */}
        <div className="menubar-cart menubar-item">
          <Link
            to="/cart"
            className="btn-minicart"
            // onClick={() => navigate("/cart")}
          >
            <span className="span-count position-relative text-center">
              <i className="menubar-icon icon anm anm-cart-l" />
              <span className="cart-count counter menubar-count">
                {CartItems.length}
              </span>
            </span>
            <span className="menubar-label">Cart</span>
          </Link>
        </div>
        <div
          className="menubar-cart menubar-item "
          onClick={() => window.open(`https://seller.ewshopping.com`, "_blank")}
        >
          <Link className="btn-minicart">
            <span className="span-count position-relative text-center">
              <FaSellcast />{" "}
            </span>
            <span className="menubar-label">Sell on Shopping</span>
          </Link>
        </div>
      </div>

      <div>
        <div id="site-scroll" onClick={() => window.scrollTo(0, 0)}>
          <i className="icon anm anm-arw-up" />
        </div>
        <div
          id="minicart-drawer"
          className="minicart-right-drawer offcanvas offcanvas-end"
          tabIndex={-1}
        >
          <div
            id="cartEmpty"
            className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-none"
          >
            <div className="minicart-header d-flex-center justify-content-between w-100">
              <h4 className="fs-6">Your cart (0 Items)</h4>
              <button
                className="close-cart border-0"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <i
                  className="icon anm anm-times-r"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Close"
                />
              </button>
            </div>
            <div className="cartEmpty-content mt-4">
              <i className="icon anm anm-cart-l fs-1 text-muted" />
              <p className="my-3">No Products in the Cart</p>
              <Link to="" className="btn btn-primary cart-btn">
                Continue shopping
              </Link>
            </div>
          </div>
          <div id="cart-drawer" className="block block-cart">
            <div className="minicart-header">
              <button
                className="close-cart border-0"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <i
                  className="icon anm anm-times-r"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Close"
                />
              </button>
              <h4 className="fs-6">Your cart (2 Items)</h4>
            </div>
            <div className="minicart-content">
              <ul className="m-0 clearfix">
                <li className="item d-flex justify-content-center align-items-center">
                  <Link className="product-image rounded-3" to="">
                    <img
                      className="blur-up lazyload"
                      data-src="/assets/images/products/cart-product-img1.jpg"
                      src="/assets/images/products/cart-product-img1.jpg"
                      alt="product"
                      title="Product"
                      width={120}
                      height={170}
                    />
                  </Link>
                  <div className="product-details">
                    <Link className="product-title" to="">
                      Women Sandals
                    </Link>
                    <div className="variant-cart my-2">Black / XL</div>
                    <div className="priceRow">
                      <div className="product-price">
                        <span className="price">₹54.00</span>
                      </div>
                    </div>
                  </div>
                  <div className="qtyDetail text-center">
                    <div className="qtyField">
                      <Link className="qtyBtn minus" to="#;">
                        <i className="icon anm anm-minus-r" />
                      </Link>
                      <input
                        type="text"
                        name="quantity"
                        defaultValue={1}
                        className="qty"
                      />
                      <Link className="qtyBtn plus" to="#;">
                        <i className="icon anm anm-plus-r" />
                      </Link>
                    </div>
                    <Link to="#" className="edit-i remove">
                      <i
                        className="icon anm anm-pencil-ar"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Edit"
                      />
                    </Link>
                    <Link to="#" className="remove">
                      <i
                        className="icon anm anm-times-r"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Remove"
                      />
                    </Link>
                  </div>
                </li>
                <li className="item d-flex justify-content-center align-items-center">
                  <Link className="product-image rounded-3" to="">
                    <img
                      className="blur-up lazyload"
                      data-src="/assets/images/products/cart-product-img2.jpg"
                      src="/assets/images/products/cart-product-img2.jpg"
                      alt="product"
                      title="Product"
                      width={120}
                      height={170}
                    />
                  </Link>
                  <div className="product-details">
                    <Link className="product-title" to="">
                      Sleeve Round T-Shirt
                    </Link>
                    <div className="variant-cart my-2">Yellow / M</div>
                    <div className="priceRow">
                      <div className="product-price">
                        <span className="price old-price">₹114.00</span>
                        <span className="price">₹99.00</span>
                      </div>
                    </div>
                  </div>
                  <div className="qtyDetail text-center">
                    <div className="qtyField">
                      <Link className="qtyBtn minus" to="#;">
                        <i className="icon anm anm-minus-r" />
                      </Link>
                      <input
                        type="text"
                        name="quantity"
                        defaultValue={1}
                        className="qty"
                      />
                      <Link className="qtyBtn plus" to="#;">
                        <i className="icon anm anm-plus-r" />
                      </Link>
                    </div>
                    <Link to="#" className="edit-i remove">
                      <i
                        className="icon anm anm-pencil-ar"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Edit"
                      />
                    </Link>
                    <Link to="#" className="remove">
                      <i
                        className="icon anm anm-times-r"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Remove"
                      />
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className="minicart-bottom">
              <div className="shipinfo">
                <div className="progress mb-2">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    50%
                  </div>
                </div>
                <div className="freeShipMsg">
                  <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                  Only{" "}
                  <span
                    className="money"
                    data-currency-usd="₹199.00"
                    data-currency="USD"
                  >
                    ₹199.00
                  </span>{" "}
                  away from
                  <b>Free Shipping</b>
                </div>
                <div className="freeShipMsg d-none">
                  <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                  Congrats! You are eligible for
                  <b>Free Shipping</b>
                </div>
              </div>
              <div className="subtotal clearfix my-3">
                <div className="totalInfo clearfix mb-1 d-none">
                  <span>Shipping:</span>
                  <span className="item product-price">₹10.00</span>
                </div>
                <div className="totalInfo clearfix mb-1 d-none">
                  <span>Tax:</span>
                  <span className="item product-price">₹0.00</span>
                </div>
                <div className="totalInfo clearfix">
                  <span>Total:</span>
                  <span className="item product-price">₹163.00</span>
                </div>
              </div>
              <div className="agree-check customCheckbox">
                <input
                  id="prTearm"
                  name="tearm"
                  type="checkbox"
                  defaultValue="tearm"
                  required
                />
                <label htmlFor="prTearm"> I agree with the </label>
                <Link to="#" className="ms-1 text-link">
                  Terms &amp; conditions
                </Link>
              </div>
              <div className="minicart-action d-flex mt-3">
                <Link
                  to=""
                  className="proceed-to-checkout btn btn-primary w-50 me-1"
                >
                  Check Out
                </Link>
                <Link to="" className="cart-btn btn btn-secondary w-50 ms-1">
                  View Cart
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="quickshop-modal modal fade"
          id="quickshop_modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <form
                  method="post"
                  action="#"
                  id="product-form-quickshop"
                  className="product-form align-items-center"
                >
                  <div className="row g-0 item mb-3">
                    <Link className="col-4 product-image" to="">
                      <img
                        className="blur-up lazyload"
                        data-src="/assets/images/products/addtocart-modal.jpg"
                        src="/assets/images/products/addtocart-modal.jpg"
                        alt="Product"
                        title="Product"
                        width={625}
                        height={800}
                      />
                    </Link>
                    <div className="col-8 product-details">
                      <div className="product-variant ps-3">
                        <Link className="product-title" to="">
                          Weave Hoodie Sweatshirt
                        </Link>
                        <div className="priceRow mt-2 mb-3">
                          <div className="product-price m-0">
                            <span className="old-price">₹114.00</span>
                            <span className="price">₹99.00</span>
                          </div>
                        </div>
                        <div className="qtyField">
                          <Link className="qtyBtn minus" to="#;">
                            <i className="icon anm anm-minus-r" />
                          </Link>
                          <input
                            type="text"
                            name="quantity"
                            defaultValue={1}
                            className="qty"
                          />
                          <Link className="qtyBtn plus" to="#;">
                            <i className="icon anm anm-plus-r" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="variants-clr swatches-image clearfix mb-3 swatch-0 option1"
                    data-option-index={0}
                  >
                    <label className="label d-flex justify-content-center">
                      Color:
                      <span className="slVariant ms-1 fw-bold">Black</span>
                    </label>
                    <ul className="swatches d-flex-justify-center pt-1 clearfix">
                      <li className="swatch large radius available active">
                        <img
                          src="/assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Blue"
                        />
                      </li>
                      <li className="swatch large radius available">
                        <img
                          src="/assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Black"
                        />
                      </li>
                      <li className="swatch large radius available">
                        <img
                          src="/assets/images/products/swatches/blue-red.jpg"
                          alt="image"
                          width={70}
                          height={70}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Pink"
                        />
                      </li>
                      <li className="swatch large radius available green">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Green"
                        />
                      </li>
                      <li className="swatch large radius soldout yellow">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Yellow"
                        />
                      </li>
                    </ul>
                  </div>
                  <div
                    className="variants-size swatches-size clearfix mb-4 swatch-1 option2"
                    data-option-index={1}
                  >
                    <label className="label d-flex justify-content-center">
                      Size:<span className="slVariant ms-1 fw-bold">S</span>
                    </label>
                    <ul className="size-swatches d-flex-justify-center pt-1 clearfix">
                      <li className="swatch large radius soldout">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XS"
                        >
                          XS
                        </span>
                      </li>
                      <li className="swatch large radius available active">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="S"
                        >
                          S
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="M"
                        >
                          M
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="L"
                        >
                          L
                        </span>
                      </li>
                      <li className="swatch large radius available">
                        <span
                          className="swatchLbl"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="XL"
                        >
                          XL
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="product-form-submit d-flex-justify-center">
                    <button
                      type="submit"
                      name="add"
                      className="btn product-cart-submit me-2"
                    >
                      <span>Add to cart</span>
                    </button>
                    <button
                      type="submit"
                      name="sold"
                      className="btn btn-secondary product-sold-out d-none"
                      disabled="disabled"
                    >
                      Sold out
                    </button>
                    <button
                      type="submit"
                      name="buy"
                      className="btn btn-secondary proceed-to-checkout"
                    >
                      Buy it now
                    </button>
                  </div>
                  <div className="text-center mt-3">
                    <Link className="text-link" to="">
                      View More Details
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="addtocart-modal modal fade"
          id="addtocart_modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <form
                  id="product-form-addtocart"
                  className="product-form align-items-center"
                >
                  <h3 className="title mb-3 text-success text-center">
                    Added to cart Successfully!
                  </h3>
                  {quickViewProduct !== "" &&
                    quickViewProduct.packSize.map((pack, packIndex) =>
                      pack.PsPrime === true ? (
                        <>
                          {pack.PackSizeOptions.map((packOption, optionIndex) =>
                            packOption.PsnPrime === true ? (
                              <>
                                <div className="row d-flex-center text-center">
                                  <div className="col-md-6">
                                    <Link className="product-image" to="">
                                      <img
                                        className="blur-up lazyload"
                                        data-src={pack.thumbnail}
                                        src={pack.thumbnail}
                                        alt="Product"
                                        title="Product"
                                        width={625}
                                        height={800}
                                      />
                                    </Link>
                                  </div>
                                  <div className="col-md-6 mt-3 mt-md-0">
                                    <div className="product-details">
                                      <Link className="product-title" to="">
                                        <Link
                                          to={`/product-info/${quickViewProduct.slugUrl}`}
                                        >
                                          {quickViewProduct.name}
                                        </Link>
                                      </Link>
                                      <p className="product-clr my-2 text-muted">
                                        Black / XL
                                      </p>
                                    </div>
                                    <div className="addcart-total rounded-5">
                                      <p className="product-items mb-2">
                                        There are <strong>1</strong> items in
                                        your cart
                                      </p>
                                      <p className="d-flex-justify-center">
                                        Total:{" "}
                                        <span className="price">
                                          ₹{packOption.Price}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="product-form-submit d-flex-justify-center">
                                      <span
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-outline-primary product-continue w-100"
                                      >
                                        Continue Shopping
                                      </span>
                                      <span
                                        onClick={() => navigate("/cart")}
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-secondary product-viewcart w-100 my-2 my-md-3"
                                      >
                                        View Cart
                                      </span>
                                      <span
                                        onClick={() => navigate("/check-out")}
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-primary product-checkout w-100"
                                      >
                                        Proceed to checkout
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div key={optionIndex}></div>
                              </>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <div key={packIndex}></div>
                        </>
                      )
                    )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="quickview-modal modal fade"
          id="quickview_modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                {quickViewProduct !== "" &&
                  quickViewProduct.packSize.map((pack, packIndex) =>
                    pack.PsPrime === true ? (
                      <>
                        <div className="row">
                          <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3 mb-md-0">
                            <div id="quickView" className="carousel slide">
                              <div className="carousel-inner">
                                {pack.slider.map((image, imageIndex) => (
                                  <div
                                    className="item carousel-item active"
                                    data-bs-slide-number={Number(imageIndex)}
                                  >
                                    <img
                                      className="blur-up lazyload"
                                      data-src={image}
                                      src={image}
                                      alt="product"
                                      title="Product"
                                      width={625}
                                      height={808}
                                    />
                                  </div>
                                ))}
                              </div>
                              {/* End Image Slide carousel items */}
                              {/* Thumbnail image */}
                              <div className="model-thumbnail-img">
                                {/* Thumbnail slide */}
                                <div className="carousel-indicators list-inline">
                                  {pack.slider.map((image, imageIndex) => (
                                    <div
                                      className="list-inline-item active"
                                      id="carousel-selector-0"
                                      data-bs-slide-to={Number(imageIndex)}
                                      data-bs-target="#quickView"
                                    >
                                      <img
                                        className="blur-up lazyload"
                                        data-src={image}
                                        src={image}
                                        alt="product"
                                        title="Product"
                                        width={625}
                                        height={808}
                                      />
                                    </div>
                                  ))}
                                </div>
                                <Link
                                  className="carousel-control-prev carousel-arrow rounded-1"
                                  to="#quickView"
                                  data-bs-target="#quickView"
                                  data-bs-slide="prev"
                                >
                                  <i className="icon anm anm-angle-left-r" />
                                </Link>
                                <Link
                                  className="carousel-control-next carousel-arrow rounded-1"
                                  to="#quickView"
                                  data-bs-target="#quickView"
                                  data-bs-slide="next"
                                >
                                  <i className="icon anm anm-angle-right-r" />
                                </Link>
                              </div>
                            </div>
                            <div className="text-center mt-3">
                              <Link to="" className="text-link">
                                View More Details
                              </Link>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                            <div className="product-arrow d-flex justify-content-between">
                              <h2 className="product-title">
                                {quickViewProduct.name}
                              </h2>
                            </div>
                            <div className="product-review d-flex mt-0 mb-2">
                              <div className="rating">
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star-o" />
                              </div>
                              <div className="reviews ms-2">
                                <Link to="#">6 Reviews</Link>
                              </div>
                            </div>
                            <div className="product-info">
                              <p className="product-vendor">
                                Vendor:
                                <span className="text">
                                  <Link to="#">Sparx</Link>
                                </span>
                              </p>
                              <p className="product-type">
                                Product Type:
                                <span className="text">
                                  {quickViewProduct.CategoryTag}
                                </span>
                              </p>
                              <p className="product-sku">
                                SKU:<span className="text">RF104456</span>
                              </p>
                            </div>
                            {pack.PackSizeOptions.map(
                              (packOption, optionIndex) =>
                                packOption.PsnPrime === true ? (
                                  <>
                                    <div className="pro-stockLbl my-2">
                                      <span className="d-flex-center stockLbl instock d-none">
                                        <i className="icon anm anm-check-cil" />
                                        <span> In stock</span>
                                      </span>
                                      <span className="d-flex-center stockLbl preorder d-none">
                                        <i className="icon anm anm-clock-r" />
                                        <span> Pre-order Now</span>
                                      </span>
                                      <span className="d-flex-center stockLbl outstock d-none">
                                        <i className="icon anm anm-times-cil" />{" "}
                                        <span>Sold out</span>
                                      </span>
                                      <span
                                        className="d-flex-center stockLbl lowstock"
                                        data-qty={15}
                                      >
                                        <i className="icon anm anm-exclamation-cir" />
                                        <span>
                                          {" "}
                                          Order now, Only{" "}
                                          <span className="items">
                                            {packOption.availablestock}
                                          </span>{" "}
                                          left!
                                        </span>
                                      </span>
                                    </div>
                                    <div className="product-price d-flex-center my-3">
                                      <span className="price old-price">
                                        ₹{packOption.Mrp}
                                      </span>
                                      <span className="price">
                                        ₹{packOption.Price}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <div key={optionIndex}></div>
                                  </>
                                )
                            )}
                            {/* <div className="sort-description">
                              The standard chunk of Lorem Ipsum used since the
                              1500s is reproduced below for those interested.
                            </div> */}
                            <form
                              method="post"
                              action="#"
                              id="product_form--option"
                              className="product-form"
                            >
                              <div className="product-options d-flex-wrap">
                                {/* <div
                                  className="product-item swatches-image w-100 mb-3 swatch-0 option1"
                                  data-option-index={0}
                                >
                                  <label className="label d-flex align-items-center">
                                    Color:
                                    <span className="slVariant ms-1 fw-bold">
                                      Blue
                                    </span>
                                  </label>
                                  <ul className="variants-clr swatches d-flex-center pt-1 clearfix">
                                    <li className="swatch large radius available active">
                                      <img
                                        src="/assets/images/products/swatches/blue-red.jpg"
                                        alt="image"
                                        width={70}
                                        height={70}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Blue"
                                      />
                                    </li>
                                    <li className="swatch large radius available">
                                      <img
                                        src="/assets/images/products/swatches/blue-red.jpg"
                                        alt="image"
                                        width={70}
                                        height={70}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Black"
                                      />
                                    </li>
                                    <li className="swatch large radius available">
                                      <img
                                        src="/assets/images/products/swatches/blue-red.jpg"
                                        alt="image"
                                        width={70}
                                        height={70}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Pink"
                                      />
                                    </li>
                                    <li className="swatch large radius available green">
                                      <span
                                        className="swatchLbl"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Green"
                                      />
                                    </li>
                                    <li className="swatch large radius soldout yellow">
                                      <span
                                        className="swatchLbl"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Yellow"
                                      />
                                    </li>
                                  </ul>
                                </div> */}
                                {/* <div
                                  className="product-item swatches-size w-100 mb-3 swatch-1 option2"
                                  data-option-index={1}
                                >
                                  <label className="label d-flex align-items-center">
                                    Size:
                                    <span className="slVariant ms-1 fw-bold">
                                      S
                                    </span>
                                  </label>
                                  <ul className="variants-size size-swatches d-flex-center pt-1 clearfix">
                                    <li className="swatch large radius soldout">
                                      <span
                                        className="swatchLbl"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="XS"
                                      >
                                        XS
                                      </span>
                                    </li>
                                    <li className="swatch large radius available active">
                                      <span
                                        className="swatchLbl"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="S"
                                      >
                                        S
                                      </span>
                                    </li>
                                    <li className="swatch large radius available">
                                      <span
                                        className="swatchLbl"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="M"
                                      >
                                        M
                                      </span>
                                    </li>
                                    <li className="swatch large radius available">
                                      <span
                                        className="swatchLbl"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="L"
                                      >
                                        L
                                      </span>
                                    </li>
                                    <li className="swatch large radius available">
                                      <span
                                        className="swatchLbl"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="XL"
                                      >
                                        XL
                                      </span>
                                    </li>
                                  </ul>
                                </div> */}
                                {/* <div className="product-action d-flex-wrap w-100 pt-1 mb-3 clearfix">
                                  <div className="quantity">
                                    <div className="qtyField rounded">
                                      <Link className="qtyBtn minus" to="#;">
                                        <i
                                          className="icon anm anm-minus-r"
                                          aria-hidden="true"
                                        />
                                      </Link>
                                      <input
                                        type="text"
                                        name="quantity"
                                        defaultValue={1}
                                        className="product-form__input qty"
                                      />
                                      <Link className="qtyBtn plus" to="#;">
                                        <i
                                          className="icon anm anm-plus-l"
                                          aria-hidden="true"
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="addtocart ms-3 fl-1">
                                    <button
                                      type="submit"
                                      name="add"
                                      className="btn product-cart-submit w-100"
                                    >
                                      <span>Add to cart</span>
                                    </button>
                                  </div>
                                </div> */}
                              </div>
                            </form>
                            {/* <div className="wishlist-btn d-flex-center">
                              <Link
                                className="add-wishlist d-flex-center me-3"
                                to="wishlist-style1.html"
                                title="Add to Wishlist"
                              >
                                <i className="icon anm anm-heart-l me-1" />{" "}
                                <span>Add to Wishlist</span>
                              </Link>
                              <Link
                                className="add-compare d-flex-center"
                                to=""
                                title="Add to Compare"
                              >
                                <i className="icon anm anm-random-r me-2" />{" "}
                                <span>Add to Compare</span>
                              </Link>
                            </div> */}
                            {/* Social Sharing */}
                            {/* <div className="social-sharing share-icon d-flex-center mx-0 mt-3">
                              <span className="sharing-lbl">Share :</span>
                              <Link
                                to="#"
                                className="d-flex-center btn btn-link btn--share share-facebook"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Share on Facebook"
                              >
                                <i className="icon anm anm-facebook-f" />
                                <span className="share-title d-none">
                                  Facebook
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="d-flex-center btn btn-link btn--share share-twitter"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Tweet on Twitter"
                              >
                                <i className="icon anm anm-twitter" />
                                <span className="share-title d-none">
                                  Tweet
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="d-flex-center btn btn-link btn--share share-pinterest"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Pin on Pinterest"
                              >
                                <i className="icon anm anm-pinterest-p" />{" "}
                                <span className="share-title d-none">
                                  Pin it
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="d-flex-center btn btn-link btn--share share-linkedin"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Share on Instagram"
                              >
                                <i className="icon anm anm-linkedin-in" />
                                <span className="share-title d-none">
                                  Instagram
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="d-flex-center btn btn-link btn--share share-whatsapp"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Share on WhatsApp"
                              >
                                <i className="icon anm anm-envelope-l" />
                                <span className="share-title d-none">
                                  WhatsApp
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="d-flex-center btn btn-link btn--share share-email"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Share by Email"
                              >
                                <i className="icon anm anm-whatsapp" />
                                <span className="share-title d-none">
                                  Email
                                </span>
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={packIndex}></div>
                      </>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="newsletter-modal style3 modal fade"
          // id="newsletter_modal"
          tabIndex={-1}
          aria-hidden="true"
          aria-modal="false"
          style={{ display: "none" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content border-0">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <div className="newsletter-wrap d-flex flex-column">
                  <div className="newsltr-img d-none d-sm-none d-md-block">
                    <img
                      className="rounded-bottom-0 blur-up lazyload"
                      data-src="/assets/images/newsletter/newsletter-s6.webp"
                      src="/assets/images/newsletter/newsletter-s6.webp"
                      alt="Join Our Newsletter Get 20% OFF First Order"
                      title="Join Our Newsletter Get 20% OFF First Order"
                      width={582}
                      height={202}
                    />
                  </div>
                  <div className="newsltr-text text-center">
                    <div className="wraptext mw-100">
                      <br />
                      <h2 className="title text-transform-none">
                        Join Our Newsletter <br />
                        Get 20% OFF First Order
                      </h2>
                      <br />

                      <br />
                      <p className="text">
                        Stay Informed! Monthly Tips, Tracks and Discount.
                      </p>
                      <form
                        action="#"
                        method="post"
                        className="mcNewsletter mb-3"
                      >
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control input-group-field newsletter-input"
                            name="email"
                            placeholder="Enter your email address..."
                            required
                          />
                          <button
                            type="submit"
                            className="input-group-btn btn btn-secondary newsletter-submit"
                            name="commit"
                          >
                            Subscribe
                          </button>
                        </div>
                      </form>
                      <ul className="list-inline social-icons d-inline-flex justify-content-center mb-3 w-100">
                        <li className="list-inline-item">
                          <Link
                            to="https://www.facebook.com/ewshopping"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Facebook"
                            target="_blank"
                          >
                            <i className="icon anm anm-facebook-f" />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Twitter"
                          >
                            <i className="icon anm anm-twitter" />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to="https://in.pinterest.com/EWShoppingPortal/"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Pinterest"
                            target="_blank"
                          >
                            <i className="icon anm anm-pinterest-p" />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Linkedin"
                          >
                            <i className="icon anm anm-linkedin-in" />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to="https://www.instagram.com/ewshoppingofficial/"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Instagram"
                            target="_blank"
                          >
                            <i className="icon anm anm-instagram" />
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Youtube"
                          >
                            <i className="icon anm anm-youtube" />
                          </Link>
                        </li>
                      </ul>
                      <div className="customCheckbox checkboxlink clearfix justify-content-center">
                        <input id="dontshow" name="newsPopup" type="checkbox" />
                        <label htmlFor="dontshow" className="mb-0">
                          Don't show this popup again
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React from "react";
import Hadear from "./layout/Hadear";
import Footer from "./layout/Footer";
import { Outlet } from "react-router-dom";

const MainLayOut = () => {
  return (
    <>
      <div style={{overflowX:"hidden"}}>
        <Hadear />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default MainLayOut;

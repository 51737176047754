import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Autoplay, Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

const FootwearThreeSlider = () => {
  const navigate = useNavigate();

  const { FootwearthreeSlider } = useSelector((store) => store.slider);
  const { categorytotal } = useSelector((store) => store.category);

  const [shoe1stImage, setshoe1stImage] = useState("");
  const [shoe2stImage, setshoe2stImage] = useState("");
  const [shoe3stImage, setshoe3stImage] = useState("");
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (categorytotal.length > 0) {
      const filterNewData = categorytotal.filter(
        (data) => data.superCategory === "Footwear"
      );
      setFilterData(filterNewData);
    }
  }, [categorytotal]);

  useEffect(() => {
    if (FootwearthreeSlider.length > 0) {
      const find1stImage = FootwearthreeSlider.find(
        (data) => data.position1 === true
      );
      const find2stImage = FootwearthreeSlider.find(
        (data) => data.position2 === true
      );
      const find3stImage = FootwearthreeSlider.find(
        (data) => data.position3 === true
      );

      setshoe1stImage(find1stImage);
      setshoe2stImage(find2stImage);
      setshoe3stImage(find3stImage);
    }
  }, [FootwearthreeSlider]);

  return (
    <>
      <section className="section collection-banners pb-0">
        <div className="container togettop blockin_mobile_version">
          <div className="collection-banner-grid">
            <div className="row sp-row">
              {shoe1stImage !== "" && (
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 collection-banner-item">
                  <div
                    className="collection-item sp-col"
                    onClick={() =>
                      navigate(`/category/${shoe1stImage.categoryUrl}`)
                    }
                  >
                    <a to="shop-left-sidebar.html" className="zoom-scal">
                      <div className="img">
                        <img
                          className="blur-up lazyload"
                          data-src={shoe1stImage.desktopImage}
                          src={shoe1stImage.desktopImage}
                          alt="Women Wear"
                          title="Women Wear"
                          width={645}
                          height={723}
                          style={{height:"500px"}}
                        />
                      </div>
                      <div className="details middle-right">
                        <div className="inner">
                          <p className="mb-2">{shoe1stImage.text}</p>
                          <h3 className="title">
                            {shoe1stImage.category} Wear
                          </h3>
                          <span className="btn btn-outline-secondary btn-md">
                            Shop Now
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )}
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 collection-banner-item">
                {shoe2stImage !== "" && (
                  <div
                    className="collection-item sp-col"
                    onClick={() =>
                      navigate(`/category/${shoe2stImage.categoryUrl}`)
                    }
                  >
                    <a to="shop-left-sidebar.html" className="zoom-scal">
                      <div className="img">
                        <img
                          className="blur-up lazyload"
                          data-src={shoe2stImage.desktopImage}
                          src={shoe2stImage.desktopImage}
                          alt="Mens Wear"
                          title="Mens Wear"
                          width={645}
                          height={344}
                          style={{height:"240px"}}
                        />
                      </div>
                      <div className="details middle-left" style={{width:"220px"}}>
                        <div className="inner">
                          <p className="mb-2">{shoe2stImage.text}</p>
                          <h3 className="title">
                            {shoe2stImage.category} Wear
                          </h3>
                          <span className="btn btn-outline-secondary btn-md">
                            Shop Now
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                )}
                {shoe3stImage !== "" && (
                  <div
                    className="collection-item sp-col"
                    onClick={() =>
                      navigate(`/category/${shoe3stImage.categoryUrl}`)
                    }
                  >
                    <a to="shop-left-sidebar.html" className="zoom-scal">
                      <div className="img">
                        <img
                          className="blur-up lazyload"
                          data-src={shoe3stImage.desktopImage}
                          src={shoe3stImage.desktopImage}
                          alt="Kids Wear"
                          title="Kids Wear"
                          width={645}
                          height={349}
                          style={{height:"240px"}}

                        />
                      </div>
                      <div className="details middle-right">
                        <div className="inner">
                          <p className="mb-2">{shoe3stImage.text}</p>
                          <h3 className="title">
                            {shoe3stImage.category} Wear
                          </h3>
                          <span className="btn btn-outline-secondary btn-md">
                            Shop Now
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mobile_version_swipperr">
          <section className="slideshow slideshow-wrapper">
            <div className="home-slideshow slick-arrow-dots">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
                style={{
                  "--swiper-pagination-color": "#FEBD69",
                }}
              >
                {filterData &&
                  filterData.map((slider, index) => (
                    <SwiperSlide key={index}>
                      <Link
                        className="slide"
                        to={`/category/${slider.slugUrl}`}
                      >
                        <div className="slideshow-wrap">
                          <picture>
                            <source
                              media="(max-width:767px)"
                              srcSet={slider.catBannerImage}
                              width={1150}
                              height={800}
                            />
                            <img
                              className="blur-up lazyload homebannerimgs homebannerimgs1"
                              srcSet={slider.catBannerImage}
                              alt="slideshow"
                              title
                              width={1920}
                              height={795}
                            />
                          </picture>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default FootwearThreeSlider;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  mainHomeSliderTotal: localStorage.getItem("mainHomeSliderTotal")
    ? JSON.parse(localStorage.getItem("mainHomeSliderTotal"))
    : [],
  mainHomeSliderLoading: true, //api has run or not 
  isMainHomeSliderAvailable: localStorage.getItem("mainHomeSliderTotal")
    ? true
    : false, // local storage

  threeSliderTotal: localStorage.getItem("threeSliderTotal")
    ? JSON.parse(localStorage.getItem("threeSliderTotal"))
    : [],
  clothingthreeSlider: localStorage.getItem("threeSliderTotal")
    ? JSON.parse(localStorage.getItem("threeSliderTotal")).filter((data) => data.superCategory === "Clothing")
    : [],
  ElectricalthreeSlider: localStorage.getItem("threeSliderTotal")
    ? JSON.parse(localStorage.getItem("threeSliderTotal")).filter((data) => data.superCategory === "Electronics")
    : [],
  FootwearthreeSlider: localStorage.getItem("threeSliderTotal")
    ? JSON.parse(localStorage.getItem("threeSliderTotal")).filter((data) => data.superCategory === "Footwear")
    : [],
  threeSliderLoading: true,
  isThreeSliderAvailable: localStorage.getItem("threeSliderTotal")
    ? true
    : false,
};

export const getMain_home_Slider = createAsyncThunk(
  "slider/getMain_home_Slider",
  async (thunkAPI) => {

    try {
      let url = `${Baseurl}/api/v1/mainhomeslider/all`;
      // if (isMainHomeSliderAvailable) {
      //    url = `${Baseurl1}/api/v1/mainhomeslider/all`;
      // } 

      const resp = await axios(url);
      return resp.data.mainHomeSliders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
export const get_three_Slider = createAsyncThunk(
  "slider/get_three_Slider",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/threeslider/all`;
      const resp = await axios(url);
      return resp.data.threeSliders;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const SliderSlice = createSlice({
  name: "slider",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMain_home_Slider.pending, (state) => {
        state.mainHomeSliderLoading = true;
      })
      .addCase(getMain_home_Slider.fulfilled, (state, action) => {
        state.mainHomeSliderTotal = action.payload;
        localStorage.setItem(
          "mainHomeSliderTotal",
          JSON.stringify(state.mainHomeSliderTotal)
        );
        state.mainHomeSliderLoading = false;
        state.isMainHomeSliderAvailable = true;
      })
      .addCase(getMain_home_Slider.rejected, (state) => {
        state.mainHomeSliderLoading = true;
      });
    builder
      .addCase(get_three_Slider.pending, (state) => {
        state.threeSliderLoading = true;
      })
      .addCase(get_three_Slider.fulfilled, (state, action) => {
        state.threeSliderTotal = action.payload;
        state.clothingthreeSlider = state.threeSliderTotal.filter((data) => data.superCategory === "Clothing");
        state.ElectricalthreeSlider = state.threeSliderTotal.filter((data) => data.superCategory === "Electronics");
        state.FootwearthreeSlider = state.threeSliderTotal.filter((data) => data.superCategory === "Footwear");
        localStorage.setItem(
          "threeSliderTotal",
          JSON.stringify(state.threeSliderTotal)
        );
        state.threeSliderLoading = false;
        state.isThreeSliderAvailable = true;
      })
      .addCase(get_three_Slider.rejected, (state) => {
        state.threeSliderLoading = true;
      });
  },
});
export const { } = SliderSlice.actions;
export default SliderSlice.reducer;

import React from 'react'

const FootwearSupercat = ({ productifo }) => {
  return (
    <tbody>
      {productifo.footwear?.color !== "" && (
        <tr>
          <th>color</th>
          <td>{productifo.footwear?.color}</td>
        </tr>
      )}
      {productifo.footwear?.outer_material !== "" && (
        <tr>
          <th>outer_material</th>
          <td>{productifo.footwear?.outer_material}</td>
        </tr>
      )}
      {productifo.footwear?.model_name !== "" && (
        <tr>
          <th>model_name</th>
          <td>{productifo.footwear?.model_name}</td>
        </tr>
      )}
      {productifo.footwear?.ideal_for !== "" && (
        <tr>
          <th>ideal_for</th>
          <td>{productifo.footwear?.ideal_for}</td>
        </tr>
      )}
      {productifo.footwear?.occassion !== "" && (
        <tr>
          <th>occassion</th>
          <td>{productifo.footwear?.occassion}</td>
        </tr>
      )}
      {productifo.footwear?.type_of_sports !== "" && (
        <tr>
          <th>type_of_sports</th>
          <td>{productifo.footwear?.type_of_sports}</td>
        </tr>
      )}
      {productifo.footwear?.net_quantity !== "" && (
        <tr>
          <th>net_quantity</th>
          <td>{productifo.footwear?.net_quantity}</td>
        </tr>
      )}
      {productifo.footwear?.sale_material !== "" && (
        <tr>
          <th>sale_material</th>
          <td>{productifo.footwear?.sale_material}</td>
        </tr>
      )}
      {productifo.footwear?.closure !== "" && (
        <tr>
          <th>closure</th>
          <td>{productifo.footwear?.closure}</td>
        </tr>
      )}
      {productifo.footwear?.weight !== "" && (
        <tr>
          <th>weight</th>
          <td>{productifo.footwear?.weight}</td>
        </tr>
      )}
      {productifo.footwear?.upper_pattern !== "" && (
        <tr>
          <th>upper_pattern</th>
          <td>{productifo.footwear?.upper_pattern}</td>
        </tr>
      )}
      {productifo.footwear?.sales_package !== "" && (
        <tr>
          <th>sales_package</th>
          <td>{productifo.footwear?.sales_package}</td>
        </tr>
      )}
      {productifo.footwear?.pack_of !== "" && (
        <tr>
          <th>pack_of</th>
          <td>{productifo.footwear?.pack_of}</td>
        </tr>
      )}
      {productifo.footwear?.technology_used !== "" && (
        <tr>
          <th>technology_used</th>
          <td>{productifo.footwear?.technology_used}</td>
        </tr>
      )}
      {productifo.footwear?.season !== "" && (
        <tr>
          <th>season</th>
          <td>{productifo.footwear?.season}</td>
        </tr>
      )}
      {productifo.footwear?.care_instruction !== "" && (
        <tr>
          <th>care_instruction</th>
          <td>{productifo.footwear?.care_instruction}</td>
        </tr>
      )}
      {productifo.footwear?.type_of_causual !== "" && (
        <tr>
          <th>type_of_causual</th>
          <td>{productifo.footwear?.type_of_causual}</td>
        </tr>
      )}
      {productifo.footwear?.tip_shape !== "" && (
        <tr>
          <th>tip_shape</th>
          <td>{productifo.footwear?.tip_shape}</td>
        </tr>
      )}
      {productifo.footwear?.type_of_formals !== "" && (
        <tr>
          <th>type_of_formals</th>
          <td>{productifo.footwear?.type_of_formals}</td>
        </tr>
      )}
      {productifo.footwear?.type !== "" && (
        <tr>
          <th>type</th>
          <td>{productifo.footwear?.type}</td>
        </tr>
      )}
      {productifo.footwear?.type_of_flats !== "" && (
        <tr>
          <th>type_of_flats</th>
          <td>{productifo.footwear?.type_of_flats}</td>
        </tr>
      )}
      {productifo.footwear?.type_of_heels !== "" && (
        <tr>
          <th>type_of_heels</th>
          <td>{productifo.footwear?.type_of_heels}</td>
        </tr>
      )}
      {productifo.footwear?.length !== "" && (
        <tr>
          <th>length</th>
          <td>{productifo.footwear?.length}</td>
        </tr>
      )}
      {productifo.footwear?.number_of_pairs !== "" && (
        <tr>
          <th>number_of_pairs</th>
          <td>{productifo.footwear?.number_of_pairs}</td>
        </tr>
      )}
      {productifo.footwear?.brand !== "" && (
        <tr>
          <th>brand</th>
          <td>{productifo.footwear?.brand}</td>
        </tr>
      )}
      {productifo.footwear?.style_code !== "" && (
        <tr>
          <th>style_code</th>
          <td>{productifo.footwear?.style_code}</td>
        </tr>
      )}
      {productifo.footwear?.size !== "" && (
        <tr>
          <th>size</th>
          <td>{productifo.footwear?.size}</td>
        </tr>
      )}
      {productifo.footwear?.brand_color !== "" && (
        <tr>
          <th>brand_color</th>
          <td>{productifo.footwear?.brand_color}</td>
        </tr>
      )}
      {productifo.footwear?.closure_type !== "" && (
        <tr>
          <th>closure_type</th>
          <td>{productifo.footwear?.closure_type}</td>
        </tr>
      )}
      {productifo.footwear?.base_material !== "" && (
        <tr>
          <th>base_material</th>
          <td>{productifo.footwear?.base_material}</td>
        </tr>
      )}
      {productifo.footwear?.strap_material !== "" && (
        <tr>
          <th>strap_material</th>
          <td>{productifo.footwear?.strap_material}</td>
        </tr>
      )}
      {productifo.footwear?.primary_color !== "" && (
        <tr>
          <th>primary_color</th>
          <td>{productifo.footwear?.primary_color}</td>
        </tr>
      )}
      {productifo.footwear?.sub_type !== "" && (
        <tr>
          <th>sub_type</th>
          <td>{productifo.footwear?.sub_type}</td>
        </tr>
      )}
      {productifo.footwear?.character !== "" && (
        <tr>
          <th>character</th>
          <td>{productifo.footwear?.character}</td>
        </tr>
      )}
      {productifo.footwear?.heel_design !== "" && (
        <tr>
          <th>heel_design</th>
          <td>{productifo.footwear?.heel_design}</td>
        </tr>
      )}
      {productifo.footwear?.warranty_summary !== "" && (
        <tr>
          <th>warranty_summary</th>
          <td>{productifo.footwear?.warranty_summary}</td>
        </tr>
      )}
      {productifo.footwear?.insole_material !== "" && (
        <tr>
          <th>insole_material</th>
          <td>{productifo.footwear?.insole_material}</td>
        </tr>
      )}
      {productifo.footwear?.inner_lining !== "" && (
        <tr>
          <th>inner_lining</th>
          <td>{productifo.footwear?.inner_lining}</td>
        </tr>
      )}
      {productifo.footwear?.removable_insole !== "" && (
        <tr>
          <th>removable_insole</th>
          <td>{productifo.footwear?.removable_insole}</td>
        </tr>
      )}
    </tbody>
  )
}

export default FootwearSupercat

import React from 'react'

const BeautySupercategory = ({ productifo }) => {
  return (
    <tbody >
      {productifo.beauty?.skin_type !== "" && (
        <tr>
          <th>skin type</th>
          <td>{productifo.beauty?.skin_type}</td>
        </tr>
      )}
      {productifo.beauty?.pack_size !== "" && (
        <tr>
          <th>pack size</th>
          <td>{productifo.beauty?.pack_size}</td>
        </tr>
      )}
      {productifo.beauty?.pack_of !== "" && (
        <tr>
          <th>pack of</th>
          <td>{productifo.beauty?.pack_of}</td>
        </tr>
      )}
      {productifo.beauty?.gender !== "" && (
        <tr>
          <th>Gender</th>
          <td>{productifo.beauty?.gender}</td>
        </tr>
      )}
    </tbody>
  )
}

export default BeautySupercategory

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Toys_subcategory = () => {
  const { Toys_games_subcategory } = useSelector(
    (store) => store.subCategories
  );

  const navigate = useNavigate();

  return (
    <>
      <div className="mendextversion">
        {Toys_games_subcategory.length > 0 && (
          <>
            <div className="section home-instagram">
              <div className="container totaketop">
                <div className="section-header">
                  {/* <p className="mb-2 mt-0">Shop by category</p> */}
                  <h2
                    // style={{
                    //   fontFamily: "Ga Maamli,sans-serif",
                    //   color: "#315999",
                    //   fontSize: "26px",
                    // }}
                    className="TitleHeading"

                  >
                    Popular Collections in Toys & games
                  </h2>
                </div>
                {/*Instagram grid*/}
                <div className="instagram-section instagram-grid">
                  <div className="row sp-row row-cols-xl-5 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2">
                    {Toys_games_subcategory &&
                      Toys_games_subcategory.map((showData, index) => (
                        // <div className="instagram-item sp-col" key={index}>
                        //   <Link
                        //     to={`/shop/${showData.slugUrl}`}
                        //     className="zoom-scal"
                        //   >
                        //     <img
                        //       className="blur-up lazyload image_positions"
                        //       src={showData.desktopImage}
                        //       onError={({ currentTarget }) => {
                        //         currentTarget.onerror = null; // prevents looping
                        //         currentTarget.src =
                        //           "assets/images/collection/sub-collection3.jpg";
                        //       }}
                        //       data-src={showData.desktopImage}
                        //       alt="image"
                        //       width={310}
                        //       height={310}
                        //     />
                        //     <div className="cat_position_div">
                        //       <span>{showData.name}</span>
                        //     </div>
                        //     {/* <span className="ins-icon d-flex-justify-center">
                        //   <i className="icon anm anm-instagram" />
                        // </span> */}
                        //   </Link>
                        // </div>
                        <div className="category-item zoomscal-hov" key={index}>
                          <Link
                            to={`/shop/${showData.slugUrl}`}
                            className="category-link clr-none"
                          >
                            <div className="zoom-scal zoom-scal-nopb rounded-3">
                              <img
                                className="blur-up lazyload"
                                src={showData.desktopImage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "/assets/images/collection/sub-collection3.jpg";
                                }}
                                alt={showData.name}
                                title={showData.name}
                                width={365}
                                height={365}
                              />
                            </div>
                            <div className="details mt-3 text-center detailsSwipper">
                              <h4 className="category-title mb-0">
                                {showData.name}
                              </h4>
                              {/* <p className="counts">
                          {showData.product_Length} Products
                        </p> */}
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <section className="mensmobileversion">
        {Toys_games_subcategory.length > 0 && (
          <section className="section collection-slider pb-0">
            <div className="container">
              <div className="section-header">
                {/* <p className="mb-2 mt-0">Shop by category</p> */}
                <h2
                  // style={{
                  //   fontFamily: "Ga Maamli,sans-serif",
                  //   color: "#315999",
                  //   fontSize: "26px",
                  // }}
                  className="TitleHeading"
                >
                  Collections in Toys & games
                </h2>
              </div>
              <div className="collection-slider-5items gp15 arwOut5 hov-arrow">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    "@0.75": {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    "@1.00": {
                      slidesPerView: 6,
                      spaceBetween: 25,
                    },
                    "@1.50": {
                      slidesPerView: 6,
                      spaceBetween: 25,
                    },
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                  style={{ marginTop: "-26px" }}
                >
                  {Toys_games_subcategory &&
                    Toys_games_subcategory.map((showData, index) => (
                      <SwiperSlide key={index}>
                        <div className="category-item zoomscal-hov">
                          <Link
                            to={`/shop/${showData.slugUrl}`}
                            className="category-link clr-none"
                          >
                            <div className="zoom-scal zoom-scal-nopb rounded-3">
                              <img
                                className="blur-up lazyload"
                                src={showData.desktopImage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src =
                                    "/assets/images/collection/sub-collection3.jpg";
                                }}
                                alt= {showData.name}
                                title= {showData.name}
                                width={365}
                                height={365}
                              />
                            </div>
                            <div className="details mt-3 text-center detailsSwipper">
                              <h4 className="category-title mb-0">
                                {showData.name}
                              </h4>
                            </div>
                          </Link>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </section>
        )}
      </section>
    </>
  );
};

export default Toys_subcategory;

import React from "react";
import Kids from "./filter_components/Kids";
import Men from "./filter_components/Men";
import Women from "./filter_components/Women";
import TV_HomeEntertainment from "./filter_components/TV_HomeEntertainment";
import Mobile_Accessories from "./filter_components/Mobile_Accessories";
import Smart_Technology from "./filter_components/Smart_Technology";
import LaptopsAccessories from "./filter_components/LaptopsAccessories";
import HomeAppliances from "./filter_components/HomeAppliances";
import MenFootwear from "./filter_components/MenFootwear";
import WomenFootwear from "./filter_components/WomenFootwear";
import KidFootwear from "./filter_components/KidFootwear";
import Toys_Games from "./filter_components/Toys_Games";
import WomenBeauty from "./filter_components/WomenBeauty";
import HomeDecore from "./filter_components/HomeDecore";

const MainFilter = ({
  current_category,
  filterSelectedCloseArray,
  selectCloserFilterPress,
  category_products,
  getfilt_Prod,
  filtered_products,
  selected_category_tag,
}) => {

  
  const renderSwitch = (param) => {
    switch (param) {
      case "Kids":
        return (
          <Kids
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Men":
        return (
          <Men
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Women":
        return (
          <Women
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "TV & Home Entertainment":
        return (
          <TV_HomeEntertainment
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Mobile & Accessories":
        return (
          <Mobile_Accessories
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Smart Technology":
        return (
          <Smart_Technology
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Laptops & Accessories":
        return (
          <LaptopsAccessories
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Home Appliances":
        return (
          <HomeAppliances
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Men's Footwear":
        return (
          <MenFootwear
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Women's Footwear":
        return (
          <WomenFootwear
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Kid's Footwear":
        return (
          <KidFootwear
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Toys & Games":
        return (
          <Toys_Games
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Women's Beauty":
        return (
          <WomenBeauty
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      case "Home Decore":
        return (
          <HomeDecore
            current_category={current_category}
            filterSelectedCloseArray={filterSelectedCloseArray}
            selectCloserFilterPress={selectCloserFilterPress}
            category_products={category_products}
            getfilt_Prod={getfilt_Prod}
            filtered_products={filtered_products}
            selected_category_tag={selected_category_tag}
          />
        );
      // default:
      //   return <Login />;
      //   break;
    }
  };

  return <>{renderSwitch(current_category.name)}</>;
};

export default MainFilter;

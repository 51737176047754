import React from "react";
import { useSelector } from "react-redux";
import Address from "./Address";
import Ordersummery from "./Ordersummery";
import PlaceOrder from "./PlaceOrder";

const Mobile_main_checkout = () => {
  const { checkout_process_steps } = useSelector(
    (state) => state.Athentication
  );

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return <Address />;
      case 1:
        return <Ordersummery />;
      // case 2:
      //   return <PlaceOrder />;
      default:
        return <Address />;
        break;
    }
  };

  return <>{renderSwitch(checkout_process_steps)}</>;
};

export default Mobile_main_checkout;
